import { Box, Grid, TextField, Button, Stack, FormControlLabel, Typography, Checkbox } from "@mui/material"
import AddressAutocomplete from "../../../components/AddressAutocomplete";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

const Form = ({onCancel, isNew, formValues, handleChange, setAddForm, onSave, onDelete, open}: {onCancel: ()=> void; isNew: boolean; formValues: any; handleChange: any; setAddForm: any; onSave: any; onDelete?: any; open?: boolean}) => {
    return <Box sx={open ? {}: {visibility: "hidden", height: "0px", overflow: "hidden"}}>
    <Grid container spacing={1}>
        <Grid item xs={12}>
            <TextField
                label="Name"
                variant="standard"
                fullWidth
                sx={{
                    '& .MuiInputBase-input': {
                        fontSize: 16
                    },
                    '& .MuiInputLabel-root': {
                        fontSize: 16
                    },
                }}
                value={formValues.name}
                onChange={(e)=> handleChange(e.target.value, "name")}
            />
        </Grid>
        <Grid item xs={12}>
            {/* <TextField
                label="Address"
                variant="standard"
                fullWidth
                sx={{
                    '& .MuiInputBase-input': {
                        fontSize: 16
                    },
                    '& .MuiInputLabel-root': {
                        fontSize: 16
                    },
                }}
                value={formValues.address1}
                onChange={(e)=> handleChange(e.target.value, "address1")}
            /> */}
            <AddressAutocomplete
                variant="standard"
                sx={{
                    '& .MuiInputBase-input': {
                        fontSize: 16
                    },
                    '& .MuiInputLabel-root': {
                        fontSize: 16
                    },
                }}
                onPlaceSelect={(place: any)=> {
                    setAddForm({
                        ...formValues, 
                        address1: place?.address,
                        city: place?.city,
                        county: place?.county,
                        country: place?.country,
                        postalCode: place?.postalCode,
                        stateProvince: place?.state
                    })
                }}
            />
        </Grid>
        {/* <Grid item xs={12}>
            <TextField
                label="Address 2"
                variant="standard"
                fullWidth
                sx={{
                    '& .MuiInputBase-input': {
                        fontSize: 16
                    },
                    '& .MuiInputLabel-root': {
                        fontSize: 16
                    },
                }}
                value={formValues.address2}
                onChange={(e)=> handleChange(e.target.value, "address2")}
            />
        </Grid> */}
        <Grid container spacing={{lg: 10, md: 3}} item xs={12}>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                    label="City"
                    variant="standard"
                    fullWidth
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: 16
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: 16
                        },
                    }}
                    value={formValues.city}
                    onChange={(e)=> handleChange(e.target.value, "city")}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                    label="State"
                    variant="standard"
                    fullWidth
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: 16
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: 16
                        },
                    }}
                    value={formValues.stateProvince}
                    onChange={(e)=> handleChange(e.target.value, "stateProvince")}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                    label="Postal Code"
                    variant="standard"
                    fullWidth
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: 16
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: 16
                        },
                    }}
                    value={formValues.postalCode}
                    onChange={(e)=> handleChange(e.target.value, "postalCode")}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControlLabel
                        label={<>
                            <Typography sx={{ 'p':{
                                fontSize: '16px !important',
                            }}}>
                                Default
                            </Typography>
                        </>}
                        control={
                            <Checkbox
                                color="secondary"
                                checked={formValues.isDefault}
                                size='small'
                                icon={<CheckBoxOutlineBlank  />}
                                checkedIcon={<CheckBox />}
                                onChange={()=> {handleChange(!formValues.isDefault, "isDefault")}}
                            />
                        }
                    />
            </Grid>
        </Grid>
    </Grid>
    <Stack direction={'row-reverse'} alignItems={'center'} justifyContent={'space-between'} sx={{mt: 4}}>
                    <Button 
                    onClick={onSave}
                    disabled={formValues.name==="" || formValues.address1==="" || formValues.city==="" || formValues.stateProvince==="" || formValues.postalCode===""}
                    sx={{
                        width: 75,
                        padding: 0,
                        paddingTop: 0.3,
                        border: (theme) => `solid 1px ${theme.palette.secondary.main}`,
                        color: (theme) => theme.palette.secondary.main,
                        fontSize: '16px !important',
                        borderRadius: '5px',
                        height: '30px'
                    }}>Save</Button>
                    {!isNew && <Button 
                    onClick={onDelete}
                    sx={{
                        width: 75,
                        padding: 0,
                        paddingTop: 0.3,
                        border: (theme) => `solid 1px ${theme.palette.secondary.main}`,
                        color: (theme) => theme.palette.secondary.main,
                        fontSize: '16px !important',
                        borderRadius: '5px',
                        height: '30px'
                    }}>Delete</Button>}
                    <Button sx={{
                        width: 75,
                        padding: 0,
                        paddingTop: 0.3,
                        border: (theme) => `solid 1px ${theme.palette.secondary.main}`,
                        color: (theme) => theme.palette.secondary.main,
                        fontSize: '16px !important',
                        borderRadius: '5px',
                        height: '30px'
                    }}
                    onClick={onCancel}
                    >Cancel</Button>                 
    </Stack>
</Box>
}

export default Form
import { Box, Button, Divider, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";

const SignIn = ({handleClickSignin}: {handleClickSignin: () => void}) => {
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const handleSubmit = (e: any)=> {
        e.preventDefault();
        console.log(email, password, "EMAIL and PASSWORD")
    }

    return <Box>
    <Typography variant="h6" sx={{background: "#ffc700", borderRadius: 1, pt: 1, px: 4, width: "max-content", margin: "0px auto"}}>
        SIGN-UP
    </Typography>
    <Stack component={'form'} sx={{mt: 3}} onSubmit={handleSubmit} spacing={3}>
        <Box>
          <Typography variant="subtitle2">First Name:</Typography>
          <TextField variant="outlined" value={firstName} onChange={(e)=> setFirstName(e.target.value)} size="small" type="text" required fullWidth />
        </Box>
        <Box>
          <Typography variant="subtitle2">Last Name:</Typography>
          <TextField variant="outlined" value={lastName} onChange={(e)=> setLastName(e.target.value)} size="small" type="text" required fullWidth />
        </Box>
        <Box>
          <Typography variant="subtitle2">Email:</Typography>
          <TextField variant="outlined" value={email} onChange={(e)=> setEmail(e.target.value)} size="small" type="email" required fullWidth />
        </Box>
        <Box>
          <Typography variant="subtitle2">Password:</Typography>
          <TextField variant="outlined" value={password} onChange={(e)=> setPassword(e.target.value)} size="small" type="password" required fullWidth />
        </Box>
        <Box>
          <Typography variant="subtitle2">Confirm Password:</Typography>
          <TextField variant="outlined" value={confirmPassword} onChange={(e)=> setConfirmPassword(e.target.value)} size="small" type="password" required fullWidth />
        </Box>
        <Box sx={{textAlign: 'center'}}>
        <Button
            variant="contained"
            color="secondary"
            style={{ borderRadius: '4px' }}
            type="submit"
            fullWidth
        >
           SUBMIT
        </Button>
        <Stack sx={{mt: 2}} direction={'row'} alignItems={'center'}><Divider sx={{flex: 1}} /><Typography textAlign={'center'} variant="caption">OR</Typography><Divider sx={{flex: 1}} /></Stack>
        <Stack direction="row" alignItems={'center'} justifyContent={'center'}><Typography color="#000" variant="caption">Already have an account? </Typography><Button sx={{color: "#ffc700", textTransform: 'initial', width: 50}} size="small" fullWidth onClick={handleClickSignin}>Signin</Button></Stack>
        </Box>
    </Stack>
    </Box>
}

export default SignIn;
import { Backdrop, Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import Form from "./form";
import Address from "./address";
import { AppState } from "../../../state/AppState";
import { bindActionCreators, Dispatch } from 'redux';
import { createNewAddress } from "../../../store/actions/Contact.Actions";
import { connect } from "react-redux";

const ManageAddress = ({addresses, create, onSuccess}: any) => {
    const [isCreatingNewAddress, setIsCreatingNewAddress] = useState<boolean>(false)
    const [addNewVisible, setAddNewVisible] = useState<boolean>(false);
    const [addForm, setAddForm] = useState<any>({
        name: "",
        address1: "",
        city: "",
        stateProvince: "",
        county: "",
        postalCode: "",
        isDefault: false
    })

    const onCancel = ()=> {
        setAddForm({
            ...addForm,
            name: "",
            address1: "",
            county: "",
            city: "",
            stateProvince: "",
            postalCode: "",
            isDefault: addresses?.length > 0 ? false : true
        })
        setAddNewVisible(false)
    }

    const onSave = ()=> {
        setIsCreatingNewAddress(true);
        create([{...addForm, country: "United States"}]).then((res: any)=> {
            setIsCreatingNewAddress(false);
            setAddForm({
                ...addForm,
                name: "",
                address1: "",
                county:"",
                city: "",
                stateProvince: "",
                postalCode: "",
            })
            setAddNewVisible(false)
            onSuccess()
        }).catch((err: any)=> {
            setIsCreatingNewAddress(false)
        })
    }

    const ManageAddressComp = (
        <>
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '40px 10%',
            flexDirection: 'column',
            width: '80%',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row-reverse'
            }}>
                <Button sx={{color: (theme) => theme.palette.secondary.main}} onClick={()=> {
                    setAddForm({...addForm, isDefault: addresses?.length > 0 ? false : true})
                    setAddNewVisible(true)
                }}><AddIcon /><span style={{height: '33px'}} >Add new address</span></Button>
            </Box>
            <Form 
                open={addNewVisible}
                isNew 
                formValues={addForm} 
                onCancel={onCancel} 
                setAddForm={setAddForm}
                handleChange={(value: any, key: any)=> setAddForm({...addForm, [key]: value})}
                onSave={onSave} 
            />
            <Box sx={{mt: 5}}>
                {addresses?.length===0 ? <Typography sx={{fontSize: "16px !important", textAlign: 'center', my: 5}}>No Saved Address!</Typography> : 
                <Stack spacing={3}>
                    {addresses?.map((address: any) => {
                        return <Address address={address} key={address?.id} onSuccess={onSuccess} />
                    })}
                </Stack>}
            </Box>
        </Box>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isCreatingNewAddress}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
        </>
    )

    return addresses ? <>
        {ManageAddressComp}
    </> : <Stack justifyContent={'center'} alignItems={'center'}>
    <CircularProgress color="secondary" />
    </Stack>
}

const mapStateToProps = (state: AppState) => ({

})

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({
       create: createNewAddress
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManageAddress);
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Theme,
  createStyles
} from '@mui/material/styles';
import { bindActionCreators, Dispatch } from 'redux';
import * as AppActionCreators from '../../store/actions/App.Actions';
// import { withRouter } from "react-router-dom";
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SimpleReactValidator from 'simple-react-validator';
import { AppState } from "../../state/AppState";
import * as LocalStorage from '../../utilities/LocalStorage';
import { WithStyles, withStyles } from "@mui/styles";
import LockIcon from '@mui/icons-material/Lock';
// import GoogleLogin from "react-google-login";  need to remove this dependency 
// import FacebookLogin from 'react-facebook-login';  need to remove this dependency 

interface ISignInProps extends WithStyles<typeof styles> { 
  style?: any;
  root?: any;
  login: (body: string) => any;
  history?: any;
  user: any;
  loginError: string;
  handleOpenSignUpModel: () => void;
  handleCloseSignInModel: () => void;
  handleCloseSignUpModel: () => void;
}
interface IPageState {
  email: string;
  password: string;
  errorCodeStatus: number | null;
}
const styles = (theme: Theme) => createStyles({
  headerContainer: {
    textAlign: 'center',
  },

  marginBottom5: {
    marginBottom: '5px',
  },
  avatar: {
    display: 'inline-flex',
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  forgotPassword: {
    textAlign: 'right',
    padding: '12px'
  },
  buttonmargin: {
    marginBottom: '10px',
    marginTop: '10px',
  },
  linkText: {
    color: '#0b22c3',
    cursor: 'pointer'
  },
  textCenter: {
    textAlign: 'center'
  },
  rememberText: {
    fontSize: '12px',
  },
});

class SignIn extends Component<ISignInProps, IPageState> {
  public validator: SimpleReactValidator = new SimpleReactValidator();
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorCodeStatus: null,
    }
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }
  public handleLogin = async (event: any) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const { email, password } = this.state;
      const pathname: string = window.location ? window.location.pathname : '';
      LocalStorage.save(LocalStorage.keys.redirectPathname, pathname);

      // const userManager = customerGrantType(email, password, true);
      // userManager.signinRedirect();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  public onhandleChange = (event: any, type: string) => {
    if (type === 'email') {
      this.setState({ email: event.target.value })
    } else if (type === 'password') {
      this.setState({ password: event.target.value })
    }
    this.setState({ errorCodeStatus: null });
  }

  public onclickClose = () => {
    this.props.handleCloseSignInModel();
    this.props.handleCloseSignUpModel();
  }

  public openSignUpModel = () => {
    this.props.handleCloseSignInModel();
    this.props.handleOpenSignUpModel();
  }

  public render() {
    const { classes, style, root, loginError } = this.props;
    const { email, password, errorCodeStatus } = this.state;
    const forgotPasswordPath: string = `${(window as any).env.REACT_APP_PORTAL}/Account/Account/ForgotPassword`;

    this.validator.purgeFields();
    return (
      <div className={root}>
        <Container component="main" maxWidth="xs" className={style}>
          <CssBaseline />
          <Grid container={true}>
            <Grid item={true} xs={12} sm={12} className={classes.headerContainer}>
              <Avatar className={classes.avatar}>
                <LockIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={12}>
              <Grid container={true}>
                <Grid item={true} xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    onChange={(e: any) => this.onhandleChange(e, 'email')}
                    onBlur={() => this.validator.showMessageFor('email')}
                    id="email"
                    value={email}
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                  {this.validator.message('email', email, 'required|email')}
                </Grid>
                <Grid item={true} xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    value={password}
                    name="password"
                    label="Password"
                    onChange={(e) => this.onhandleChange(e, 'password')}
                    onBlur={() => this.validator.message('password', typeof password === "string" ? password : password,
                      'required'
                    )}
                    type="password"
                    autoComplete="current-password"
                    id="password"
                  />
                  {this.validator.message('password', password, 'required')}
                  {errorCodeStatus === 500 || !!loginError ? <Typography className="srv-validation-message">Email or Password is incorrect.</Typography> : ''}
                </Grid>
                <Grid item={true} xs={12} sm={12} className={classes.textCenter}>
                  <Link
                    href={forgotPasswordPath}
                    className={classes.linkText}
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item={true} xs={12} sm={12} className={classes.buttonmargin}>
                  <Button
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    style={{ textTransform: 'capitalize' }}
                    onClick={(event: any) => this.handleLogin(event)}
                    color="primary"
                  >
                    Sign In
                  </Button>
                </Grid>
                <Grid item={true} xs={12} sm={12} className={classes.buttonmargin}>
                  <Button
                    type="submit"
                    style={{ textTransform: 'capitalize' }}
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    onClick={this.onclickClose}
                  >
                    continue as guest
                  </Button>
                </Grid>
              </Grid>
              <Grid item={true} xs={12} sm={12} style={{ marginTop: '20px', marginBottom: '20px' }} className={classes.textCenter}>
                <Typography onClick={() => this.openSignUpModel()} className={classes.linkText}>
                  Don't have an account? Sign Up
                </Typography>
              </Grid>
            </Grid>
          </Grid>

        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state?.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    handleCloseSignInModel: AppActionCreators.handleCloseSignInModel,
    handleOpenSignUpModel: AppActionCreators.handleOpenSignUpModel,
    handleCloseSignUpModel: AppActionCreators.handleCloseSignUpModel,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignIn));

import * as React from 'react';
import { connect } from 'react-redux';
import { lighten } from '@mui/material/styles';
import { withStyles } from '@mui/styles';

import {
    Box,
    CircularProgress,
    Stack,
    Theme, Typography, createStyles,
} from '@mui/material';
import IMenuCategory from '../models/IMenuCategory';

const styles = (theme: Theme) => createStyles({
    gridList: {
        display: 'flex',
        scrollBehavior: 'smooth',
        flexWrap: 'nowrap',
        'overflowX': 'hidden',
        position: 'relative',
        marginLeft: -4,
        marginBottom: 15,
        [theme.breakpoints.up('xs')]: {
            marginLeft: '0px',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '8px',
        },
    },
    gridCategoryTileStyle: {
        cursor: 'pointer',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        minWidth: '100px',
        marginRight: 20,
        color: 'black',
        borderRadius: '16px',
        padding: '20px 16px',
        marginTop: 10,
    },
    containerWrapper: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
    },
});

interface IPageProps {
    menuCategories: IMenuCategory[];
    organizationSettings: any;
}

interface IPageState {
    selectedCategoryId: any;
}

class CategoryRow extends React.Component<IPageProps, IPageState> {

    public state = {
        selectedCategoryId: 0,
    }

    public render() {
        const { menuCategories, organizationSettings } = this.props;
        const { selectedCategoryId } = this.state;

        return (
            <div className='containerWrapper'>
                <Box id="containerCategory" sx={{
                    display: 'flex',
                    scrollBehavior: 'smooth',
                    flexWrap: 'nowrap',
                    overflowY: 'hidden',
                    overflowX: 'scroll',
                    position: 'relative',
                    marginLeft: { xs: '8px' },
                    marginBottom: '20px',
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    /* hide scrollbar on IE and Edge */
                    '-ms-overflow-style': 'none',
                    /* hide scrollbar on Firefox */
                    'scrollbar-width': 'none',
                }}>
                    {menuCategories ? menuCategories?.length > 0
                        ? menuCategories.map((menuCategory: IMenuCategory, index: number) => (
                            <Box sx={{
                                backgroundColor: selectedCategoryId === menuCategory.id ? organizationSettings?.layoutColor : organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, 0.5),
                                cursor: 'pointer',
                                overflow: 'hidden',
                                display: 'inline-flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 'fit-content',
                                minWidth: 'fit-content',
                                marginRight: '20px',
                                borderRadius: '16px',
                                padding: '24px 20px',
                                marginTop: '15px',
                                marginBottom: '15px',
                                zIndex: '5',
                            }}
                                key={menuCategory.id +'key'+index}
                                onClick={() => this.handleCategoryMenuProduct(menuCategory)}
                            >
                                <Typography sx={{
                                    fontSize: { sm: '20px' },
                                    fontWeight: 400,
                                    color: selectedCategoryId === menuCategory.id ? 'layoutColor.contrastText' : '',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    margin: '0 auto',
                                }}>
                                    {menuCategory.name}
                                </Typography>
                            </Box>
                        )) : <Box>
                            <Typography align='center' component="span"
                                variant="body1"
                                color="textPrimary">No Category Found</Typography>
                        </Box> : <Stack width="100%" alignItems={'center'} justifyContent={'center'}><CircularProgress size={30} color='secondary' /></Stack>}
                </Box>
            </div>
        );
    }

    private handleCategoryMenuProduct = (menuCategory: IMenuCategory) => {
        
        this.setState({ selectedCategoryId: menuCategory.id });

        const element = document.getElementById(`scroll${menuCategory.id}`);

        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" }); // behavior:"smooth",
        }
    }
}

const mapStateToProps = (state: any) => ({
    organizationSettings: state?.organizations?.organizationSettings,
});

export default connect(mapStateToProps, null)(withStyles(styles as any)(CategoryRow as any));


import React from 'react';
import { connect } from 'react-redux';
import {
    put as orderPut,
    post as orderPost,
    addItem as orderAddItem,
    removeItem as orderRemoveItem,
} from '../../store/actions/Order.Actions';
import IOrderItem from '../../models/IOrderItem';
import IOrder from '../../models/IOrder';
import {
    addCartPopWindowOpen,
} from "../../store/actions/App.Actions";
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import './ProductDetail.css'
import { formatMoney } from '../../pipes/PriceFormatter'
import { bindActionCreators, Dispatch } from 'redux';
// import { withRouter } from "react-router-dom";
// import { RouteComponentProps } from "react-router-dom";
import Grid from '@mui/material/Grid';
// import ProductSlider from '../../components/product-slider/ProductSlider';
import { openProductDetailPageModel, closeProductDetailPageModel } from "../../store/actions/App.Actions";
import IOrderItemModifier from '../../models/IOrderItemModifier';
// import ProductModifiers from './ProductModifiers';
import IMenuCategoryItem from '../../models/IMenuCategoryItem';
import IMenuItemModifier from '../../models/IMenuItemModifier';
import { showHtml } from '../../services/HelperFunctions';
import ImgPlaceholder from '../../components/ImgPlaceholder';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ISetting from '../../models/ISetting';
import ItemQtyDropdownNew from '../../components/ItemQtyDropdownNew';
import { styled, withStyles } from '@mui/styles';
import ProductModifiersNew from './ProductModifiersNew';
import { getModifiersPrice } from '../../utilities/modifiers';
import { Box, Snackbar, SnackbarContent } from '@mui/material';
import { Stack } from '@mui/material';
import { IconButton } from '@mui/material';
import { withRouter } from '../../utilities/withRouter';
const classNames = require('classnames');

const useStyles = (theme: Theme) => ({
    root: {
        width: '100%',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up('xs')]: {
        },
        [theme.breakpoints.down('sm')]: {
            position: 'initial',
            paddingBottom: 100,
        },
    },
    shoppingButtons: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#fff',
        },
    },
    buttonsList: {
        maxWidth: 500,
        margin: 'auto',
        padding: 0,
    },
    subheader: {
        padding: '10px',
    },
    subheaderList: {
        backgroundColor: theme.palette.primary.main,
        justifyContent: 'center',
    },
    muiListItemRoot: {
        paddingTop: '0px',
        paddingBottom: '9px',
    },
    qtyListItem: {
        display: 'block',
    },
    name: {
        display: 'flex',
        justifyContent: 'left',
        textAlign: 'left',
        fontSize: '30px',
        fontWeight: 500,
        textTransform: 'uppercase',
    },
    calories: {
        display: 'flex',
        justifyContent: 'left',
        textAlign: 'left',
        fontSize: '20px',
        fontWeight: 500,
    },
    allergens: {
        display: 'flex',
        justifyContent: 'left',
        textAlign: 'left',
        fontSize: '15px',
        fontWeight: 300,
        color: 'red',
    },
    description: {
        display: 'flex',
        justifyContent: 'left',
        fontWeight: 300,
        color: '#8D92A3',
        maxHeight: 100,
        overflow: 'auto',
    },
    price: {
        display: 'flex',
        justifyContent: 'right',
        fontSize: '25px',
        fontWeight: 500,
    },
    nameWithPrice: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '13px',
    },
    quantityControl: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        width: '100%',
        // padding: 5,
        color: '#8D92A3',
        marginLeft: '-8px',
    },
    errorMessage: {
        color: '#ff0000',
        textAlign: 'center',
        fontSize: '22px',
    },
    qtyErrorMessage: {
        fontSize: '16px',
        paddingRight: '10px',
        transition: '1s',
        opacity: 0,
    },
    qtyErrorMessageActive: {
        opacity: 1,
    },
    safaryStickyDescription: {
        position: '-webkit-sticky',
    },
    stickyDescription: {
        position: 'sticky',
        top: 0,
    },
    imgPlaceholder: {
        height: '325px !important',
        borderRadius: '10px',
    },
    notesSection: {
        marginBottom: -10,
        // marginRight: 10,
    },
    textareaBoxSize: {
        minWidth: '100%',
        maxWidth: '100%',
        border: '2px solid #cccc'
    },
    dialogWidth: {
        minWidth: '800px',
        [theme.breakpoints.down('xs')]: {
            minWidth: 'auto',
        },
    },
});

const StyledTextareaAutosize = styled(TextareaAutosize)(
    ({
        boxSizing: 'border-box',
        width: '100%',
        padding: '16px',
        borderRadius: 8,
        border: '1px solid #cccc',
        resize: 'none',
        fontSize: '14px',
        fontFamily: 'inherit',
        '&:focus': {
            outline: '1px auto #281A01',
        }
    }),
);

interface IPageProps {
    classes: any;
    router: any;
    dispatch: Dispatch;
    selectedMenuCategoryItem: IMenuCategoryItem;
    allSelectedItemModifiers: { [id: number]: IMenuItemModifier };
    history: any;
    shopingCart: any;
    order: IOrder;
    orderAddItem: (orderItem: IOrderItem) => Promise<any>;
    orderRemoveItem: (orderItemId: number) => Promise<any>;
    user: any;
    showCartPop: () => void;
    menuItemById: (menuItemId: string) => any;
    orderItemsModifire: any;
    orderItem?: IOrderItem;
    openProductDetailModel: () => void;
    closeProductDetailModel: () => void;
    settings: { [key: string]: ISetting };
    onUpdateItemQuantity: any;
    organizationSettings: any;
}

interface IPageState {
    itemQuantity: number;
    qtyError: string;
    orderItemModifiers: { [id: number]: IOrderItemModifier };
    modifiersError: string;
    notes: string;
    modifiers: any;
    modifiersPrice: any;
    modifierErrorMessage: string;
    modifierError: boolean;
    errorModifierName: string;
}

interface IRouteParams { id: string, param2?: string }
class ProductDetail extends React.Component<IPageProps, IPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            itemQuantity: 1,
            qtyError: '',
            orderItemModifiers: {} as { [id: number]: IOrderItemModifier },
            modifiersError: '',
            notes: '',
            modifiers: [],
            modifiersPrice: 0,
            modifierErrorMessage: "",
            modifierError: false,
            errorModifierName:"0"
        }
    }

    public setOrderItemModifiers = (orderItemModifiers: { [id: number]: IOrderItemModifier }) => {
        this.setState({ orderItemModifiers });
    }

    public setModifiersError = (modifiersError: string) => {
        this.setState({ modifiersError });
    }

    private showQtyError = (qtyError: string) => {
        this.setState({
            qtyError,
        }, () => {
            setTimeout(() => {
                this.setState({
                    qtyError: '',
                })
            }, 5000);
        });
    }

    private getConfiguredQuantity = (): number => {
        const { selectedMenuCategoryItem } = this.props;
        const menuItem = selectedMenuCategoryItem.menuItem;

        let itemQuantity = menuItem?.defaultQuantity || 1;
        if (!!menuItem?.minimumQuantity && itemQuantity < menuItem.minimumQuantity) {
            itemQuantity = menuItem.minimumQuantity;
        }

        return itemQuantity;
    }

    private onUpdateItemQuantity = (orderItem: IOrderItem, itemQuantity: number) => {
        const { selectedMenuCategoryItem } = this.props;
        const configuredMinQty = selectedMenuCategoryItem?.menuItem?.minimumQuantity;

        if (configuredMinQty && configuredMinQty > itemQuantity) {
            this.showQtyError(`Minimum quantity: ${configuredMinQty}`);
            this.setState({ itemQuantity: configuredMinQty });
            return;
        }

        this.setState({ itemQuantity });
    }

    private validateModifiers = () => {
        const { modifiers } = this.state;
        for (const modifier of modifiers) {
            const selectedOptions = modifier.options.filter((option:any) => option.checked);
            const selectedOptionsLength = selectedOptions.length;
            const min = modifier.minimumQuantity;
            const max = modifier.maximumQuantity;
            if (selectedOptionsLength < min) {
                this.setState({ modifierErrorMessage: `Min limit is ${min}`, errorModifierName: modifier.name,modifierError: true });
                return false;
            } else if (max !== 0 && selectedOptionsLength > max) {
                this.setState({ modifierErrorMessage: `Max limit is ${max}`, errorModifierName: modifier.name ,modifierError: true  });
                return false;
            }
        }
        this.setState({ modifierErrorMessage: "", modifierError: false, errorModifierName: ""});
        return true;
    };

    private onhandleNotesChange = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }

    private addToCart = async () => {
        const { selectedMenuCategoryItem, orderItem, order } = this.props;
        const { itemQuantity, notes } = this.state;
        if (!this.validateModifiers()) {
            return;
        }
        const { router } = this.props;
        const searchParams = new URLSearchParams(router?.location?.search);
        searchParams.delete('id');
        searchParams.delete('menuId');
        searchParams.delete('productId');
        router.navigate(`${router.location.pathname}?${searchParams.toString()}`);

        const selectedMenuItem = selectedMenuCategoryItem?.menuItem;
        const editItme = !!orderItem;
        const item: any = editItme
            ? { ...orderItem, orderItemModifiers: this.state.modifiers || [] }
            : {
                menuCategoryItemId: selectedMenuCategoryItem?.id,
                name: selectedMenuCategoryItem?.name,
                description: selectedMenuCategoryItem?.description,
                notes: selectedMenuItem?.notes,
                price: selectedMenuCategoryItem?.price ? selectedMenuCategoryItem.price : selectedMenuItem?.price,
                revenueTypeId: selectedMenuItem?.revenueTypeId,
                quantity: itemQuantity,
                orderItemModifiers: this.state.modifiers || [],
                id: Math.floor(Math.random() * 100000),
                menuItemId: selectedMenuItem?.menuItemId
            };
        if (editItme) {
            item.quantity = itemQuantity;
        }
        // const updatedOrderItem = order?.orderItems?.find((item: any) => item.menuItemId === selectedMenuItem?.menuItemId);
        // if (updatedOrderItem && !editItme) {
        //     item.quantity = updatedOrderItem.quantity + itemQuantity;
        //     item.id = updatedOrderItem.id;
        // }

        item.notes = notes;
        item.menuCategoryItem = selectedMenuCategoryItem
        // item.orderItemModifiers = Object.values(orderItemModifiers)
        //     .map(modifire => {
        //         const cloneModifier = { ...modifire };
        //         delete cloneModifier.menuItemModifier;
        //         return cloneModifier;
        //     });

        await this.props.orderAddItem(item);
        if (!window.location.pathname.includes('proceed-to-checkout')) {
            this.props.showCartPop();
        }
        this.props.closeProductDetailModel();
    };

    private backToShop = () => {
        this.props.closeProductDetailModel();
    }

    private updateCheckedItems = (options: any, min: number) => {
        const array = [...options]?.map(
            (option: any)=> {
                return {...option, checked: false, selectedSubOption: null}
            }
        )
        if (min === 0) {
          return array;
        }
      
        for (let i = 0; i < min; i++) {
          array[i].checked = true;
        }
      
        return array;
      }

    private initializeModifiers = () => {
        this.setState({
            modifiers: this.props.selectedMenuCategoryItem?.menuItem?.modifiers?.map((modifier)=> {
                // if(modifier.rule === "PickMany"){
                    const min = 0;
                    const options = this.updateCheckedItems([...modifier?.options], min);
                    return {
                        ...modifier,
                        options: [...options]
                    }
                // }
                // return {
                //     ...modifier,
                //     options: modifier?.options?.map(
                //         (option: any)=> {
                //             return {...option, checked: false}
                //         }
                //     )
                // }
            })
        })
    }

    public componentDidMount() {
        const { orderItem } = this.props;

        if (orderItem) {
            const itemQuantity = orderItem.quantity;
            const notes = orderItem.notes;
            const orderItemModifiers: any = {};
            orderItem.orderItemModifiers.forEach((modifire: IOrderItemModifier) => orderItemModifiers[modifire?.menuItemModifierId]
                = { ...modifire });

            this.setState({
                itemQuantity,
                // orderItemModifiers,
                notes,
                modifiers: orderItem.orderItemModifiers
            });
        } else {
            this.setState({ itemQuantity: this.getConfiguredQuantity() });
            this.initializeModifiers()
        }
    }

    public componentDidUpdate(prevProps: Readonly<IPageProps>, prevState: Readonly<IPageState>, snapshot?: any): void {
        if(prevState.modifiers!==this.state.modifiers){
            this.setState({
                modifiersPrice: getModifiersPrice(this.state.modifiers)
            }) 
        }
    }

    private onOptionSelect = (menuItemModifierId: any, optionIndex: number, type: string)=> {
        const arr = [...this.state.modifiers];
        const findModifierIndex = arr?.findIndex((modi: any)=> modi.id===menuItemModifierId);
        const findModifier = arr?.find((modi: any)=> modi.id===menuItemModifierId);
        if(type==="Radio"){
            findModifier.options = findModifier.options?.map((option: any) => {
                return {...option, checked: false}
            })
        }
        findModifier.options[optionIndex] = {...findModifier.options[optionIndex], checked: !findModifier.options[optionIndex].checked, selectedSubOption: null}
        arr[findModifierIndex] = {...findModifier};
        this.setState({
            modifiers: [...arr]
        })
    }

    private onSubOptionSelect = (menuItemModifierId: any, optionIndex: number, value: any)=> {
        const arr = [...this.state.modifiers];
        const findModifierIndex = arr?.findIndex((modi: any)=> modi.id===menuItemModifierId);
        const findModifier = arr?.find((modi: any)=> modi.id===menuItemModifierId);
        findModifier.options[optionIndex] = {...findModifier.options[optionIndex], selectedSubOption: value}
        arr[findModifierIndex] = {...findModifier};
        this.setState({
            modifiers: [...arr]
        })
    }

    public render() {
        const { classes, selectedMenuCategoryItem, orderItem, allSelectedItemModifiers, settings, organizationSettings } = this.props;
        const { itemQuantity, orderItemModifiers, modifiersError, notes, qtyError } = this.state;
        // const modifiersTotalPrice = Object.values(orderItemModifiers)
        //     .filter(orderItemModifier => orderItemModifier.recordStatus === 'Active')
        //     .map((orderItemModifier: any) => orderItemModifier.menuItemModifier.price)
        //     .reduce((prev, next) => (prev ? prev : 0) + (next ? next : 0), 0);

        const {
            name,
            price,
            description,
            calories,
            imageUrl,
        } = selectedMenuCategoryItem;

        const menuItemAllergens = selectedMenuCategoryItem?.menuItem?.allergens ? selectedMenuCategoryItem.menuItem.allergens.join(", ") : '';

        const isEdit = !!orderItem;
        const showAllergens = settings.GRAZE_ENABLE_PRODUCT_ALLERGENS && settings.GRAZE_ENABLE_PRODUCT_ALLERGENS.value === false;
        const showSpecialInstructions = settings.GRAZE_ENABLE_SPECIAL_INSTRUCTIONS && settings.GRAZE_ENABLE_SPECIAL_INSTRUCTIONS.value === false;

        return (
            <Box>
                <Grid container={true} spacing={0} >
                    <Grid item={true} xs={12} sm={12} >
                        <div className={`${classes.safaryStickyDescription} ${classes.stickyDescription}`}>
                            <ImgPlaceholder name={name} imageUrl={imageUrl} className={classes.imgPlaceholder} />
                            <ListItem alignItems="flex-start" className={classes.muiListItemRoot} sx={{ padding: 0}}>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <div className={classes.nameWithPrice}>
                                                <Typography
                                                    className={classes.name}
                                                    color="#281A01"
                                                    sx={{ fontSize: { xs: '24px' }, fontWeight: 600 }}
                                                >{showHtml(name ? name : '')}</Typography>
                                                <Typography
                                                    component="span"
                                                    className={classes.price}
                                                    color={organizationSettings?.layoutColor}
                                                    sx={{ fontSize: { xs: '24px' }, fontWeight: 500 }}
                                                >
                                                    {price === 0 && this.state.modifiersPrice === 0 ? "" : "$" + formatMoney(((price + this.state.modifiersPrice || 0) * itemQuantity))}
                                                </Typography>
                                            </div>
                                            {calories !== null ? organizationSettings.enableCalorieInfo && <Typography sx={{ fontSize: { xs: '14px' }, fontWeight: 400, color: 'textPrimary', mt: 0.5, mb: 0.5 }} className={classes.calories}>{calories + ' Calories'}</Typography> : ''}
                                            {menuItemAllergens ? organizationSettings.enableAllergyInfo && <Typography sx={{
                                                display: 'flex',
                                                justifyContent: 'left',
                                                textAlign: 'left',
                                                fontSize: { xs: '10px' },
                                                fontWeight: 300,
                                                color: 'textPrimary',
                                                mt: 0.5,
                                                mb: 0.5,
                                            }}>
                                                {'Allergen: ' + menuItemAllergens}</Typography> : ''}
                                            <Typography color='text.primary' sx={{ fontSize: { xs: '12px' }, fontWeight: 400, mt: 0.5, mb: 0.5 }} className={classes.description}>{showHtml(description ? description : '')}</Typography>
                                            {
                                                organizationSettings?.enablePrepTime &&
                                                selectedMenuCategoryItem?.menuItem?.preparationTimeInSeconds !== undefined &&
                                                selectedMenuCategoryItem.menuItem.preparationTimeInSeconds > 0 && (
                                                    <Typography color='textPrimary' sx={{ fontSize: { xs: '12px' }, fontWeight: 400, mt: 0.5 }}>
                                                        {`Please allow ${(selectedMenuCategoryItem.menuItem.preparationTimeInSeconds / 60)} minutes preparation time for this item`}
                                                    </Typography>
                                                )
                                            }
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        </div>
                    </Grid>
                    <Grid item={true} xs={12} sm={12}>
                        <div style={{ padding: 0 }}>
                            <Box>
                                <List className={classes.root}>
                                    {/* <ProductModifiers
                                        selectedMenuItem={selectedMenuCategoryItem.menuItem}
                                        allSelectedItemModifiers={allSelectedItemModifiers}
                                        orderItemModifiers={orderItemModifiers}
                                        setOrderItemModifiers={this.setOrderItemModifiers}
                                        setModifiersError={this.setModifiersError}
                                        editModifires={isEdit}
                                    /> */}
                                    <ProductModifiersNew 
                                        modifiers={this.state.modifiers} 
                                        onOptionSelect={this.onOptionSelect}
                                        onSubOptionSelect={this.onSubOptionSelect}
                                    />
                                    {organizationSettings?.enableSpecialInstructions &&
                                        <ListItemText
                                            primary={
                                                <React.Fragment>
                                                <div className={classes.notesSection}>
                                                    {/* <div>
                                                        <Typography
                                                            component="span"
                                                            color="textPrimary"
                                                            sx={{ fontSize: { xs: '20px' } }}
                                                        >Special Instructions: </Typography>
                                                    </div> */}
                                                    <StyledTextareaAutosize
                                                        name="notes"
                                                        autoComplete="off"
                                                        id="notes"
                                                        onChange={(e: any) => this.onhandleNotesChange(e)}
                                                        maxRows={5}
                                                        placeholder="Special Instructions"
                                                        value={notes}
                                                    />
                                                </div>
                                                </React.Fragment>
                                            }
                                        />}
                                </List>
                                <Box sx={{
                                    bgcolor: { xs: '#fff' },
                                    display: 'flex',
                                }}>
                                    <ListItem sx={{
                                        margin: 'auto',
                                        padding: 0,
                                    }}>
                                        <div className={classes.quantityControl}>
                                            <ItemQtyDropdownNew
                                                orderItem={{
                                                    quantity: itemQuantity,
                                                }}
                                                onUpdateItemQuantity={this.onUpdateItemQuantity}
                                                autofocus={true}
                                            />
                                            <div className={classNames(classes.errorMessage, classes.qtyErrorMessage, { [classes.qtyErrorMessageActive]: qtyError })}>
                                                <span style={{ visibility: qtyError ? 'visible' : 'visible' }}>&nbsp;{qtyError}</span>
                                            </div>
                                        </div>
                                    </ListItem>
                                    <ListItem sx={{
                                        margin: 'auto',
                                        padding: 0,
                                        justifyContent: 'right'
                                    }}>
                                        <Button
                                            color="layoutColor"
                                            variant="contained"
                                            disableElevation={true}
                                            onClick={this.addToCart}
                                            sx={{ 
                                                width: {xs: '170px'}, 
                                                height: {xs: '35px'},
                                                margin: '10px 0px', 
                                                borderRadius: '6px', 
                                                fontSize: '16px', 
                                                padding: {xs: '10px'}, 
                                                backgroundColor: organizationSettings?.layoutColor,
                                                "&.MuiButtonBase-root:hover": {
                                                    bgcolor: organizationSettings?.layoutColor,
                                                }
                                            }}
                                        // disabled={!!modifiersError}
                                        >
                                            <Typography sx={{fontSize: {xs: '16px'}, fontWeight: 500, textTransform: 'none'}}>
                                                {isEdit ? 'Save Changes' : 'Add to Order'}
                                            </Typography>
                                        </Button>
                                    </ListItem>
                                </Box>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
                <Snackbar
                        open={this.state.modifierError}
                        autoHideDuration={3000}
                        onClose={() => this.setState({ modifierError: false, modifierErrorMessage: "" })}
                    >
                        <SnackbarContent sx={{
                            backgroundColor: '#d32f2f',
                        }}
                            message={<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} >
                                <span>{this.state.modifierErrorMessage + " For " + this.state.errorModifierName+" Modifier"} </span>
                               </Stack>}
                               action={ <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => {
                                    this.setState({ modifierError: false, modifierErrorMessage: "" })
                                }}
                            >
                                <Close fontSize="small" />
                            </IconButton>}
                                
                        />
                    </Snackbar>

            </Box>
        );
    };
};
const mapStateToProps = (state: any) => {
    return {
        order: state.order,
        selectedMenuCategoryItem: state.menu?.selectedMenuCategoryItem,
        orderItem: state.menu.orderItem,
        allSelectedItemModifiers: state.menu?.allSelectedItemModifiers,
        user: state.authentication,
        orderItemsModifire: state.updateModifire,
        settings: state.settings,
        organizationSettings: state?.organizations?.organizationSettings,

    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        orderPut,
        orderPost,
        orderAddItem,
        orderRemoveItem,
        showCartPop: addCartPopWindowOpen,
        openProductDetailModel: openProductDetailPageModel,
        closeProductDetailModel: closeProductDetailPageModel

    }, dispatch);
};
const ProductDetailView = withStyles(useStyles as any)(ProductDetail);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductDetailView));

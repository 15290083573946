import * as React from 'react';
// import { withRouter } from "react-router-dom";
// import { RouteComponentProps } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
// import FeatureCardImagelessLayout from "../../components/FeatureCardImagelessLayout";
import * as AppMenuActionCreators from '../../store/actions/Menu.Action';
import { Utility } from '../../state/Utility';
import { Theme } from '@mui/material/styles';
import { openProductDetailPageModel, closeProductDetailPageModel } from "../../store/actions/App.Actions";
import IMenuCategoryItem from '../../models/IMenuCategoryItem';
import { searchName } from '../../services/HelperFunctions';
import { CircularProgress, ImageList } from '@mui/material';
import EnhancedFeatureCard from "../../components/FeatureCard";
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

const classNames = require('classnames');
// import GlobalSearchBoxImagelessLayout from '../../components/search/GlobalSearchBoxImagelessLayout'
// import IMenuCategoryItem from 'src/models/IMenuCategoryItem';
// import { searchName } from 'src/services/HelperFunctions';

const styles = (theme: Theme) => ({
    container: {
        padding: '2%',
        backgroundColor: '#fff',
        height: '100%',
    },
    title: {
        backgroundColor: 'white',
        padding: '5px',
        margin: '5px',
        width: '100%',
        textAlign: 'center',
        fontSize: 24,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        width: '80% !important'
    },
    searchItems: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    gridFeatureTileStyle: {
        marginRight: 5,
        marginBottom: 25,
    },
    root: {
        'flex-shrink': 'unset',
        alignItems: 'center',
    },
    gridList: {
        flexWrap: 'nowrap',
        'overflowX': 'auto',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    searchBar: {
        "margin": "10px",
        minWidth: '300px',
        height: '100%',
        borderRadius: '15px',
        flex: 1,
        backgroundColor: 'rgba(0,0,0,.1)',
    },
    emptyFeatureCard: {
        width: 380,
        marginRight: 5,
    },
    generalMenuCategoryItemsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginLeft: '8px',
        marginRight: '16px',
        paddingBottom:'40px'
    },
    loading: {
        textAlign: 'center',
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
});

interface IPageProps {
    classes: any;
    utility: Utility;
    searchValue: string;
    allOnlineMenuItems: any //IMenuCategoryItem[];
    selectedMenuProduct: (body: any) => void;
    selectedCategoryMenuProduct: (data: any) => any;
    openProductDetailModel: () => void;
    closeProductDetailModel: () => void;
    menus: any;
    searchLoader: boolean;
}
interface IPageState {
    title: string;
}

class HomeSearch extends React.Component<IPageProps, IPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            title: "Home Search All",
        }
    }
    public handleProduct = async (payload: any) => {
        const item = {
            id: payload.id,
            menuItem: { ...payload, modifiers: [...payload?.menuItemModifiers] },
            name: payload?.name,
            price: payload?.price,
            description: payload?.description,
            calories: payload?.calories,
            imageUrl: payload?.imageUrl
        } 
        payload.isEditCartItem = false;
        this.props?.selectedCategoryMenuProduct(item);
        this.props.openProductDetailModel();
    }
    public render() {
        const { classes, utility, allOnlineMenuItems, menus, searchValue, searchLoader } = this.props;

        return (
            <div className={classes?.container}>
                
                {searchLoader ?  <div className={classes.loading} style={{alignItems: 'center'}}><CircularProgress color='info' /></div>
                    : <div className={classes?.generalMenuCategoryItemsContainer}>
                        {menus?.length ? menus?.map((menuCategoryItem: IMenuCategoryItem) => (
                            (searchName(menuCategoryItem?.name || "", searchValue) || searchName(menuCategoryItem?.description || "", searchValue)) &&
                            <EnhancedFeatureCard
                                    key={menuCategoryItem.id}
                                    menuCategoryItem={menuCategoryItem}
                                    onClick={() => { this.handleProduct(menuCategoryItem) }}
                                />
                        )) : <div >
                                <Typography align='center' component="span"
                                    variant="body1"
                                    color="textPrimary">No Product Found</Typography>
                            </div>}
                             <div className={classes.emptyFeatureCard} />
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                            <div className={classes.emptyFeatureCard}/>
                            
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: any, props: any) => ({
    utility: state.utility,
    allOnlineMenuItems: state.menu.allOnlineMenuItems,
    searchValue: state.menu.searchValue,
    menus: state.items.menuItems
});

const mapDispatchtoProps = (dispatch: Dispatch) =>
    bindActionCreators({
        selectedCategoryMenuProduct: AppMenuActionCreators.selectedCategoryMenuProduct,
        openProductDetailModel: openProductDetailPageModel,
        closeProductDetailModel: closeProductDetailPageModel,
    }, dispatch);

    const HomeSearchView = withStyles(styles as any)(HomeSearch);
    export default connect(mapStateToProps, mapDispatchtoProps)(HomeSearchView as any);
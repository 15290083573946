import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import SignIn from './signin';
import SignUp from './signup';
import { Box, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AuthModal({open, onClose}: {open: boolean, onClose: () => void}) {
  const [signupFormVisible, setSignupFormVisible] = React.useState<boolean>(false);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Box sx={{textAlign: 'right', mb: 2}}>
            <IconButton size='small' onClick={onClose}><CloseIcon sx={{fontSize: 24}} /></IconButton>
          </Box>
          {signupFormVisible ? <SignUp handleClickSignin={()=> setSignupFormVisible(false)} /> : <SignIn handleClickSignup={()=> setSignupFormVisible(true)} />}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

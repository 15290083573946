import { CircularProgress, Stack } from "@mui/material";
import OrderHistoryComp from "../../account/OrderHistory";

const OrderHistory = ({items}: any) => {
    return items ? <>
        {items?.length <= 0 && <div>
            <p style={{textAlign: "center"}}>Empty Order History!</p>
        </div>}
        {items?.length > 0 && <div>
            <OrderHistoryComp customerOrders={items} />
        </div>}
    </> : <Stack justifyContent={'center'} alignItems={'center'}>
    <CircularProgress color="secondary" />
    </Stack>
}

export default OrderHistory;
import { coverApi } from '../../api';
import { ActionType, IAppAction } from '../actions/Helpers';
import { Dispatch } from "redux";
import { AppState } from "../../state/AppState";
import { Spinner } from '../../state/Spinner';
import IRoom from '../../models/IRoom';

export const getRooms = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });

  return coverApi.getRooms()
    .then((response: any) => {
      const rooms = response.parsedBody as IRoom[];
      dispatch({ type: ActionType.SET_ROOMS, payload: rooms });
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return response;
    })
    .catch((error: any) => {
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return error;
    });
}

import { ActionType, IAppAction } from '../actions/Helpers';

const initialState: any = {
    auth0UserDetails: null,
    appUserDetails: null,
};

const userReducer = (state = { ...initialState }, action: IAppAction) => {
    switch (action.type) {
        case ActionType.GET_AUTH0_USER_DETAILS_SUCCESS:
            return { ...state, auth0UserDetails: action.payload };
        case ActionType.GET_AUTH0_USER_DETAILS_FAILURE:
            return { ...state, auth0UserDetails: null };
        case ActionType.GET_APP_USER_DETAILS_SUCCESS:
            return { ...state, appUserDetails: action.payload };
        case ActionType.GET_APP_USER_DETAILS_FAILURE:
            return { ...state, appUserDetails: action.payload };
        default:
            return state;
    }
};

export default userReducer;
import { AuthenticatedBase, IHttpResponse } from './AuthenticatedBase';
import ICoverApi from './ICoverApi';
import IMenu from '../models/IMenu';
import IAccountLocation from '../models/IAccountLocation';
import ISettingArea from '../models/ISettingArea';
import ICustomer from '../models/ICustomer';
import ICustomerMessage from '../models/ICustomerMessage';
import IOrderListEntry from '../models/IOrderListEntry';
import IOrder from '../models/IOrder';
import IAddress from '../models/IAddress';
import IOrderCloneSetting from '../models/IOrderCloneSetting';
import IRoom from '../models/IRoom';

export default class CoverApi extends AuthenticatedBase
    implements ICoverApi {

    private url: string = '';

    constructor(url: string) {
        super();
        this.url = url;
    }

    public async getOnlineOrderMenu(): Promise<IHttpResponse<IMenu[]>> {
        return await super.get<IMenu[]>(`${this.url}menu/onlineorder`);
    }

    public async getMenuList(): Promise<IHttpResponse<IMenu>> {
        return await super.get<IMenu>(`${this.url}MenuCategoryItem`);
    }

    public async getLocation(organizationId: string, locationId: string, wihtLocation: boolean = false): Promise<IHttpResponse<IAccountLocation>> {
        return await super.get<IAccountLocation>(`${this.url}graze/${organizationId}/${locationId}/${wihtLocation}/location`);
    }

    public async getSettingAreas(): Promise<IHttpResponse<ISettingArea[]>> {
        return await super.get<ISettingArea[]>(`${this.url}settingArea?$filter=Name in ('Graze','Account')`);
    }

    public async getEventPickLists(): Promise<IHttpResponse<any[]>> {
        return await super.get<any[]>(`${this.url}pickList?$filter=Name in ('Category','Theme', 'Operation')`);
    }

    public async getCustomer(): Promise<IHttpResponse<ISettingArea[]>> {
        return await super.get<ICustomer[]>(`${this.url}Customer/current`);
    }

    public async getRooms(): Promise<IHttpResponse<IRoom[]>> {
        return await super.get<IRoom[]>(`${this.url}room`);
    }

    public async updateCustomer(customer: ICustomer): Promise<IHttpResponse<ISettingArea[]>> {
        return await super.put<ICustomer[]>(`${this.url}Customer/current`, customer);
    }

    public async sendCustomerMessage(customerMessage: ICustomerMessage): Promise<IHttpResponse<ISettingArea[]>> {
        return await super.post(`${this.url}Email/CustomerMessage`, customerMessage);
    }

    public async getOrderList(userId: string): Promise<IHttpResponse<ISettingArea[]>> {
        return await super.get<IOrderListEntry[]>(`${this.url}Order/list?$filter=CreatedBy eq '${userId}' and PlacedDateTime ne null&$orderby=PlacedDateTime desc&$top=10`);
    }

    public async cloneOrder(orderId: number, orderCloneSetting: IOrderCloneSetting): Promise<IHttpResponse<IOrder>> {
        return await super.post(`${this.url}Order/${orderId}/clone`, orderCloneSetting);
    }

    public async ApplyCoupon(orderId: number, couponCode: string): Promise<IHttpResponse<IOrder>> {
        return await super.post<IOrder>(`${this.url}Order/${orderId}/CouponCode/${couponCode}`, null);
    }

    public async RemoveCoupon(orderId: number): Promise<IHttpResponse<IOrder>> {
        return await super.delete<IOrder>(`${this.url}Order/${orderId}/Coupon`);
    }

    public async GetAddress(): Promise<IHttpResponse<IAddress[]>> {
        return await super.get<IAddress[]>(`${this.url}UserAddress`);
    }

    public async UpsertAddress(address: IAddress): Promise<IHttpResponse<IAddress>> {
        if (address.id) {
            return await super.put<IAddress>(`${this.url}UserAddress`, address);
        }
        else {
            return await super.post<IAddress>(`${this.url}UserAddress`, address);
        }
    }

    public async DeleteAddress(addressId: string): Promise<IHttpResponse<IAddress>> {
        return await super.delete<IAddress>(`${this.url}UserAddress/${addressId}`);
    }
}

import React, { useState, useEffect } from 'react';
import { MenuItem, TextField } from '@mui/material';

interface TimeSelectionProps {
  startTime: string;
  endTime: string;
  value: string;  
  handleChange: (value: string) => void;
  label:string
  outlined?:boolean
}

const TimeSelection: React.FC<TimeSelectionProps> = ({ startTime, endTime, value, handleChange,label,outlined }) => {
  const [selectedTime, setSelectedTime] = useState(value.split(' ')[0]);
  const [period, setPeriod] = useState<'AM' | 'PM'>(value.split(' ')[1] as 'AM' | 'PM');
  const [timeOptions, setTimeOptions] = useState<string[]>([]);
  const [initialSetupDone, setInitialSetupDone] = useState(false);
  const [amAvailable, setAmAvailable] = useState(false);
  const [pmAvailable, setPmAvailable] = useState(false); 

  useEffect(() => {
    const [initialTime, initialPeriod] = value.split(' ');
    setSelectedTime(initialTime);
    if (initialPeriod === 'AM' || initialPeriod === 'PM') {
      setPeriod(initialPeriod as 'AM' | 'PM');
    }
  }, [value]);

  useEffect(() => {
    const newTimeOptions = generateTimeOptions(period);
    setTimeOptions(newTimeOptions);
    if (initialSetupDone) {
      setSelectedTime(newTimeOptions[0]);
    }
    setInitialSetupDone(true); 
  }, [period,startTime, endTime]);

  useEffect(() => {
    if (selectedTime && period) {
        handleChange(`${selectedTime} ${period}`);
    }
  }, [selectedTime, period, handleChange]);

  useEffect(() => {
    checkAmPmAvailability();
  }, [startTime, endTime]);

  const generateTimeOptions = (period: 'AM' | 'PM'): string[] => {
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);
    const times: string[] = [];
    const startTotalMinutes = startHour * 60 + startMinute;
    const endTotalMinutes = endHour * 60 + endMinute;
    const hourStart = period === 'AM' ? 0 : 12;
    const hourEnd = period === 'AM' ? 11 : 23;

    for (let hour = hourStart; hour <= hourEnd; hour++) {
      ['00', '15', '30', '45'].forEach(minute => {
        const totalMinutes = hour * 60 + parseInt(minute);
        if (totalMinutes >= startTotalMinutes && totalMinutes <= endTotalMinutes) {
          const displayHour = hour % 12 === 0 ? 12 : hour % 12;
          const paddedHour = displayHour < 10 ? `0${displayHour}` : displayHour.toString();
          times.push(`${paddedHour}:${minute}`);
        }
      });
    }

    return times;
  };

  const checkAmPmAvailability = () => {
    const startHour = parseInt(startTime.split(':')[0], 10);
    const endHour = parseInt(endTime.split(':')[0], 10);
  
    const newAmAvailable = startHour < 12 || (startHour === 12 && parseInt(startTime.split(':')[1], 10) === 0);
    const newPmAvailable = endHour >= 12;
  
    setAmAvailable(newAmAvailable);
    setPmAvailable(newPmAvailable);
  
    // If the current period is no longer available, switch to the one that is
    if (!newAmAvailable && period === 'AM' && newPmAvailable) {
      setPeriod('PM');
      handleChange(`${selectedTime} PM`);
    } else if (!newPmAvailable && period === 'PM' && newAmAvailable) {
      setPeriod('AM');
      handleChange(`${selectedTime} AM`);
    }
  };

  const handleTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedTime(event.target.value as string);
    handleChange(`${event.target.value as string} ${period}`);
  };

  const handlePeriodChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newPeriod = event.target.value as 'AM' | 'PM';
    setPeriod(newPeriod);
  };

  return (
    <div style={{ display: 'flex', gap: '14px' }}>
      <TextField
        select
        label={label}
        value={selectedTime}
        onChange={handleTimeChange}
        fullWidth
        size='small'
        variant={outlined ? "outlined" : "standard"}
        required
        sx={{
          minWidth: { xs: "110px", sm: "180px" },
          "& .MuiInputBase-input": { fontSize: "18px" },
          "& .MuiInputLabel-root": { fontSize: "18px" },
          "& .MuiOutlinedInput-root": { borderRadius: "8px",padding:'3px' }
        }}
      >
        {timeOptions.map((option, index) => (
          <MenuItem key={index+option} value={option}>{option}</MenuItem>
        ))}
      </TextField>
      
      <TextField
        select
        label="AM/PM"
        value={period}
        onChange={handlePeriodChange}
        fullWidth
        variant={outlined ? "outlined" : "standard"}
        size='small'
        required
        sx={{
          minWidth: { xs: "70px", sm: "180px" },
          "& .MuiInputBase-input": { fontSize: "18px" },
          "& .MuiInputLabel-root": { fontSize: "18px" },
          "& .MuiOutlinedInput-root": { borderRadius: "8px",padding:'3px' },
        }}
      >
         <MenuItem value="AM" disabled={!amAvailable}>AM</MenuItem>
        <MenuItem value="PM" disabled={!pmAvailable}>PM</MenuItem>
      </TextField>
    </div>
  );
};

export default TimeSelection;

import { createStyles } from '@mui/material/styles';
import IAddress from '../../models/IAddress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { replaceComma } from '../../services/HelperFunctions';

const styles = (theme: any) => createStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: '16px',
        overflow: 'hidden',
        scrollBehavior: 'smooth',
        flexDirection: 'column',
        width: '100%',
    },
    select: {
        width: '100%'
    },
    buttonsRow: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    selectItem: {
        display: 'flex',
        justifyContent: 'left',
        alignContent: 'flex-start',
        flexFlow: 'column wrap',
        '& div': {
            width: '100%',
        }
    },
});

interface IProps {
    classes?: any;
    className?: string;
    theme?: any;
    addresses: IAddress[],
    onAddressSelect: (addressId: string) => {};
    selectedAddressId: string
};

const AddressSelector = (props: IProps) => {
    const navigate = useNavigate();
    const { classes, className, addresses, onAddressSelect, selectedAddressId } = props;

    const renderSelectItem = (address: IAddress) => {
        return (<MenuItem key={address.id} value={address.id} disabled={selectedAddressId===address?.id} sx={{borderBottom: "1px solid lightgray", fontSize: '16px !important'}} className={classNames(classes.selectItem)}>
            {address.name && <div>{replaceComma(address.name || "")}</div>}
            <div>{replaceComma(address.address1 || "")}</div>
            {address.address2 && <div>{address.address2}</div>}
            <div>{address.city}, {address.stateProvince}, {address.postalCode}</div>
        </MenuItem>);
    }

    return (
        <div className={classNames(classes.root, className)}>
            <Select
                value={selectedAddressId}
                onChange={(e) => onAddressSelect(e.target.value)}
                className={classes.select}
                label=""
                variant='standard'
                sx={{fontSize: "18px !important"}}
            >
                {addresses?.map(address => renderSelectItem(address))}
                <MenuItem value={"new"} sx={{borderBottom: "1px solid lightgray", fontSize: '16px !important'}} className={classNames(classes.selectItem)}>
                    New
                </MenuItem>
            </Select>
            <div className={classes.buttonsRow}>
                <Button
                    color="secondary"
                    onClick={()=> navigate("/profile?tab=manage-address")}
                    sx={{mt: 1, fontSize: {sm: '18px'}}}
                >
                    Manage
                </Button>
            </div>
        </div>
    );
}

export default withStyles(styles)((AddressSelector as any)) as any;

import { createStyles, Theme } from '@mui/material'

const drawerWidth = 140;

export const styles = (theme: Theme) => createStyles({
  rootMain: {
    flexGrow: 1,
    height: '100vh',
    minHeight: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.background.default,
  },
  rootMainImagelessLayout: {
    flexGrow: 1,
    height: '100vh',
    minHeight: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `100%`,
    },
    '@media print': {
      display: 'none',
    },
  },
  appBarImagelessLayout: {
    boxShadow: 'none',
    backgroundColor: '#fff',
  },
  miniCart: {
    margin: '3px 15px',
    padding: '5px 15px',
    // border: `solid 1px ${theme.imagelessLayout.palette.primary.main}`,
    // boxShadow: `1px 1px 25px 4px ${theme.imagelessLayout.palette.primary.light}`,
    borderRadius: '40px',
    fontSize: 14,
    display: 'flex',
    cursor: 'pointer',
    backgroundColor: '#fff',
    alignItems: 'center',
  },
  miniCartPopover: {
    padding: '8px 15px',
    cursor: 'auto',
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  miniCartClose: {
    color: 'red',
    fontSize: '14px',
    lineHeight: '18px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  miniCartCloseIcon: {
    fontSize: 10,
    margin: 5,
  },
  miniCartUnitPriceIcon: {
    fontSize: 12,
    marginBottom: -1,
  },
  miniCartHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  miniCartComponent: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    alignItems: 'center',
  },
  miniCartIcon: {
    fontSize: 20,
    marginRight: 5,
  },
  miniCartIconDelete: {
    fontSize: 16,
    marginBottom: -3,
    position: 'relative' as 'relative',
    left: 55,
    cursor: 'pointer',
  },
  miniCartCount: {
    // color: theme.imagelessLayout.palette.primary.contrastText,
    marginLeft: 5,
  },
  miniCartMobile: {
    fontSize: 17,
    padding: 5,
    // border: `solid 1px ${theme.imagelessLayout.palette.primary.main}`,
    // boxShadow: `px 1px 25px 4px ${theme.imagelessLayout.palette.primary.light}`,
    borderRadius: '40px',
  },
  miniCartItems: {
    marginTop: 30,
    marginBottom: 10,
    fontSize: 18,
    fontWeight: 600,
  },
  miniCartPayment: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 18,
    fontWeight: 600,
  },
  paymentSubtotal: {
    fontWeight: 600,
  },
  minicartMoney: {
    // color: theme.imagelessLayout.palette.primary.contrastText,
  },
  minicartPaymentMoney: {
    // color: theme.imagelessLayout.palette.primary.contrastText,
    marginBottom: 5,
    textAlign: 'right',
  },
  headerToolbar: {
    minHeight: '45px',
  },
  appBarShift: {
    marginLeft: 'auto',
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logo_image: {
    height: '40px',
    marginLeft: 0,
    '@media only screen and (min-width: 280px) and (max-width: 399px)': {
      width: '120px',
    },
    '@media only screen and (min-width: 280px) and (max-width: 959px)': {
      display: 'flex',
      justifyContent: 'center',
      // marginLeft: 5,
    },
    '@media only screen and (min-width: 600px)': {
      marginLeft: 5,
    },
  },
  appBarRight: {
    display: 'flex',
    marginLeft: 'auto',
    paddingRight: '10px',
    alignItems: 'center',
  },
  appBarRightImagelessLayout: {
    display: 'flex',
    marginLeft: 'auto',
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      width: '100%',
      margin: '0 2%',
    },
  },
  appBarTitle: {
    marginRight: 'auto',
  },
  menuButton: {
    '@media only screen and (min-width: 280px) and (max-width: 399px)': {
      marginLeft: 0,
      marginRight: 0,
    },
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    top: 0,
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })

  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      // width: theme.spacing.unit * 9,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    // padding: theme.spacing.unit * 3,
    minHeight: '100%',
    height: '100%',
    flex: '1 1 auto',
    overflowY: 'scroll',
    '@media print': {
      margin: 0,
      padding: 0,
    },
  },
  contentImagelessLayout: {
    backgroundColor: '#fff',
  },
  button: {
    // margin: theme.spacing.unit,
  },
  link: {
    textDecoration: 'none',
  },
  current: {
    color: 'red !important',
  },
  notifications: {
    overflowX: 'hidden'
  },
  fillSpace: {
    flex: '1 1 auto'
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    // marginRight: 'auto',
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    color: theme.palette.primary.main,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    // color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  cartText: {
    fontSize: '15px'
  },
  alignNav: {
    color: '#000000',
    fontSize: '16px'
  },
  paper: {
    marginTop: '45px',
    marginLeft: '13px',
    border: '1px solid rgba(0, 0, 0, 0.26)'
  },
  paperImageless: {
    borderRadius: '30% 0% 0% 30%/70% 0% 0% 70%',
    // backgroundColor: theme.imagelessLayout.palette.primary.light,
    top: '0px !important',
    height: '100%',
    paddingLeft: 50,
    marginLeft: 16,
    minHeight: '100% !important',
  },
  typography: {
    padding: "8px",
  },
  proceedToCheckout: {
    color: "white",
    width: '60%',
    borderRadius: '50px',
    backgroundColor: '#000000',
    '&:hover': {
      backgroundColor: '#000000',
    },
    '& .MuiButton-label': {
      padding: 'inherit',
    },
  },
  proceedToCheckoutImagelessLayout: {
    // backgroundColor: theme.imagelessLayout.palette.primary.main,
    textTransform: 'capitalize',
    padding: '5px 10px',
    width: '100%',
    borderRadius: '10px',
    fontSize: '18px',
    marginTop: '10px',
  },
  cartItemProductName: {
    fontSize: '12px',
    'word-wrap': 'break-word'
  },

  cartEmpty: {
    height: '50vh',
    background: "url('/static/images/shopping-bag-empty.png') no-repeat center center",
    color: '#000000',
    overflow: 'hidden'
  },
  panndingZero: {
    padding: '0 !important',
    margin: 'auto',
    width: '100%',
  },
  deleteButton: {
    padding: 0,
    color: '#000000',
  },
  cartItems: {
    padding: 10,
    alignItems: 'center',
  },
  cartItemContent: {
    textTransform: 'capitalize',
    width: '100%',
  },
  cartItemName: {
    marginBottom: -5,
    fontSize: 16,
    fontWeight: 600,
  },
  cartItemContentPrice: {
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'initial',
    alignItems: 'center',
    marginTop: 5,
  },
  cartItemsImagelessLayout: {
    backgroundColor: '#fff',
    borderRadius: 12,
    marginBottom: 5,
    padding: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cartItemPrice: {
    textAlign: 'right',
  },
  paymentInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: '18px',
  },
  cartItemButton: {
    padding: 0,
    textAlign: 'left',
    display: 'block',
  },
  popupHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '34px',
    height: '6vh',
  },
  popupHeader: {
    fontWeight: 600,
    color: '#000000',
    marginLeft: '5px'
  },
  popupMainContainer: {
    minWidth: '400px',
    maxWidth: '400px',
  },
  popupMainContainerImagelessLayout: {
    minWidth: '400px',
    maxWidth: '400px',
    padding: 30,
  },
  productDetailsItem: {
    maxHeight: '300px',
    overflowY: 'scroll',
  },
  productDetailsItemImagelessLayout: {
    overflowY: 'scroll',
    maxHeight: 455,
  },
  productToCheckoutcontainer: {
    margin: '10px',
    textAlign: 'center'
  },
  productToCheckoutcontainerImagelessLayout: {
    margin: '10px 0',
    textAlign: 'center'
  },
  itemTotalAlign: {
    padding: '15px'
  },
  miniSubTotal: {
    fontWeight: 600,
  },
  loginContainer: {
    border: '1px solid #ccc',
    // paddingLeft: '10px',
    // paddingRight: '10px',
    // '-webkit-box-shadow': '-1px 3px 35px -13px rgba(148,145,148,0.87)',
    // '-moz-box-shadow': '-1px 3px 35px -13px rgba(148,145,148,0.87)',
    // boxShadow: '-1px 3px 35px -13px rgba(148,145,148,0.87)'
  },
  root: {
    padding: '2px !important'
  },
  rootCart: {
    // paddingRight:'5px',
  },
  cartIcon: {
    [theme.breakpoints.down('xs')]: {
      '& span': {
        transform: 'scale(1) translate(50%, -20%)',
      },
    },
  },
  rootButton: {
    padding: '3px !important',
    '@media only screen and (min-width: 399px)': {
      padding: '8px !important',
    },
    minWidth: 'auto'
  },
  badge: {
    padding: '0px !important',
    '& .MuiBadge-anchorOriginTopRight': {
      top: 8,
      right: 8,
    }
  },
  root_Badge: {
    // fontSize:'13px',
  },
  dialogContainer: {
    borderRadius: '12px !important',
    border: '1px solid black',
    // border: `solid 1px ${theme.imagelessLayout.palette.primary.main}`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      margin: 0,
      maxWidth: '100%',
      maxHeight: 'none',
      borderRadius: 0,
    },
  },
  dialogContainerImagelessLayout: {
    maxWidth: 600,
    borderRadius: '10px',
    // border: `solid 1px ${theme.imagelessLayout.palette.primary.main}`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      margin: 0,
      maxWidth: '100%',
      maxHeight: 'none',
      borderRadius: 0,
    },
  },
  dialog: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-paperScrollPaper': {
        maxWidth: 'initial',
        maxHeight: 'initial',
        borderRadius: 'initial',
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
      }
    }
  },
  dialogTitle: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  dialogCloseButton: {
    '& svg': {
      width: 25,
    },
    padding: '0px 24px',
  },
  costContainer: {
    paddingLeft: '10px',
    paddingRight: '20px',
    paddingTop: '0',
    paddingBottom: '0'
  },
  profileLink: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  unavailableItemsAlert: {
    '& svg': {
      fontSize: '35px'
    }
  },
  selectMenuSection: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  tabs: {
    paddingTop: 100,
    paddingRight: 15,
    [theme.breakpoints.down('md')]: {
      paddingTop: 77,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tabsImagelessLayout: {    
    padding: '52px 2% 0',
  },
  selectMenuTab: {
    fontSize: 16,
    minHeight: 38,
  },
  settingsOutlined: {
    color: '#a3a2a2',
    cursor: 'pointer',
    fontSize: 25,
    margin: 5,
  },
  settingsOutlinedDivider: {
    width: 1,
    height: 24,
    display: 'inline-block',
    backgroundColor: '#a3a2a2',
    margin: '5px 15px',
  },
  userName: {
    margin: '10px 0',
    fontSize: 18,
    fontWeight: 500,
  },
  selectMenu: {
    width: '90%',
    marginTop: 47,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  selectSelect: {
    '&.MuiSelect-select.MuiSelect-select': {
      paddingRight: 35,
    },
  },
    selectIcon: {
      top: 'calc(50% - 23px)',
  },
  itemNotesContainer: {
    textAlign: 'left',
    marginTop: -5,
    marginBottom: 5,
  },
  itemNotes: {
    fontSize: 12,
    textTransform: 'none',
    fontWeight: 400,
  },
  orderTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: '5px',
  },
  myOrderTitle: {
    fontSize: 14,
  },
});

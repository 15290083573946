export const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

export const componentForm: any = {
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  locality: 'long_name',
  postal_code: 'short_name',
  route: 'long_name',
  street_number: 'short_name',
};

export const fieldMap: any = {
  administrative_area_level_1: 'stateProvince',
  country: 'country',
  locality: 'city',
  postal_code: 'postalCode',
  route: 'address1Route',
  street_number: 'address1Number',
};

let addressParts: any = { address1Number: null, address1Route: null };

export const geocodePlaceId = (placeId: string) => {
  return fetch(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${apiKey}`)
    .then(response => response.json())
    .then(response => response.results[0]);
};

export const handleAddressSearchDone = (selection: any, onFieldChange: (value: string, fieldName: string) => void) => {
  if (selection && selection.place_id) {
    if (!!selection.structured_formatting && !!selection.structured_formatting.main_text) {
      onFieldChange(selection.structured_formatting.main_text, 'name');
    }

    return geocodePlaceId(selection.place_id).then(results => {
      if (results) {
        const latLng = results.geometry.location;

        fillInAddress(results.address_components, onFieldChange);
        onFieldChange(latLng.lat, 'latitude');
        onFieldChange(latLng.lng, 'longitude');
      } else {
        console.log('no results for some reason:', results);
      }
    });
  }
  return Promise.resolve();
};

const fillInAddress = (addressComponents: any[], onFieldChange: (value: string, fieldName: string) => void) => {
  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  if (!!addressComponents) {
    for (const addressComponent of addressComponents) {
      const addressType = addressComponent.types[0];
      const googleComponentName = componentForm[addressType];

      if (!!googleComponentName) {
        const value = addressComponent[googleComponentName];
        const fieldName = fieldMap[addressType];

        fillInField(fieldName, value, onFieldChange);
      }
    }

    const { address1Number, address1Route } = addressParts;
    let address1 = '';

    if (address1Number && address1Route) {
      // after street address components are retrieved, combine them into one string
      address1 = `${address1Number} ${address1Route}`;
    } // else set to empty string if address not provided by place fetch
    onFieldChange(address1, 'address1');
  }
};

const fillInField = (fieldName: string, value: string, onFieldChange: (value: string, fieldName: string) => void) => {
  try {
    switch (fieldName) {
      case 'address1Number':
      case 'address1Route':
        const newAddressParts = {
          ...addressParts,
          [fieldName]: value,
        };

        addressParts = newAddressParts;
        break;
      default:
        onFieldChange(value, fieldName);
    }
  }
  catch (error) {
    console.error(fieldName + 'not found in Google results. Skipping...');
  }
};

import * as React from 'react';
import {
    Theme,
    createStyles,
} from '@mui/material';
import { IconButton } from '@mui/material';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';
import { NavLink } from "react-router-dom";
import { withStyles } from '@mui/styles';
const classNames = require('classnames');

const styles = (theme: Theme) => createStyles({
    scrollIconAlign: {
        float: 'right',
        padding: '0px',
        marginTop: '11px',
        marginRight: '5px',
        transition: '1s',
    },
    scrollIconHidden: {
        opacity: 0.3,
    },
    scrollIcon: {
        fontSize: '25px',
        color: 'rgb(0 0 0 / 87%)',
    },
});

interface IProps {
    classes: any;
    moduleType: string;
    scrollIconClass?: string;
}

interface IState {
    showLeftScroll: boolean;
    showRightScroll: boolean;
}

class ScrollButtons extends React.Component<IProps, IState> {
    public state = {
        showLeftScroll: false,
        showRightScroll: false,
    };

    private clickLeft = (moduleType: string) => {
        const module = document.getElementById(moduleType);
        if (module) {
            const newScrollValue = module.scrollLeft + module.offsetWidth * 1;
            module.scrollLeft = newScrollValue;
            this.checkScrollShow(newScrollValue);
        }

    }

    private clickRight = (moduleType: string) => {
        const module = document.getElementById(moduleType);
        if (module) {
            const newScrollValue = module.scrollLeft - module.offsetWidth * 1;
            module.scrollLeft = newScrollValue < 0 ? 0 : newScrollValue;
            this.checkScrollShow(newScrollValue);
        }

    }

    private checkScrollOnResize = () => {
        this.checkScrollShow();
    }

    private checkScrollShow = (newScrollValue: number = 0) => {
        const module: any = document.getElementById(this.props.moduleType);
        if (!module) {
            return;
        }

        const showLeftScroll = (module.scrollWidth - newScrollValue > module?.parentElement?.offsetWidth) ? true : false;
        const showRightScroll = newScrollValue > 0 ? true : false;

        this.setState({
            showLeftScroll,
            showRightScroll,
        });
    }

    public componentDidMount() {
        this.checkScrollShow();
        window.addEventListener('resize', this.checkScrollOnResize);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.checkScrollOnResize);
    }

    public render() {
        const { classes, moduleType, scrollIconClass } = this.props;
        const { showLeftScroll, showRightScroll } = this.state;

        return (<>
            <IconButton
                type="button"
                disabled={!showLeftScroll}
                className={classNames(classes.scrollIconAlign, { [classes.scrollIconHidden]: !showLeftScroll })}
                onClick={() => this.clickLeft(moduleType)}
                aria-label="scroll-left"
            >
                <ArrowForwardIos sx={{
                    fontSize: '25px',
                    color: 'rgb(0 0 0 / 87%)',
                }} classes={scrollIconClass} />
            </IconButton>
            <IconButton
                type="button"
                disabled={!showRightScroll}
                className={classNames(classes.scrollIconAlign, { [classes.scrollIconHidden]: !showRightScroll })}
                onClick={() => this.clickRight(moduleType)}
                aria-label="scroll-right"
            >
                <ArrowBackIos sx={{
                    fontSize: '25px',
                    color: 'rgb(0 0 0 / 87%)',
                }} classes={scrollIconClass} />
            </IconButton>
        </>);
    }
}

export default withStyles(styles)(ScrollButtons);
import { orderPaymentAPIs } from '../../services/ApiVariables';
import { 
//    ActionType, 
    IAppAction 
} from './Helpers';
import { Dispatch } from "redux";
import { AppState } from "../../state/AppState";
//  import { Spinner } from '../state/Spinner';


export const makePayment = (orderId: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    // dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({message:'' }) });
    return new Promise((resolve, reject) => {
        orderPaymentAPIs.placePayment.id = orderId;
        api(orderPaymentAPIs.placePayment).then((response: any) => {
            resolve(response);
      //      dispatch({ type: ActionType.CLOSE_SPINNER});
        }).catch((data: any) => {
            reject(false);
        //    dispatch({ type: ActionType.CLOSE_SPINNER});
        })
    })
}

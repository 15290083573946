import { Button, Container, Box, Grid, TextField, Stack, SnackbarContent, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../../state/AppState";
import { connect } from "react-redux";
import { restaurantContact } from "../../../store/actions/Contact.Actions";
import { Snackbar } from "@mui/material";
import {Close} from '@mui/icons-material';


const Conact = ({contact,organization,userDetails}: any) => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const onSave = async () => {
    if (!validateEmail(formValues.email)) {
      setEmailError(true);
      setEmailErrorMessage("Invalid email format");
      return;
    }
    try {
      const response = await contact({ ...formValues, organizationId: organization?.id || 0 });
      
      if (response && response.status === 200) {
        setFormValues({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        setEmailError(false);
        setEmailErrorMessage("");
        setSnackbarMessage("Message sent successfully");
        setSnackbarColor("#4caf50"); 
      } else {
        throw new Error("API call failed");
      }
    } catch (error) {
      setEmailError(false);
      setEmailErrorMessage("");
      setSnackbarMessage("Something went wrong");
      setSnackbarColor("#d32f2f"); 
    } finally {
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if(userDetails){
      setFormValues({
        name: userDetails?.firstName + " " + userDetails?.lastName || "",
        phone: userDetails?.phoneNumber || "",
        email: userDetails?.emailAddress || "",
        message: ""
    })
    }
  }, [userDetails])
  

  return (
    <>
    <Box>
      <Container>
        <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}>
          Reach Out and We'll Be in Touch within 24 Hours!
        </p>
        <Grid container spacing={2} alignItems={"stretch"}>
          <Grid item xs={12} sm={12} md={6} height={"100%"}>
            <Stack spacing={"20px"} height={"100%"}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                required
                inputProps={{ maxLength: 100 }}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 16,
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: 16,
                  },
                }}
                value={formValues.name}
                onChange={(e) => {
                  setFormValues({ ...formValues, name: e.target.value });
                }}
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                type="email"
                inputProps={{ maxLength: 100 }}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 16,
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: 16,
                  },
                }}
                required
                value={formValues.email}
                onChange={(e)=>{setFormValues({ ...formValues, email: e.target.value })}}
                error={emailError}
                helperText={emailErrorMessage}
              />
              <TextField
                label="Phone"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 20 }}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 16,
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: 16,
                  },
                }}
                required
                value={formValues.phone}
                onChange={(e) => {
                  setFormValues({ ...formValues, phone: e.target.value });
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} height={"auto"}>
            <TextField
              label="Message"
              variant="outlined"
              multiline
              rows={5}
              fullWidth
              inputProps={{ maxLength: 1000 }}
              required
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: 16,
                },
                "& .MuiInputLabel-root": {
                  fontSize: 16,
                },
              }}
              value={formValues.message}
              onChange={(e) => {
                setFormValues({ ...formValues, message: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <Box pt={2} display={"flex"} justifyContent={"flex-end"}>
          <Button
            onClick={onSave}
            disabled={
              formValues.name === "" ||
              formValues.email === "" ||
              formValues.phone === "" ||
              formValues.message === ""
            }
            sx={{
              width: 140,
              paddingY: 0,
              paddingTop: 0.3,
              border: (theme) => `solid 1px ${theme.palette.secondary.main}`,
              color: (theme) => theme.palette.secondary.main,
              fontSize: "14px !important",
              borderRadius: "5px",
              height: "40px",
            }}
          >
            Send Message
          </Button>
        </Box>
      </Container>
    </Box>
    <Snackbar
    open={snackbarOpen}
    autoHideDuration={3000}
    onClose={() => setSnackbarOpen(false)}
>
            <SnackbarContent 
              sx={{ backgroundColor: snackbarColor }}
              message={<Stack direction={'row'} alignItems={'center'} spacing={2}>
              <span>{snackbarMessage}</span>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  setSnackbarOpen(false)
                }}
                 >
                <Close fontSize="small" />
            </IconButton>
            </Stack>}
              />
      </Snackbar>
    </>

  );
};


const mapStateToProps = (state: AppState) => ({
  organization: state?.organizations?.organization,
  userDetails: state?.organizations?.userDetails
})

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({
       contact: restaurantContact,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Conact);

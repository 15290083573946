import axios from "axios";

const fetchCoordinates = async (address: string) => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY
    const formattedAddress = encodeURIComponent(address);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=${apiKey}`;
  
    try {
      // Using Axios
      const response = await axios.get(url);
      const data = response.data;
      
      if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      } else {
        console.error('Geocoding error:', data.status);
        return null;
      }
    } catch (error) {
      console.error('Failed to fetch coordinates:', error);
      return null;
    }
  };

  export default fetchCoordinates
import { ActionType, IAppAction } from './Helpers';
import { Dispatch } from 'redux';
import { AppState } from '../../state/AppState';
import { itemsAPIs } from '../../services/ApiVariables';
import IMenu from '../../models/IMenu';

export const getMenuCategories = (menuId: number) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    const id = getState().organizations?.organizationID || -1;
    dispatch({ type: ActionType.SET_MENU_CATEGORIES, payload: null });
    return fetchMenuCategories(id, menuId,dispatch, api)
        .then((response: any) => {
            return response;
        })
        .catch((response: any) => {
            return response;
        });
}

const fetchMenuCategories = (organizationID: number | string, menuId: number, dispatch: Dispatch<IAppAction>, api: any) => {
    return api(itemsAPIs.getMenuCategories(organizationID, menuId))
        .then((response: any) => {
            dispatch({ type: ActionType.SET_MENU_CATEGORIES, payload: response?.data?.menuCategories });
            return response;
        }).catch((response: any) => {
            return response;
        });
}

export const searchMenuItems = (params: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return fetchMenuItems(params, dispatch, api)
    .then((response: any) => {
        return response;
    })
}

const fetchMenuItems = (params: any, dispatch: Dispatch<IAppAction>, api: any) => {
    return api(itemsAPIs.menuItemSearch(params)).then((response: any) => {
        dispatch({ type: ActionType.GET_MENU_ITEMS, payload: response?.data });
        return response;
    })
}

export const getMenus = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    const id = getState().organizations?.organizationID || -1;
    return fetchMenus(id, dispatch, api)
        .then((response: any) => {
            return response;
        })
        .catch((response: any) => {
            return response;
        });
}

const fetchMenus = (organizationID: number | string, dispatch: Dispatch<IAppAction>, api: any) => {
    return api(itemsAPIs.getMenus(organizationID))
        .then((response: any) => {
            const menuId = new URLSearchParams(window?.location?.search)?.get("id");
            const menus = response?.data.filter((menu: IMenu) => menu.visibilityStatus === 'Active' || menu.id === Number(menuId));
            dispatch({ type: ActionType.SET_MENUS, payload:  menus });
            // if(activeMenus?.length > 0){
            //     dispatch({ type: ActionType.SET_SELECTED_MENU_ID, payload: activeMenus[0].id });
            // }
            return  menus;
        }).catch((response: any) => {
            return response;
        });
}

export const setSelectedMenu = (selectedMenuId: number) => (dispatch: Dispatch<IAppAction>) => {
    dispatch({ type: ActionType.SET_SELECTED_MENU_ID, payload: selectedMenuId });
  }
  

  export const getOrderHistoryItems = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return api(itemsAPIs.getOrderHistoryItems())
        .then((response: any) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
}


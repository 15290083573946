import { coverApi } from '../../api';
import { ActionType, IAppAction } from '../actions/Helpers';
import { Dispatch } from "redux";
import { AppState } from "../../state/AppState";
import { Spinner } from '../../state/Spinner';
import IMenuCategoryItem from '../../models/IMenuCategoryItem';
import IOrderItem from '../../models/IOrderItem';
import IMenuCategory from '../../models/IMenuCategory';
import IMenu from '../../models/IMenu';
import { MenuList } from '../../data/OnlineOrder';
export const getMenuDetailsList = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });

  return coverApi.getOnlineOrderMenu()
    .then((response: any) => {
      const menus = response.parsedBody as IMenu[];


      dispatch({ type: ActionType.GET_MENU_LIST, payload: menus || [] });
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return response;
    })
    .catch((error: any) => {
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return error;
    });
}

export const getMenuDetailsListStatic = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState) => {
  dispatch({ type: ActionType.GET_MENU_LIST, payload: MenuList });
}


export const setSelectedMenu = (selectedMenuId: number) => (dispatch: Dispatch<IAppAction>) => {
  dispatch({ type: ActionType.SET_SELECTED_MENU, payload: selectedMenuId });
}

export const updateSelectedCategoryMenuById = (categoryMenuId: number) => (dispatch: Dispatch<IAppAction>, getState: () => AppState) => {
  const findMenuById = (menuId: number, menuCategories?: IMenuCategory[]): IMenuCategory | null => {
    if (!menuCategories) {
      return null;
    }

    for (const menuCategory of menuCategories) {
      if (menuCategory.id === menuId) {
        return menuCategory;
      }

      const subMenuCategory = findMenuById(menuId, menuCategory?.menuCategories);
      if (subMenuCategory) {
        return subMenuCategory;
      }
    }

    return null;
  }

  const { menu } = getState();
  const selectedMenu = findMenuById(categoryMenuId, menu.onlineMenu.menuCategories);

  dispatch({ type: ActionType.UPDATE_SELECTED_CATEGORY_MENU_ITEMS, payload: selectedMenu });
};

export const selectedCategoryMenuProduct = (menuCategoryItem: IMenuCategoryItem, orderItem: IOrderItem): IAppAction => {
  return {
    type: ActionType.SELECTED_CATEGORY_MENU_PRODUCT,
    payload: {
      menuCategoryItem,
      orderItem,
    },
  }
};

export const setSearchValue = (searchValue: string): IAppAction => {
  return {
    type: ActionType.SET_SEARCH_VALUE,
    payload: searchValue
  }
};

import { Model } from "./Helpers";

interface IAlertButtonOptions {
    label: string;
    handler: () => void;
}

export interface IAlert {
    alertType?: string | null;
    message: string | null;
    buttons?: IAlertButtonOptions[] | null;
}

export const AlertModel = Model<IAlert>({
    alertType: null,
    message: null,
    buttons: null
});

export class Alert extends AlertModel {
    public alertType!: string;
    public message!: string;
    public buttons!: IAlertButtonOptions[];

    constructor(data: IAlert) {
        super(data);
    }
}
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { createStyles } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { useState } from 'react';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { connect } from 'react-redux';

const classNames = require('classnames');

const styles = (theme: any) => createStyles({
    rootModifire: {
        width: 'auto',
        borderRadius: '8px !important',
        border: '1px solid gray',
        marginBottom: '16px',
    },
    panelExpanded: {
        margin: '20px 0',
    },
    heading: {
    },
    panelDetails: {
        paddingTop: '10px',
    },
    modifierContainer: {
        width: '100%',
        marginTop: '-10px',
    },
    modifierInlineOption: {
        display: 'inline-block',
    },
    childContainer: {
        width: '100%',
        marginTop: 10,
        marginLeft: '5%',
    },
    formControlLabel: {
        fontSize: '12px !important',
        // fontWeight: "bold !important"
    },
    sizeIcon: {
        width: 20,
        height: 20,
    },
    buttonOption: {
        fontSize: '14px',
        lineHeight: '14px',
        marginRight: 10,
        marginBottom: 10,
    },
    optionMessage: {
        fontWeight: 300,
        fontSize: '12px',
    },
    dropdownOptions: {
        minWidth: '50%',
        marginBottom: 10,
    },
    dropdownSelect: {
        padding: '2px 20px 0px 10px',
        fontSize: '14px',
    },
    dropdownIcon: {
        height: 25,
        width: 25,
    },
    dropdownMenuItem: {
        fontSize: '14px',
    },
    errorMessage: {
        color: '#ff0000',
        transition: '0.5s',
        opacity: 0,
    },
    errorMessageActive: {
        marginLeft: 5,
        opacity: 1,
        marginBottom: 5
    },
});

interface IProps {
    classes?: any;
    theme?: any;
    modifiers: any;
    onOptionSelect: (menuItemModifierId: any, optionIndex: number, type: string) => void;
    onSubOptionSelect: (menuItemModifierId: any, optionIndex: number, value: any) => void;
    organizationSettings: any;
};

const ProductModifiersNew = (props: IProps) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [errorModifierId, setErrorModifierId] = useState(0);
    const { classes, modifiers, onOptionSelect, onSubOptionSelect, organizationSettings } = props;
    const handleSelect = (menuItemModifierId: any, optionIndex: number, type: string) => {
        setErrorMessage(``);
        setErrorModifierId(0);
        if(type==="Checkbox"){
            const arr = [...modifiers];
            const findModifier = arr?.find((modi: any)=> modi.id===menuItemModifierId);
            const options = [...findModifier.options]
            const flag = !options[optionIndex].checked
            const selectedOptions = options?.filter((item: any) => item.checked === true);
            const selectedOptionsLength = flag ? (selectedOptions?.length + 1): (selectedOptions?.length - 1)
            const modifierOption = findModifier.options[optionIndex]
            const min = findModifier?.minimumQuantity;
            const max = findModifier?.maximumQuantity;

            // if(selectedOptionsLength < min){
            //     setErrorMessage(`Min limit is ${min}`);
            //     setErrorModifierId(modifierOption?.id);
            // }
            // else if(max!==0 && selectedOptionsLength > max){
            //     setErrorMessage(`Max limit is ${max}`);
            //     setErrorModifierId(modifierOption?.id);
            // }
            // else{
                onOptionSelect(menuItemModifierId, optionIndex, type)
            // }
        }
        else{
            onOptionSelect(menuItemModifierId, optionIndex, type)
        }
    }   

    const sortOptions = (options: any) => {
        if (options) {
            return options
                .sort((a: any, b: any) => {
                    if (a.menuItemId < b.menuItemId) { return -1; }
                    if (a.menuItemId > b.menuItemId) { return 1; }
                    return 0;
                })
                .sort((a: any, b: any) => {
                    if (a.sortOrder < b.sortOrder) { return -1; }
                    if (a.sortOrder > b.sortOrder) { return 1; }
                    return 0;
                });
        }

        return [];
    }

    const renderErrorMessage = (menuItemModifier: any) => {
        const showError = menuItemModifier.id === errorModifierId;
        return (
            <div className={
                showError
                    ? `${classes.errorMessage} ${classes.errorMessageActive}`
                    : classes.errorMessage
            }>
                <span style={{ display: showError ? 'inline' : 'none' }}>{errorMessage}</span>
            </div>
        )
    }

    const renderSelectboxOptions = (menuItemModifierId: any, optionIndex: any, option: any) => {
        if(option?.subOptions?.length === 0){
            return null
        }
        return <FormControl 
            variant="standard" 
            sx={{ m: 1, minWidth: 90, "label":{
                fontSize: "0.8rem",
                color: "#00000099 !important"
            }}}
        >
        <InputLabel id={`standard-label-subOptions-${option?.id}`}>{option?.selectedSubOption ? null: "Options"}</InputLabel>
        <Select
            IconComponent={ExpandMoreIcon}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                },
                PaperProps: {
                    sx: {
                    '.MuiMenuItem-root': {
                        fontSize: '12px',
                    },
                    }
                },
            }}
            onChange={(e)=> {onSubOptionSelect(menuItemModifierId, optionIndex, e.target.value)}}
            sx={{
                fontSize: '12px !important',
                fontWeight: "bold !important", 
                height: '25px !important',
                '.MuiSvgIcon-root': { 
                fontSize: '16px !important'
                },
            }}
            value={option?.selectedSubOption}
            labelId={`standard-label-subOptions-${option?.id}`}
            label="Options"
            renderValue={(selected) => {
                return selected!==null ? option?.subOptions?.find((subOpt: any)=> subOpt.id===selected)?.name : null
            }}
        >
            {option?.subOptions?.map((subOption: any)=> {
                return <MenuItem value={subOption?.id}>{subOption?.name} {subOption?.price ? ` + $${subOption?.price}`: ""}</MenuItem>
            })}
        </Select>
      </FormControl>
    }

    const renderCheckbox = (option: any, menuItemModifierId: any, optionIndex: number) => {
        return (
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Box sx={{flex: 1}}>
                    <FormControlLabel
                        classes={{ label: classNames(classes.formControlLabel) }}
                        label={<>
                            <span>
                                {option.name}
                            </span>
                            <span className={classes.optionMessage}>
                                {getOptionMessage(option)}
                            </span>
                        </>}
                        sx={{
                            '& .MuiCheckbox-root':{
                                marginLeft: 1,
                                width: "30px",
                                height: '30px'
                            },
                            '& .MuiSvgIcon-root ':{
                                width: '0.6em',
                                height: '0.6em'
                            }
                        }}
                        control={
                            <Checkbox
                                checked={option.checked}
                                color='secondary'
                                name={option.name}
                                size='small'
                                icon={<CheckBoxOutlineBlank className={classes.sizeIcon} />}
                                checkedIcon={<CheckBox className={classNames(classes.sizeIcon)} />}
                                onClick={()=> handleSelect(menuItemModifierId, optionIndex, "Checkbox")}
                                sx={{
                                    '&.Mui-checked': {
                                        color: organizationSettings?.layoutColor,
                                    },
                                }}
                            />
                        }
                    />
                </Box>
                {option.checked ? renderSelectboxOptions(menuItemModifierId, optionIndex, option): null}
            </Stack>
        )
    }

    const renderCheckboxOptions = (menuItemModifier: any) => {
        return sortOptions(menuItemModifier.options)?.map((option: any, optionIndex: number) =>
            <div key={option.id}>
                {renderCheckbox(option, menuItemModifier.id, optionIndex)}
                {renderErrorMessage(option)}
            </div>
        )
    }

    const renderRadio = (option: any, menuItemModifierId: any, optionIndex: number) => {
        return (
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Box sx={{flex: 1}}>
                    <FormControlLabel
                        classes={{ label: classNames(classes.formControlLabel) }}
                        label={<>
                            <span>
                                {option.name}
                            </span>
                            <span className={classes.optionMessage}>
                                {getOptionMessage(option)}
                            </span>
                        </>}
                        sx={{
                            '& .MuiCheckbox-root':{
                                marginLeft: 1,
                                width: "30px",
                                height: '30px'
                            },
                            '& .MuiSvgIcon-root ':{
                                width: '0.6em',
                                height: '0.6em'
                            }
                        }}
                        control={
                            <Checkbox
                                checked={option.checked}
                                color='secondary'
                                name={option.name}
                                size='small'
                                icon={<RadioButtonUncheckedIcon className={classes.sizeIcon} />}
                                checkedIcon={<RadioButtonCheckedIcon className={classNames(classes.sizeIcon)} />}
                                onClick={()=> handleSelect(menuItemModifierId, optionIndex, "Radio")}
                                sx={{
                                    '&.Mui-checked': {
                                        color: organizationSettings?.layoutColor,
                                    },
                                }}
                            />
                        }
                    />
                </Box>
                {renderSelectboxOptions(menuItemModifierId, optionIndex, option)}
            </Stack>
        )
    }

    const renderRadioOptions = (menuItemModifier: any) => {
        return sortOptions(menuItemModifier.options)?.map((option: any, optionIndex: number) =>
            <div key={option.id}>
                {renderRadio(option, menuItemModifier.id, optionIndex)}
                {renderErrorMessage(option)}
            </div>
        )
    }

    // useEffect(()=>{
    //     console.log(modifiers)
    // }, [modifiers])

    const getQuantityMessage = (menuItemModifier: any): string => {
        let message = '';
        // if (menuItemModifier.rule === 'PickOne') {
        //     return message;
        // }
        if (!!menuItemModifier.minimumQuantity) {
            message += `min ${menuItemModifier.minimumQuantity}`;
        }
        if (!!menuItemModifier.minimumQuantity && !!menuItemModifier.maximumQuantity) {
            message += ' and '
        }
        if (!!menuItemModifier.maximumQuantity) {
            message += `max ${menuItemModifier.maximumQuantity}`;
        }

        return message;
    }

    const getOptionMessage = (menuItemModifier: any): string => {
        let priceMessage = menuItemModifier.price ? `+$${menuItemModifier.price.toFixed(2)}` : '';
        const quantityMessage = getQuantityMessage(menuItemModifier);

        if (!!priceMessage && !!quantityMessage) {
            priceMessage += ' ';
        }

        return !!priceMessage || !!quantityMessage ? ` (${priceMessage}${quantityMessage})` : '';
    }


    if (modifiers?.length === 0) {
        return null;
    }

    return (
        <>
        {sortOptions(modifiers).map((menuItemModifier: any, index: number) => (
            <Accordion
                key={`menu-item-modifier-id-${menuItemModifier.id}`}
                className={classes.rootModifire}
                classes={{ expanded: classes.panelExpanded }}
                elevation={0}
                disableGutters
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-content-modifire-id-${menuItemModifier.id}`}
                    id={`panel-root-modifire-id-${menuItemModifier.id}`}
                    sx={{
                        height: '38px !important',
                        '.MuiSvgIcon-root': {
                            fontSize: '28px !important'
                        },
                    }}
                >
                    <Typography className={classes.heading} sx={{fontSize: '16px !important'}}>
                        {menuItemModifier.name}
                        <span className={classes.optionMessage}>
                            {getOptionMessage(menuItemModifier)}
                        </span>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.panelDetails}>
                    <div key={`menu-item-modifier-id-${menuItemModifier.id}`} className={classes.modifierContainer}>
                        {menuItemModifier.rule === 'PickMany'
                            ? renderCheckboxOptions(menuItemModifier)
                            : renderRadioOptions(menuItemModifier)
                        }
                     </div>
                </AccordionDetails>
            </Accordion>
        ))}
        </>
    );
}

const mapStateToProps = (state: any) => ({
    organizationSettings: state?.organizations?.organizationSettings,
});

export default connect(mapStateToProps, null)(withStyles(styles as any)(ProductModifiersNew as any));

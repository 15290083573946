import IAddress from '../../models/IAddress';
import { coverApi } from '../../api';
import { ActionType, IAppAction } from '../actions/Helpers';
import { Dispatch } from "redux";
import { AppState } from "../../state/AppState";
import { Spinner } from '../../state/Spinner';
import ICustomer from '../../models/ICustomer';
import ICustomerMessage from '../../models/ICustomerMessage';

export const getCustomer = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });

  return coverApi.getCustomer()
    .then((response: any) => {
      const customer = response.parsedBody as ICustomer;
      dispatch({ type: ActionType.SET_CUSTOMER, payload: customer });
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return response;
    })
    .catch((error: any) => {
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return error;
    });
}

export const updateCustomer = (customer: ICustomer) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });

  return coverApi.updateCustomer(customer)
    .then((response: any) => {
      const updatedCustomer = response.parsedBody as ICustomer;
      dispatch({ type: ActionType.SET_CUSTOMER, payload: updatedCustomer });
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return response;
    })
    .catch((error: any) => {
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return error;
    });
}

export const sendCustomerMessage = (customerMessage: ICustomerMessage) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });

  return coverApi.sendCustomerMessage(customerMessage)
    .then((response: any) => {
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return response;
    })
    .catch((error: any) => {

      dispatch({ type: ActionType.CLOSE_SPINNER });

      return error;
    });
}



export const GetAddresses = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });

  return coverApi.GetAddress()
    .then((response: any) => {
      const addresses = response.parsedBody as IAddress[];
      dispatch({ type: ActionType.SET_ADDRESSES, payload: addresses });
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return response;
    })
    .catch((error: any) => {
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return error;
    });
}

export const UpsertAddress = (address: IAddress) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });

  if (address.default) {

    const { customerAddresses } = getState();

    const defaultAddresses = customerAddresses?.filter(a => a.default && a.id !== address.id)
    if (defaultAddresses) {
      defaultAddresses.forEach(a => {
        a.default = false;
        UpsertAddress(a)(dispatch, getState, api);
      });
    }
  }

  return coverApi.UpsertAddress(address).then((response: any) => {
    const upsertedAddress = response.parsedBody as IAddress;
    if (address.id) {
      dispatch({ type: ActionType.UPDATED_ADDRESS, payload: upsertedAddress });
    } else {
      dispatch({ type: ActionType.CREATED_ADDRESS, payload: upsertedAddress });
    }
    dispatch({ type: ActionType.CLOSE_SPINNER });

    return response;
  })
    .catch((error: any) => {
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return error;
    });
}

export const DeleteAddress = (addressId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });

  return coverApi.DeleteAddress(addressId).then((response: any) => {
    dispatch({ type: ActionType.DELETE_ADDRESS, payload: addressId });
    dispatch({ type: ActionType.CLOSE_SPINNER });

    return response;
  })
    .catch((error: any) => {
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return error;
    });
}
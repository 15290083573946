import React, { useEffect, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Divider, IconButton, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IOrderItem from '../models/IOrderItem';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 60,
    },
    dropdownSelect: {
        padding: '2px 20px 0px 10px',
        textAlign: 'center',
    },
    dropdownIcon: {
        height: 20,
        width: 20,
    },
    inputOptions: {
        height: "35px",
        width: "106px",
        paddingTop: "6px",
        paddingBottom: "6px",
        fontSize: '16px',
        minWidth: '20px',
        fontWeight: 'bold',
        borderRadius: '4px',
    },
    inputButton: {
        height: 40,
    },
    updateIcon: {
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        cursor: 'pointer',
    },
    updateButton: {
        padding: 0,
    },
}));

interface ItemQtyDropdownProps {
    orderItem?: any;
    onUpdateItemQuantity: (orderItem: IOrderItem, newQuantity: number) => void;
    className?: any;
    dropdownOptions?: any;
    autofocus?: boolean;
}

const ItemQtyDropdown = ({
    orderItem,
    onUpdateItemQuantity,
    className,
    dropdownOptions,
    autofocus = false
}: ItemQtyDropdownProps) => {
    const classes = useStyles();
    const [quantity, setQuantity] = React.useState<number>(orderItem?.quantity);
    // This is used so that the text input can always be autofocused when the quantity is manually set to "10+"
    const [quantityChanged, setQuantityChanged] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [showInput, setShowInput] = React.useState(orderItem?.quantity >= 10);
    const [showUpdateButton, setShowUpdateButton] = React.useState(false);
    const timeoutRef = useRef<any>(null);

    useEffect(() => {
        setQuantity(orderItem.quantity);

        if (orderItem.quantity >= 10) {
            setShowInput(true);
        } else {
            setShowInput(false);
        }
    }, [orderItem]);

    const updateItemQuantity = (newQuantity: number) => {
        onUpdateItemQuantity(
            orderItem,
            newQuantity,
        );
    }

    const onQuantityChange = (event: any) => {
        if (event.target.value) {
            setQuantity(event.target.value);
            updateItemQuantity(event.target.value);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const addTenPlusQuantity = () => {
        setQuantityChanged(true);
        setShowInput(true);
    };

    const onTenPlusChange = (event: any) => {
        let inputValue = event.target.value;
    
        if (inputValue.length > 3) {
            inputValue = inputValue.slice(0, 3);
        }
    
        setQuantity(inputValue);
    
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    
        if (inputValue.length > 0) {
            timeoutRef.current = setTimeout(() => {
                const numericValue = parseInt(inputValue, 10);
    
                if (numericValue >= 10 && numericValue <= 999) {
                    updateItemQuantity(numericValue);
                    setShowUpdateButton(false);
                } else if (numericValue >= 1 && numericValue < 10) {
                    updateItemQuantity(numericValue); 
                    setShowUpdateButton(false);
                }
            }, 1500);
        }
    };
    
    
    const updateNewQuantity = () => {
        const newQuantity = quantity <= 999 ? quantity : 999;
    
        if (newQuantity < 10) {
            setShowInput(false);
        }
        setShowUpdateButton(false);
        updateItemQuantity(newQuantity);
    };
    
    const enterPressed = (event: any) => {
        if (event.key !== 'Enter') {
            return;
        }
        event.target.blur();
        updateNewQuantity();
    };

    return (
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 54 }} >
            {!showInput &&
                <Select
                    name='quantity'
                    classes={{ select: classes.dropdownSelect, icon: classes.dropdownIcon }}
                    // label="Qty"
                    color='secondary'
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={quantity}
                    onChange={onQuantityChange}
                    IconComponent={ExpandMoreIcon}
                    SelectDisplayProps={{
                        style: {
                            paddingTop: '6px',
                            paddingBottom: '6px',
                            borderRadius: '8px',
                            width: '60px',
                            textAlign: 'left',
                        }
                    }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                        // getContentAnchorEl: null
                        PaperProps: {
                            sx: {
                                '.MuiMenuItem-root': {
                                    fontSize: '16px',
                                },
                            }
                        }
                    }}
                    sx={{
                        fontSize: '16px !important',
                        fontWeight: "bold !important",
                        // height: '25px !important',
                        // paddingLeft: '12px !important',
                        '.MuiSvgIcon-root': { 
                            fontSize: '28px !important'
                        },
                    }}
                >
                    <MenuItem className={dropdownOptions} value={1}>1</MenuItem>
                    <MenuItem className={dropdownOptions} value={2}>2</MenuItem>
                    <MenuItem className={dropdownOptions} value={3}>3</MenuItem>
                    <MenuItem className={dropdownOptions} value={4}>4</MenuItem>
                    <MenuItem className={dropdownOptions} value={5}>5</MenuItem>
                    <MenuItem className={dropdownOptions} value={6}>6</MenuItem>
                    <MenuItem className={dropdownOptions} value={7}>7</MenuItem>
                    <MenuItem className={dropdownOptions} value={8}>8</MenuItem>
                    <MenuItem className={dropdownOptions} value={9}>9</MenuItem>
                    <Divider />
                    <MenuItem className={dropdownOptions} value={10} onClick={addTenPlusQuantity}>10 +</MenuItem>
                </Select>}
            {showInput &&
                <Box sx={{ display: "flex" }}>
                    <input
                        id="quantity"
                        name="quantity"
                        type="number"
                        className={classes.inputOptions}
                        onChange={onTenPlusChange}
                        value={quantity}
                        onKeyDown={enterPressed}
                        autoFocus={autofocus || quantityChanged}
                        onFocus={(event) => event.target.select()}
                    />
                    {showUpdateButton &&
                        <div className={classes.updateIcon}>
                            <Tooltip title="Update Quantity">
                                <IconButton
                                    aria-label="update"
                                    className={classes.updateButton}
                                    onChange={onTenPlusChange}
                                    onClick={updateNewQuantity}>
                                    <AutorenewIcon
                                        color="action"
                                        style={{ fontSize: 24 }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                </Box>}
        </FormControl>
    );
};
export default ItemQtyDropdown;
import moment from "moment";

interface DayTiming {
    timings: string;
}

type WeeklySchedule = Record<string, DayTiming>;

export const getDay = (date?:Date) => {
    const currentDate = date ? new Date(date) : new Date();
    const dayOfWeek = currentDate?.getDay();
    const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const currentDay = daysOfWeek[dayOfWeek];
    return currentDay;
}

export const checkIfOpenUsingHoursandScheduledTime = (openFrom: string, openTo: string, scheduleTime: string) => {
    const openingTime = new Date(`${new Date().toISOString().split('T')[0]}T${openFrom}Z`);
    const closingTime = new Date(`${new Date().toISOString().split('T')[0]}T${openTo}Z`);
    const currentTimeDate = new Date(`${new Date().toISOString().split('T')[0]}T${scheduleTime}Z`);
    if (closingTime < openingTime) {
        closingTime.setDate(closingTime.getDate() + 1);
    }
    return (currentTimeDate >= openingTime && currentTimeDate <= closingTime);
};

export const checkIfOpenUsingHours = (openFrom: string, openTo: string) => {
    const now = new Date();
    const currentTime = now.toISOString().split('T')[1].substring(0, 8);
    const openingTime = new Date(`${new Date().toISOString().split('T')[0]}T${openFrom}Z`);
    const closingTime = new Date(`${new Date().toISOString().split('T')[0]}T${openTo}Z`);
    const currentTimeDate = new Date(`${new Date().toISOString().split('T')[0]}T${currentTime}Z`);
    if (closingTime < openingTime) {
        closingTime.setDate(closingTime.getDate() + 1);
    }
    return (currentTimeDate >= openingTime && currentTimeDate < closingTime);
};

export const isClosed = (organizationSettings: any, organizationOrdersOpenSchedule: any) => {
    const daySettings = organizationOrdersOpenSchedule ? organizationOrdersOpenSchedule[getDay()]: null;
    if(organizationSettings){
        if(organizationSettings?.ordersHolidays?.some(
            (holiday: any) =>
              moment(holiday.holidayDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          )){
            return true;
        } 
        else if(!organizationSettings?.enableOrders){
            return true;
        }
        else if(daySettings?.closedAllDay){
            return true;
        }
        else if(daySettings?.openAllDay === false && daySettings?.closedAllDay === false){
            return !checkIfOpenUsingHours(daySettings?.openFrom, daySettings?.openTo)
        }
        else{
            return false
        }
    }
    return false;
}

export const isOpenHoursClosed = (organizationSettings: any, organizationOrdersOpenSchedule: any) => {
    const daySettings = organizationOrdersOpenSchedule ? organizationOrdersOpenSchedule[getDay()]: null;
    if(organizationSettings){
        if(organizationSettings?.ordersHolidays?.some(
            (holiday: any) =>
              moment(holiday.holidayDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          )){
            return true;
        } 
        else if(daySettings?.closedAllDay){
            return true;
        }
        else if(daySettings?.openAllDay === false && daySettings?.closedAllDay === false){
            return !checkIfOpenUsingHours(daySettings?.openFrom, daySettings?.openTo)
        }
        else{
            return false
        }
    }
    return false;
}

export const roundToNext15Min = (date:any) => {
    const remainder = 15 - date.minute() % 15;
    if (remainder !== 15) {
        date.add(remainder, 'minutes'); // Only add remainder if it's not already on a 15 minute interval
    }
    return date;
}

export const getWeeklySchedule = (organizationOrdersOpenSchedule:any) => {
    const days: (keyof any)[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const schedule: WeeklySchedule = {};
    if (!organizationOrdersOpenSchedule) {
        return schedule;
    }
    days.forEach((day:any) => {
        const daySettings = organizationOrdersOpenSchedule[day];
        const openFrom = `${new Date().toISOString().split('T')[0]}T${daySettings?.openFrom}Z`;
        const openTo = `${new Date().toISOString().split('T')[0]}T${daySettings?.openTo}Z`;
        if (daySettings?.closedAllDay) {
            schedule[day] = { timings: "Closed" };
        } else if (daySettings?.openFrom && daySettings?.openTo) {
            schedule[day] = { timings: `${moment(new Date(openFrom)).format("h:mmA")}-${moment(new Date(openTo)).format("h:mmA")}`};
        }
        else{
            schedule[day] = { timings: "-" };
        }
    });
    return schedule;
};

export const getFormattedHolidays = (organizationSettings: any) => {
    const holidays = organizationSettings?.ordersHolidays || [];

    if(!organizationSettings){
        return []
    }

    return holidays?.map((holiday: any) => {
        const date = moment(holiday?.holidayDate);
        return {
            date: date.format('MMMM, DD YYYY'),
            day: date.format('dddd')
        };
    });
};
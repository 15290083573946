import axios from "axios"
import Auth0Lock from "auth0-lock";
// import { history } from "../store/Store"
// import { inactivityLogoutRemoveListener }from "./HelperFunctions"

export const axiosInstance = axios.create({
    // baseURL:  process.env.REACT_APP_BASE_URL,
    headers: {
        // "Cache-Control":"no-cache"
        "Accept": 'application/json;odata.metadata=minimal;odata.streaming=true',
        'Content-Type': 'application/json;odata.metadata=minimal;odata.streaming=true',
        // 'Authorization': `Bearer ${localStorage.getItem(keys.authAccessToken)}`
    },
});



export const Auth0LockOptions: Auth0LockConstructorOptions = {
    theme: {
        logo: window.location.origin+'/static/images/logos/defaultLogo.svg', 
        primaryColor: '#ea5323',
        hideMainScreenTitle:true,
      },
    auth: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect: true,
        redirectUrl: window.location.origin,
        responseType: 'token id_token',
        sso: false,
        autoParseHash: true,
        params: {
            scope: "openid profile email name"
           },
      },
    loginAfterSignUp:true,
    additionalSignUpFields: [{
        name: "given_name",
        placeholder: "Enter your First Name",
        storage: "root",
        icon: window.location.origin+"/static/images/user.png",
        validator: function(first_name:string) {
            if(first_name.length<=0) {
                return {
                    valid: false,
                    hint: "First Name can't be blank"
                };
            }
            return {
                valid: first_name.length >= 0,
                hint: "Must have 1 or more characters"
            };
        }
    },{
        name: "family_name",
        placeholder: "Enter your Last Name",
        storage: "root",
        icon: window.location.origin+"/static/images/user.png",
        validator: function(family_name:string) {
            if(family_name.length<=0) {
                return {
                    valid: false,
                    hint: "Last Name can't be blank"
                };
            }
            return {
                valid: family_name.length >= 0,
                hint: "Must have 1 or more characters"
            };
        }
    },
    {
        name: "phoneNumber",
        placeholder: "Enter your phone number",
        icon: window.location.origin+"/static/images/phone_icon.png",
        validator: function(phoneNumber) {
          return {
            valid: (phoneNumber?.match(/\d/g) || [])?.length === 10,
            hint: "Please enter 10-digit phone number"
          }
        }
    }

]
};

export const Auth0LockGraze = new Auth0Lock(process.env.REACT_APP_AUTH0_CLIENT_ID || '' , process.env.REACT_APP_AUTH0_DOMAIN || '',Auth0LockOptions);

export const Auth0WithOutRedirect = new Auth0Lock(process.env.REACT_APP_AUTH0_CLIENT_ID || '' , process.env.REACT_APP_AUTH0_DOMAIN || '',Auth0LockOptions);

import * as React from 'react';
import { Box, CardContent, CardMedia, Stack, Theme } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
// import { RouteComponentProps } from "react-router-dom";
// import { withRouter } from "react-router-dom";
// import Button from '@mui/material/Button';
import { showHtml } from '../services/HelperFunctions';
import ImgPlaceholder from './ImgPlaceholder';
import IMenuCategoryItem from '../models/IMenuCategoryItem';
// import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { formatMoney } from '../pipes/PriceFormatter';
import { connect } from 'react-redux';
interface IEnhancedFeatureCardProps {
    onRequestSort?: (event: any, property: any) => any;
    onSelectAllClick?: () => void;
    classes?: any;
    selected?: number;
    id?:any;
    count?: number;
    onClick: () => void;
    menuCategoryItem: IMenuCategoryItem;
    history: any;
    organizationSettings: any;
}

class EnhancedFeatureCard extends React.Component<{} & IEnhancedFeatureCardProps, {}> {

    public render() {
        const { classes, menuCategoryItem, onClick, organizationSettings,id} = this.props;
        const { name, imageUrl, description, price } = menuCategoryItem;

        return (
            <Box sx={{ width: { xs: '100%', sm: '350px', md: '380px' }}} mt={2} mb={2} onClick={() => onClick()} id={id}>
                <Card elevation={0} sx={{ height: 'auto', borderRadius: '16px', cursor: 'pointer', boxShadow: '14px 16px 50px 14px rgba(243 243 246)'}}>
                    <CardMedia sx={{ height: 'auto' }}>
                        <ImgPlaceholder 
                            className={classes.media}
                            name={name}
                            imageUrl={imageUrl} />
                    </CardMedia>
                    <CardContent sx={{borderRadius: '16px', p: '10px !important', height: '132px', mb: '20px'}}>
                        <Stack>
                            <Typography sx={{
                                fontSize: { xs: "20px" },
                                height: "35px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}>
                                {showHtml(name ? name : '')}
                            </Typography>
                        </Stack>
                        <Stack sx={{ height: '72px' }}>
                            <Typography
                                sx={{
                                    fontSize: { xs: "18px"},
                                    color: 'gray',
                                    lineHeight: 1.2,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: { xs: "3" },
                                    WebkitBoxOrient: "vertical",
                                }}>
                                {showHtml(description ? description : '')}
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography
                                sx={{
                                    fontSize: { xs: '18px'},
                                    fontWeight: 500,
                                    color: organizationSettings?.layoutColor,
                                }}>
                                {price === 0 ? "" : "$" + formatMoney(price)}
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>
            </Box>
        );
    }
}

const styles = (theme: Theme) => ({
    priceWrap: {
        display: "flex",
        flexDirection: "row",
        margin: "5px"
    },
    orderButton: {
        width: '95%',
        fontSize: 20,
        borderRadius: '50px',
        bottom: '0px',
        margin: 'auto',
        display: 'block',
    },
    orderButtonLabel: {
        margin: '-10px',
        fontWeight: 400,
    },
    titleTitleText: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 20,
        [theme.breakpoints.down('md')]: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        [theme.breakpoints.down('sm')]: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    },
    titleDescriptionText: {
        fontSize: 15,
        fontWeight: 300,
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('md')]: {
            '-webkit-line-clamp': 3,
        },
    },
    "price": {
        "fontSize": "14px",
        "color": "gray",

    },
    "discountPrice": {
        "color": '#fa5176',
        "fontSize": "14px",
        "margin-left": "5px",
    },
    "bestseller": {
        "padding": "3px",
        "color": "black",
        "fontSize": "10px",
        "position": "absolute",
        "bottom": "0",
        "margin-left": "auto",
        "margin-right": "auto",
        "width": "90%"
    },
    "eta": {
        "padding": "3px",
        "color": theme.palette.primary.contrastText,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    media: {
        width: '100%',
        height: '161px !important',
        padding: 0,
        margin: 0,
    },
    "cardStyle": {
        boxShadow: 'none',
        "flexWrap": "nowrap",
        "width": "100%",
        "margin": "5px",
        "alignItems": "center",
    },
    title: {
        "fontSize": "14px",
        "color": "black",
        "margin": '0 5px',
        height: 28,
    },
    "restaurantWrap": {
        "display": "flex",
        "flexDirection": "row",
    },
    "restaurant": {
        "fontSize": "12px",
        "color": 'black',
        "margin": '5px'
    },
    description: {
        fontSize: "12px",
        color: 'gray',
        margin: '0 5px 5px',
        height: 82,
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('md')]: {
            marginBottom: 20,
        },
    },
    "status": {
        "color": "#3bd4e4",
        "fontSize": "14px",
        "margin": "1px"
    },
    feature_containerFC: {
        "borderRadius": "5%",
        "position": "relative",
        "background-color": "white",
        height: '300px',
        width: '390px',
        marginBottom: 15,
    },
    top_container: {
        "display": "flex",
        "borderTopLeftRadius": "5%",
        "borderTopRightRadius": "5%",
        width: "100%",
        height: "45%",
    },
    bottom_container: {
        height: "auto",
        width: '100%',
        "borderBottomLeftRadius": "5%",
        "borderBottomRightRadius": "5%",

    },
    "half_circle_left": {
        "width": "5px",
        "height": "10px",
        "backgroundColor": "#f3f3f6",
        "borderBottomLeftRadius": "15px",
        "borderTopLeftRadius": "15px",
        "borderRight": "0",
        '@media only screen and (min-width : 1224px)': {
            "width": "7.5px",
            "height": "15px",

        }
    },
    "half_circle_right": {
        "width": "5px",
        "height": "10px",
        "backgroundColor": "#f3f3f6",
        "borderBottomRightRadius": "15px",
        "borderTopRightRadius": "15px",
        "borderLeft": "0",
        '@media only screen and (min-width : 1224px)': {
            "width": "7.5px",
            "height": "15px",

        }
    },
    horizontal_dash: {
        "border": "3px dashed #f3f3f6",
        "borderStyle": "none none dashed none",
        "width": "100%",
        "height": "4px",
        '@media only screen and (min-width : 1024px)': {
            height: '8px !important'
        }
    },
    dash_container: {
        "align": "center",
        "display": "flex",
        "width": "100%",
        "top": "45%",
        [theme.breakpoints.down('md')]: {
            'display': 'none',
        },
    }
})

const mapStateToProps = (state: any) => ({
    organizationSettings: state?.organizations?.organizationSettings,
});

export default connect(mapStateToProps, null)(withStyles(styles as any)(EnhancedFeatureCard as any));


import { useState, useEffect } from "react";
import { Auth0LockGraze } from "../../services/Utilities";
import { keys } from "../LocalStorage";
// import { storeAuth0User } ''
import { storeAuth0User } from '../../store/actions/App.Actions';

import { useDispatch } from "react-redux";

const useGrazeAuth0Lock = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState({} as any);
    const [isLoading, setIsLoading] = useState(true);
    const [tokens, setTokens] = useState(null as any);
    const [checkedForTokens, setCheckedForTokens] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const checkForTokens = async () => {
          try {
            setIsLoading(true);
            Auth0LockGraze.checkSession({},function (error, authResult){
                if (error) {
                    setIsLoading(false);
                    setIsAuthenticated(false);
                    return;
                  }
                  if(authResult) {
                    localStorage.setItem(keys.authAccessToken, authResult?.idToken);
                    setIsAuthenticated(true);
                    setTokens(authResult);
                    setIsLoading(false);
                  }
            });
           
          } catch (e: any) {
            console.error(`Error: check for tokens; ${e}`);
            if (e?.message?.includes("401")) {
              localStorage.clear();
              sessionStorage.clear();
              setIsAuthenticated(false);
            //   redirectToLogin();
            }
            setIsLoading(false);

          }
          setIsLoading(false);
          setCheckedForTokens(true);
        }
    
        const getUserDetails = async()=>{
          try {
            setIsLoading(true);
            Auth0LockGraze.getUserInfo(tokens?.accessToken, function (error, profileResult) {
                if (error) {
                  setIsLoading(false);
                  setUser(null);
                  return;
                }
                if(profileResult) {
                  dispatch(storeAuth0User(profileResult));
                    setIsAuthenticated(true);
                    setUser(profileResult);
                    setIsLoading(false);
                }
              });
          } catch (e: any) {
        if (e?.message?.includes("401")) {
          localStorage.clear();
          sessionStorage.clear();
          setUser(null);
          setIsAuthenticated(false);
          setIsLoading(false);
        }
        setIsLoading(false);
      }
        }

        if (!checkedForTokens) {
          checkForTokens();
        }

        if(isAuthenticated && tokens && !user?.name) {
          getUserDetails();
        }

      }, [checkedForTokens, dispatch, isAuthenticated, tokens, user]);
  return {isAuthenticated, isLoading, user, tokens};
};

export default useGrazeAuth0Lock;
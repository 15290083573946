import { ActionType, IAppAction } from '../actions/Helpers';
import IOrganizationState from '../../models/IOrganization';

const initialState: IOrganizationState = {
    organization: null,
    organizationID: null,
    errorGettingOrganization: false,
    organizationSettings: null,
    organizationOrdersOpenSchedule: null,
    userDetails: null
};

const organizationReducer = (state = { ...initialState }, action: IAppAction) => {
    switch (action.type) {
        case ActionType.SET_ORGANIZATION:
            const organization = action.payload;
            return { ...state, organization, organizationID: organization?.id, errorGettingOrganization: false };
        case ActionType.ERROR_GETTING_ORGANIZATION:
            return { ...state, organization: null, organizationID: null, errorGettingOrganization: true }; 
        case ActionType.SET_ORGANIZATION_SETTINGS:
            return { ...state, organizationSettings: action.payload };   
        case ActionType.SET_ORGANIZATION_ORDERS_OPEN_SCHEDULE:
            return { ...state, organizationOrdersOpenSchedule: action.payload };   
        case ActionType.SET_USER_DETAILS:
            return { ...state, userDetails: action.payload };   
        default:
            return state;
    }
};

export default organizationReducer;
import * as React from 'react';
// import { withRouter } from "react-router-dom";
// import { RouteComponentProps } from "react-router-dom";
import {
    Theme,
    createStyles,
} from '@mui/material';
import { Fastfood } from '@mui/icons-material';
import CardMedia from "@mui/material/CardMedia";
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
const styles = (theme: Theme) => createStyles({
    acronym: {
        display: 'inline-block',
        textAlign: 'center',
        fontSize: '30px',
        fontWeight: 'bold',
        color: '#FFF',
    },
    acronymOverlay: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#fff',
        alignItems: 'center',
        width: '95%'
    },
    acronymOverlayGray: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        border: '1px solid gray',
        borderRadius: '16px 16px 0px 0px',
        alignItems: 'center',
    },
});
interface MatchParams {
    id: string
}
interface IPageProps {
    classes: any;
    name: string;
    imageUrl: string;
    reorderVersion?: boolean;
    bgColor?: string;
    className?: string;
    organizationSettings: any
}

class ImgPlaceholder extends React.Component<IPageProps & {}> {
    public render() {
        const { classes, name, bgColor, className, imageUrl, organizationSettings,reorderVersion } = this.props;
        
        if(organizationSettings?.layoutVariant === "Imageless"){
            return null
        }

        return (
            !!imageUrl
                ?
                <CardMedia
                    className={className ? className : ''}
                    image={imageUrl}
                    title={name}
                />
                :
                <span className={`${className} ${classes.acronym}`} style={{
                    backgroundColor: bgColor ? bgColor : '#eee',
                    height: reorderVersion ? '75px':'100%',
                    width: reorderVersion ? '65px': '100%',
                    padding: 0,
                }}
                >
                    <span className={`${className} ${bgColor ? classes.acronymOverlay : classes.acronymOverlayGray}`} style={{
                        height: (reorderVersion ? '75px': bgColor ? '94%' : '325px'),
                        width: (reorderVersion ? '65px': bgColor ? '94%' : 'auto'),
                        margin: (reorderVersion ? '0' : bgColor ? '3%' : '0'),
                        padding: 0,
                        borderRadius: reorderVersion ? '6px' : '16px 16px 0px 0px'
                    }}
                    >
                        <Fastfood sx={{
                            textAlign: 'center',
                            width: '100%',
                            color: '#aaa',
                            fontSize: { xs: 'medium' },
                            height: { xs: '60%' },
                            borderRadius: '10px',
                        }} />
                    </span>
                </span>
        );
    }
}

const mapStateToProps = (state: any) => ({
    organizationSettings: state?.organizations?.organizationSettings,
});

export default connect(mapStateToProps, null)(withStyles(styles as any)(ImgPlaceholder as any));
import { coverApi } from '../../api';
import { ActionType, IAppAction } from '../actions/Helpers';
import { Dispatch } from "redux";
import { AppState } from "../../state/AppState";
import { Spinner } from '../../state/Spinner';
import ISettingArea from '../../models/ISettingArea';

export const getSettingAreas = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });

  return coverApi.getSettingAreas()
    .then((response: any) => {
      const settingAreas = response.parsedBody as ISettingArea[];
      dispatch({ type: ActionType.SET_SETTINGS, payload: settingAreas });
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return response;
    })
    .catch((error: any) => {
      dispatch({ type: ActionType.CLOSE_SPINNER });

      return error;
    });
}

import { Component, useEffect, useState } from 'react';
// import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import {
    AccountCircle,
    ClearOutlined,
    DeleteOutlined,
    MenuOutlined,
    SettingsOutlined,
    ShoppingBasketOutlined
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import Up from '@mui/icons-material/ArrowUpwardOutlined';
// import CheckIcon from '@mui/icons-material/Check';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
    AppBar,
    CircularProgress,
    FormControl,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Typography,
} from '@mui/material';
import { Theme, lighten } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import * as _ from 'lodash';
// import { User } from 'oidc-client';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import IOrder from '../models/IOrder';
// import { customerGrantType } from '../auth/userManager';
import AppDrawer from './App.Drawer';
import Dialog from '@mui/material/Dialog';
import { DialogTitle, DialogContent } from '../components/dialog/DialogTitle'
// import { ConfirmBox } from '../alert/ConfirmBox';
// import SpinnerDialog from '../spinner/Spinner';
// import Profile from '../pages/account/Profile';
// import { MailPage } from '../pages/mail/Mail';
import Home from '../pages/home/Home';
// import HomeImagelessLayout from '../pages/homeImagelessLayout/Home';
// import StoresPage from '../pages/home/Stores';
// import { actions as MailActionCreators } from '../data/mail';
// import { actions as MaterialActionCreators } from '../data/material';
// import { getMaterialChartItems, getMailitems } from '../selectors';
// import OrderSuccess from '../pages/orders/OrderSuccess';
import ProductDetails from '../pages/productdetails/ProductDetail';
//  import ProductDetailsImagelessLayout from '../pages/productdetails/ProductDetailsImagelessLayout';
import Badge from '@mui/material/Badge';
import SignInPage from "../pages/auth/SignIn";
import SignUpPage from "../pages/auth/SignUp";
// import CategoryBoard from "../pages/category/CategoryHome";
// import DealBoard from "../pages/home/DealHome";
// import FeatureBoard from "../pages/home/FeatureHome";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { NavLink, useNavigate } from 'react-router-dom';
import { formatMoney } from "../pipes/PriceFormatter";
// import CategoryMenuView from '../pages/category/CategoryMenuView';
import { Box, Grid, Select } from '@mui/material';
import Popover from '@mui/material/Popover';
import ProceedToCheckout from '../pages/checkout/ProceedToCheckout';
// import ProceedToCheckoutImagelessLayout from '../pages/checkout/ProceedToCheckoutImagelessLayout';
// import ReviewProductDetails from '../pages/checkout/ReviewProductDetails';
// import ReviewProductDetailsImagelessLayout from '../pages/checkout/ReviewProductDetailsImagelessLayout';
// import PaymentProcess from '../pages/checkout/PaymentProcess';
// import { DialogTitle, DialogContent } from '../components/dialog/DialogTitle'
// import AlertMessage from '../alert/AlertMessage';
import HomeSearch from '../pages/home/HomeSearch'
import HomeSearchImagelessLayout from '../pages/homeImagelessLayout/HomeSearch'
import IMenuCategoryItem from '../models/IMenuCategoryItem';
import IOrderItem from '../models/IOrderItem';
import * as LocalStorage from '../utilities/LocalStorage';
// import GlobalSearchBox from '../components/search/GlobalSearchBox';
// import GlobalSearchBoxImagelessLayout from '../components/search/GlobalSearchBoxImagelessLayout';
// import { useAuth0 } from '@auth0/auth0-react';
import { Tab, Tabs } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import ICustomer from '../models/ICustomer';
import ICustomerMessage from '../models/ICustomerMessage';
// import IOrderListEntry from '../models/IOrderListEntry';
import { AppState } from '../state/AppState';
import * as AppActionCreators from '../store/actions/App.Actions';
import { IApplicationProps } from '../store/actions/App.Actions';
import * as CustomerActions from '../store/actions/CustomerActions';
import * as AppMenuActionCreators from '../store/actions/Menu.Action';
import * as MenuActions from '../store/actions/Menu.Action';
import * as OrderActions from '../store/actions/Order.Actions';
import * as OrganizationActions from '../store/actions/Organization.Actions'
import * as ItemsActions from '../store/actions/Items.Actions'
import { getSettingAreas } from '../store/actions/SettingAreasActions';
import {
    // BrowserRouter,
    Link,
    Route,
    Routes,
} from "react-router-dom";
import { withStyles } from '@mui/styles';
// import { Switch } from 'react-router-dom';
// import { RouteComponentProps } from 'react-router-dom';
import { styles } from './styles';
import Splash from '../pages/splash/Splash';
import { selectedCategoryMenuProduct } from '../store/actions/Menu.Action';
import { openProductDetailPageModel } from '../store/actions/App.Actions';
import OrderConfirmation from '../pages/orderConfirmation/orderConfirmation';
import AuthModal from '../pages/auth/modal';
import { isClosed, isOpenHoursClosed } from '../services/IsClosed';
import Profile from '../pages/profile';
import { keys } from '../utilities/LocalStorage';
import { getCheckedOptions, getReorderModifiersPrice } from '../utilities/modifiers';
import { getDomainName } from '../domain-name';
import { Auth0LockGraze } from '../services/Utilities';
import useGrazeAuth0Lock from '../utilities/hooks/useGrazeAuth0Lock';
import GlobalSearch from '../components/GlobalSearch';
import { withRouter } from '../utilities/withRouter';
const classNames = require('classnames');

function Alert(props: any) {
    return <Paper component={'div'} elevation={6}  {...props} />;
    // MuiAlert
};

// const userManager = customerGrantType();

interface IProps extends IApplicationProps {
    classes?: any;
    theme?: any;
    router?: any;
    dispatch?: Dispatch;
    cartLength?: number;
    addCartOpen?: boolean;
    loader?: boolean;
    getMenuDetailsList?: () => any[];
    order?: IOrder;
    orderGetIfIncomplete?: (orderId: number) => any;
    orderRemoveItem?: (itemId: number) => void;
    clearUnavailableItems: () => void;
    getOrder: (id: number) => void;
    selectedCategoryMenuProduct?: (data: IMenuCategoryItem, orderItem: IOrderItem) => void;
    setSearchValue?: (searchValue: string) => void;
    getGrazeSettingAreas?: () => void;
    customer: ICustomer;
    getCustomer: () => Promise<ICustomer>;
    updateCustomer: (customer: ICustomer) => Promise<ICustomer>;
    sendCustomerMessage: (customerMessage: ICustomerMessage) => Promise<any>;
    searchValue?: string;
    user?: any;
    appUserDetails: any;
    isLoadingUser: boolean;
    companyLogo: string;
    onlineMenuList: Array<{ id: number, name: string }>;
    selectedMenuId: number;
    setSelectedMenuId: (selectedMenuId: number) => void;
    assignOrgToLoggedInUser: (payload: any) => any;
    layout: string;
    storeAuth0User: (auth0User: any) => void;
    auth0User: any;
    getUserByEmailId: (payload: any) => any;
    registerUser: (payload: any) => any;
    searchMenuItems: (params: any) => any;
};

interface IState {
    anchorEl: any;
    notificationEl: any;
    anchorElMenu: any;
    loginError: string | null;
    userAuth0Lock: any
    authModalOpen: boolean;
    searchValue:string | null;
    searchLoader: boolean;
};

class MiniDrawer extends Component<any & IProps, IState> {
    registerCountCall: number = 0;
    
    constructor(props: any) {
        super(props);
        this.state = {
            anchorEl: false,
            notificationEl: null,
            anchorElMenu: null,
            loginError: null,
            authModalOpen: false,
            userAuth0Lock:{},
            searchValue: null,
            searchLoader:false
        }

    }

    private init = () => {
        const {
            //getMenuDetailsList,
            orderGetIfIncomplete,
            //getGrazeSettingAreas,
            //getCustomer,
            order,
        } = this.props;

        // getMenuDetailsList();
        // getGrazeSettingAreas();
        // getCustomer();

        const storedOrderId = LocalStorage.get<number>(LocalStorage.keys.orderId);
        const getStoredOrder = !order.id && !!storedOrderId;

        if (getStoredOrder) {
            orderGetIfIncomplete(storedOrderId);
        }
    };

    private checkUser = () => {
        // const { isLoadingUser, location } = this.props;

        // if (isLoadingUser) {
        //     return;
        // }

        // userManager.getUser().then((user: User) => {
        //     if (!user || user.expired) {
        //         const pathname: string = location ? location.pathname : '';
        //         if (pathname.includes('signin-oidc') || pathname.includes('location')) {
        //             return;
        //         }

        //         LocalStorage.save(LocalStorage.keys.redirectPathname, pathname);
        //        // userManager.signinRedirect();
        //     }
        // });
    }


    private handleLogin = () => {
        this.setState({ anchorElMenu: null });
        this.props.handleClickOpenSignInModel();
    }

    private handleLogout = async () => {
        // const { user } = this.props;
        //const idToken = user.idToken;

        // await userManager.signoutRedirect({ id_token_hint: idToken });
        // await userManager.removeUser();
        LocalStorage.remove(LocalStorage.keys.contactInfo);
    };

    private handleClick = (event: any) => {
        this.setState({ anchorEl: event.currentTarget })
    };

    private handleClose = () => {
        this.setState({ anchorEl: null })
    };

    private handleClickOpenMenu = (event: any) => {
        this.setState({ anchorElMenu: event.currentTarget })
    };

    private handleCloseMenu = () => {
        this.setState({ anchorElMenu: null })
    };

    private removeItem = (orderItemId: any) => {
        this.props.orderRemoveItem(orderItemId);
        const items = localStorage.getItem("order");
        if (items) {
            const orderParsed = JSON.parse(items)
            if (orderParsed?.orderItems?.length === 1) {
                localStorage.removeItem("order")
            }
        }
    };

    private backToProductEdit = (orderItem: IOrderItem) => {
        this.handleClose();
        this.props.selectedCategoryMenuProduct(orderItem.menuCategoryItem, orderItem);
        this.props.openProductDetailPageModel();
    };

    private productToCheckout = (history: any) => {
        this.handleClose();
        history(`/proceed-to-checkout`);
    };

    private handleCloseSignInModel = () => {
        this.props.handleCloseSignInModel();
        this.props.handleCloseSignUpModel();
    };

    private handleCloseProductDetailPageModel = () => {
        const { router } = this.props;
        const searchParams = new URLSearchParams(router?.location?.search);
        searchParams.delete('id');
        searchParams.delete('productId');
        router.navigate(`${router.location.pathname}?${searchParams.toString()}`);
        this.props.closeProductDetailPageModel();
    };

    private handleSearchChange = async (value:string) => {
        this.setState({ searchValue: value, searchLoader:true })
        this.props.setSearchValue(value)
        await this.props.searchMenuItems(`Search=${value}&OrganizationId=${this.props?.organization?.id}`).finally(() => this.setState({ searchLoader: false }))
    };

    private logoOnClick = () => {
        this.props.setSearchValue('');
    }

    private renderConfirmBox = (): JSX.Element => {
        if (this.props.utility?.confirmBox) {
            return (<></>
                // <ConfirmBox
                //     handleClose={this.props.closePopup}
                //     data={this.props.utility?.confirmBox}
                // />
            );
        }

        return <></>;
    };

    private renderAlert = (): JSX.Element => {
        if (this.props.utility?.alert) {
            return (<></>
                // <AlertMessage />
            );
        }

        return <></>;
    };

    private renderSpinner = (): JSX.Element => {
        if (this.props.utility?.spinner) {
            return (<></>
                // <SpinnerDialog
                //     message={this.props.utility?.spinner.message}
                // />
            );
        }

        return <></>;
    };

    private renderAppBarImagelessLayout = () => {
        const [guestLogin, setGuestLogin] = useState(false);
        const [isuserApiCalled, setUserApiCalled] = useState(false);
        const { isAuthenticated, user,  isLoading, tokens  } = useGrazeAuth0Lock();
        const history = useNavigate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const {
            classes,
            utility,
            cartLength,
            order,
            companyLogo,
            customer,
            layout,
            auth0UserDetails,
            storeAuth0User,
            location,
            organizationSettings,
            getUserDetails
        } = this.props;
        const isLoggedIn = isAuthenticated ;
        useEffect(() => {
            const fetchToken = async (self: any) => {
                if (isAuthenticated && user?.email) {
                    storeAuth0User(user);
                    if (tokens) {
                        this.registerCountCall = this.registerCountCall + 1;
                        if (this.registerCountCall === 1) {
                            setUserApiCalled(true)
                            await self.checkAppUserAvailable();
                          
                        const userTokenObject: any = jwtDecode(tokens.idToken);
                        if (userTokenObject && !userTokenObject.orgName && userTokenObject?.email) {
                            const payload: any = {
                                orgName: getDomainName(),
                                members: [userTokenObject?.email]
                            };
                            self.props.assignOrgToLoggedInUser(payload).then((response: any) => {
                            }).catch((err: any) => {
                                console.error(err)
                            });
                        }
                    }
                        
                    }
                }
            };
            if (!isLoading) {
                if (!isAuthenticated) {
                    localStorage.removeItem('isGuest');
                    localStorage.removeItem(keys.authAccessToken);
                    setGuestLogin(localStorage.getItem('isGuest')==='true'?true:false);
                }
                const currentToken = localStorage.getItem(keys.authAccessToken);
                if (!currentToken) {
                    fetchToken(this);
                }
                if (isAuthenticated && user?.email && currentToken) {
                    // getUserDetails();
                    if (!auth0UserDetails) {
                        storeAuth0User(user);
                    }
                };
            }
        }, [isAuthenticated, user, auth0UserDetails, storeAuth0User, isLoading, getUserDetails, tokens]);
       

        if (!isLoggedIn) {
            return null;
        }

        
        const { anchorEl, anchorElMenu, loginError } = this.state;
        const isOrderEmpty = !order || !order.orderItems || order.orderItems.length === 0;
        const open = Boolean(anchorEl);
        const openid = open ? 'simple-popover' : undefined
        //this.props.user.profile.name === 'Guest';
        const mainLayout = layout === 'Main Layout';
        return (
            <AppBar
                position="fixed"
                className={classNames(classes?.appBar, classes?.appBarImagelessLayout, utility?.drawerOpen && classes?.appBarShift)}
            >
                <Toolbar className={classes?.headerToolbar} disableGutters={!utility?.drawerOpen}>
                    <Paper sx={{ display: { xs: 'none', sm: 'none' } }} >
                        <Box display='flex'>
                            <div className={classes?.logo_image}>
                                <NavLink className={classes?.alignNav} to="/dashboard" onClick={this.logoOnClick}>
                                    <img src={'https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F73%2F6e3e8fa0-587c-4f1c-a8de-027a3accdc90.jpg'} className={classes?.logo_image} alt='' />
                                </NavLink>
                            </div>
                        </Box>
                    </Paper>
                    {/* <Paper only={['xs', 'sm']}>
                        <GlobalSearchBoxImagelessLayout searchComponentType={'homeSearchMenuAndIteam'} />
                    </Paper> */}
                    <div className={classes?.appBarRightImagelessLayout}>
                        {isLoggedIn && <>
                            <Paper sx={{ display: { xs: 'none', sm: 'none' } }}>
                                <SettingsOutlined
                                    aria-label="show profile"
                                    color="inherit"
                                    aria-controls="simple-menu"
                                    className={classes?.settingsOutlined}
                                    aria-haspopup="true"
                                    onClick={this.handleClickOpenMenu}
                                    fontSize='small'
                                />
                                <span className={classes?.settingsOutlinedDivider} />
                            </Paper>
                            <Paper>
                                {/* only={['lg', 'md', 'xl']} */}
                                <MenuOutlined
                                    aria-label="show profile"
                                    color="inherit"
                                    aria-controls="simple-menu"
                                    className={classes?.settingsOutlined}
                                    aria-haspopup="true"
                                    onClick={this.handleClickOpenMenu}
                                    fontSize='small'
                                />
                                <Box display='flex'>
                                    <div className={classes?.logo_image}>
                                        {organizationSettings ? <NavLink className={classes?.alignNav} to="/dashboard" onClick={this.logoOnClick}>
                                            <img src={companyLogo} className={classes?.logo_image} alt='' />
                                        </NavLink> : <Stack sx={{ height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                                            <CircularProgress size={25} color='secondary' />
                                        </Stack>}
                                    </div>
                                </Box>
                            </Paper>

                            {isLoggedIn && <Menu style={{ top: '45px' }}
                                id="simple-menu"
                                anchorEl={anchorElMenu}
                                keepMounted={true}
                                open={Boolean(anchorElMenu)}
                                onClose={this.handleCloseMenu}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >

                                <MenuItem key={1} onClick={() =>{
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    localStorage.removeItem(keys.authAccessToken);
                                    Auth0LockGraze.logout({ returnTo: window.location.origin })
                                }}>Logout</MenuItem>
                                {/* <MenuItem key={2}>
                                    <NavLink
                                        className={classes?.profileLink}
                                        to="/profile/address"
                                        onClick={this.handleCloseMenu}
                                    >
                                        Profile
                                    </NavLink>
                                </MenuItem> */}
                                <MenuItem key={3}>
                                    <NavLink
                                        className={classes?.profileLink}
                                        to="/profile?tab=order-history"
                                        onClick={this.handleCloseMenu}
                                    >
                                        Reorder Past Order
                                    </NavLink>
                                </MenuItem>
                                <MenuItem key={4}>
                                    <NavLink
                                        className={classes?.profileLink}
                                        to="/profile?tab=manage-address"
                                        onClick={this.handleCloseMenu}
                                    >
                                        Manage Address
                                    </NavLink>
                                </MenuItem>
                                <MenuItem key={4}>
                                        <NavLink
                                            className={classes?.profileLink}
                                            to="/profile?tab=contact"
                                            onClick={this.handleCloseMenu}
                                        >
                                            Contact
                                        </NavLink>
                                    </MenuItem>
                            </Menu>}

                            {!isLoggedIn &&
                                <Menu style={{ top: '45px' }}
                                    id="simple-menu"
                                    anchorEl={anchorElMenu}
                                    keepMounted={true}
                                    open={Boolean(anchorElMenu)}
                                    onClose={this.handleCloseMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={this.handleLogin}>Login</MenuItem>

                                </Menu>
                            }
                            <Paper >
                                {/* only={['xs', 'sm']} */}
                                <span className={classes?.userName}>{customer ? `${customer.firstName} ${customer.lastName}` : ''}</span>
                            </Paper>
                        </>}

                        <Dialog
                            className={classes?.dialog}
                            onClose={this.handleCloseSignInModel}
                            aria-labelledby="customized-dialog-title"
                            open={utility?.openSignInModel || utility?.openSignUpModel}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={this.handleCloseSignInModel}
                            >
                                {utility?.openSignInModel ? "Sign in with your account" : ""}
                            </DialogTitle>
                            <DialogContent>
                                {utility?.openSignInModel ? <SignInPage loginError={loginError} /> : null}
                                {utility?.openSignUpModel ? <SignUpPage /> : null}
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            classes={{ paper: classes?.dialogContainerImagelessLayout }}
                            onClose={this.handleCloseProductDetailPageModel}
                            maxWidth="md"
                            aria-labelledby="Items-View"
                            open={utility?.openProductDetailPageModel}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={() => this.handleCloseProductDetailPageModel()}
                                classes={{ closeButton: classes?.dialogCloseButton, root: classes?.dialogCloseButton }}
                                className={classes?.dialogTitle}
                            />
                            <DialogContent >
                                {/* <ProductDetailsImagelessLayout /> */}
                            </DialogContent>
                        </Dialog>
                        {/* only={['lg', 'md', 'xl']} */}
                        <Paper >
                            <Link to={`/review-your-product-details`} style={{ paddingRight: 2, paddingTop: 4, }}>
                                <IconButton
                                    aria-label="show shopping cart"
                                    color="inherit"
                                    className={classes?.miniCartMobile}
                                >
                                    <ShoppingBasketOutlined fontSize="inherit" color="secondary" />
                                </IconButton>
                            </Link>
                        </Paper>
                        {!mainLayout && !location?.pathname?.includes('/proceed-to-checkout') && <Paper >
                            {/* only={['xs', 'sm']} */}
                            <div
                                aria-label="show shopping cart"
                                color="inherit"
                                id="openModel"
                                className={classes?.miniCart}
                                onClick={this.handleClick}
                            >
                                <span className={classes?.miniCartComponent}>
                                    <ShoppingBasketOutlined fontSize="inherit" color="secondary" className={classes?.miniCartIcon} />
                                </span>
                                <span className={classes?.miniCartComponent}>My order</span>
                                <span className={classNames(classes?.miniCartCount, classes?.miniCartComponent)}>{cartLength}</span>
                            </div>
                            <Popover
                                id={openid}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                classes={{
                                    paper: classes?.paperImageless
                                }}
                            >
                                <Grid container={true} className={classes?.popupMainContainerImagelessLayout}>
                                    <Grid item={true} xs={12} sm={12}>
                                        <Container component="main" disableGutters={true} maxWidth="md" >
                                            <Grid container={true} spacing={2}>
                                                <Grid item={true} xs={12} sm={12}>
                                                    <Grid container={true} spacing={2}>
                                                        <Grid item={true} xs={12} sm={12}>
                                                            <div
                                                                aria-label="show shopping cart"
                                                                color="inherit"
                                                                id="openModel"
                                                                className={classNames(classes?.miniCart, classes?.miniCartPopover)}
                                                            >
                                                                <span className={classes?.miniCartHeader}>
                                                                    <span className={classes?.miniCartComponent2}>
                                                                        <ShoppingBasketOutlined fontSize="inherit" color="secondary" className={classes?.miniCartIcon} />
                                                                    </span>
                                                                    <span className={classes?.miniCartComponent2}><Typography className={classes?.myOrderTitle}>My order</Typography></span>
                                                                    <span className={classNames(classes?.miniCartCount, classes?.miniCartComponent2)}><Typography className={classes?.myOrderTitle}>{cartLength}</Typography></span>
                                                                </span>
                                                                <span
                                                                    className={classes?.miniCartClose}
                                                                    onClick={this.handleClose}
                                                                >
                                                                    <span>Close</span>
                                                                    <ClearOutlined className={classes?.miniCartCloseIcon} />
                                                                </span>
                                                            </div>
                                                            <div className={classes?.miniCartItems}>
                                                                <Typography className={classes?.orderTitle}>My orders</Typography>
                                                            </div>
                                                            <Grid
                                                                container={true}
                                                                className={classNames(classes?.productDetailsItemImagelessLayout, order && order.orderItems.length <= 0 ? classes?.cartEmpty : '')}
                                                                style={{ textTransform: 'uppercase', overflow: 'scroll', overflowY: 'scroll' }}
                                                            >
                                                                {!isOrderEmpty ?
                                                                    order.orderItems.map((orderItem: IOrderItem,index:number) => {
                                                                        const { name, price, quantity, id, orderItemModifiers } = orderItem
                                                                        const modifiers = orderItemModifiers
                                                                            ?.filter(orderItemModifier => orderItemModifier.recordStatus === 'Active')
                                                                            ?.map(orderItemModifier => orderItemModifier?.menuItemModifier?.name || '');

                                                                        return (
                                                                            <Grid item={true} xs={12} sm={12} key={id+'key-val'+index}>
                                                                                <Grid
                                                                                    container={true}
                                                                                    className={classNames(classes?.cartItems, classes?.cartItemsImagelessLayout)}
                                                                                >

                                                                                    <div className={classes?.cartItemContent}>
                                                                                        <Button color="secondary" className={classes?.cartItemButton}>
                                                                                            <div onClick={() => this.backToProductEdit(orderItem)}>
                                                                                                <Typography className={classes?.cartItemName}>{name}</Typography>
                                                                                            </div>
                                                                                            <div className={classes?.itemNotesContainer}>
                                                                                                <div className={classes?.itemNotes}>{modifiers.join(', ')}</div>
                                                                                                <div className={classes?.itemNotes}>{orderItem.notes}</div>
                                                                                            </div>
                                                                                        </Button>
                                                                                        <div className={classes?.cartItemContentPrice}>
                                                                                            <span>{quantity} <ClearOutlined className={classes?.miniCartUnitPriceIcon} /> ${formatMoney(price)}</span>
                                                                                            <span>
                                                                                                <DeleteOutlined className={classes?.miniCartIconDelete} onClick={() => this.removeItem(id)} />
                                                                                            </span>
                                                                                            <span className={classes?.minicartMoney}>
                                                                                                ${formatMoney(price + getReorderModifiersPrice(orderItemModifiers))}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                    :
                                                                    <Grid >
                                                                        {/* container={true} justify='center' alignItems='center' */}
                                                                        <Grid item={true} xs={12} sm={6}>
                                                                            <Typography
                                                                                variant="body2"
                                                                                align="center"
                                                                                gutterBottom={true}
                                                                                style={{ position: 'sticky', color: '#c0c0c0', transform: 'translateY(-61px)' }}
                                                                            >
                                                                                Your cart is empty.
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        {!isOrderEmpty ?
                                                            <Grid item={true} xs={12} sm={12}>
                                                                <div className={classes?.miniCartPayment}>
                                                                    <Typography className={classes?.orderTitle}>Payment</Typography>
                                                                </div>
                                                                <Grid container={true} className={classes?.cartItemsImagelessLayou}>
                                                                    {!!order.serviceCharge && <div className={classes?.paymentInfo}>
                                                                        <Grid item={true} xs={6} sm={6}>
                                                                            <div>Service Charge:</div>
                                                                        </Grid>
                                                                        <Grid item={true} xs={6} sm={6}>
                                                                            <div className={classes?.minicartPaymentMoney} >
                                                                                <div>${order.serviceCharge ? formatMoney(order.serviceCharge) : '00.00'}</div>
                                                                            </div>
                                                                        </Grid>
                                                                    </div>}
                                                                    {!!order.gratuity && <div className={classes?.paymentInfo}>
                                                                        <Grid item={true} xs={6} sm={6}>
                                                                            <div>Gratuity:</div>
                                                                        </Grid>
                                                                        <Grid item={true} xs={6} sm={6}>
                                                                            <div className={classes?.minicartPaymentMoney} >
                                                                                <div>${order.gratuity ? formatMoney(order.gratuity) : '00.00'}</div>
                                                                            </div>
                                                                        </Grid>
                                                                    </div>}
                                                                    {!!order.taxTotal && <div className={classes?.paymentInfo}>
                                                                        <Grid item={true} xs={6} sm={6}>
                                                                            <div>Tax:</div>
                                                                        </Grid>
                                                                        <Grid item={true} xs={6} sm={6}>
                                                                            <div className={classes?.minicartPaymentMoney} >
                                                                                <div>${order.taxTotal ? formatMoney(order.taxTotal) : '00.00'}</div>
                                                                            </div>
                                                                        </Grid>
                                                                    </div>}
                                                                    <div className={classes?.paymentInfo}>
                                                                        <Grid item={true} xs={6} sm={6}>
                                                                            <div className={classes?.miniSubTotal}>Subtotal:</div>
                                                                        </Grid>
                                                                        <Grid item={true} xs={6} sm={6}>
                                                                            <div className={classNames(classes?.minicartPaymentMoney, classes?.miniSubTotal)} >
                                                                                <div>${order.subTotal ? formatMoney(order.subTotal) : '00.00'}</div>
                                                                            </div>
                                                                        </Grid>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                            : <></>}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                        {!isOrderEmpty ?
                                            <Grid item={true} xs={12} sm={12}>
                                                <Grid item={true} xs={12} sm={12} className={classes?.productToCheckoutcontainerImagelessLayout}>
                                                    <Button
                                                        className={classes?.proceedToCheckoutImagelessLayout}
                                                        onClick={() => this.productToCheckout(history)}
                                                    >
                                                        Checkout
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            : <></>}
                                    </Grid>
                                </Grid>
                            </Popover>
                        </Paper>}
                    </div>
                </Toolbar>
            </AppBar>
        );
    }

    private renderAppBar = () => {
        const [guestLogin, setGuestLogin] = useState(false);
        let history = useNavigate();
        const {isAuthenticated, isLoading, user, tokens} =  useGrazeAuth0Lock();
        const {
            classes,
            utility,
            cartLength,
            order,
            companyLogo,
            organizationSettings,
            auth0UserDetails,
            appUserDetails,
            storeAuth0User,
            orderAddItem,
            organizationOrdersOpenSchedule,
            getUserDetails,
            appUserDetails2
        } = this.props;
        const { anchorEl, anchorElMenu, authModalOpen} = this.state;
        const isOrderEmpty = !order || !order.orderItems || order.orderItems.length === 0;
        const open = Boolean(anchorEl);
        const openid = open ? 'simple-popover' : undefined
        const [userApiCalled, setUserApiCalled] = useState(false);

        const isLoggedIn = isAuthenticated;

        useEffect(() => {
            const fetchUserDetails = async (self: any) => {
                if (isAuthenticated && auth0UserDetails?.email && !appUserDetails) {
                    if (tokens && tokens?.idToken) {
                        if (this.registerCountCall === 0) {
                            setUserApiCalled(true)
                            await self.checkAppUserAvailable();
                            this.registerCountCall = this.registerCountCall + 1;
                            const userTokenObject: any = jwtDecode(tokens?.idToken);
                            if (userTokenObject && !userTokenObject.orgName && userTokenObject?.sub) {
                                const payload: any = {
                                    orgName: getDomainName(),
                                    members: [userTokenObject?.sub]
                                };
                                self.props.assignOrgToLoggedInUser(payload).then((response: any) => {
                                }).catch((err: any) => {
                                    console.error(err)
                                });
                            }
                            // getUserDetails();
                        }
                 
                    }
                }
            };
            if (!isLoading) {
                if (!isAuthenticated) {
                    localStorage.removeItem('isGuest');
                    localStorage.removeItem(keys.authAccessToken);
                    setGuestLogin(localStorage.getItem('isGuest')==='true'?true:false);
                }
                if (!appUserDetails && auth0UserDetails?.email && !userApiCalled) {
                        this.registerCountCall=0;
                        fetchUserDetails(this);
                }
            }
        }, [isAuthenticated, user, auth0UserDetails, storeAuth0User, isLoading, getUserDetails, tokens, appUserDetails, userApiCalled]);
       
        useEffect(() => {
            const items = localStorage.getItem("order");
            if (items) {
                const orderParsed = JSON.parse(items)
                for (var i = 0; i < orderParsed?.orderItems?.length; i++) {
                    orderAddItem(orderParsed?.orderItems[i])
                }
            }
        }, [])

        useEffect(() => {
            if (order?.orderItems?.length > 0) {
                localStorage.setItem("order", JSON.stringify(order))
            }
        }, [order])

        // if (!isLoggedIn) {
        //     return null;
        // }

        const getSubTotalPrice = () => {
            const total = this.props.order.orderItems.reduce((total: any, item: any) => {
                return total + ((item.price + getReorderModifiersPrice(item.orderItemModifiers)) * item.quantity);
              }, 0);
            return total || 0
        }

        return (
            <>
                <AppBar
                    position="fixed"
                    className={classNames(classes?.appBar, utility?.drawerOpen && classes?.appBarShift)}
                    elevation={0}
                    sx={{backgroundColor: '#fff'}}
                >
                    {organizationSettings?.enableOrders === false ? (
                        <Stack
                            sx={{
                                background: "#ffc700",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography variant='caption'>CLOSED: {organizationSettings?.ordersDisabledMessage}</Typography>
                        </Stack>
                    ) : isOpenHoursClosed(organizationSettings, organizationOrdersOpenSchedule) ? <Stack
                    sx={{
                        background: "#ffc700",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant='caption'>CLOSED: {organizationSettings?.outsideOpenHoursMessage}</Typography>
                </Stack> : null}
                    {process.env?.REACT_APP_BUILD_NUMBER  &&
                      <Stack
                            sx={{
                                background: "#eedd9e",
                                alignItems: "center",
                                justifyContent: "center",
                                border:'none',
                                height:'18px'
                            }}
                        >
                            <Typography variant='caption'>V:{process.env?.REACT_APP_BUILD_NUMBER?process.env?.REACT_APP_BUILD_NUMBER:0}</Typography>
                        </Stack>}
                    <Toolbar disableGutters={!utility?.drawerOpen} sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                        {isLoggedIn ?
                        <IconButton
                            size="large"
                            aria-label="profile"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={this.handleClickOpenMenu}
                            color="inherit"
                            sx={{ display: { xs: 'flex', md: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        : 
                        <Box sx={{ display: { xs: 'flex', sm: 'none' }}}>
                                {!isLoggedIn && !isLoading &&
                                    <Box>
                                        <Button
                                            id="login"
                                            style={{ 
                                                color: '#000000',
                                                // width:'100px',
                                                whiteSpace: 'nowrap',
                                                minWidth: 'auto',
                                            }}
                                            onClick={() => {
                                                //this.setState({authModalOpen: true})
                                                Auth0LockGraze.show();
                                            }}
                                        >
                                            Sign In
                                        </Button>
                                    </Box>}
                                {isLoading && <CircularProgress color='secondary' size={28} sx={{ mx: 1 }} />}
                            </Box>}
                        <Box sx={{ margin: {xs: '0 auto', md: 0}, position: {xs: 'absolute', sm: 'relative'}, transform: {xs: 'translate(110%)', sm: 'none'}}}>
                            <div className={classes?.logo_image}>
                                {organizationSettings ? <NavLink className={classes?.alignNav} to="/dashboard" onClick={this.logoOnClick}>
                                    <img src={companyLogo} className={classes?.logo_image} alt='' />
                                </NavLink> : <Stack sx={{ height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                                    <CircularProgress size={25} color='secondary' />
                                </Stack>}
                            </div>
                        </Box>
                        {['/dashboard','/'].includes(window.location?.pathname) &&
                        <Box sx={{width:'100%', display: {xs: 'none', sm: 'flex'}, justifyContent: 'end',paddingRight:'10px'}}>
                            <GlobalSearch onSearchChange={this.handleSearchChange} />
                        </Box>}
                        <Box sx={{
                            display: 'flex',
                            marginLeft: {xs: 0, md: 'auto'},
                            paddingRight: '10px',
                            alignItems: 'center',
                            }}>
                            {isLoggedIn && !isLoading && window.location?.pathname !== '/proceed-to-checkout' && <>
                                <IconButton
                                    aria-label="show profile"
                                    color="inherit"
                                    aria-controls="simple-menu"
                                    className={classes?.root}
                                    aria-haspopup="true"
                                    onClick={this.handleClickOpenMenu}
                                    sx={{display: {xs: 'none', md: 'block'}}}
                                >
                                    <SettingsOutlined sx={{height: 24, width: 24, cursor: 'pointer', color: '#979797' }} />
                                </IconButton>
                                {isLoggedIn && window.location?.pathname !== '/proceed-to-checkout' && <Menu style={{ top: '35px' }}
                                    id="simple-menu"
                                    anchorEl={anchorElMenu}
                                    keepMounted={true}
                                    open={Boolean(anchorElMenu)}
                                    onClose={this.handleCloseMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >

                                    <MenuItem key={1} onClick={() => {
                                        localStorage.clear();
                                        sessionStorage.clear();
                                        localStorage.removeItem(keys.authAccessToken);
                                        Auth0LockGraze.logout({ returnTo: window.location.origin })
                                       
                                    }}>Logout</MenuItem>
                                    <MenuItem key={2}>
                                        <NavLink
                                            className={classes?.profileLink}
                                            to="/profile"
                                            onClick={this.handleCloseMenu}
                                        >
                                            Profile
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem key={3}>
                                        <NavLink
                                            className={classes?.profileLink}
                                            to="/profile?tab=order-history"
                                            onClick={this.handleCloseMenu}
                                        >
                                            Reorder Past Order
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem key={4}>
                                        <NavLink
                                            className={classes?.profileLink}
                                            to="/profile?tab=manage-address"
                                            onClick={this.handleCloseMenu}
                                        >
                                            Manage Address
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem key={4}>
                                        <NavLink
                                            className={classes?.profileLink}
                                            to="/profile?tab=contact"
                                            onClick={this.handleCloseMenu}
                                        >
                                            Contact
                                        </NavLink>
                                    </MenuItem>
                                </Menu>}

                                {!isLoggedIn &&
                                    <Menu style={{ top: '45px' }}
                                        id="simple-menu"
                                        anchorEl={anchorElMenu}
                                        keepMounted={true}
                                        open={Boolean(anchorElMenu)}
                                        onClose={this.handleCloseMenu}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <MenuItem onClick={this.handleLogin}>Login</MenuItem>

                                    </Menu>
                                }
                                <Divider
                                    orientation='vertical'
                                    variant="middle"
                                    flexItem 
                                    sx={{ mx: '20px', color: '#E7E7E7', display: {xs: 'none', md: 'block'}}}/>
                                <Box sx={{
                                    display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }
                                }}>
                                    <Button disabled={true}
                                        style={{
                                            color: '#000000',
                                            height: '40px',
                                            alignItems: 'flex-start',
                                            whiteSpace: 'nowrap',
                                            minWidth: 'auto',
                                        }}>
                                        <Typography sx={{ fontSize: { xs: '20px' } }}>{appUserDetails2 ? `Welcome ${appUserDetails2?.firstName}` : user ? `Welcome ${user?.given_name}` : ''}</Typography>
                                    </Button>
                                </Box>
                            </>}
                            <Box sx={{display: {xs: 'none', sm: 'flex'}}}>
                                {!isLoggedIn && !isLoading && window.location?.pathname !== '/proceed-to-checkout' && <Box>
                                    <Button
                                        id="login"
                                        style={{ 
                                            color: '#000000',
                                            // width:'100px',
                                            whiteSpace: 'nowrap',
                                            minWidth: 'auto',
                                        }}
                                        onClick={() => {
                                            //this.setState({authModalOpen: true})
                                            //loginWithPopup();
                                            Auth0LockGraze.show();
                                        }}
                                    >
                                        Sign In
                                    </Button>
                                </Box>}
                                {isLoading && <CircularProgress color='secondary' size={28} sx={{ mr: 1 }} />}
                            </Box>

                            {/* <Dialog
                            className={classes?.dialog}
                            onClose={this.handleCloseSignInModel}
                            aria-labelledby="customized-dialog-title"
                            open={utility?.openSignInModel || utility?.openSignUpModel}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={this.handleCloseSignInModel}
                            >
                                {utility?.openSignInModel ? "Sign in with your account" : ""}
                            </DialogTitle>
                            <DialogContent>
                                {utility?.openSignInModel ? <SignInPage loginError={loginError} /> : null}
                                {utility?.openSignUpModel ? <SignUpPage /> : null}
                            </DialogContent>
                        </Dialog> */}
                            <Dialog
                                classes={{ paper: classes?.dialogContainer }}
                                onClose={() => this.handleCloseProductDetailPageModel()}
                                maxWidth="sm"
                                aria-labelledby="Items-View"
                                open={utility?.openProductDetailPageModel}
                                PaperProps={{
                                    sx: {
                                        border: '1px solid',
                                        borderColor: organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, .5),
                                        borderRadius: '12px',
                                    },
                                }}
                            >
                                <DialogTitle 
                                    id="customized-dialog-title" 
                                    onClose={() => this.handleCloseProductDetailPageModel()} 
                                    classes={{ closeButton: classes?.dialogCloseButton, root: classes?.dialogCloseButton }}
                                    className={classes?.dialogTitle} />
                                <DialogContent >
                                    {<ProductDetails />}
                                </DialogContent>
                            </Dialog>

                            <Box>
                                {window.location?.pathname !== '/proceed-to-checkout' &&
                                    <Box
                                        aria-label="show shopping cart"
                                        color="inherit"
                                        id="openModel"
                                        sx={{
                                            margin: { xs: 0, md: '3px 15px' },
                                            padding: { xs: '2px 10px', md: '5px 15px' },
                                            border: '1px solid',
                                            borderColor: organizationSettings?.layoutColor,
                                            borderRadius: '100px',
                                            fontSize: { xs: '14px' },
                                            display: 'flex',
                                            cursor: 'pointer',
                                            backgroundColor: '#fff',
                                            alignItems: 'center',
                                            minWidth: 'auto',
                                            whiteSpace: 'nowrap',
                                        }}
                                        onClick={this.handleClick}

                                    >
                                        <span className={classes?.miniCartComponent}>
                                            <img src='/static/images/shopping-bag.svg' alt='' color="secondary" style={{ marginRight: '10px' }} />
                                        </span>
                                        <Typography sx={{ fontSize: { xs: '14px' }, marginRight: '10px', display: { xs: 'none', md: 'block' } }}>My order</Typography>
                                        <Typography sx={{ fontSize: { xs: '18px' }, color: organizationSettings?.layoutColor, fontWeight: 500, }}>{cartLength}</Typography>
                                    </Box>}
                                <Paper elevation={0}>
                                    <Popover
                                        id={openid}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={this.handleClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        slots={{
                                            paper: Paper,
                                        }}
                                        slotProps={{
                                            paper: {
                                                sx: {
                                                    borderRadius: {xs: 'none', sm:'30% 0% 0% 30%/70% 0% 0% 70%'},
                                                    backgroundColor: organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, .5),
                                                    height: '100%',
                                                    paddingLeft: '50px',
                                                    marginLeft: '16px',
                                                    minHeight: '100% !important',
                                                    outline: 0,
                                                    position: 'absolute',
                                                    maxWidth: '460px',
                                                    maxHeight: '900px',
                                                    overflowX: 'hidden',
                                                    overflowY: 'auto',
                                                    top: '0px !important',
                                                },
                                            },
                                        }}
                                    >
                                        <Grid container={true} className={classes?.popupMainContainerImagelessLayout}>
                                            <Grid item={true} xs={12} sm={12}>
                                                <Container component="main" disableGutters={true} maxWidth="md" >
                                                    <Grid container={true}>
                                                        <Grid item={true} xs={12} sm={12}>
                                                            <Grid container={true}>
                                                                <Grid item={true} xs={12} sm={12}>
                                                                    <Box
                                                                        aria-label="show shopping cart"
                                                                        color="inherit"
                                                                        id="openModel"
                                                                        sx={{
                                                                            margin: 0,
                                                                            padding: '8px 15px',
                                                                            border: '1px solid',
                                                                            borderColor: organizationSettings?.layoutColor,
                                                                            borderRadius: '40px',
                                                                            fontSize: 14,
                                                                            display: 'flex',
                                                                            backgroundColor: '#fff',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between',
                                                                        }}
                                                                    >
                                                                        <Stack direction="row" alignItems="center">
                                                                            <img src='/static/images/shopping-bag.svg' alt='' color="secondary" style={{marginRight: '5px'}}/>
                                                                            <Typography sx={{ fontSize: { xs: '14px', marginRight: '5px' } }}>My order</Typography>
                                                                        </Stack>
                                                                        <span
                                                                            className={classes?.miniCartClose}
                                                                            onClick={this.handleClose}
                                                                        >
                                                                            <Typography sx={{fontSize: {xs: '14px'}}}>Close</Typography>
                                                                            <ClearOutlined sx={{
                                                                                fontSize: '16px',
                                                                                margin: '5px',
                                                                            }} />
                                                                        </span>
                                                                    </Box>
                                                                    <div className={classes?.miniCartItems}>
                                                                        <Typography sx={{
                                                                            fontSize: { sm: '16px' },
                                                                            fontWeight: 600,
                                                                            marginBottom: '5px',
                                                                        }}>My orders</Typography>
                                                                    </div>
                                                                    <Grid
                                                                        container={true}
                                                                        className={classNames(classes?.productDetailsItemImagelessLayout, order && order.orderItems.length <= 0 ? classes?.cartEmpty : '')}
                                                                        style={{ textTransform: 'uppercase', overflow: 'hidden', overflowY: 'auto' }}
                                                                    >
                                                                        {!isOrderEmpty ?
                                                                            order.orderItems.map((orderItem: IOrderItem,index:number) => {
                                                                                const { name, price, quantity, id, orderItemModifiers } = orderItem
                                                                            
                                                                                let modifiers = getCheckedOptions(orderItemModifiers)?.map((orderItemModifier: any) => {
                                                                                    // Find the selected sub-option based on selectedSubOption ID
                                                                                    const selectedSubOption = orderItemModifier?.subOptions?.find(
                                                                                        (sub: any) => sub.id === orderItemModifier.selectedSubOption
                                                                                    );
                                                                                
                                                                                    // Generate the sub-options text only if a selected sub-option exists
                                                                                    const subOptionsText = selectedSubOption 
                                                                                        ? ` (${selectedSubOption.name})` 
                                                                                        : '';
                                                                                
                                                                                    // Return the modifier name concatenated with the selected sub-option name
                                                                                    return `${orderItemModifier?.name || ''}${subOptionsText}`;
                                                                                });

                                                                                if (modifiers.length === 0 && orderItemModifiers) {
                                                                                    modifiers = orderItemModifiers.flatMap((orderItemModifier: any) => {
                                                                                        return orderItemModifier.options
                                                                                            .filter((option: any) => option.checked !== false) // Process only if checked is not false or if checked is not present
                                                                                            .map((option: any) => {
                                                                                                const subOptionsText = option.subOptions.length > 0 
                                                                                                    ? ` (${option.subOptions.map((sub: any) => sub.name).join(', ')})`
                                                                                                    : '';
                                                                                                return `${option.name}${subOptionsText}`;
                                                                                            });
                                                                                    });
                                                                                }
                                                        

                                                                                return (
                                                                                    <Grid item={true} xs={12} sm={12} key={id+'key'+index} sx={{
                                                                                        width: '100%',
                                                                                        margin: '4px 0px',
                                                                                        justifyContent: 'space-between',
                                                                                        alignItems: 'center',
                                                                                        backgroundColor: 'white',
                                                                                        padding: '5px 15px',
                                                                                        borderRadius: '12px',
                                                                                    }}>
                                                                                        <Grid container={true}>
                                                                                            <Button sx={{
                                                                                                color: "#000000",
                                                                                                textAlign: "left",
                                                                                                display: "block",
                                                                                                px: 0,
                                                                                            }}
                                                                                                onClick={() => this.backToProductEdit(orderItem)}>
                                                                                                <Typography sx={{
                                                                                                    textOverflow: "ellipsis",
                                                                                                    overflow: "hidden",
                                                                                                    whiteSpace: "nowrap",
                                                                                                    fontSize: { xs: '16px' },
                                                                                                    fontWeight: 600,
                                                                                                }}
                                                                                                >
                                                                                                    {name}
                                                                                                </Typography>
                                                                                                <Box sx={{ textAlign: "left" }}>
                                                                                                    <Typography sx={{ fontSize: { xs: '12px' }, textTransform: "none", fontWeight: 400 }}>{modifiers.join(', ')}</Typography>
                                                                                                </Box>
                                                                                                <Box sx={{ textAlign: "left" }}>
                                                                                                    <Typography sx={{ fontSize: { xs: '12px' }, textTransform: 'none', fontWeight: 400, color: "#000000" }}>{orderItem.notes}</Typography>
                                                                                                </Box>
                                                                                            </Button>
                                                                                        </Grid>
                                                                                        <Grid container={true} sx={{ mt: '-6px' }}>
                                                                                            <Grid item={true} xs={12} sm={12}>
                                                                                                <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', pr: '20px'}}>
                                                                                                        <Typography sx={{fontSize: {xs: '15px'}, mr: '10px'}}>
                                                                                                            {quantity}
                                                                                                        </Typography>
                                                                                                        <ClearOutlined sx={{
                                                                                                            fontSize: { xs: '12px' },
                                                                                                            marginBottom: '-1px', mr: '10px'
                                                                                                        }} />
                                                                                                        <Typography sx={{ fontSize: { xs: '15px' } }}>${formatMoney(price + getReorderModifiersPrice(orderItemModifiers))}</Typography>
                                                                                                    </Box>
                                                                                                    <Button sx={{ justifyContent: 'right' }}>
                                                                                                        <DeleteOutlined sx={{ fontSize: { xs: '15px' }, color: '#000000', p: 0 }} onClick={() => this.removeItem(id)} />
                                                                                                    </Button>
                                                                                                    <Box sx={{ textAlign: 'right' }}>
                                                                                                        <Typography sx={{ fontSize: { xs: '15px' }, color: organizationSettings?.layoutColor, fontWeight: 500 }}>${formatMoney((price + getReorderModifiersPrice(orderItemModifiers)) * quantity)}</Typography>
                                                                                                    </Box>
                                                                                                </Stack>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                            :
                                                                            <Grid container={true} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                                                <Grid item={true} xs={12} sm={12}>
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        align="center"
                                                                                        gutterBottom={true}
                                                                                        style={{ position: 'sticky', color: '#000000', transform: 'translateY(100px)', textTransform: 'none', fontWeight: 600 }}
                                                                                    >
                                                                                        Your cart is empty.
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            }
                                                                    </Grid>
                                                                </Grid>
                                                                {!isOrderEmpty ?
                                                                    <Grid item={true} xs={12} sm={12}>
                                                                        <div className={classes?.miniCartPayment}>
                                                                            <Typography sx={{
                                                                                fontSize: { sm: '16px' },
                                                                                fontWeight: 600,
                                                                                marginBottom: '5px',
                                                                                marginTop: '24px',
                                                                            }}>Payment</Typography>
                                                                        </div>
                                                                        <Grid container={true} sx={{
                                                                            width: '100%',
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between',
                                                                            alignItems: 'center',
                                                                            backgroundColor: 'white',
                                                                            padding: '10px 15px',
                                                                            borderRadius: '12px',
                                                                            mb: '20px'
                                                                        }}>
                                                                            {!!order.serviceCharge && <div className={classes?.paymentInfo}>
                                                                                <Grid item={true} xs={6} sm={6}>
                                                                                    <div>Service Charge:</div>
                                                                                </Grid>
                                                                                <Grid item={true} xs={6} sm={6}>
                                                                                    <div className={classes?.minicartPaymentMoney} >
                                                                                        <div>${order.serviceCharge ? formatMoney(order.serviceCharge) : '00.00'}</div>
                                                                                    </div>
                                                                                </Grid>
                                                                            </div>}
                                                                            {!!order.gratuity && <div className={classes?.paymentInfo}>
                                                                                <Grid item={true} xs={6} sm={6}>
                                                                                    <div>Gratuity:</div>
                                                                                </Grid>
                                                                                <Grid item={true} xs={6} sm={6}>
                                                                                    <div className={classes?.minicartPaymentMoney} >
                                                                                        <div>${order.gratuity ? formatMoney(order.gratuity) : '00.00'}</div>
                                                                                    </div>
                                                                                </Grid>
                                                                            </div>}
                                                                            {!!order.taxTotal && <div className={classes?.paymentInfo}>
                                                                                <Grid item={true} xs={6} sm={6}>
                                                                                    <div>Tax:</div>
                                                                                </Grid>
                                                                                <Grid item={true} xs={6} sm={6}>
                                                                                    <div className={classes?.minicartPaymentMoney} >
                                                                                        <div>${order.taxTotal ? formatMoney(order.taxTotal) : '00.00'}</div>
                                                                                    </div>
                                                                                </Grid>
                                                                            </div>}
                                                                            <div className={classes?.paymentInfo}>
                                                                                <Grid item={true} xs={6} sm={6}>
                                                                                    <Typography sx={{ fontSize: { sm: '18px' }, fontWeight: 600 }}>Subtotal:</Typography>
                                                                                </Grid>
                                                                                <Grid item={true} xs={6} sm={6}>
                                                                                    <Typography sx={{
                                                                                        color: organizationSettings?.layoutColor,
                                                                                        marginBottom: '5px',
                                                                                        textAlign: 'right',
                                                                                        fontSize: { sm: '18px' },
                                                                                        fontWeight: 600,
                                                                                    }}>
                                                                                        <div>${formatMoney(getSubTotalPrice())}</div>
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                    : <></>}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                                {!isOrderEmpty ?
                                                    <Grid item={true} xs={12} sm={12}>
                                                        <Grid item={true} xs={12} sm={12} sx={{
                                                            margin: '10px 0',
                                                            textAlign: 'center',
                                                        }}>
                                                            <Button
                                                                variant='contained'
                                                                color='layoutColor'
                                                                disableElevation={true}
                                                                sx={{
                                                                    backgroundColor: organizationSettings?.layoutColor,
                                                                    textTransform: 'none',
                                                                    padding: '5px 10px',
                                                                    width: '100%',
                                                                    borderRadius: '12px',
                                                                    fontSize: '18px',
                                                                    marginTop: '10px',
                                                                    "&.MuiButtonBase-root:hover": {
                                                                        backgroundColor: organizationSettings?.layoutColor,
                                                                    }
                                                                }}
                                                                onClick={() => this.productToCheckout(history)}
                                                            >
                                                                Checkout
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    : <></>}
                                            </Grid>
                                        </Grid>
                                    </Popover>
                                </Paper>
                            </Box>
                        </Box>
                        {isLoggedIn && !isLoading && window.location?.pathname === '/proceed-to-checkout' && <Box sx={{
                            display: { xs: 'none', md: 'flex' },
                            marginLeft: 'auto',
                            paddingRight: '10px',
                            alignItems: 'center',
                            translate: {xs: 'none', sm: 'none', md:'none', lg: '-410px'},
                            }}>
                                <IconButton
                                    aria-label="show profile"
                                    color="inherit"
                                    aria-controls="simple-menu"
                                    className={classes?.root}
                                    aria-haspopup="true"
                                    onClick={this.handleClickOpenMenu}
                                    sx={{ display: { xs: 'none', md: 'flex' } }}
                                >
                                    <SettingsOutlined sx={{ height: 24, width: 24, cursor: 'pointer', color: '#979797' }} />
                                </IconButton>
                                {isLoggedIn && window.location?.pathname === '/proceed-to-checkout' && <Menu style={{ top: '35px' }}
                                    id="simple-menu"
                                    anchorEl={anchorElMenu}
                                    keepMounted={true}
                                    open={Boolean(anchorElMenu)}
                                    onClose={this.handleCloseMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >

                                    <MenuItem key={1} onClick={() => {
                                       localStorage.clear();
                                       sessionStorage.clear();
                                       localStorage.removeItem(keys.authAccessToken);
                                       Auth0LockGraze.logout({ returnTo: window.location.origin})
                                
                                    }}>Logout</MenuItem>
                                    <MenuItem key={2}>
                                        <NavLink
                                            className={classes?.profileLink}
                                            to="/profile"
                                            onClick={this.handleCloseMenu}
                                        >
                                            Profile
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem key={3}>
                                        <NavLink
                                            className={classes?.profileLink}
                                            to="/profile?tab=order-history"
                                            onClick={this.handleCloseMenu}
                                        >
                                            Reorder Past Order
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem key={4}>
                                        <NavLink
                                            className={classes?.profileLink}
                                            to="/profile?tab=manage-address"
                                            onClick={this.handleCloseMenu}
                                        >
                                            Manage Address
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem key={4}>
                                        <NavLink
                                            className={classes?.profileLink}
                                            to="/profile?tab=contact"
                                            onClick={this.handleCloseMenu}
                                        >
                                            Contact
                                        </NavLink>
                                    </MenuItem>
                                </Menu>}
                            <Divider
                                orientation='vertical'
                                variant="middle"
                                flexItem
                                sx={{ mx: '20px', color: '#E7E7E7', display: {xs: 'none', md: 'flex'} }} />
                            <Box sx={{
                                display: { xs: 'none', md: 'flex' }
                            }}>
                                <Button disabled={true} style={{ color: '#000000', height: '40px', alignItems: 'flex-start' }}><Typography sx={{ fontSize: { xs: '20px' } }}>{user ? `Welcome ${user?.given_name}` : ''}</Typography></Button>
                            </Box>

                                {!isLoggedIn &&
                                    <Menu style={{ top: '45px' }}
                                        id="simple-menu"
                                        anchorEl={anchorElMenu}
                                        keepMounted={true}
                                        open={Boolean(anchorElMenu)}
                                        onClose={this.handleCloseMenu}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <MenuItem onClick={this.handleLogin}>Login</MenuItem>

                                    </Menu>
                                }
                        </Box>}
                        {!isLoggedIn && !isLoading && window.location?.pathname === '/proceed-to-checkout' && <Box sx={{
                            display: { xs: 'none', md: 'flex' },
                            marginLeft: 'auto',
                            paddingRight: '10px',
                            alignItems: 'center',
                            translate: { xs: 'none', sm: 'none', md: 'none', lg: '-410px' },
                        }}>

                            <Box sx={{
                                display: { xs: 'none', md: 'flex'}
                            }}>
                                {
                                    !guestLogin &&  
                                    <Button disabled={true}  style={{ color: '#000000', height: '40px', alignItems: 'flex-start' }}>
                                    <Typography sx={{ fontSize: { xs: '20px' } }}>Welcome Guest</Typography>
                                    </Button>
                                //     :
                                //  <Button  style={{ color: '#000000', height: '40px', alignItems: 'flex-start' }}onClick={()=> {setGuestLogin(true);localStorage.setItem('isGuest','true')}}>
                                // <Typography sx={{ fontSize: { xs: '20px' } }}>Continue as Guest</Typography>
                                // </Button>
                                }
                            
                              
                            </Box>
                        </Box>}
                    </Toolbar >
                    <Box sx={{ width: '100%', display: { xs: 'flex', sm: 'none' }, justifyContent: 'center', marginTop: {xs: '10px'} }}>
                        <GlobalSearch onSearchChange={this.handleSearchChange} />
                    </Box>
                </AppBar >
                <AuthModal open={authModalOpen} onClose={() => { this.setState({ authModalOpen: false }) }} />
            </>
        );
    }


    private renderDrawer = () => {
        const { utility, user } = this.props;

        return (
            <Paper>
                {/* mdDown={!utility?.drawerOpen && true} */}
                <AppDrawer
                    utility={utility}
                    user={user}
                    handleDrawerClose={this.props.closeDrawer}
                />
            </Paper>
        );
    }

    private switchSelectedMenu = (menuId: number) => {
        const { setSelectedMenu, history } = this.props;

        history?.push('/dashboard');
        setSelectedMenu(menuId);
    }

    private renderMultiMenuSwitch = () => {
        const { classes, onlineMenuList, selectedMenuId, layout, location, menus, setSelectedMenuId } = this.props;
        const mainLayout = true;
        // layout === 'Main Layout';

        if (window.location.pathname === '/proceed-to-checkout') {
            return <div className={classes?.toolbar} />;
        }

        if (window.location.pathname.split("/")[1] === 'order-confirmed') {
            return <div className={classes?.toolbar} />;
        }

        if (!menus || menus?.length < 2) {
            return <div className={classes?.toolbar} />;
        }

        return <div className={classes?.selectMenuSection}>
            <Box sx={{
                width: '100%',
                display: { xs: "none", sm: "none", md: "none", lg: "block" },
            }}>
                <Tabs
                    className={classNames(classes?.tabs, { [classes?.tabsImagelessLayout]: !mainLayout })}
                    value={selectedMenuId}
                    onChange={(e, newMenuId) => setSelectedMenuId(newMenuId as number)}
                    variant="scrollable"
                    textColor="secondary"
                    indicatorColor="secondary" 
                >
                    {menus?.map((menu: any, index:number) => {
                        return <Tab
                            className={classNames({ [classes?.selectMenuTab]: !mainLayout })}
                            key={`tab-option-menu-id-${menu.id +''+ index}`}
                            value={menu.id}
                            label={menu.name}
                        />
                    })}
                </Tabs>
            </Box>
            <FormControl variant="standard" sx={{
                display: { md: "block", lg: "none" },
                margin: {xs: '32px 16px -10px', sm: '32px 16px -5px'},
                width: "100%",
            }}>
                <Select
                    sx={{
                        width: {xs: '100%', md: '90%'},
                        marginTop: {xs: '25%', sm: '5%'},
                        display: {lg: 'none'},
                    }}
                    classes={{
                        select: classes?.selectSelect,
                        icon: classes?.selectIcon,
                    }}
                    label="Select Menu"
                    value={selectedMenuId}
                    onChange={event => {
                        setSelectedMenuId(event.target.value as number);
                    }}
                >
                    {menus?.map((menu: any, index:number) => {
                        return <MenuItem
                            key={`option-menu-id-${menu.name + index}`}
                            value={menu.id}
                        >
                            {menu.name}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        </div>
    }

    private MailBoard = () => <></>
    // <MailPage
    //     mail={this.props.mail}
    // />

    private CategoryMenuViewBoard = () => <></>
    // <CategoryMenuView dispatch={this.props.dispatch} />


     checkAppUserAvailable = async () => {
        const { appUserDetails, auth0UserDetails, organization } = this.props;
        if (!appUserDetails && auth0UserDetails) {
            await this.props.getUserByEmailId(auth0UserDetails.email).then(async (response: any) => {
                if (!response.successful) {
                    const payload: any = [
                        {
                            "organizationId": organization?.id,
                            //   "defaultAddressId": 0,
                            "organizationDomainName": organization?.domainName,
                            "userName": auth0UserDetails?.nickname,
                            "firstName": auth0UserDetails?.given_name,
                            "lastName": auth0UserDetails?.family_name,
                            "emailAddress": auth0UserDetails?.email,
                            "userType": "User",
                            "phoneNumber": auth0UserDetails?.phoneNumber
                        }
                    ];
                    await this.props.registerUser(payload).then((res: any) => {
                        Auth0LockGraze.checkSession({}, (error, authResult) =>{
                            if (error) {
                                return;
                              }
                              if(authResult) {
                                localStorage.setItem(keys.authAccessToken, authResult?.idToken);
                                this.props.getUserDetails();
                                //window.location.reload();
                              }
                        });
                        // localStorage.removeItem(keys.authAccessToken);
                        // sessionStorage.removeItem(keys.authAccessToken);
                        // window.location.reload();
                    }).catch((err: any) => {
                        console.error("::::::: error ", err);
                    });

                
                } else {
                    Auth0LockGraze.checkSession({}, (error, authResult) =>{
                        if (error) {
                            return;
                          }
                          if(authResult) {
                            localStorage.setItem(keys.authAccessToken, authResult?.idToken);
                            this.props.getUserDetails();
                            //window.location.reload();
                          }
                    });
                }
            }).catch((err: any) => {
                console.error("::::::: error ", err);
            });

        }

    }
    public componentDidMount() {
        const { router, location, history, getOrganization, getOrganizationSettings, getOrganizationOrdersOpenSchedule, getUserDetails, getMenus, appUserDetails, user, setSelectedMenuId,selectedMenuId } = this.props;
        window.document.documentElement.dataset.scroll = 'hide';
        const loginError = new URLSearchParams(location?.search)?.get('loginError');
        if (loginError) {
            this.handleLogin();
            this.setState({ loginError });
            history.push(location?.pathname);
        }

        // userManager.events.addUserSignedOut(() => {
        //     userManager.removeUser().then(() => {
        //         this.checkUser();
        //     });
        // });

        // userManager.events.addSilentRenewError(error => {
        //     userManager.removeUser().then(() => {
        //         this.checkUser();
        //     });
        // });

        this.checkUser();
        const menuId = new URLSearchParams(router?.location?.search)?.get("id")

        getOrganization().then(async () => {
            const activeMenus = await getMenus();
            if(menuId){
                setSelectedMenuId(Number(menuId));
            } else if (activeMenus.length > 0) {
                setSelectedMenuId(activeMenus[0].id);
            }
            getOrganizationSettings();
            getOrganizationOrdersOpenSchedule();
            if (user) {
                getUserDetails();
            }
        });
    }

    public componentDidUpdate = (prevProps: IProps) => {
        const {
            location,
            addCartOpen,
            user,
            addCartPopWindowClose,
            setSearchValue,
            order,
        } = this.props;

        if (addCartOpen !== prevProps.addCartOpen) {
            if (addCartOpen) {
                const clickEvent = document.getElementById("openModel");
                if (clickEvent) {
                    clickEvent.click();
                }
                setTimeout(() => {
                    addCartPopWindowClose()
                }, 4000);
            } else {
                this.handleClose();
            }
        }

        if (prevProps.location?.pathname !== location?.pathname) {
            setSearchValue('');
        }

        if ((!prevProps.user && !!user) || (!!prevProps.user && !!user && prevProps.user.profile && prevProps.user.profile.sub !== user.profile.sub)) {
            if (location && location.pathname && !location.pathname.includes('/location')) {
                this.init();
            }
        }

        if (order?.unavailableOrderItems?.length ?? 0 > 0) {
            console.log("Unavailable menu items");
        }

        this.checkUser();
    }

    private unavailableOrderItemsWarningClosed = () => {
        this.props.clearUnavailableItems();
    }

    private onMainScrolled = _.debounce((value: number) => {
        window.document.documentElement.dataset.scroll = value > 400 ? 'show' : 'hide';
    }, 300);

    private scrollToTop = () => {
        const main = document.getElementById('main');
        main?.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    private MainLayout = (props: any) => {
        const { classes, order, layout, theme, organizationSettings, organizationOrdersOpenSchedule } = this.props;
        const mainLayout = true;
        // layout === 'Main Layout';
        return (
            <>
                <div className={mainLayout ? classes?.rootMain : classes?.rootMainImagelessLayout} style={{ fontFamily: theme.typography.fontFamily }}>
                    {mainLayout ? this.renderAppBar() : this.renderAppBarImagelessLayout()}
                    {this.renderDrawer()}
                    <main
                        className={classNames(classes?.content, { [classes?.contentImagelessLayout]: !mainLayout })}
                        id="main"
                        onScroll={(e: any) => this.onMainScrolled(e.target.scrollTop)}
                        style={{ marginTop: !isClosed(organizationSettings, organizationOrdersOpenSchedule) ? 0 : 40 }}
                    >
                        {this.renderMultiMenuSwitch()}
                        {this.renderAlert()}
                        {props.children}
                        {this.renderConfirmBox()}
                        {this.renderSpinner()}
                        <Snackbar open={(order?.unavailableOrderItems?.length ?? 0) > 0} autoHideDuration={10000} onClose={this.unavailableOrderItemsWarningClosed} className={classes?.unavailableItemsAlert}>
                            <Alert onClose={this.unavailableOrderItemsWarningClosed} severity="warning" >
                                Some of the items in your order are no longer available!
                            </Alert>
                        </Snackbar>
                    </main>
                </div>
                <Box>
                    <Button
                        className='main-scroll-button'
                        endIcon={<Up />}
                        sx={{
                            color: "layoutColor.contrastText",
                            backgroundColor: organizationSettings?.layoutColor,
                            "&.MuiButton-root:hover": { backgroundColor: organizationSettings?.layoutColor },
                            position: "absolute",
                            bottom: 20,
                            right: 20,
                            zIndex: 50,
                            borderRadius: "16px",
                            height: "48px",
                            textTransform: "capitalize",
                            padding: "15px",
                        }}
                        onClick={() => this.scrollToTop()}
                    >
                        <Typography sx={{ fontSize: { xs: "18px", paddingRight: "2px" } }}>
                            Scroll to Top
                        </Typography>
                    </Button>
                </Box>
            </>
        )
    }

    public render() {
        const { isLoadingUser, user, searchValue, history, layout, theme, organization, appUserDetails, } = this.props;

        // if (isLoadingUser || !theme) {
        //     return null;
        // }

        // const redirectPathname = LocalStorage.get<string>(LocalStorage.keys.redirectPathname);
        // const signIn = history.location.pathname === '/signin-oidc/';

        // if (redirectPathname && !signIn) {
        //     LocalStorage.save(LocalStorage.keys.redirectPathname, '');
        //     history.push(redirectPathname);
        // }

        // if (!signIn && !layout) {
        //     return null;
        // }
        const Layout = this.MainLayout;
        const HomePage = layout === 'Main Layout' ? Home : Home;
        const HomeSearchPage = layout === 'Main Layout' ? HomeSearch : HomeSearchImagelessLayout;
        // const ReviewProductDetailsPage = layout === 'Main Layout' ? ReviewProductDetails : ReviewProductDetailsImagelessLayout;
        //   const ProceedToCheckoutPage = layout === 'Main Layout' ? ProceedToCheckout : ProceedToCheckoutImagelessLayout;

        return (
            !organization ? <Splash /> : <Layout>
                <Routes>
                    <Route path="/" element={searchValue ? <HomeSearchPage searchLoader={this.state.searchLoader} /> : <HomePage />} />
                    <Route path='/dashboard' element={searchValue ? <HomeSearchPage searchLoader={this.state.searchLoader} /> : <HomePage />}></Route>
                    <Route path='/proceed-to-checkout' element={(layout === 'Main Layout') ? <ProceedToCheckout /> : <ProceedToCheckout />} />
                    <Route path='/order-confirmed/:id' element={<OrderConfirmation />} />
                    <Route path='/profile' element={<Profile />} />
                    {/* <Route path='/profile/:page' element={<Profile/>}/> */}

                </Routes>
            </Layout>
            //         /* <Route path='/signin-oidc'  component={() =>
            //             <OidcCallback userManager={userManager} />
            //         } /> */}
            //         {/* <Route path='/mail'  component={this.MailBoard} />
            //         <Route path='/profile'  render={(props) => this.renderProfile(props)} />
            //         <Route path='/profile/:page'  render={(props) => this.renderProfile(props)} />
            //         <Route path='/categoryhome'  component={CategoryBoard} />
            //         <Route path='/dealhome'  component={DealBoard} />
            //         <Route path='/featurehome'  component={FeatureBoard} />
            //         <Route path='/product/:id'  component={ProductDetails} />
            //         <Route path='/product/eidt/:id'  component={() => <ProductDetails isEdit={true} />} />
            //         <Route path='/order/:id'  component={OrderSuccess} />
            //         <Route path='/category/menu/:id'  component={this.CategoryMenuViewBoard} />
            //         <Route path='/proceed-to-checkout'  component={ProceedToCheckoutPage} />
            //         <Route path='/review-your-product-details'  component={ReviewProductDetailsPage} />
            //         <Route path='/payment-process'  component={PaymentProcess} />
            //         <Route path='/Stores'  component={() => <StoresPage />} />
            //         <Route path='/dashboard'  component={() => searchValue ? <HomeSearchPage /> : <HomePage />} />*/}
            //         {/* <Route path='/'>{searchValue ? <HomeSearchPage /> : <HomePage />}</Route> */}

            // // </Layout>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    utility: state.utility,
    materials: state.materials,
    // materialCharts: getMaterialChartItems(state),
    // mail: getMailitems(state),
    cartLength: state?.order?.orderItems ? state.order.orderItems.length : 0,
    order: state.order,
    addCartOpen: state?.common?.addCartPopWindowOpen,
    loader: state?.common?.loader,
    searchValue: state?.menu?.searchValue,
    auth0UserDetails: state?.user?.auth0UserDetails,
    user: state?.user?.auth0UserDetails,
    isLoadingUser: state?.user?.isLoadingUser,
    appUserDetails: state?.user?.appUserDetails,
    appUserDetails2: state?.organizations?.userDetails,
    // companyLogo: !!state.settings.SettingsKeyValue.GRAZE_COMPANY_LOGO ? state.settings.SettingsKeyValue.GRAZE_COMPANY_LOGO.value : '',
    companyLogo: state?.organizations?.organizationSettings?.grazeLogo || "/static/images/logos/defaultLogo.svg",
    customer: state?.customer,
    onlineMenuList: state?.menu?.onlineMenuList,
    selectedMenuId: state?.items?.selectedMenuId,
    layout: !!state.settings.SettingsKeyValue.GRAZE_LAYOUT ? state.settings.SettingsKeyValue.GRAZE_LAYOUT.value : '',
    organization: state?.organizations?.organization,
    menus: state.items?.menus,
    organizationSettings: state?.organizations?.organizationSettings,
    organizationOrdersOpenSchedule: state?.organizations?.organizationOrdersOpenSchedule,
    searchItems: state?.items?.menus,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(_.assign(
        {
            orderGetIfIncomplete: OrderActions.getIfIncomplete,
            orderRemoveItem: OrderActions.removeItem,
            clearUnavailableItems: OrderActions.clearUnavailableItems,
            getOrder: OrderActions.get,
            setSearchValue: MenuActions.setSearchValue,
            setSelectedMenuId: ItemsActions.setSelectedMenu,
            getGrazeSettingAreas: getSettingAreas,
            getCustomer: CustomerActions.getCustomer,
            updateCustomer: CustomerActions.updateCustomer,
            sendCustomerMessage: CustomerActions.sendCustomerMessage,
            closeProductDetailPageModel: AppActionCreators.closeProductDetailPageModel,
            getOrganization: OrganizationActions.getOrganization,
            getMenus: ItemsActions.getMenus,
            selectedCategoryMenuProduct: selectedCategoryMenuProduct,
            openProductDetailPageModel: openProductDetailPageModel,
            getOrganizationSettings: OrganizationActions.getOrganizationSettings,
            getOrganizationOrdersOpenSchedule: OrganizationActions.getOrganizationOrdersOpenSchedule,
            assignOrgToLoggedInUser: OrderActions.assignOrgToLoggedInUser,
            storeAuth0User: AppActionCreators.storeAuth0User,
            orderAddItem: OrderActions.addItem,
            getUserDetails: OrganizationActions.getUserDetails,
            getUserByEmailId: OrderActions.getUserByEmailId,
            registerUser: OrderActions.registerUser,
            searchMenuItems: ItemsActions.searchMenuItems
        },
        AppActionCreators,
        // MailActionCreators,
        // MaterialActionCreators,
        AppMenuActionCreators,
    ), dispatch);
//
const MiniDrawerView = withStyles(styles as any)(MiniDrawer);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MiniDrawerView))

import { ActionType, IAppAction } from './Helpers';
import { Dispatch } from 'redux';
import { AppState } from '../../state/AppState';
import { checkoutAPIs, paymentAPIs } from '../../services/ApiVariables';

export const createGuestOrder = (payload: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return postGuestOrder(payload, dispatch, api)
        .then((response: any) => {
            return response;
        })
        .catch((response: any) => {
            return response;
        });
}

const postGuestOrder = (payload: any, dispatch: Dispatch<IAppAction>, api: any) => {
    return api(checkoutAPIs.createGuestOrder(payload))
        .then((response: any) => {
            // dispatch({ type: ActionType.CREATE_, payload: response?.data?.menuCategories });
            return response;
        }).catch((response: any) => {
            return response;
        });
}




export const getPaymentCardDetails = (payload: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return getCardDetails(payload, dispatch, api)
        .then((response: any) => {
            return response;
        })
        .catch((response: any) => {
            return response;
        });
}

const getCardDetails = (payload: any, dispatch: Dispatch<IAppAction>, api: any) => {
    return api(paymentAPIs.getCardDetails(payload))
        .then((response: any) => {
            // dispatch({ type: ActionType., payload: response?.data });
            return response;
        }).catch((response: any) => {
            return response;
        });
}
export const postCreatePaymentLink = (payload: any, processorType: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return postCreatePaymentUrl(payload, processorType, dispatch, api)
        .then((response: any) => {
            return response;
        })
        .catch((response: any) => {
            return response;
        });
}

const postCreatePaymentUrl = (payload: any, processorType: any, dispatch: Dispatch<IAppAction>, api: any) => {
   
    return api(paymentAPIs.postCreatePayLink(payload, processorType))
        .then((response: any) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
}


export const postPaymentForOrder = (payload: any, processorType: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return postPayment(payload, processorType, dispatch, api)
        .then((response: any) => {
            return response;
        })
        .catch((response: any) => {
            return response;
        });
}

const postPayment = (payload: any, processorType: any, dispatch: Dispatch<IAppAction>, api: any) => {
    return api(paymentAPIs.postPayment(payload, processorType))
        .then((response: any) => {
            // dispatch({ type: ActionType., payload: response?.data });
            return response;
        }).catch((response: any) => {
            return response;
        });
}


export const getGuestOrderDetails = (orderId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return getOrderDetails(orderId, dispatch, api)
        .then((response: any) => {
            return response;
        })
        .catch((response: any) => {
            return response;
        });
}

const getOrderDetails = (orderId: string, dispatch: Dispatch<IAppAction>, api: any) => {
    return api(checkoutAPIs.getGuestOrder(orderId))
        .then((response: any) => {
            // dispatch({ type: ActionType., payload: response?.data });
            return response;
        }).catch((response: any) => {
            return response;
        });
}

export const getCalculatedPayment = (body: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    const id = getState().organizations?.organizationID || -1;
    return api(checkoutAPIs.getCalculatedValues({ ...body, organizationId: id }))
        .then((response: any) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
}

export const getUndeliverablePostalCodes = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    const id = getState().organizations?.organizationID || -1;
    return api(checkoutAPIs.getUndeliverablePostalCodes(id))
        .then((response: any) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
}

export const getCalculatedEstimatedTime = (body: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    const id = getState().organizations?.organizationID || -1;
    return api(checkoutAPIs.getCalculatedEstimatedTime({...body, organizationId: id}))
        .then((response: any) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
}

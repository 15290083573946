import * as React from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Drawer, IconButton, Divider, Theme, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import { User } from 'oidc-client';
import { Utility } from '../state/Utility';
import { NavLink } from 'react-router-dom';
import { styles } from './styles';
import Hidden from '@mui/material/Hidden';
import { withStyles } from '@mui/styles';


const classNames = require('classnames');


interface IAppDrawer {
    user?: any;
    utility: Utility;
    classes?: any;
    theme?: Theme;
    handleDrawerClose?: () => void;
}

class AppDrawer extends React.Component<IAppDrawer, {}> {
    public routes = [
        { path: '/', title: 'Menu', icon: () => <DashboardIcon /> },

        { path: '/account', title: 'Profile', icon: () => <AccountCircleIcon /> }
    ]

    public render(): JSX.Element {
        const { user, classes, utility, theme } = this.props;
        return (
            <Hidden only={['xs','sm','lg', 'xl', 'md']}>
                <Drawer
                    hidden={!user}
                    variant="permanent"
                    classes={{
                        paper: classNames(classes?.drawerPaper, !utility?.drawerOpen && classes?.drawerPaperClose),
                    }}
                    open={utility?.drawerOpen}
                >
                    <div className={classes?.toolbar}>
                        <IconButton onClick={this.props.handleDrawerClose}>
                            {theme?.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    {this.routes.map((route, index) => {
                        return (
                            <NavLink key={index} className={classes?.link} to={route.path} >
                                <ListItem component={'button'}>
                                    <ListItemIcon>
                                        {route.icon()}
                                    </ListItemIcon>
                                    <ListItemText primary={route.title} />
                                </ListItem>
                            </NavLink>
                        );
                    })}
                    <Divider />
                </Drawer>
            </Hidden>
        );
    }
}

export default withStyles(styles)(AppDrawer);

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { reducers } from './store/reducers/CombinedReducers';
import { createStore } from 'redux';
// import { Auth0Provider } from '@auth0/auth0-react';
import enhancer from './store/Store';
// export const store = configureStore(
//   {
//     reducer: reducers, middleware: (getDefaultMiddleware) => getDefaultMiddleware({
//       serializableCheck: false,
//     }),
//   }
// );
export const store = createStore(
  reducers,
  enhancer,
);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{ redirect_uri: window.location.origin }}
    > */}
      <Provider store={store}>
        <App />
      </Provider>
    {/* </Auth0Provider> */}

  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

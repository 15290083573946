import { ActionType, IAppAction } from '../actions/Helpers';
import IOrder from '../../models/IOrder';
import IOrderItem from '../../models/IOrderItem';
import * as LocalStorage from '../../utilities/LocalStorage';

const initialOrder: IOrder = {
    locationId: '',
    asap: true,
    orderItems: [] as IOrderItem[],
};

const orderReducer = (state = { ...initialOrder, orderItems: [...initialOrder.orderItems] }, action: IAppAction) => {
    switch (action.type) {
        case ActionType.ORDER_SET:
            const order = action.payload;
            order.location = {};
            saveOrderId(order);
            const orderTotalobject: any = { total: 0, balanceDue: 0, subTotal: 0 };
            order?.orderItems?.forEach((element: any) => {
                orderTotalobject.total = orderTotalobject.total + (element.price * element.quantity);
                orderTotalobject.balanceDue = orderTotalobject.total;
                orderTotalobject.subTotal = orderTotalobject.total;
            });
            order.total = orderTotalobject?.total;
            order.balanceDue = orderTotalobject?.balanceDue
            order.subTotal = orderTotalobject?.subTotal
            order.estimatedReadyTimeMin = new Date();
            order.estimatedReadyTimeMax = new Date();

            return { ...order };

        case ActionType.ORDER_SET_DEFAULT:
            saveOrderId(initialOrder);
            return initialOrder;
        case ActionType.ORDER_CLEAR_UNAVAILABLE_ITEMS:
            return { ...state, unavailableOrderItems: [] }
        case ActionType.CLEAR_CART:
            return { ...state, orderItems: [] }
        default:
            return state;
    }

};

export default orderReducer;

const saveOrderId = (order: IOrder): void => {
    LocalStorage.save(
        LocalStorage.keys.orderId,
        !!order && !!order.id ? order.id : 0
    );
}

import { Backdrop, Box, Button, CircularProgress, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import Form from "./form";
import { AppState } from "../../../state/AppState";
import { bindActionCreators, Dispatch } from 'redux';
import { deleteAddress, updateAddress } from "../../../store/actions/Contact.Actions";
import { connect } from "react-redux";
import Map from "../../../components/map/Map";
import fetchCoordinates from "../../../services/fetchCoordinates";
import { replaceComma } from "../../../services/HelperFunctions";

const Address = ({address, update, onSuccess, deleteAddress}: {address: any; update: any; onSuccess: any; deleteAddress: any}) => {
    const [coordinates, setCoordinates] = useState<any>(null);
    const [isUpdatingAddress, setIsUpdatingAddress] = useState<boolean>(false)
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<any>({
        name: "",
        address1: "",
        city: "",
        stateProvince: "",
        postalCode: "",
        isDefault: false
    })

    useEffect(()=> {
        fetchCoordinates(address?.address1).then((res)=> setCoordinates(res));
        setFormValues({
            name: address?.name,
            address1: address?.address1,
            city: address?.city,
            stateProvince: address?.stateProvince,
            postalCode: address?.postalCode,
            isDefault: address?.isDefault
        })
    }, [address])

    const onCancel = ()=> {
        setIsEditing(false)
        setFormValues({
            name: address?.name,
            address1: address?.address1,
            city: address?.city,
            stateProvince: address?.stateProvince,
            postalCode: address?.postalCode,
            isDefault: false
        })
    }

    const onSave = ()=> {
        setIsUpdatingAddress(true);
        update({...formValues, country: "United States"}, address?.id).then(()=> {
            setIsUpdatingAddress(false);
            setIsEditing(false);
            onSuccess()
        }).catch((err: any)=> {
            setIsUpdatingAddress(true);
        })     
    }

    const onDelete = ()=> {
        setIsUpdatingAddress(true);
        deleteAddress(address?.id).then(()=> {
            setIsUpdatingAddress(false);
            setIsEditing(false);
            onSuccess()
        }).catch((err: any)=> {
            setIsUpdatingAddress(true);
        })     
    }

    return <Box>
        {isEditing ? 
        <Box sx={{border:"1px solid lightgray", padding: 2, borderRadius: "4px"}}>
        <Form 
            open={isEditing} 
            isNew={false} 
            onCancel={onCancel}
            formValues={formValues}
            setAddForm={setFormValues}
            handleChange={(value: any, key: any)=> setFormValues({...formValues, [key]: value})}
            onSave={onSave}        
            onDelete={onDelete}
        /> 
        </Box>
        : <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Box sx={{width: '250px'}}>
                <Typography sx={{fontSize: "16px !important"}}>{address?.name}</Typography>
                <Typography sx={{fontSize: "16px !important"}}>{replaceComma(address?.address1)}</Typography>
                <Typography sx={{fontSize: "16px !important"}}>{address?.address2}</Typography>
                <Typography sx={{fontSize: "16px !important"}}>{address?.city} {address?.stateProvince} {address?.postalCode}</Typography>
                {address?.isDefault && <Typography sx={{fontSize: "16px !important", fontWeight: 'bold'}}>Default</Typography>}
            </Box>
            <div style={{
                width: '100%',
                height: '80px',
                border: '1px solid #9f9d9a',
                marginLeft: '20px',
                marginRight: '20px',
            }} >
                {coordinates ? <Map lat={coordinates?.lat} lng={coordinates?.lng} /> : <Box sx={{width: "100%", height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress color="secondary" size={'20px'} /></Box>}
            </div>

            <Box>
                <Button sx={{
                        width: 75,
                        padding: 0,
                        paddingTop: 0.3,
                        border: (theme) => `solid 1px ${theme.palette.secondary.main}`,
                        color: (theme) => theme.palette.secondary.main,
                        fontSize: '16px !important',
                        borderRadius: '5px',
                        height: '30px'
                    }}
                    onClick={()=> setIsEditing(true)}
                    >Edit</Button>
            </Box>
        </Stack>}
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingAddress}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
    </Box>
}

const mapStateToProps = (state: AppState) => ({

})

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({
       update: updateAddress,
       deleteAddress: deleteAddress
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Address);
import { ActionType, IAppAction } from '../actions/Helpers';
import IItemsState from '../../models/IITems';

const initialState: IItemsState = {
    menus: null,
    menuItems: null,
    menuCategories: null,
    selectedMenuId: null,
};

const itemsReducer = (state = { ...initialState }, action: IAppAction) => {
    switch (action.type) {
        case ActionType.SET_MENUS:
            return { ...state, menus: action.payload?.sort((a: any, b: any) => a.sortOrder - b.sortOrder)};
        case ActionType.SET_MENU_CATEGORIES:
            return { ...state, menuCategories: action.payload};
        case ActionType.GET_MENU_ITEMS:
            return { ...state, menuItems: action.payload};
        case ActionType.SET_SELECTED_MENU_ID:
            return { ...state, selectedMenuId: action.payload};
        default:
            return state;
    }
};

export default itemsReducer;
import { ActionType, IAppAction } from '../actions/Helpers';
import ISettingArea from '../../models/ISettingArea';
import ISetting from '../../models/ISetting';
import { Settings } from '../../data/settings';

const initialSettings: { [key: string]: ISetting } = {};

const settingAreasReducer = (state = initialSettings, action: IAppAction) => {
    switch (action.type) {
        case ActionType.SET_SETTINGS:
            const settingAreas = action.payload as ISettingArea[];
            const settingsKeyValue: any = {};
            settingAreas
                .forEach(area => area?.settings
                    ?.forEach((setting: ISetting) => settingsKeyValue[setting.key ? setting.key : ''] = setting));

            return settingsKeyValue;

        default:

            const SettingAreas = [...Settings];
            const SettingsKeyValue: any = {};
            SettingAreas
                .forEach(area => area.settings
                    .forEach((settingValue: any) => SettingsKeyValue[settingValue.key] = settingValue));


            return {
                ...state,
                SettingsKeyValue
            };
    }

};

export default settingAreasReducer;

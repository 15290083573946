import { contactAPIs } from '../../services/ApiVariables';
import { IAppAction } from './Helpers';
import { AppState } from '../../state/AppState';
import IContact from '../../models/IContact';
import { Dispatch } from 'redux';

export const post = (contact: IContact) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return api(contactAPIs.contactPost(contact))
        .then((response: IContact) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
}

export const getSavedAddresses = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return api(contactAPIs.getAllSavedAddresses())
        .then((response: any) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
}

export const createNewAddress = (body: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return api(contactAPIs.createNewAddress(body))
        .then((response: any) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
}


export const restaurantContact = (body: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return api(contactAPIs.restaurantContact(body))
        .then((response: any) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
}

export const updateAddress = (body: any, id: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return api(contactAPIs.udpateAddress(body, id))
        .then((response: any) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
}

export const deleteAddress = (id: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return api(contactAPIs.deleteAddress(id))
        .then((response: any) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
}

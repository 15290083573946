import { ActionType, IAppAction } from '../actions/Helpers';

const initialState = {
    loading: Boolean(false),
    addCartPopWindowOpen: Boolean(false),
}
const commonReducer = (state = initialState, action: IAppAction) => {

    switch (action.type) {
        case ActionType.UPDATE_LOADER:
            return { ...state, loading: action.payload };
        case ActionType.ADD_CART_POP_WINDOW_OPEN:
            return { ...state, addCartPopWindowOpen: true };
        case ActionType.ADD_CART_POP_WINDOW_CLOSE:
            return { ...state, addCartPopWindowOpen: false };
        default:
            return state
    };
};

export default commonReducer;
import { Action } from "redux";

export enum ActionType {
    OPEN_DRAWER = 'OPEN_DRAWER',
    CLOSE_DRAWER = 'CLOSE_DRAWER',
    OPEN_ALERT = 'OPEN_ALERT',
    CLOSE_ALERT = 'CLOSE_ALERT',
    OPEN_SPINNER = 'OPEN_SPINNER',
    CLOSE_SPINNER = 'CLOSE_SPINNER',
    ORDER_SET = 'ORDER_SET',
    ORDER_SET_DEFAULT = 'ORDER_SET_DEFAULT',
    ORDER_CLEAR_UNAVAILABLE_ITEMS = 'ORDER_CLEAR_UNAVAILABLE_ITEMS',
    SEARCH_PRODUCT_LIST = 'SEARCH_PRODUCT_LIST',
    UPDATE_LOADER = 'UPDATE_LOADER',
    GET_MENU_LIST = 'GET_MENU_LIST',
    SET_SELECTED_MENU = 'SET_SELECTED_MENU',
    UPDATE_SELECTED_CATEGORY_MENU_ITEMS = 'UPDATE_SELECTED_CATEGORY_MENU_ITEMS',
    SELECTED_CATEGORY_MENU_PRODUCT = 'SELECTED_CATEGORY_MENU_PRODUCT',
    ADD_CART_POP_WINDOW_OPEN = 'ADD_CART_POP_WINDOW_OPEN',
    ADD_CART_POP_WINDOW_CLOSE = 'ADD_CART_POP_WINDOW_CLOSE',
    GUEST_LOGIN_SUCCESS = 'GUEST_LOGIN_SUCCESS',
    OPEN_SIGN_IN_MODEL = 'OPEN_SIGN_IN_MODEL',
    CLOSE_SIGN_IN_MODEL = '',
    OPEN_CONFIRM_BOX = 'OPEN_CONFIRM_BOX',
    CLOSE_CONFIRM_BOX = 'CLOSE_CONFIRM_BOX',
    OPEN_SIGN_UP_MODEL = 'OPEN_SIGN_UP_MODEL',
    CLOSE_SIGN_UP_MODEL = 'CLOSE_SIGN_UP_MODEL',
    CLOSE_PRODUCT_DETAIL_PAGE_MODEL = 'CLOSE_PRODUCT_DETAIL_PAGE_MODEL',
    OPEN_PRODUCT_DETAIL_PAGE_MODEL = 'OPEN_PRODUCT_DETAIL_PAGE_MODEL',
    SET_SEARCH_VALUE = 'SET_SEARCH_VALUE',
    SET_SETTINGS = 'SET_SETTINGS',
    SET_PICK_LISTS = 'SET_PICK_LISTS',
    SET_ROOMS = 'SET_ROOMS',
    SET_CUSTOMER = 'SET_CUSTOMER',
    SET_ADDRESSES = 'SET_ADDRESSES',
    DELETE_ADDRESS = 'DELETE_ADDRESS',
    UPDATED_ADDRESS = 'UPDATED_ADDRESS',
    CREATED_ADDRESS = 'CREATED_ADDRESS',
    CLEAR_UNAVAILABLE_ITEMS = 'CLEAR_UNAVAILABLE_ITEMS',
    SET_ORGANIZATION = 'SET_ORGANIZATION',
    SET_MENU_CATEGORIES = 'SET_MENU_CATEGORIES',
    GET_MENU_ITEMS = 'GET_MENU_ITEMS',
    ERROR_GETTING_ORGANIZATION = 'ERROR_GETTING_ORGANIZATION',
    SET_MENUS = 'SET_MENUS',
    SET_SELECTED_MENU_ID = 'SET_SELECTED_MENU_ID',
    CLEAR_CART = "CLEAR_CART",
    SET_ORGANIZATION_SETTINGS = "SET_ORGANIZATION_SETTINGS",
    SET_ORGANIZATION_ORDERS_OPEN_SCHEDULE = "SET_ORGANIZATION_ORDERS_OPEN_SCHEDULE",
    GET_AUTH0_USER_DETAILS_SUCCESS = "GET_AUTH0_USER_DETAILS_SUCCESS",
    GET_AUTH0_USER_DETAILS_FAILURE = "GET_AUTH0_USER_DETAILS_FAILURE",
    GET_APP_USER_DETAILS_SUCCESS = "GET_APP_USER_DETAILS_SUCCESS",
    GET_APP_USER_DETAILS_FAILURE = "GET_APP_USER_DETAILS_FAILURE",
    SET_USER_DETAILS = "SET_USER_DETAILS",
}

export interface IAppAction extends Action<ActionType> {
    payload?: any;
}

export default interface IGloabl {
    Window: Window;
}
import { combineReducers } from "redux";
import SettingAreasReducer from "./SettingAreasReducer";
import menuReducer from "./CategoryMenuReducer";
import { UtilityReducer } from "./UtilityReducer";
import CommonReducer from "./CommonReducer";
import OrderReducer from "./OrderReducer";
import OrganizationReducer from "./OrganizationReducer";
import ItemsReducer from "./ItemsReducer";
import userReducer from "./UserReducer";
// import { reducer as oidc } from 'redux-oidc';

export const reducers = combineReducers({
    // oidc,
    utility: UtilityReducer,
    // materials: materialsReducers,
    // mail: mailReducers,
    // product: ProductSearchReducer,
    common: CommonReducer,
    // customer: CustomerReducer,
    menu: menuReducer,
    order: OrderReducer,
    settings: SettingAreasReducer,
    organizations: OrganizationReducer,
    items: ItemsReducer,
    // customerAddresses: CustomerAddress,
    // room: RoomReducer,
    // pickList: PickListReducer,
    user: userReducer
});
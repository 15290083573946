import { keys } from "../utilities/LocalStorage";
import { axiosInstance } from "./Utilities";
export let api = async ({ method = "get", body = '', token = '', apiEndPoint = '', isHPay = false , processorType = 0}) => {
  return await new Promise((resolve, reject) => {
    const APIInstance: any = axiosInstance;
    const HeaderKey = ['Processor'];
    let BaseURL = isHPay? process.env.REACT_APP_PAYMENT_URL : process.env.REACT_APP_GRAZE_API_URL;
    if(isHPay) {
      APIInstance.defaults.headers.common[HeaderKey[0]] = processorType;
    }
    const authAccessToken = localStorage.getItem(keys.authAccessToken)
    if (authAccessToken) {
      APIInstance.defaults.headers.common['Authorization'] = `Bearer ${authAccessToken}`;
    }

    APIInstance[method](`${BaseURL}${apiEndPoint}`, (body ? body : "")).then((response: any) => {
      if (token === 'string') {
        resolve(response.data);
      } else {
        resolve(statusHelper(response))
      }
    }).catch((error: any) => {
      try {
        if (error.response) {
          reject(statusHelper(error.response))
        } else {
          reject(error)
        }
      }
      catch (err) {
        console.error(err)
        reject(err)
      }
    })
  })
}


const statusHelper = (response: any) => {
  if (response.status === 200) {
    return {
      status: response.status,
      ...response.data
    }
  } else {
    return response.data
  }
}




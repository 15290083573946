import { Alert, Backdrop, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { Theme, createStyles } from "@mui/material/styles";
import classNames from "classnames";
import { AppState } from "../../state/AppState";
import { WithStyles, withStyles } from "@mui/styles";
import { withRouter } from '../../utilities/withRouter';
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { keys } from "../../utilities/LocalStorage";
// import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import OrderHistory from "./order-history";
import ManageAddress from "./manage-address";
import Contact from "./contact";
import { bindActionCreators, Dispatch } from 'redux';
import { getOrderHistoryItems } from "../../store/actions/Items.Actions";
import { getSavedAddresses } from "../../store/actions/Contact.Actions";
import { getUserDetails, updateUserDetails } from "../../store/actions/Organization.Actions";
import Auth0Lock from "auth0-lock";
import { normalizePhoneInput } from "../../services/HelperFunctions";

const styles = (theme: Theme) => createStyles({
    profileContent: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: theme.typography.fontFamily,
        wordWrap: 'break-word',
        padding :"20px"
    },
    userInfo: {
        // display: 'flex',
        justifyContent: 'space-between',
        margin: '30px auto',
        padding: '20px 50px 30px',
        maxWidth: 1024,
        width: '100%',
        '-webkit-box-shadow': '14px 16px 50px 14px rgba(243 243 246)',
        boxShadow: '14px 16px 50px 14px rgba(243 243 246)',
        borderRadius: 7,
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'inherit',
        },
        [theme.breakpoints.down('sm')]: {
            flexFlow: 'column',
            margin: '15px auto 30px',
            '& div': {
                margin: '5px 0',
            },
        },
    },
    saveButton: {
        width: 75,
        padding: 0,
        paddingTop: 3,
        border: `solid 1px ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        fontSize: 14,
        borderRadius: 5,
    },
    profileRow: {
        width: 130,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    phoneRow: {
        // width: 90,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    emailRow: {
        width: 200,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    saveRow: {
        marginTop: 16,
        float: 'right',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    profileHeader: {
        marginTop: -7,
    },
    profileHeaderEdit: {
        marginTop: -7,
        marginBottom: 5,
    },
    columnHeader: {
        marginBottom: 5,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    profileTiles: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    tileContainer: {
        margin: '0 20px',
        borderRadius: 7,
    },
    tileButton: {
        padding: 5,
    },
    tile: {
        width: 150,
        height: 150,
        color: '#98989b',
        fontSize: 14,
        backgroundColor: '#fff',
        textAlign: 'center',
        '-webkit-box-shadow': '14px 16px 50px 14px rgba(243 243 246)',
        boxShadow: '14px 16px 50px 14px rgba(243 243 246)',
        borderRadius: 7,
        border: `solid 1px ${theme.palette.primary.main}`
    },
    tileActive: {
        border: `solid 1px ${theme.palette.secondary.main}`
    },
    editButton: {
        position: "absolute",
        top: -5,
        right: 0,
        cursor: "pointer"
    },
    editIcon: {
        fontSize: 17,
        cursor: 'pointer',
    },
    textField: {
        marginBottom: 5,
    },
    input: {
        fontSize: 14,
    },
    buttonImage: {
        width: '100px',
        height: '100px'
    },
    content:{
        padding: "10px 30px"
    }
});

type Props = WithStyles<typeof styles> & {
    orderHistoryGet: any;
    addressesGet: any;
    userDetails: any;
    upsertUserDetails: any;
    fetchUserDetails: any
}

const Profile = ({classes, orderHistoryGet, addressesGet, userDetails, upsertUserDetails, fetchUserDetails}: Props) => {
     
      const options:any= {
        additionalSignUpFields: [{
            name: "given_name",
            placeholder: "Enter your First Name",
            storage: "root",
            validator: function(first_name:string) {
                return {
                    valid: first_name.length >= 0,
                    hint: "Must have 1 or more characters"
                };
            }
        },{
            name: "family_name",
            placeholder: "Enter your Last Name",
            storage: "root",
            validator: function(family_name:string) {
                return {
                    valid: family_name.length >= 0,
                    hint: "Must have 1 or more characters"
                };
            }
        }]
    };
     // eslint-disable-next-line react-hooks/exhaustive-deps
    const Lock = new Auth0Lock(process.env.REACT_APP_AUTH0_CLIENT_ID || '' , process.env.REACT_APP_AUTH0_DOMAIN || '',options);
    const [isUpdating, setIsUpdating] = useState(false)
    const [profileForm, setProfileForm] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailAddress: ""
    });
    const [isEditingProfile, setIsEditingProfile] = useState(false);
    // const { logout } = useAuth0();
    const [linkBroken, setLinkBroken] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    const [orderHistoryItems, setOrderHistoryItems] = useState(null)
    const [savedAddresses, setSavedAddresses] = useState(null)

    useEffect(()=>{ 
        getHistoryItems();
        getSavedAddresses()
    }, [])

    const getHistoryItems = () => {
        orderHistoryGet().then((res: any)=>{
            setOrderHistoryItems(res?.data)
        })
    }

    const getSavedAddresses = () => {
        setSavedAddresses(null);
        addressesGet().then((res: any)=>{
            setSavedAddresses(res?.data)
        })
    }

    useEffect(()=>{
        if(tab){
            if(tab==="order-history" || tab==="manage-address" || tab==="contact"){
                setLinkBroken(false)
            }
            else{
                setLinkBroken(false)
            }
        }
    }, [tab, location])

    const renderedContent = useMemo(() => {
        return <>
            {tab === "order-history" && <OrderHistory items={orderHistoryItems} />}
            {tab === "manage-address" && <ManageAddress addresses={savedAddresses} onSuccess={()=>{
                getSavedAddresses()
            }} />}
            {tab === "contact" && <Contact />}
        </>;
    }, [tab, orderHistoryItems, savedAddresses]);

    const handleUpdateProfile = () => {
        setIsUpdating(true)
        upsertUserDetails({...profileForm, userName: userDetails?.userName}).then((res: any)=> {
            fetchUserDetails().then(()=> {
                setIsUpdating(false)
                setIsEditingProfile(false);
                setProfileForm({
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    emailAddress: ""
                });
            })
        })
    }

    return <div className={classes.profileContent}>
    {linkBroken ? <Stack sx={{p:4}}>
        <Alert severity="error" sx={{alignItems: 'center'}}> 
                ERROR: The Link is Broken.
            </Alert>
    </Stack> : <>
    <div className={classes.userInfo}>
        <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography sx={{ fontSize: { xs: '20px' } }} className={classes.profileRow}>
            <div className={classes.columnHeader}>
                Profile
                {!isEditingProfile && <div className={classes.editButton} onClick={()=> {
                    setProfileForm({
                        firstName: userDetails?.firstName || "",
                        lastName: userDetails?.lastName || "",
                        phoneNumber: userDetails?.phoneNumber || "",
                        emailAddress: userDetails?.emailAddress || ""
                    })
                    setIsEditingProfile(true)
                    }}>
                    <EditIcon sx={{fontSize: 17, color: "#000"}} />
                </div>}
            </div>
            <div>
                {isEditingProfile ? <>
                <Stack spacing={1}>
                    <TextField
                        label="First Name"
                        variant="standard"
                        fullWidth
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: 14
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: 14
                            },
                        }}
                        value={profileForm.firstName}
                        onChange={(e)=> setProfileForm({...profileForm, firstName: e.target.value})}
                    />
                     <TextField
                        label="Last Name"
                        variant="standard"
                        fullWidth
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: 14
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: 14
                            },
                        }}
                        value={profileForm.lastName}
                        onChange={(e)=> setProfileForm({...profileForm, lastName: e.target.value})}
                    />
                </Stack>
                </> : <span>{userDetails?.firstName} {userDetails?.lastName}</span>}
            </div>
        </Typography>
        <Typography sx={{ fontSize: { xs: '20px' } }} className={classes.phoneRow}>
            <div className={classes.columnHeader}>Phone</div>
                <div>
                    {isEditingProfile ? <>
                <Stack spacing={1}>
                    <TextField
                        label="Phone"
                        name="phoneNumber"
                        autoComplete="tel"
                        id="phoneNumber"
                        value={profileForm.phoneNumber}
                        variant="standard"
                        placeholder="(xxx) xxx-xxxx"
                        onChange={(e)=> setProfileForm({...profileForm, phoneNumber: normalizePhoneInput(e.target.value, profileForm.phoneNumber ? profileForm.phoneNumber : '')})}
                        fullWidth={true}
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: 14,
                                textAlign: 'left'
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: 14
                            },
                        }}
                    /> 
                </Stack>
                    </> : <span>{userDetails?.phoneNumber}</span>}
                </div>
        </Typography>
        <Typography sx={{ fontSize: { xs: '20px' } }} className={classes.emailRow}>
            <div className={classes.columnHeader}>Email</div>
                <div>
                    {isEditingProfile ? <>
                    <Stack spacing={1}>
                    <TextField
                        label="Email"
                        variant="standard"
                        fullWidth
                        type="email"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: 14,
                                textAlign: 'left'
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: 14
                            },
                        }}
                        value={profileForm.emailAddress}
                        onChange={(e)=> setProfileForm({...profileForm, emailAddress: e.target.value})}
                    />
                </Stack>
                    </> : <span>{userDetails?.emailAddress}</span>}
                </div>
        </Typography>
        </Stack>
        {isEditingProfile && <Stack direction={'row-reverse'} alignItems={'center'} justifyContent={'space-between'} sx={{mt: 4}}>
                    <Button 
                    sx={{
                        width: 75,
                        padding: 0,
                        paddingTop: 0.3,
                        border: (theme) => `solid 1px ${theme.palette.secondary.main}`,
                        color: (theme) => theme.palette.secondary.main,
                        fontSize: '16px !important',
                        borderRadius: '5px',
                        height: '30px'
                    }}
                    onClick={handleUpdateProfile}
                    disabled={profileForm.firstName==="" || profileForm.lastName==="" || profileForm.phoneNumber==="" || profileForm.emailAddress===""}
                    >Save</Button>
                    
                    <Button sx={{
                        width: 75,
                        padding: 0,
                        paddingTop: 0.3,
                        border: (theme) => `solid 1px ${theme.palette.secondary.main}`,
                        color: (theme) => theme.palette.secondary.main,
                        fontSize: '16px !important',
                        borderRadius: '5px',
                        height: '30px'
                    }}
                    onClick={()=> {
                        setIsEditingProfile(false);
                        setProfileForm({
                            firstName: "",
                            lastName: "",
                            phoneNumber: "",
                            emailAddress: ""
                        })
                    }}
                    >Cancel</Button>                 
        </Stack>}
    </div>
    <div className={classes.profileTiles}>
        <div className={classes.tileContainer}>
            <Button onClick={()=> navigate("/profile?tab=order-history")} className={classes.tileButton}>
                <div className={classNames(classes.tile, { [classes.tileActive]: tab==="order-history" })}>
                    <img src='/static/images/logos/OrderHistory.png' alt='OrderHistory' />
                    <div>Order History</div>
                </div>
            </Button>
        </div>
        <div className={classes.tileContainer}>
            <Button onClick={()=> navigate("/profile?tab=manage-address")} className={classes.tileButton}>
                <div className={classNames(classes.tile, { [classes.tileActive]: tab==="manage-address" })}>
                    <img className={classes.buttonImage} src='/static/images/logos/house.png' alt='house' />
                    <div>Manage Address</div>
                </div>
            </Button>
        </div>
        <div className={classes.tileContainer}>
            <Button onClick={()=> navigate("/profile?tab=contact")} className={classes.tileButton}>
                <div className={classNames(classes.tile, { [classes.tileActive]: tab==="contact" })}>
                    <img src='/static/images/logos/Contact.png' alt='Contact' />
                    <div>Contact</div>
                </div>
            </Button>
        </div>
        <div className={classes.tileContainer}>
            <Button onClick={()=> {
                  localStorage.removeItem(keys.authAccessToken)
                  Lock.logout({ returnTo: window.location.origin })
            }}>
                <div className={classes.tile}>
                    <img src='/static/images/logos/Logout.png' alt='Logout' />
                    <div>Log out</div>
                </div>
            </Button>
        </div>
    </div>
    <div className={classes.content}>
        {renderedContent}
    </div>
    </>}
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUpdating}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
</div>
}

const mapStateToProps = (state: AppState) => ({
    userDetails: state?.organizations?.userDetails
})

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({
       orderHistoryGet: getOrderHistoryItems,
       addressesGet: getSavedAddresses,
       upsertUserDetails: updateUserDetails,
       fetchUserDetails: getUserDetails
}, dispatch);

const ProfileView = withRouter(Profile);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileView as any));
import { ActionType, IAppAction } from './Helpers';
import { Dispatch } from 'redux';
import { AppState } from '../../state/AppState';
import { organizationsAPIs } from '../../services/ApiVariables';
import * as LocalStorage from '../../utilities/LocalStorage';
import { getDomainName } from '../../domain-name';

export const getOrganization = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return fetchOrganizations(dispatch, api)
        .then((response: any) => {
            return response;
        })
        .catch((response: any) => {
            return response;
        });
}

const fetchOrganizations = (dispatch: Dispatch<IAppAction>, api: any) => {
    return api(organizationsAPIs.getOrganizations())
        .then((response: any) => {
            const urlParams = new URLSearchParams(window.location.search);
            const domainURL = urlParams.get('domainURL');
            const domainName = getDomainName();
            const organizations = [...response?.data]
            const organization = organizations?.find(obj => obj.domainName === domainName)
            if(organization){
                dispatch({ type: ActionType.SET_ORGANIZATION, payload: organization });
            }
            else{
                dispatch({ type: ActionType.ERROR_GETTING_ORGANIZATION });
            }
            return response;
        }).catch((response: any) => {
            return response;
        });
}

export const getOrganizationSettings = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    const id = getState().organizations?.organizationID || -1;
    return fetchOrganizationsSettings(id, dispatch, api)
        .then((response: any) => {
            return response;
        })
        .catch((response: any) => {
            return response;
        });
}

const fetchOrganizationsSettings = (organizationID: number | string, dispatch: Dispatch<IAppAction>, api: any) => {
    return api(organizationsAPIs.getOrganizationsSettings(organizationID))
        .then((response: any) => {
            if(response?.data){
                LocalStorage.save(LocalStorage.keys.orderType, response?.data?.defaultOrderType);
                dispatch({ type: ActionType.SET_ORGANIZATION_SETTINGS, payload: response?.data });
            }
            return response;
        }).catch((response: any) => {
            return response;
        });
}

export const getOrganizationOrdersOpenSchedule = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    const id = getState().organizations?.organizationID || -1;
    return fetchOrganizationOrdersOpenSchedule(id, dispatch, api)
        .then((response: any) => {
            return response;
        })
        .catch((response: any) => {
            return response;
        });
}

const fetchOrganizationOrdersOpenSchedule = (organizationID: number | string, dispatch: Dispatch<IAppAction>, api: any) => {
    return api(organizationsAPIs.getOrganizationOrdersOpenSchedule(organizationID))
        .then((response: any) => {
            if(response?.data){
                dispatch({ type: ActionType.SET_ORGANIZATION_ORDERS_OPEN_SCHEDULE, payload: response?.data });
            }
            return response;
        }).catch((response: any) => {
            return response;
        });
}

export const getUserDetails = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return fetchUserDetails(dispatch, api)
        .then((response: any) => {
            return response;
        })
        .catch((response: any) => {
            return response;
        });
  }
  
  const fetchUserDetails = (dispatch: Dispatch<IAppAction>, api: any) => {
    return api(organizationsAPIs.getUserDetails())
        .then((response: any) => {
            if(response?.data){
                dispatch({ type: ActionType.SET_USER_DETAILS, payload: response?.data });
            }
            return response;
        }).catch((response: any) => {
            return response;
        });
  }

  export const updateUserDetails = (body: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    const id = getState().organizations?.organizationID || -1;
    return api(organizationsAPIs.udpateUserDetails({...body, organizationId: id}))
        .then((response: any) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
}

export const getEventPicklistsOptions = (pickListName: "Theme" | "Category" | "Operation") => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    const id = getState().organizations?.organizationID || -1;
    return api(organizationsAPIs.getEventPicklistOptions({pickListName, organizationId: id}))
        .then((response: any) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
  }
export const MenuList: any = [
    {
        "id": 1,
        "name": "Online Menu test long long long",
        "menuCategories": [
            {
                "id": 1,
                "name": "Hors D'Oeuvres",
                "color": null,
                "description": "false",
                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e/menuCategory/1/527b71f6-13b5-4bc9-a98d-8746ca34789c.jpg",
                "menuId": 1,
                "parentMenuCategoryId": null,
                "sortOrder": 3,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 71,
                        "menuCategoryId": 1,
                        "menuItemId": 10,
                        "name": "Coconut Shrimp",
                        "description": "Succulent shrimp dipped in batter and rolled in an aromatic blend of shredded coconut and Panko breadcrumbs and fried for an irresistible crunch in every bite.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F10%2Fba9d2df4-7fe9-44d9-86fa-659f357a5143.jpg",
                        "calories": 126,
                        "cost": 20.0,
                        "revenueTypeId": null,
                        "price": 40.0,
                        "serviceCharge": null,
                        "sortOrder": 2,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 10,
                            "locationId": null,
                            "status": "0",
                            "name": "Coconut Shrimp",
                            "description": "Succulent shrimp dipped in batter and rolled in an aromatic blend of shredded coconut and Panko breadcrumbs and fried for an irresistible crunch in every bite.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F10%2Fba9d2df4-7fe9-44d9-86fa-659f357a5143.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 126,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 40.0,
                            "cost": 20.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 73,
                        "menuCategoryId": 1,
                        "menuItemId": 9,
                        "name": "Egg Rolls",
                        "description": "Fried nicely",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F9%2F854944bd-cc23-46ff-b4b3-22c0b2ccb4ea.jpg",
                        "calories": 420,
                        "cost": 9.0,
                        "revenueTypeId": 1,
                        "price": 10.0,
                        "serviceCharge": 5.0,
                        "sortOrder": 4,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 9,
                            "locationId": null,
                            "status": "0",
                            "name": "Egg Rolls",
                            "description": "Fried nicely",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F9%2F854944bd-cc23-46ff-b4b3-22c0b2ccb4ea.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 420,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 10.0,
                            "cost": 9.0,
                            "serviceCharge": 5.0,
                            "minimumQuantity": 7.0,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 74,
                        "menuCategoryId": 1,
                        "menuItemId": 8,
                        "name": "BBQ Wings",
                        "description": "The sauce is excellent.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F8%2F7e102b1a-8e7a-47dc-9652-270851393ad4.jpg",
                        "calories": 450,
                        "cost": 7.0,
                        "revenueTypeId": 1,
                        "price": 0.0,
                        "serviceCharge": null,
                        "sortOrder": 5,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 8,
                            "locationId": null,
                            "status": "0",
                            "name": "BBQ Wings",
                            "description": "The sauce is excellent.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F8%2F7e102b1a-8e7a-47dc-9652-270851393ad4.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 450,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 11.0,
                            "cost": 7.0,
                            "serviceCharge": null,
                            "minimumQuantity": 2.0,
                            "defaultQuantity": 3.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 25,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": 1,
                                    "options": [
                                        {
                                            "id": 28,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 29,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 4,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Jack Daniel's BBQ",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 30,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 17,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Garlic Parmesan",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Wing Sauce",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": 1,
                                    "maximumQuantity": 2,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 26,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 32,
                                            "parentMenuItemModifierId": 26,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 34,
                                                    "parentMenuItemModifierId": 32,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Extra",
                                                    "price": 9.0,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Ranch",
                                            "price": 8.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 33,
                                            "parentMenuItemModifierId": 26,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 35,
                                                    "parentMenuItemModifierId": 33,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Extra",
                                                    "price": 9.0,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Blue Cheese",
                                            "price": 8.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Dipping Sauce",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 100,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 303,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": 1,
                                    "options": [
                                        {
                                            "id": 305,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 306,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 4,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Jack Daniel's BBQ",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 307,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 17,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Garlic Parmesan",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 308,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic Copy",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Wing Sauce many",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickMany",
                                    "style": "Dropdown",
                                    "minimumQuantity": 3,
                                    "maximumQuantity": 5,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 75,
                        "menuCategoryId": 1,
                        "menuItemId": 20,
                        "name": "Shrimp Cocktail",
                        "description": "Flavorful, poached shrimp boiled and chilled and served with a side of our homemade horseradish cocktail sauce and plenty of lemon.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F20%2F3fc0c9d7-f3da-4b78-b28e-165a2b4f4238.png",
                        "calories": 295,
                        "cost": 5.0,
                        "revenueTypeId": null,
                        "price": 10.0,
                        "serviceCharge": null,
                        "sortOrder": 6,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 20,
                            "locationId": null,
                            "status": "0",
                            "name": "Shrimp Cocktail",
                            "description": "Flavorful, poached shrimp boiled and chilled and served with a side of our homemade horseradish cocktail sauce and plenty of lemon.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F20%2F3fc0c9d7-f3da-4b78-b28e-165a2b4f4238.png",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 295,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 10.0,
                            "cost": 5.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 121,
                        "menuCategoryId": 1,
                        "menuItemId": 7,
                        "name": "Vegetable Skewer",
                        "description": "A healthy choice.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F7%2F2331fdbc-f76b-4477-a177-bd20e6b7c0b1.jpg",
                        "calories": 100,
                        "cost": 3.0,
                        "revenueTypeId": null,
                        "price": 5.0,
                        "serviceCharge": null,
                        "sortOrder": 7,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 7,
                            "locationId": null,
                            "status": "0",
                            "name": "Vegetable Skewer",
                            "description": "A healthy choice.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F7%2F2331fdbc-f76b-4477-a177-bd20e6b7c0b1.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 100,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 5.0,
                            "cost": 3.0,
                            "serviceCharge": null,
                            "minimumQuantity": 4.0,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 123,
                        "menuCategoryId": 1,
                        "menuItemId": 88,
                        "name": "Jalapeno Poppers",
                        "description": "<p>Jalapeno halves stuffed with mild cheddar cheese, then breaded and fried crisp. Served with Ranch dressing made with Tabasco pepper sauce</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F88%2Fe683dd4a-ca80-42e5-8fea-28e75ed44014.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 8.95,
                        "serviceCharge": null,
                        "sortOrder": 8,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 88,
                            "locationId": null,
                            "status": null,
                            "name": "Jalapeno Poppers",
                            "description": "<p>Jalapeno halves stuffed with mild cheddar cheese, then breaded and fried crisp. Served with Ranch dressing made with Tabasco pepper sauce</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F88%2Fe683dd4a-ca80-42e5-8fea-28e75ed44014.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 8.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 175,
                        "menuCategoryId": 1,
                        "menuItemId": 5,
                        "name": "Lunch Salad",
                        "description": "Crisp and delicious.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F5%2Fe04bbca9-25c0-435e-9b30-5185e990dba9.jpg",
                        "calories": 50,
                        "cost": 6.0,
                        "revenueTypeId": null,
                        "price": 8.0,
                        "serviceCharge": null,
                        "sortOrder": 9,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 5,
                            "locationId": null,
                            "status": "0",
                            "name": "Lunch Salad",
                            "description": "Crisp and delicious.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F5%2Fe04bbca9-25c0-435e-9b30-5185e990dba9.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 50,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 8.0,
                            "cost": 6.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 42,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 5,
                                    "menuItemModifierTemplateId": 19,
                                    "options": [
                                        {
                                            "id": 43,
                                            "parentMenuItemModifierId": 42,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 20,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Italian",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 44,
                                            "parentMenuItemModifierId": 42,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 21,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Ranch",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 45,
                                            "parentMenuItemModifierId": 42,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 22,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Mustard",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 46,
                                            "parentMenuItemModifierId": 42,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 23,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Balsamic Vinaigrette",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 47,
                                            "parentMenuItemModifierId": 42,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 24,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Blue Cheese",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Dressing",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 184,
                        "menuCategoryId": 1,
                        "menuItemId": 1,
                        "name": "Popcorn Shrimp",
                        "description": "<p>Fresh shrimp breaded and deep fried and perfectly sized for dipping in one of our signature dipping sauces. comes with choice of sauces</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F1%2Ffa0edaf7-497a-4529-9e44-541e71205ee3.jpg",
                        "calories": 120,
                        "cost": 4.22,
                        "revenueTypeId": 1,
                        "price": 11.95,
                        "serviceCharge": 10.0,
                        "sortOrder": 10,
                        "preparationTimeInSeconds": 5401,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 1,
                            "locationId": null,
                            "status": "0",
                            "name": "Popcorn Shrimp",
                            "description": "<p>Fresh shrimp breaded and deep fried and perfectly sized for dipping in one of our signature dipping sauces. comes with choice of sauces</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F1%2Ffa0edaf7-497a-4529-9e44-541e71205ee3.jpg",
                            "preparationTimeInSeconds": 5401,
                            "preparationArea": "Kitchen",
                            "storageArea": "Refrigerator",
                            "preparationNotes": null,
                            "calories": 120,
                            "serviceware": "Oval Tray",
                            "responsibleStaff": "Chef Michael, Chef Lisa",
                            "price": 11.95,
                            "cost": 4.22,
                            "serviceCharge": 10.0,
                            "minimumQuantity": 1.0,
                            "defaultQuantity": 1.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": "8 per order",
                            "customerSystemId": "1452",
                            "pluCode": "001",
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 215,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 217,
                                            "parentMenuItemModifierId": 215,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "undefined level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "dfgdfg",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 216,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 218,
                                            "parentMenuItemModifierId": 216,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "1234 level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "1234",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 297,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 298,
                                            "parentMenuItemModifierId": 297,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 299,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 301,
                                            "parentMenuItemModifierId": 299,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf Copy",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 300,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 302,
                                            "parentMenuItemModifierId": 300,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1 asdfasdf",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 312,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 313,
                                            "parentMenuItemModifierId": 312,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1 asdfasdf",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf Copy 2",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "app",
                                        "normalizedName": "APP",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "seafood",
                                        "normalizedName": "SEAFOOD",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "popcorn",
                                        "normalizedName": "POPCORN",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "vegetarian",
                                        "normalizedName": "VEGETARIAN",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [
                                "app",
                                "seafood",
                                "popcorn",
                                "vegetarian"
                            ],
                            "allergens": [],
                            "label": "<p>test</p>",
                            "comment": "test\nfdsf\n\ndfs\nf\n\n\n\n\nfas\n\nf\nads\nf\ndas\nf\ndas\ndfas\nsd",
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 186,
                        "menuCategoryId": 1,
                        "menuItemId": 4,
                        "name": "Panini",
                        "description": "Great for lunch!",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F4%2Fb76b64c4-7cd9-47d8-aae9-1ea7a6e2ebc6.png",
                        "calories": 150,
                        "cost": 4.5,
                        "revenueTypeId": 1,
                        "price": 9.5,
                        "serviceCharge": null,
                        "sortOrder": 11,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 4,
                            "locationId": null,
                            "status": "0",
                            "name": "Panini",
                            "description": "Great for lunch!",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F4%2Fb76b64c4-7cd9-47d8-aae9-1ea7a6e2ebc6.png",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 150,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 9.5,
                            "cost": 4.5,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 314,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 4,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 316,
                                            "parentMenuItemModifierId": 314,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 317,
                                            "parentMenuItemModifierId": 314,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 2",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 318,
                                            "parentMenuItemModifierId": 314,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 3",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 319,
                                            "parentMenuItemModifierId": 314,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 4",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "test 1",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 322,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 4,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 323,
                                            "parentMenuItemModifierId": 322,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 324,
                                            "parentMenuItemModifierId": 322,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 2",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 325,
                                            "parentMenuItemModifierId": 322,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 3",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 326,
                                            "parentMenuItemModifierId": 322,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 4",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "test 1 Copy",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 100,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 234,
                        "menuCategoryId": 1,
                        "menuItemId": 8,
                        "name": "BBQ Wings",
                        "description": "The sauce is excellent.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F8%2F7e102b1a-8e7a-47dc-9652-270851393ad4.jpg",
                        "calories": 450,
                        "cost": 7.0,
                        "revenueTypeId": 1,
                        "price": 0.0,
                        "serviceCharge": null,
                        "sortOrder": 12,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 8,
                            "locationId": null,
                            "status": "0",
                            "name": "BBQ Wings",
                            "description": "The sauce is excellent.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F8%2F7e102b1a-8e7a-47dc-9652-270851393ad4.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 450,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 11.0,
                            "cost": 7.0,
                            "serviceCharge": null,
                            "minimumQuantity": 2.0,
                            "defaultQuantity": 3.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 25,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": 1,
                                    "options": [
                                        {
                                            "id": 28,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 29,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 4,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Jack Daniel's BBQ",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 30,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 17,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Garlic Parmesan",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Wing Sauce",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": 1,
                                    "maximumQuantity": 2,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 26,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 32,
                                            "parentMenuItemModifierId": 26,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 34,
                                                    "parentMenuItemModifierId": 32,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Extra",
                                                    "price": 9.0,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Ranch",
                                            "price": 8.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 33,
                                            "parentMenuItemModifierId": 26,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 35,
                                                    "parentMenuItemModifierId": 33,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Extra",
                                                    "price": 9.0,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Blue Cheese",
                                            "price": 8.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Dipping Sauce",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 100,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 303,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": 1,
                                    "options": [
                                        {
                                            "id": 305,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 306,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 4,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Jack Daniel's BBQ",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 307,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 17,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Garlic Parmesan",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 308,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic Copy",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Wing Sauce many",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickMany",
                                    "style": "Dropdown",
                                    "minimumQuantity": 3,
                                    "maximumQuantity": 5,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 265,
                        "menuCategoryId": 1,
                        "menuItemId": 73,
                        "name": "Firecracker Shrimp",
                        "description": "<p>A firecracker shrimp recipe that takes spicy shrimp to a new level, with just the right combination of sweet, heat and awesome flavor.</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F73%2F6e3e8fa0-587c-4f1c-a8de-027a3accdc90.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 31.95,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 73,
                            "locationId": null,
                            "status": null,
                            "name": "Firecracker Shrimp",
                            "description": "<p>A firecracker shrimp recipe that takes spicy shrimp to a new level, with just the right combination of sweet, heat and awesome flavor.</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F73%2F6e3e8fa0-587c-4f1c-a8de-027a3accdc90.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 31.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            },
            {
                "id": 4,
                "name": "Sides",
                "color": null,
                "description": "true",
                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuCategory%2F4%2F525441c7-7c5c-4191-9417-0bdb3ffcaa6c.jpg",
                "menuId": 1,
                "parentMenuCategoryId": null,
                "sortOrder": 6,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 107,
                        "menuCategoryId": 4,
                        "menuItemId": 77,
                        "name": "Scalloped Potatoes",
                        "description": null,
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F77%2Ff3fea46c-d292-4cd8-a4e1-949d4e6c3c3f.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 77,
                            "locationId": null,
                            "status": null,
                            "name": "Scalloped Potatoes",
                            "description": null,
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F77%2Ff3fea46c-d292-4cd8-a4e1-949d4e6c3c3f.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 108,
                        "menuCategoryId": 4,
                        "menuItemId": 78,
                        "name": "Crispy Brussel Sprouts",
                        "description": "<p>Brussels sprouts will never be the same with this delicious party in your mouth</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F78%2F6955d19b-17f5-4d15-a464-f39f06978cbe.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 1,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 78,
                            "locationId": null,
                            "status": null,
                            "name": "Crispy Brussel Sprouts",
                            "description": "<p>Brussels sprouts will never be the same with this delicious party in your mouth</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F78%2F6955d19b-17f5-4d15-a464-f39f06978cbe.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 109,
                        "menuCategoryId": 4,
                        "menuItemId": 79,
                        "name": "Parmesan Encrusted Carrots",
                        "description": "<p>Crispy parmesan roasted carrots with a hint of garlic for a delicious side dish!</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F79%2F37cda9d8-9d32-4d25-9da5-3fef6956e404.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": 1,
                        "price": 12.95,
                        "serviceCharge": null,
                        "sortOrder": 2,
                        "preparationTimeInSeconds": 0,
                        "noTax": true,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 79,
                            "locationId": null,
                            "status": null,
                            "name": "Parmesan Encrusted Carrots",
                            "description": "<p>Crispy parmesan roasted carrots with a hint of garlic for a delicious side dish!</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F79%2F37cda9d8-9d32-4d25-9da5-3fef6956e404.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 12.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": 3.0,
                            "defaultQuantity": 4.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": true,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 222,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 223,
                                            "parentMenuItemModifierId": 222,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "S",
                                            "price": 5.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 224,
                                            "parentMenuItemModifierId": 222,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "M",
                                            "price": 10.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 225,
                                            "parentMenuItemModifierId": 222,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "L",
                                            "price": 15.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Size",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 275,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 276,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test2",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 285,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test ddd",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 286,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test Copy asdfasdfasdf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 287,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 288,
                                            "parentMenuItemModifierId": 287,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "new level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "new",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 289,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 290,
                                            "parentMenuItemModifierId": 289,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 291,
                                                    "parentMenuItemModifierId": 290,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "tjtjtj level 1 level 2",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "tjtjtj level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": true,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "tjtjtj",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 292,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 293,
                                            "parentMenuItemModifierId": 292,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "rere level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "rere",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 294,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test Copy Copy",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 295,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 296,
                                            "parentMenuItemModifierId": 295,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdfadf level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdfadf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 110,
                        "menuCategoryId": 4,
                        "menuItemId": 75,
                        "name": "Bang Bang Brocolli",
                        "description": "<p>This special dish will entice your taste buds and make you crave to be a vegetarian</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F75%2F3b7c76ca-499a-4ee7-8aeb-10cb85af1162.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 3,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 75,
                            "locationId": null,
                            "status": null,
                            "name": "Bang Bang Brocolli",
                            "description": "<p>This special dish will entice your taste buds and make you crave to be a vegetarian</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F75%2F3b7c76ca-499a-4ee7-8aeb-10cb85af1162.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 111,
                        "menuCategoryId": 4,
                        "menuItemId": 74,
                        "name": "Truffle Mac and Cheese",
                        "description": null,
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F74%2F0204476e-0be4-45e9-848e-c80d3d7eafeb.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 4,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 74,
                            "locationId": null,
                            "status": null,
                            "name": "Truffle Mac and Cheese",
                            "description": null,
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F74%2F0204476e-0be4-45e9-848e-c80d3d7eafeb.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 112,
                        "menuCategoryId": 4,
                        "menuItemId": 80,
                        "name": "Buttery Garlic Mushrooms",
                        "description": null,
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F80%2Fa50dcf47-c6c7-4ac3-aca2-e75eca23822e.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 5,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 80,
                            "locationId": null,
                            "status": null,
                            "name": "Buttery Garlic Mushrooms",
                            "description": "<p>Sautéed Mushrooms swimming in a silky garlic butter sauce…pure mushroom perfection!</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F80%2Fa50dcf47-c6c7-4ac3-aca2-e75eca23822e.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 113,
                        "menuCategoryId": 4,
                        "menuItemId": 76,
                        "name": "Sweet Potato Waffle Fries",
                        "description": "<p>Fries never tasted better!</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F76%2F1ee5f22c-328d-4459-8f8d-3d6f3911d820.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": 1,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 6,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 76,
                            "locationId": null,
                            "status": null,
                            "name": "Sweet Potato Waffle Fries",
                            "description": "<p>Fries never tasted better!</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F76%2F1ee5f22c-328d-4459-8f8d-3d6f3911d820.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 226,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 76,
                                    "menuItemModifierTemplateId": 36,
                                    "options": [
                                        {
                                            "id": 230,
                                            "parentMenuItemModifierId": 226,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 231,
                                            "parentMenuItemModifierId": 226,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 232,
                                            "parentMenuItemModifierId": 226,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 245,
                                                    "parentMenuItemModifierId": 232,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "test level 1 level 2",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                },
                                                {
                                                    "id": 246,
                                                    "parentMenuItemModifierId": 232,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "test level 1 level 2",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "test level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": true,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 233,
                                            "parentMenuItemModifierId": 226,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 247,
                                                    "parentMenuItemModifierId": 233,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "test level 1 level 2",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": true,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                },
                                                {
                                                    "id": 248,
                                                    "parentMenuItemModifierId": 233,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "test level 1 level 2",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": true,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                },
                                                {
                                                    "id": 249,
                                                    "parentMenuItemModifierId": 233,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "test level 1 level 2",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                },
                                                {
                                                    "id": 250,
                                                    "parentMenuItemModifierId": 233,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "test level 1 level 2",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": true,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "test level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": true,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "test",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickMany",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 227,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 76,
                                    "menuItemModifierTemplateId": 19,
                                    "options": [
                                        {
                                            "id": 234,
                                            "parentMenuItemModifierId": 227,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 20,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Italian",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 235,
                                            "parentMenuItemModifierId": 227,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 21,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Ranch",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 236,
                                            "parentMenuItemModifierId": 227,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 22,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Mustard",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 237,
                                            "parentMenuItemModifierId": 227,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 23,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Balsamic Vinaigrette",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 238,
                                            "parentMenuItemModifierId": 227,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 24,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Blue Cheese",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Dressing",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": 1,
                                    "maximumQuantity": 1,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 228,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 76,
                                    "menuItemModifierTemplateId": 13,
                                    "options": [
                                        {
                                            "id": 239,
                                            "parentMenuItemModifierId": 228,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 14,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Rare",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 240,
                                            "parentMenuItemModifierId": 228,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 15,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Medium",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 241,
                                            "parentMenuItemModifierId": 228,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 16,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Well",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Temperature",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 229,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 76,
                                    "menuItemModifierTemplateId": 9,
                                    "options": [
                                        {
                                            "id": 242,
                                            "parentMenuItemModifierId": 229,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 10,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "French Fries",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": true,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 243,
                                            "parentMenuItemModifierId": 229,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 11,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Coleslaw",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 244,
                                            "parentMenuItemModifierId": 229,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 12,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Onion Rings",
                                            "price": 2.95,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Sides",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 165,
                        "menuCategoryId": 4,
                        "menuItemId": 80,
                        "name": "Buttery Garlic Mushrooms",
                        "description": "<p>Sautéed Mushrooms swimming in a silky garlic butter sauce…pure mushroom perfection!</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F80%2Fa50dcf47-c6c7-4ac3-aca2-e75eca23822e.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 8,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 80,
                            "locationId": null,
                            "status": null,
                            "name": "Buttery Garlic Mushrooms",
                            "description": "<p>Sautéed Mushrooms swimming in a silky garlic butter sauce…pure mushroom perfection!</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F80%2Fa50dcf47-c6c7-4ac3-aca2-e75eca23822e.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 166,
                        "menuCategoryId": 4,
                        "menuItemId": 79,
                        "name": "Parmesan Encrusted Carrots",
                        "description": "<p>Crispy parmesan roasted carrots with a hint of garlic for a delicious side dish!</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F79%2F37cda9d8-9d32-4d25-9da5-3fef6956e404.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": 1,
                        "price": 12.95,
                        "serviceCharge": null,
                        "sortOrder": 9,
                        "preparationTimeInSeconds": 0,
                        "noTax": true,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 79,
                            "locationId": null,
                            "status": null,
                            "name": "Parmesan Encrusted Carrots",
                            "description": "<p>Crispy parmesan roasted carrots with a hint of garlic for a delicious side dish!</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F79%2F37cda9d8-9d32-4d25-9da5-3fef6956e404.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 12.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": 3.0,
                            "defaultQuantity": 4.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": true,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 222,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 223,
                                            "parentMenuItemModifierId": 222,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "S",
                                            "price": 5.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 224,
                                            "parentMenuItemModifierId": 222,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "M",
                                            "price": 10.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 225,
                                            "parentMenuItemModifierId": 222,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "L",
                                            "price": 15.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Size",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 275,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 276,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test2",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 285,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test ddd",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 286,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test Copy asdfasdfasdf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 287,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 288,
                                            "parentMenuItemModifierId": 287,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "new level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "new",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 289,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 290,
                                            "parentMenuItemModifierId": 289,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 291,
                                                    "parentMenuItemModifierId": 290,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "tjtjtj level 1 level 2",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "tjtjtj level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": true,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "tjtjtj",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 292,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 293,
                                            "parentMenuItemModifierId": 292,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "rere level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "rere",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 294,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test Copy Copy",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 295,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 296,
                                            "parentMenuItemModifierId": 295,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdfadf level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdfadf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 270,
                        "menuCategoryId": 4,
                        "menuItemId": 147,
                        "name": "Sweet Potato Waffle Fries - Large",
                        "description": "Sweet Potato Waffle Fries",
                        "imageUrl": null,
                        "calories": null,
                        "cost": 0.04,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 7,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 147,
                            "locationId": null,
                            "status": null,
                            "name": "Sweet Potato Waffle Fries - Large",
                            "description": "Sweet Potato Waffle Fries",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": "Kitchen",
                            "storageArea": "Back Freezer",
                            "preparationNotes": "Prep Notes",
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": 0.04,
                            "serviceCharge": null,
                            "minimumQuantity": 2.0,
                            "defaultQuantity": 3.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": "<p>Item Notes</p>",
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            },
            {
                "id": 5,
                "name": "Dinner test long long long long long long",
                "color": null,
                "description": "true",
                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e/menuCategory/5/791f48b4-ca83-49ca-8c60-26cbc939a93c.jpg",
                "menuId": 1,
                "parentMenuCategoryId": null,
                "sortOrder": 5,
                "menuCategories": [
                    {
                        "id": 15,
                        "name": "Steaks",
                        "color": "#50c6ca",
                        "description": "true",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuCategory%2F15%2Fd333be83-f7e7-428a-9701-a44bdae8aed5.jpg",
                        "menuId": null,
                        "parentMenuCategoryId": 5,
                        "sortOrder": 0,
                        "menuCategories": null,
                        "menuCategoryItems": [
                            {
                                "id": 95,
                                "menuCategoryId": 15,
                                "menuItemId": 36,
                                "name": "Prime Rib",
                                "description": "Charbroiled to perfection",
                                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F36%2Fdacea24f-3e73-4769-bbd4-be97d58802ec.jpg",
                                "calories": 495,
                                "cost": 5.71,
                                "revenueTypeId": null,
                                "price": 38.95,
                                "serviceCharge": null,
                                "sortOrder": 1,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 36,
                                    "locationId": null,
                                    "status": "0",
                                    "name": "Prime Rib",
                                    "description": "Charbroiled to perfection",
                                    "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F36%2Fdacea24f-3e73-4769-bbd4-be97d58802ec.jpg",
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": 495,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 38.95,
                                    "cost": 5.71,
                                    "serviceCharge": null,
                                    "minimumQuantity": null,
                                    "defaultQuantity": null,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            },
                            {
                                "id": 96,
                                "menuCategoryId": 15,
                                "menuItemId": 65,
                                "name": "New York Strip",
                                "description": "<p>Charbroiled to perfection, served medium with golden brown onion rings Includes garden salad with house dressing, vegetable du jour, rolls and butter, dessert, coffee, tea or milk</p>",
                                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F65%2F2d85a929-5789-473b-85d5-f53d592d99a6.jpg",
                                "calories": null,
                                "cost": null,
                                "revenueTypeId": null,
                                "price": 28.95,
                                "serviceCharge": null,
                                "sortOrder": 2,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 65,
                                    "locationId": null,
                                    "status": null,
                                    "name": "New York Strip",
                                    "description": "<p>Charbroiled to perfection, served medium with golden brown onion rings Includes garden salad with house dressing, vegetable du jour, rolls and butter, dessert, coffee, tea or milk</p>",
                                    "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F65%2F2d85a929-5789-473b-85d5-f53d592d99a6.jpg",
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": null,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 28.95,
                                    "cost": null,
                                    "serviceCharge": null,
                                    "minimumQuantity": null,
                                    "defaultQuantity": null,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            },
                            {
                                "id": 97,
                                "menuCategoryId": 15,
                                "menuItemId": 66,
                                "name": "Top Sirloin",
                                "description": "<p>Charbroiled to perfection, served medium with golden brown onion rings Includes garden salad with house dressing, vegetable du jour, rolls and butter, dessert, coffee, tea or milk</p>",
                                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F66%2Fb44fb9ea-3663-430a-a1ef-0123a053776b.jpg",
                                "calories": null,
                                "cost": null,
                                "revenueTypeId": null,
                                "price": 24.95,
                                "serviceCharge": null,
                                "sortOrder": 3,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 66,
                                    "locationId": null,
                                    "status": null,
                                    "name": "Top Sirloin",
                                    "description": "<p>Charbroiled to perfection, served medium with golden brown onion rings Includes garden salad with house dressing, vegetable du jour, rolls and butter, dessert, coffee, tea or milk</p>",
                                    "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F66%2Fb44fb9ea-3663-430a-a1ef-0123a053776b.jpg",
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": null,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 24.95,
                                    "cost": null,
                                    "serviceCharge": null,
                                    "minimumQuantity": null,
                                    "defaultQuantity": null,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            },
                            {
                                "id": 98,
                                "menuCategoryId": 15,
                                "menuItemId": 67,
                                "name": "Herb Tenderloin",
                                "description": "<p>8 oz tenderloin perfectly prepared and marinated in a homemade herb butter</p>",
                                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F67%2Fce5c3dd7-f5fc-4b9c-80cf-a78b012418e5.jpg",
                                "calories": null,
                                "cost": null,
                                "revenueTypeId": null,
                                "price": 32.95,
                                "serviceCharge": null,
                                "sortOrder": 4,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 67,
                                    "locationId": null,
                                    "status": null,
                                    "name": "Herb Tenderloin",
                                    "description": "<p>8 oz tenderloin perfectly prepared and marinated in a homemade herb butter.</p>",
                                    "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F67%2Fce5c3dd7-f5fc-4b9c-80cf-a78b012418e5.jpg",
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": null,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 32.95,
                                    "cost": null,
                                    "serviceCharge": null,
                                    "minimumQuantity": null,
                                    "defaultQuantity": null,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [
                                        {
                                            "id": 107,
                                            "parentMenuItemModifierId": null,
                                            "menuItemId": 67,
                                            "menuItemModifierTemplateId": 9,
                                            "options": [
                                                {
                                                    "id": 108,
                                                    "parentMenuItemModifierId": 107,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": 10,
                                                    "options": [],
                                                    "customerSystemId": null,
                                                    "name": "French Fries",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": true,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                },
                                                {
                                                    "id": 109,
                                                    "parentMenuItemModifierId": 107,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": 11,
                                                    "options": [],
                                                    "customerSystemId": null,
                                                    "name": "Coleslaw",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                },
                                                {
                                                    "id": 110,
                                                    "parentMenuItemModifierId": 107,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": 12,
                                                    "options": [],
                                                    "customerSystemId": null,
                                                    "name": "Onion Rings",
                                                    "price": 2.95,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Sides",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Dropdown",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            },
                            {
                                "id": 171,
                                "menuCategoryId": 15,
                                "menuItemId": 37,
                                "name": "Balsamic Filet",
                                "description": "<p>An elegant and quick dinner served with steamed asparagus and baby red potatoes. An elegant and quick dinner served with steamed asparagus and baby red potatoes. An elegant and quick dinner served with steamed asparagus and baby red potatoes. An elegant and quick dinner served with steamed asparagus and baby red potatoes. An elegant and quick dinner served with steamed asparagus and baby red potatoes. An elegant and quick dinner served with steamed asparagus and baby red potatoes. An elegant and quick dinner served with steamed asparagus and baby red potatoes. An elegant and quick dinner served with steamed asparagus and baby red potatoes. An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p><p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p><p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p><p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p><p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p><p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p><p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p><p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p><p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p><p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p><p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p><p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p>",
                                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F37%2F3a485504-b448-4c80-978a-b9c7302c659c.jpg",
                                "calories": 285,
                                "cost": 10.0,
                                "revenueTypeId": 1,
                                "price": 15.5,
                                "serviceCharge": null,
                                "sortOrder": 0,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": false,
                                "menuItem": {
                                    "id": 37,
                                    "locationId": null,
                                    "status": "0",
                                    "name": "Balsamic Filet",
                                    "description": "<p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p>",
                                    "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F37%2F3a485504-b448-4c80-978a-b9c7302c659c.jpg",
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": 285,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 15.5,
                                    "cost": 10.0,
                                    "serviceCharge": null,
                                    "minimumQuantity": null,
                                    "defaultQuantity": null,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [
                                        {
                                            "id": 78,
                                            "parentMenuItemModifierId": null,
                                            "menuItemId": 37,
                                            "menuItemModifierTemplateId": 13,
                                            "options": [
                                                {
                                                    "id": 81,
                                                    "parentMenuItemModifierId": 78,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": 14,
                                                    "options": [],
                                                    "customerSystemId": null,
                                                    "name": "Rare",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                },
                                                {
                                                    "id": 82,
                                                    "parentMenuItemModifierId": 78,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": [],
                                                    "customerSystemId": null,
                                                    "name": "Medium Rare",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 100,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                },
                                                {
                                                    "id": 83,
                                                    "parentMenuItemModifierId": 78,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": 15,
                                                    "options": [],
                                                    "customerSystemId": null,
                                                    "name": "Medium",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 200,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                },
                                                {
                                                    "id": 84,
                                                    "parentMenuItemModifierId": 78,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": [],
                                                    "customerSystemId": null,
                                                    "name": "Medium Well",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 300,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                },
                                                {
                                                    "id": 85,
                                                    "parentMenuItemModifierId": 78,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": 16,
                                                    "options": [],
                                                    "customerSystemId": null,
                                                    "name": "Well",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 400,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Temperature",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            }
                        ],
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategory"
                    },
                    {
                        "id": 16,
                        "name": "Chicken",
                        "color": null,
                        "description": null,
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuCategory%2F16%2F6b36b1e5-6d18-4903-882c-acfbe0a018aa.jpg",
                        "menuId": null,
                        "parentMenuCategoryId": 5,
                        "sortOrder": 1,
                        "menuCategories": null,
                        "menuCategoryItems": [
                            {
                                "id": 99,
                                "menuCategoryId": 16,
                                "menuItemId": 68,
                                "name": "Chicken Florentine",
                                "description": "<p>The light and luscious lemon sauce really pops without being too acidic; it is simply divine. Serve it with herb-roasted potatoes or lemon-rice pilaf</p>",
                                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F68%2F9e65aaa2-3b28-406e-8aff-729625b65f3a.jpg",
                                "calories": null,
                                "cost": null,
                                "revenueTypeId": null,
                                "price": 17.95,
                                "serviceCharge": null,
                                "sortOrder": 0,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 68,
                                    "locationId": null,
                                    "status": null,
                                    "name": "Chicken Florentine",
                                    "description": "<p>The light and luscious lemon sauce really pops without being too acidic; it is simply divine. Serve it with herb-roasted potatoes or lemon-rice pilaf</p>",
                                    "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F68%2F9e65aaa2-3b28-406e-8aff-729625b65f3a.jpg",
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": null,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 17.95,
                                    "cost": null,
                                    "serviceCharge": null,
                                    "minimumQuantity": null,
                                    "defaultQuantity": null,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            },
                            {
                                "id": 100,
                                "menuCategoryId": 16,
                                "menuItemId": 69,
                                "name": "Chicken Marsala",
                                "description": "<p>Herbed chicken in a sweet Marsala and mushroom sauce.&nbsp;</p><p>Includes garden salad with house dressing, vegetable du jour, rolls and butter, dessert, coffee, tea or milk</p>",
                                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F69%2Feb95d6ed-5912-4abc-be83-e7c283a36b06.jpg",
                                "calories": null,
                                "cost": null,
                                "revenueTypeId": null,
                                "price": 17.95,
                                "serviceCharge": null,
                                "sortOrder": 2,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 69,
                                    "locationId": null,
                                    "status": null,
                                    "name": "Chicken Marsala",
                                    "description": "<p>Herbed chicken in a sweet Marsala and mushroom sauce.&nbsp;</p><p>Includes garden salad with house dressing, vegetable du jour, rolls and butter, dessert, coffee, tea or milk</p>",
                                    "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F69%2Feb95d6ed-5912-4abc-be83-e7c283a36b06.jpg",
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": null,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 17.95,
                                    "cost": null,
                                    "serviceCharge": null,
                                    "minimumQuantity": null,
                                    "defaultQuantity": null,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            },
                            {
                                "id": 101,
                                "menuCategoryId": 16,
                                "menuItemId": 70,
                                "name": "Chicken Piccata",
                                "description": "<p>Pan-fried chicken breast medallions get a light, fresh lemon-butter sauce with capers and parsley Includes garden salad with house dressing, vegetable du jour, rolls and butter, dessert, coffee, tea or milk</p>",
                                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F70%2F50adf112-830a-41c8-980b-c409da21e047.jpg",
                                "calories": null,
                                "cost": null,
                                "revenueTypeId": null,
                                "price": 17.95,
                                "serviceCharge": null,
                                "sortOrder": 3,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 70,
                                    "locationId": null,
                                    "status": null,
                                    "name": "Chicken Piccata",
                                    "description": "<p>Pan-fried chicken breast medallions get a light, fresh lemon-butter sauce with capers and parsley Includes garden salad with house dressing, vegetable du jour, rolls and butter, dessert, coffee, tea or milk</p>",
                                    "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F70%2F50adf112-830a-41c8-980b-c409da21e047.jpg",
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": null,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 17.95,
                                    "cost": null,
                                    "serviceCharge": null,
                                    "minimumQuantity": null,
                                    "defaultQuantity": null,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            },
                            {
                                "id": 102,
                                "menuCategoryId": 16,
                                "menuItemId": 35,
                                "name": "Chicken Parmesan",
                                "description": "<p>A classic Italian dish prepared with tomato sauce and mozzarella&nbsp;</p><p>Includes garden salad with house dressing, vegetable du jour, rolls and butter, dessert, coffee, tea or milk</p>",
                                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F35%2Fbcc8e922-ac46-4c38-aa61-6967c7a7b772.jpg",
                                "calories": 300,
                                "cost": 3.59,
                                "revenueTypeId": null,
                                "price": 17.95,
                                "serviceCharge": null,
                                "sortOrder": 1,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 35,
                                    "locationId": null,
                                    "status": "0",
                                    "name": "Chicken Parmesan",
                                    "description": "<p>A classic Italian dish prepared with tomato sauce and mozzarella&nbsp;</p><p>Includes garden salad with house dressing, vegetable du jour, rolls and butter, dessert, coffee, tea or milk</p>",
                                    "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F35%2Fbcc8e922-ac46-4c38-aa61-6967c7a7b772.jpg",
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": 300,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 17.95,
                                    "cost": 3.59,
                                    "serviceCharge": null,
                                    "minimumQuantity": null,
                                    "defaultQuantity": null,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            }
                        ],
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategory"
                    },
                    {
                        "id": 17,
                        "name": "Seafood",
                        "color": null,
                        "description": null,
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuCategory%2F17%2F880e39d8-b620-4f57-a586-182693fbfe7e.jpg",
                        "menuId": null,
                        "parentMenuCategoryId": 5,
                        "sortOrder": 2,
                        "menuCategories": null,
                        "menuCategoryItems": [
                            {
                                "id": 103,
                                "menuCategoryId": 17,
                                "menuItemId": 71,
                                "name": "Brown Butter Scallops",
                                "description": "<p>Perfectly seared and juicy scallops that pair well with pasta</p>",
                                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F71%2Fd712f18c-9782-4445-8027-0a75582b6254.jpg",
                                "calories": null,
                                "cost": null,
                                "revenueTypeId": null,
                                "price": 34.95,
                                "serviceCharge": null,
                                "sortOrder": 0,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 71,
                                    "locationId": null,
                                    "status": null,
                                    "name": "Brown Butter Scallops",
                                    "description": "<p>Perfectly seared and juicy scallops that pair well with pasta</p>",
                                    "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F71%2Fd712f18c-9782-4445-8027-0a75582b6254.jpg",
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": null,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 34.95,
                                    "cost": null,
                                    "serviceCharge": null,
                                    "minimumQuantity": null,
                                    "defaultQuantity": null,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            },
                            {
                                "id": 104,
                                "menuCategoryId": 17,
                                "menuItemId": 72,
                                "name": "Soy-Glazed Salmon",
                                "description": null,
                                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F72%2F1b7cbfe7-0f78-43f7-8415-2a3baf003447.jpg",
                                "calories": null,
                                "cost": null,
                                "revenueTypeId": null,
                                "price": 29.95,
                                "serviceCharge": null,
                                "sortOrder": 1,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 72,
                                    "locationId": null,
                                    "status": null,
                                    "name": "Soy-Glazed Salmon",
                                    "description": null,
                                    "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F72%2F1b7cbfe7-0f78-43f7-8415-2a3baf003447.jpg",
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": null,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 29.95,
                                    "cost": null,
                                    "serviceCharge": null,
                                    "minimumQuantity": null,
                                    "defaultQuantity": null,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            },
                            {
                                "id": 164,
                                "menuCategoryId": 17,
                                "menuItemId": 73,
                                "name": "Firecracker Shrimp",
                                "description": "<p>A firecracker shrimp recipe that takes spicy shrimp to a new level, with just the right combination of sweet, heat and awesome flavor.</p>",
                                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F73%2F6e3e8fa0-587c-4f1c-a8de-027a3accdc90.jpg",
                                "calories": null,
                                "cost": null,
                                "revenueTypeId": null,
                                "price": 31.95,
                                "serviceCharge": null,
                                "sortOrder": 5,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 73,
                                    "locationId": null,
                                    "status": null,
                                    "name": "Firecracker Shrimp",
                                    "description": "<p>A firecracker shrimp recipe that takes spicy shrimp to a new level, with just the right combination of sweet, heat and awesome flavor.</p>",
                                    "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F73%2F6e3e8fa0-587c-4f1c-a8de-027a3accdc90.jpg",
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": null,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 31.95,
                                    "cost": null,
                                    "serviceCharge": null,
                                    "minimumQuantity": null,
                                    "defaultQuantity": null,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            },
                            {
                                "id": 167,
                                "menuCategoryId": 17,
                                "menuItemId": 34,
                                "name": "Seafood Paella",
                                "description": "<p>A saffron-infused paella is loaded with mussels, clams, and shrimp.</p>",
                                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F34%2Fa79c8d5b-f6ae-4eca-aa55-b35b70e951ce.jpg",
                                "calories": 210,
                                "cost": 6.92,
                                "revenueTypeId": null,
                                "price": 31.95,
                                "serviceCharge": null,
                                "sortOrder": 2,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 34,
                                    "locationId": null,
                                    "status": "0",
                                    "name": "Seafood Paella",
                                    "description": "<p>A saffron-infused paella is loaded with mussels, clams, and shrimp.</p>",
                                    "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F34%2Fa79c8d5b-f6ae-4eca-aa55-b35b70e951ce.jpg",
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": 210,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 31.95,
                                    "cost": 6.92,
                                    "serviceCharge": null,
                                    "minimumQuantity": null,
                                    "defaultQuantity": null,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            }
                        ],
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategory"
                    },
                    {
                        "id": 39,
                        "name": "Vegan",
                        "color": null,
                        "description": null,
                        "imageUrl": null,
                        "menuId": null,
                        "parentMenuCategoryId": 5,
                        "sortOrder": 3,
                        "menuCategories": null,
                        "menuCategoryItems": [],
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategory"
                    }
                ],
                "menuCategoryItems": [],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            },
            {
                "id": 7,
                "name": "Lunch",
                "color": null,
                "description": "true",
                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e/menuCategory/7/64abe3df-f478-4d3f-9685-17ed28ef4160.jpg",
                "menuId": 1,
                "parentMenuCategoryId": null,
                "sortOrder": 4,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 78,
                        "menuCategoryId": 7,
                        "menuItemId": 39,
                        "name": "Pulled Pork",
                        "description": "Mouth watering!",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F39%2F79a0723a-0408-4e98-b389-7aadff7cf2be.jpg",
                        "calories": 250,
                        "cost": 3.0,
                        "revenueTypeId": null,
                        "price": 6.5,
                        "serviceCharge": null,
                        "sortOrder": 1,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 39,
                            "locationId": null,
                            "status": "0",
                            "name": "Pulled Pork",
                            "description": "Mouth watering!",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F39%2F79a0723a-0408-4e98-b389-7aadff7cf2be.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 250,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 6.5,
                            "cost": 3.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 80,
                        "menuCategoryId": 7,
                        "menuItemId": 6,
                        "name": "Ham and Bacon",
                        "description": "Everyone loves bacon!",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F6%2Ffadfec26-e49c-4799-975f-946b4af9b05b.jpg",
                        "calories": 225,
                        "cost": 6.0,
                        "revenueTypeId": null,
                        "price": 7.0,
                        "serviceCharge": null,
                        "sortOrder": 2,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 6,
                            "locationId": null,
                            "status": "0",
                            "name": "Ham and Bacon",
                            "description": "Everyone loves bacon!",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F6%2Ffadfec26-e49c-4799-975f-946b4af9b05b.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 225,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 7.0,
                            "cost": 6.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 81,
                        "menuCategoryId": 7,
                        "menuItemId": 14,
                        "name": "All-American Club",
                        "description": "A simple sandwich.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F14%2F5dae9c0e-3f8c-4460-b426-940fbff75557.jpg",
                        "calories": 300,
                        "cost": 4.0,
                        "revenueTypeId": null,
                        "price": 7.95,
                        "serviceCharge": null,
                        "sortOrder": 3,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 14,
                            "locationId": null,
                            "status": "0",
                            "name": "All-American Club",
                            "description": "<p>A simple sandwich.</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F14%2F5dae9c0e-3f8c-4460-b426-940fbff75557.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 300,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 7.95,
                            "cost": 4.0,
                            "serviceCharge": 0.0,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 82,
                        "menuCategoryId": 7,
                        "menuItemId": 15,
                        "name": "Beef Tacos",
                        "description": "Corn Tortilla. Tastes great!",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F15%2F696e5bc4-af62-4108-8a67-83d8703bc8d7.jpg",
                        "calories": 250,
                        "cost": 3.0,
                        "revenueTypeId": null,
                        "price": 7.95,
                        "serviceCharge": null,
                        "sortOrder": 4,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 15,
                            "locationId": null,
                            "status": "0",
                            "name": "Beef Tacos",
                            "description": "Corn Tortilla. Tastes great!",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F15%2F696e5bc4-af62-4108-8a67-83d8703bc8d7.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 250,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 7.95,
                            "cost": 3.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 84,
                        "menuCategoryId": 7,
                        "menuItemId": 4,
                        "name": "Panini",
                        "description": "Great for lunch!",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F4%2Fb76b64c4-7cd9-47d8-aae9-1ea7a6e2ebc6.png",
                        "calories": 150,
                        "cost": 4.5,
                        "revenueTypeId": 1,
                        "price": 9.5,
                        "serviceCharge": null,
                        "sortOrder": 5,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 4,
                            "locationId": null,
                            "status": "0",
                            "name": "Panini",
                            "description": "Great for lunch!",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F4%2Fb76b64c4-7cd9-47d8-aae9-1ea7a6e2ebc6.png",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 150,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 9.5,
                            "cost": 4.5,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 314,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 4,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 316,
                                            "parentMenuItemModifierId": 314,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 317,
                                            "parentMenuItemModifierId": 314,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 2",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 318,
                                            "parentMenuItemModifierId": 314,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 3",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 319,
                                            "parentMenuItemModifierId": 314,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 4",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "test 1",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 322,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 4,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 323,
                                            "parentMenuItemModifierId": 322,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 324,
                                            "parentMenuItemModifierId": 322,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 2",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 325,
                                            "parentMenuItemModifierId": 322,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 3",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 326,
                                            "parentMenuItemModifierId": 322,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 4",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "test 1 Copy",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 100,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 124,
                        "menuCategoryId": 7,
                        "menuItemId": 88,
                        "name": "Jalapeno Poppers",
                        "description": "<p>Jalapeno halves stuffed with mild cheddar cheese, then breaded and fried crisp. Served with Ranch dressing made with Tabasco pepper sauce</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F88%2Fe683dd4a-ca80-42e5-8fea-28e75ed44014.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 8.95,
                        "serviceCharge": null,
                        "sortOrder": 7,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 88,
                            "locationId": null,
                            "status": null,
                            "name": "Jalapeno Poppers",
                            "description": "<p>Jalapeno halves stuffed with mild cheddar cheese, then breaded and fried crisp. Served with Ranch dressing made with Tabasco pepper sauce</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F88%2Fe683dd4a-ca80-42e5-8fea-28e75ed44014.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 8.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 139,
                        "menuCategoryId": 7,
                        "menuItemId": 5,
                        "name": "Lunch Salad",
                        "description": "Crisp and delicious.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F5%2Fe04bbca9-25c0-435e-9b30-5185e990dba9.jpg",
                        "calories": 50,
                        "cost": 6.0,
                        "revenueTypeId": null,
                        "price": 8.0,
                        "serviceCharge": null,
                        "sortOrder": 8,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 5,
                            "locationId": null,
                            "status": "0",
                            "name": "Lunch Salad",
                            "description": "Crisp and delicious.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F5%2Fe04bbca9-25c0-435e-9b30-5185e990dba9.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 50,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 8.0,
                            "cost": 6.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 42,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 5,
                                    "menuItemModifierTemplateId": 19,
                                    "options": [
                                        {
                                            "id": 43,
                                            "parentMenuItemModifierId": 42,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 20,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Italian",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 44,
                                            "parentMenuItemModifierId": 42,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 21,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Ranch",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 45,
                                            "parentMenuItemModifierId": 42,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 22,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Mustard",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 46,
                                            "parentMenuItemModifierId": 42,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 23,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Balsamic Vinaigrette",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 47,
                                            "parentMenuItemModifierId": 42,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 24,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Blue Cheese",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Dressing",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 174,
                        "menuCategoryId": 7,
                        "menuItemId": 29,
                        "name": "Grilled Chicken Sandwich",
                        "description": "<p>Deeply charred mustardy chicken thighs are paired with a crunchy and refreshing fennel-basil slaw for the ultimate chicken sandwich.</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F29%2Ff6c05ad0-e8ff-47e5-a87b-f29d06f9a4c9.jpg",
                        "calories": 150,
                        "cost": 3.5,
                        "revenueTypeId": 1,
                        "price": 8.95,
                        "serviceCharge": 1.0,
                        "sortOrder": 9,
                        "preparationTimeInSeconds": 540,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 29,
                            "locationId": null,
                            "status": "0",
                            "name": "Grilled Chicken Sandwich",
                            "description": "<p>Deeply charred mustardy chicken thighs are paired with a crunchy and refreshing fennel-basil slaw for the ultimate chicken sandwich.</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F29%2Ff6c05ad0-e8ff-47e5-a87b-f29d06f9a4c9.jpg",
                            "preparationTimeInSeconds": 540,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 150,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 8.95,
                            "cost": 3.5,
                            "serviceCharge": 1.0,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 97,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 29,
                                    "menuItemModifierTemplateId": 9,
                                    "options": [
                                        {
                                            "id": 98,
                                            "parentMenuItemModifierId": 97,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 10,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "French Fries",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": true,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 99,
                                            "parentMenuItemModifierId": 97,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 11,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Coleslaw",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 100,
                                            "parentMenuItemModifierId": 97,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 12,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Onion Rings",
                                            "price": 2.95,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Sides",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "chicken",
                                        "normalizedName": "CHICKEN",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [
                                "chicken"
                            ],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            },
            {
                "id": 8,
                "name": "Dessert",
                "color": null,
                "description": "true",
                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuCategory%2F8%2F44434435-e4fc-460b-b2fd-d7355aa92cc3.jpg",
                "menuId": 1,
                "parentMenuCategoryId": null,
                "sortOrder": 9,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 114,
                        "menuCategoryId": 8,
                        "menuItemId": 81,
                        "name": "Cookie Dough Flautas",
                        "description": "<p>Chocolate chip cookie dough loaded into a flour tortilla, lightly fried and topped with powdered sugar</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F81%2F21125411-a16e-44aa-8bc0-0a9a06f1f439.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 5.95,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 81,
                            "locationId": null,
                            "status": null,
                            "name": "Cookie Dough Flautas",
                            "description": "<p>Chocolate chip cookie dough loaded into a flour tortilla, lightly fried and topped with powdered sugar</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F81%2F21125411-a16e-44aa-8bc0-0a9a06f1f439.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 5.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 115,
                        "menuCategoryId": 8,
                        "menuItemId": 82,
                        "name": "Oreo Cheesecake",
                        "description": "<p>A decadent cookies and cream-filled cheesecake with an Oreo cookie crust, chocolate ganache, and homemade whipped cream</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F82%2F80538b53-a5fa-47ea-815f-c65bbe6053b6.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 5.95,
                        "serviceCharge": null,
                        "sortOrder": 2,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 82,
                            "locationId": null,
                            "status": null,
                            "name": "Oreo Cheesecake",
                            "description": "<p>A decadent cookies and cream-filled cheesecake with an Oreo cookie crust, chocolate ganache, and homemade whipped cream</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F82%2F80538b53-a5fa-47ea-815f-c65bbe6053b6.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 5.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 116,
                        "menuCategoryId": 8,
                        "menuItemId": 83,
                        "name": "Molten Chocolate Lava Cake",
                        "description": null,
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F83%2F7091ac03-4ec8-483d-9f73-3a541a2e7c6a.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 5.95,
                        "serviceCharge": null,
                        "sortOrder": 3,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 83,
                            "locationId": null,
                            "status": null,
                            "name": "Molten Chocolate Lava Cake",
                            "description": null,
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F83%2F7091ac03-4ec8-483d-9f73-3a541a2e7c6a.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 5.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 117,
                        "menuCategoryId": 8,
                        "menuItemId": 84,
                        "name": "S'mores Brownie",
                        "description": null,
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F84%2Fba597c21-71ad-4432-b2e5-3851728083c4.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 5.95,
                        "serviceCharge": null,
                        "sortOrder": 4,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 84,
                            "locationId": null,
                            "status": null,
                            "name": "S'mores Brownie",
                            "description": null,
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F84%2Fba597c21-71ad-4432-b2e5-3851728083c4.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 5.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 168,
                        "menuCategoryId": 8,
                        "menuItemId": 85,
                        "name": "Chocolate Chip Cannolis",
                        "description": "<p>A crisp and flaky cannoli shell enclosing a lusciously creamy, sweet ricotta filling dotted with decadent dark chocolate chips.</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F85%2Ff1c7bd4f-c4c1-473a-bad2-1f989f7b4721.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 5.95,
                        "serviceCharge": null,
                        "sortOrder": 5,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 85,
                            "locationId": null,
                            "status": null,
                            "name": "Chocolate Chip Cannolis",
                            "description": "<p>A crisp and flaky cannoli shell enclosing a lusciously creamy, sweet ricotta filling dotted with decadent dark chocolate chips.</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F85%2Ff1c7bd4f-c4c1-473a-bad2-1f989f7b4721.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 5.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 170,
                        "menuCategoryId": 8,
                        "menuItemId": 86,
                        "name": "Pina Colada Cake",
                        "description": "<p>Combines the tropical flavors of your favorite beach-side drink with a tender and moist cake!</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F86%2F1f238399-3c06-4677-8e34-9e35b00c6bad.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": 1,
                        "price": 7.95,
                        "serviceCharge": null,
                        "sortOrder": 6,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 86,
                            "locationId": null,
                            "status": null,
                            "name": "Pina Colada Cake",
                            "description": "<p>Combines the tropical flavors of your favorite beach-side drink with a tender and moist cake!</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F86%2F1f238399-3c06-4677-8e34-9e35b00c6bad.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 7.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": 20.0,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 218,
                        "menuCategoryId": 8,
                        "menuItemId": 15,
                        "name": "Beef Tacos",
                        "description": "Corn Tortilla. Tastes great!",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F15%2F696e5bc4-af62-4108-8a67-83d8703bc8d7.jpg",
                        "calories": 250,
                        "cost": 3.0,
                        "revenueTypeId": null,
                        "price": 7.95,
                        "serviceCharge": null,
                        "sortOrder": 7,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 15,
                            "locationId": null,
                            "status": "0",
                            "name": "Beef Tacos",
                            "description": "Corn Tortilla. Tastes great!",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F15%2F696e5bc4-af62-4108-8a67-83d8703bc8d7.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 250,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 7.95,
                            "cost": 3.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 220,
                        "menuCategoryId": 8,
                        "menuItemId": 101,
                        "name": "Bananas Foster II",
                        "description": "Bananas cooked in a warm, buttery rum sauce and served over vanilla ice cream, this dessert originated in New Orleans in the 1950s and has been a classic ever since. ",
                        "imageUrl": null,
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 7.95,
                        "serviceCharge": null,
                        "sortOrder": 1,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 101,
                            "locationId": null,
                            "status": null,
                            "name": "Bananas Foster II",
                            "description": "Bananas cooked in a warm, buttery rum sauce and served over vanilla ice cream, this dessert originated in New Orleans in the 1950s and has been a classic ever since. ",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 7.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            },
            {
                "id": 14,
                "name": "Beverages",
                "color": null,
                "description": "true",
                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuCategory%2F14%2F22aa998e-8053-4df2-93d2-4a2fe1b4faf0.jpg",
                "menuId": 1,
                "parentMenuCategoryId": null,
                "sortOrder": 7,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 90,
                        "menuCategoryId": 14,
                        "menuItemId": 38,
                        "name": "Fountain Drink",
                        "description": "<p>A cold soda goes with anything</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F38%2F9abeb922-6c0a-46f4-89bc-a5767b7f2f4c.jpg",
                        "calories": 350,
                        "cost": 1.0,
                        "revenueTypeId": null,
                        "price": 2.95,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 38,
                            "locationId": null,
                            "status": "0",
                            "name": "Fountain Drink",
                            "description": "<p>A cold soda goes with anything</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F38%2F9abeb922-6c0a-46f4-89bc-a5767b7f2f4c.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 350,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 2.95,
                            "cost": 1.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 91,
                        "menuCategoryId": 14,
                        "menuItemId": 47,
                        "name": "Iced Tea",
                        "description": null,
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F47%2F51189f12-a249-415a-aa30-0ab8cfa9bf4d.jpg",
                        "calories": null,
                        "cost": 1.0,
                        "revenueTypeId": null,
                        "price": 1.95,
                        "serviceCharge": null,
                        "sortOrder": 1,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 47,
                            "locationId": null,
                            "status": "0",
                            "name": "Iced Tea",
                            "description": null,
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F47%2F51189f12-a249-415a-aa30-0ab8cfa9bf4d.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": "Bar",
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 1.95,
                            "cost": 1.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 92,
                        "menuCategoryId": 14,
                        "menuItemId": 48,
                        "name": "Lemonade",
                        "description": null,
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F48%2F50780a89-1903-47e4-b980-49b12ecf372a.jpg",
                        "calories": null,
                        "cost": 1.0,
                        "revenueTypeId": null,
                        "price": 1.95,
                        "serviceCharge": null,
                        "sortOrder": 2,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 48,
                            "locationId": null,
                            "status": "0",
                            "name": "Lemonade",
                            "description": null,
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F48%2F50780a89-1903-47e4-b980-49b12ecf372a.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 1.95,
                            "cost": 1.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 93,
                        "menuCategoryId": 14,
                        "menuItemId": 49,
                        "name": "Evian Water",
                        "description": null,
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F49%2F68db0b6d-fe67-42d1-a03c-9a8290732939.jpg",
                        "calories": null,
                        "cost": 1.5,
                        "revenueTypeId": null,
                        "price": 3.5,
                        "serviceCharge": null,
                        "sortOrder": 3,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 49,
                            "locationId": null,
                            "status": "0",
                            "name": "Evian Water",
                            "description": null,
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F49%2F68db0b6d-fe67-42d1-a03c-9a8290732939.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": "Bar",
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 3.5,
                            "cost": 1.5,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            },
            {
                "id": 25,
                "name": "Featured Items",
                "color": null,
                "description": "false",
                "imageUrl": null,
                "menuId": 1,
                "parentMenuCategoryId": null,
                "sortOrder": 1,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 159,
                        "menuCategoryId": 25,
                        "menuItemId": 34,
                        "name": "Seafood Paella",
                        "description": "<p>A saffron-infused paella is loaded with mussels, clams, and shrimp.</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F34%2Fa79c8d5b-f6ae-4eca-aa55-b35b70e951ce.jpg",
                        "calories": 210,
                        "cost": 6.92,
                        "revenueTypeId": null,
                        "price": 31.95,
                        "serviceCharge": null,
                        "sortOrder": 1,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 34,
                            "locationId": null,
                            "status": "0",
                            "name": "Seafood Paella",
                            "description": "<p>A saffron-infused paella is loaded with mussels, clams, and shrimp.</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F34%2Fa79c8d5b-f6ae-4eca-aa55-b35b70e951ce.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 210,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 31.95,
                            "cost": 6.92,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 161,
                        "menuCategoryId": 25,
                        "menuItemId": 73,
                        "name": "Firecracker Shrimp",
                        "description": "<p>A firecracker shrimp recipe that takes spicy shrimp to a new level, with just the right combination of sweet, heat and awesome flavor.</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F73%2F6e3e8fa0-587c-4f1c-a8de-027a3accdc90.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 31.95,
                        "serviceCharge": null,
                        "sortOrder": 2,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 73,
                            "locationId": null,
                            "status": null,
                            "name": "Firecracker Shrimp",
                            "description": "<p>A firecracker shrimp recipe that takes spicy shrimp to a new level, with just the right combination of sweet, heat and awesome flavor.</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F73%2F6e3e8fa0-587c-4f1c-a8de-027a3accdc90.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 31.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 162,
                        "menuCategoryId": 25,
                        "menuItemId": 80,
                        "name": "Buttery Garlic Mushrooms",
                        "description": "<p>Sautéed Mushrooms swimming in a silky garlic butter sauce…pure mushroom perfection!</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F80%2Fa50dcf47-c6c7-4ac3-aca2-e75eca23822e.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 3,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 80,
                            "locationId": null,
                            "status": null,
                            "name": "Buttery Garlic Mushrooms",
                            "description": "<p>Sautéed Mushrooms swimming in a silky garlic butter sauce…pure mushroom perfection!</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F80%2Fa50dcf47-c6c7-4ac3-aca2-e75eca23822e.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 163,
                        "menuCategoryId": 25,
                        "menuItemId": 85,
                        "name": "Chocolate Chip Cannolis",
                        "description": "<p>A crisp and flaky cannoli shell enclosing a lusciously creamy, sweet ricotta filling dotted with decadent dark chocolate chips.</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F85%2Ff1c7bd4f-c4c1-473a-bad2-1f989f7b4721.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 5.95,
                        "serviceCharge": null,
                        "sortOrder": 4,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 85,
                            "locationId": null,
                            "status": null,
                            "name": "Chocolate Chip Cannolis",
                            "description": "<p>A crisp and flaky cannoli shell enclosing a lusciously creamy, sweet ricotta filling dotted with decadent dark chocolate chips.</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F85%2Ff1c7bd4f-c4c1-473a-bad2-1f989f7b4721.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 5.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 196,
                        "menuCategoryId": 25,
                        "menuItemId": 8,
                        "name": "BBQ Wings",
                        "description": "The sauce is excellent.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F8%2F7e102b1a-8e7a-47dc-9652-270851393ad4.jpg",
                        "calories": 450,
                        "cost": 7.0,
                        "revenueTypeId": 1,
                        "price": 0.0,
                        "serviceCharge": null,
                        "sortOrder": 5,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 8,
                            "locationId": null,
                            "status": "0",
                            "name": "BBQ Wings",
                            "description": "The sauce is excellent.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F8%2F7e102b1a-8e7a-47dc-9652-270851393ad4.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 450,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 11.0,
                            "cost": 7.0,
                            "serviceCharge": null,
                            "minimumQuantity": 2.0,
                            "defaultQuantity": 3.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 25,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": 1,
                                    "options": [
                                        {
                                            "id": 28,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 29,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 4,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Jack Daniel's BBQ",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 30,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 17,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Garlic Parmesan",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Wing Sauce",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": 1,
                                    "maximumQuantity": 2,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 26,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 32,
                                            "parentMenuItemModifierId": 26,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 34,
                                                    "parentMenuItemModifierId": 32,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Extra",
                                                    "price": 9.0,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Ranch",
                                            "price": 8.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 33,
                                            "parentMenuItemModifierId": 26,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 35,
                                                    "parentMenuItemModifierId": 33,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Extra",
                                                    "price": 9.0,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Blue Cheese",
                                            "price": 8.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Dipping Sauce",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 100,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 303,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": 1,
                                    "options": [
                                        {
                                            "id": 305,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 306,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 4,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Jack Daniel's BBQ",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 307,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 17,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Garlic Parmesan",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 308,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic Copy",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Wing Sauce many",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickMany",
                                    "style": "Dropdown",
                                    "minimumQuantity": 3,
                                    "maximumQuantity": 5,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 216,
                        "menuCategoryId": 25,
                        "menuItemId": 29,
                        "name": "Grilled Chicken Sandwich",
                        "description": "<p>Deeply charred mustardy chicken thighs are paired with a crunchy and refreshing fennel-basil slaw for the ultimate chicken sandwich.</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F29%2Ff6c05ad0-e8ff-47e5-a87b-f29d06f9a4c9.jpg",
                        "calories": 150,
                        "cost": 3.5,
                        "revenueTypeId": 1,
                        "price": 8.95,
                        "serviceCharge": 1.0,
                        "sortOrder": 6,
                        "preparationTimeInSeconds": 540,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 29,
                            "locationId": null,
                            "status": "0",
                            "name": "Grilled Chicken Sandwich",
                            "description": "<p>Deeply charred mustardy chicken thighs are paired with a crunchy and refreshing fennel-basil slaw for the ultimate chicken sandwich.</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F29%2Ff6c05ad0-e8ff-47e5-a87b-f29d06f9a4c9.jpg",
                            "preparationTimeInSeconds": 540,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 150,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 8.95,
                            "cost": 3.5,
                            "serviceCharge": 1.0,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 97,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 29,
                                    "menuItemModifierTemplateId": 9,
                                    "options": [
                                        {
                                            "id": 98,
                                            "parentMenuItemModifierId": 97,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 10,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "French Fries",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": true,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 99,
                                            "parentMenuItemModifierId": 97,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 11,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Coleslaw",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 100,
                                            "parentMenuItemModifierId": 97,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 12,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Onion Rings",
                                            "price": 2.95,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Sides",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "chicken",
                                        "normalizedName": "CHICKEN",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [
                                "chicken"
                            ],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 221,
                        "menuCategoryId": 25,
                        "menuItemId": 4,
                        "name": "Panini",
                        "description": "Great for lunch!",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F4%2Fb76b64c4-7cd9-47d8-aae9-1ea7a6e2ebc6.png",
                        "calories": 150,
                        "cost": 4.5,
                        "revenueTypeId": 1,
                        "price": 9.5,
                        "serviceCharge": null,
                        "sortOrder": 7,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 4,
                            "locationId": null,
                            "status": "0",
                            "name": "Panini",
                            "description": "Great for lunch!",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F4%2Fb76b64c4-7cd9-47d8-aae9-1ea7a6e2ebc6.png",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 150,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 9.5,
                            "cost": 4.5,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 314,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 4,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 316,
                                            "parentMenuItemModifierId": 314,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 317,
                                            "parentMenuItemModifierId": 314,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 2",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 318,
                                            "parentMenuItemModifierId": 314,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 3",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 319,
                                            "parentMenuItemModifierId": 314,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 4",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "test 1",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 322,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 4,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 323,
                                            "parentMenuItemModifierId": 322,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 324,
                                            "parentMenuItemModifierId": 322,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 2",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 325,
                                            "parentMenuItemModifierId": 322,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 3",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 326,
                                            "parentMenuItemModifierId": 322,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "test 1 level 4",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "test 1 Copy",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 100,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 272,
                        "menuCategoryId": 25,
                        "menuItemId": 79,
                        "name": "Parmesan Encrusted Carrots",
                        "description": "<p>Crispy parmesan roasted carrots with a hint of garlic for a delicious side dish!</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F79%2F37cda9d8-9d32-4d25-9da5-3fef6956e404.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": 1,
                        "price": 12.95,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": true,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 79,
                            "locationId": null,
                            "status": null,
                            "name": "Parmesan Encrusted Carrots",
                            "description": "<p>Crispy parmesan roasted carrots with a hint of garlic for a delicious side dish!</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F79%2F37cda9d8-9d32-4d25-9da5-3fef6956e404.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 12.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": 3.0,
                            "defaultQuantity": 4.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": true,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 222,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 223,
                                            "parentMenuItemModifierId": 222,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "S",
                                            "price": 5.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 224,
                                            "parentMenuItemModifierId": 222,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "M",
                                            "price": 10.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 225,
                                            "parentMenuItemModifierId": 222,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "L",
                                            "price": 15.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Size",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 275,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 276,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test2",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 285,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test ddd",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 286,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test Copy asdfasdfasdf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 287,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 288,
                                            "parentMenuItemModifierId": 287,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "new level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "new",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 289,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 290,
                                            "parentMenuItemModifierId": 289,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 291,
                                                    "parentMenuItemModifierId": 290,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "tjtjtj level 1 level 2",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "tjtjtj level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": true,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "tjtjtj",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 292,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 293,
                                            "parentMenuItemModifierId": 292,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "rere level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "rere",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 294,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [],
                                    "customerSystemId": null,
                                    "name": "test Copy Copy",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 295,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 79,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 296,
                                            "parentMenuItemModifierId": 295,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdfadf level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdfadf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            },
            {
                "id": 26,
                "name": "Deal of the Day",
                "color": null,
                "description": "false",
                "imageUrl": null,
                "menuId": 1,
                "parentMenuCategoryId": null,
                "sortOrder": 0,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 158,
                        "menuCategoryId": 26,
                        "menuItemId": 86,
                        "name": "Pina Colada Cake",
                        "description": "<p>Combines the tropical flavors of your favorite beach-side drink with a tender and moist cake!</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F86%2F1f238399-3c06-4677-8e34-9e35b00c6bad.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": 1,
                        "price": 7.95,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 86,
                            "locationId": null,
                            "status": null,
                            "name": "Pina Colada Cake",
                            "description": "<p>Combines the tropical flavors of your favorite beach-side drink with a tender and moist cake!</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F86%2F1f238399-3c06-4677-8e34-9e35b00c6bad.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 7.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": 20.0,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 187,
                        "menuCategoryId": 26,
                        "menuItemId": 37,
                        "name": "Balsamic Filet",
                        "description": "<p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F37%2F3a485504-b448-4c80-978a-b9c7302c659c.jpg",
                        "calories": 285,
                        "cost": 10.0,
                        "revenueTypeId": 1,
                        "price": 15.5,
                        "serviceCharge": null,
                        "sortOrder": 2,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 37,
                            "locationId": null,
                            "status": "0",
                            "name": "Balsamic Filet",
                            "description": "<p>An elegant and quick dinner served with steamed asparagus and baby red potatoes.</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F37%2F3a485504-b448-4c80-978a-b9c7302c659c.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 285,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 15.5,
                            "cost": 10.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 78,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 37,
                                    "menuItemModifierTemplateId": 13,
                                    "options": [
                                        {
                                            "id": 81,
                                            "parentMenuItemModifierId": 78,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 14,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Rare",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 82,
                                            "parentMenuItemModifierId": 78,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Medium Rare",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 100,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 83,
                                            "parentMenuItemModifierId": 78,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 15,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Medium",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 200,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 84,
                                            "parentMenuItemModifierId": 78,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Medium Well",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 300,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 85,
                                            "parentMenuItemModifierId": 78,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 16,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Well",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 400,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Temperature",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 192,
                        "menuCategoryId": 26,
                        "menuItemId": 8,
                        "name": "BBQ Wings",
                        "description": "The sauce is excellent.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F8%2F7e102b1a-8e7a-47dc-9652-270851393ad4.jpg",
                        "calories": 450,
                        "cost": 7.0,
                        "revenueTypeId": 1,
                        "price": 0.0,
                        "serviceCharge": null,
                        "sortOrder": 3,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 8,
                            "locationId": null,
                            "status": "0",
                            "name": "BBQ Wings",
                            "description": "The sauce is excellent.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F8%2F7e102b1a-8e7a-47dc-9652-270851393ad4.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 450,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 11.0,
                            "cost": 7.0,
                            "serviceCharge": null,
                            "minimumQuantity": 2.0,
                            "defaultQuantity": 3.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 25,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": 1,
                                    "options": [
                                        {
                                            "id": 28,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 29,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 4,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Jack Daniel's BBQ",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 30,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 17,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Garlic Parmesan",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Wing Sauce",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": 1,
                                    "maximumQuantity": 2,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 26,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 32,
                                            "parentMenuItemModifierId": 26,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 34,
                                                    "parentMenuItemModifierId": 32,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Extra",
                                                    "price": 9.0,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Ranch",
                                            "price": 8.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 33,
                                            "parentMenuItemModifierId": 26,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 35,
                                                    "parentMenuItemModifierId": 33,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Extra",
                                                    "price": 9.0,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Blue Cheese",
                                            "price": 8.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Dipping Sauce",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 100,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 303,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": 1,
                                    "options": [
                                        {
                                            "id": 305,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 306,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 4,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Jack Daniel's BBQ",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 307,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 17,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Garlic Parmesan",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 308,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic Copy",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Wing Sauce many",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickMany",
                                    "style": "Dropdown",
                                    "minimumQuantity": 3,
                                    "maximumQuantity": 5,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 194,
                        "menuCategoryId": 26,
                        "menuItemId": 3,
                        "name": "S'mores Brownie",
                        "description": "Very good!",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F3%2F21644997-6c98-4fae-85be-07dc21607ce5.jpg",
                        "calories": 175,
                        "cost": 0.75,
                        "revenueTypeId": 1,
                        "price": 1.44,
                        "serviceCharge": null,
                        "sortOrder": 4,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 3,
                            "locationId": null,
                            "status": "0",
                            "name": "S'mores Brownie",
                            "description": "Very good!",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F3%2F21644997-6c98-4fae-85be-07dc21607ce5.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 175,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 1.44,
                            "cost": 0.75,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "dairy",
                                        "normalizedName": "DAIRY",
                                        "tagType": "Allergen",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 198,
                        "menuCategoryId": 26,
                        "menuItemId": 9,
                        "name": "Egg Rolls",
                        "description": "Fried nicely",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F9%2F854944bd-cc23-46ff-b4b3-22c0b2ccb4ea.jpg",
                        "calories": 420,
                        "cost": 9.0,
                        "revenueTypeId": 1,
                        "price": 10.0,
                        "serviceCharge": 5.0,
                        "sortOrder": 5,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 9,
                            "locationId": null,
                            "status": "0",
                            "name": "Egg Rolls",
                            "description": "Fried nicely",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F9%2F854944bd-cc23-46ff-b4b3-22c0b2ccb4ea.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 420,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 10.0,
                            "cost": 9.0,
                            "serviceCharge": 5.0,
                            "minimumQuantity": 7.0,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 209,
                        "menuCategoryId": 26,
                        "menuItemId": 104,
                        "name": "Boneless Wings",
                        "description": "<p>this is the description</p>",
                        "imageUrl": null,
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 9.95,
                        "serviceCharge": null,
                        "sortOrder": 6,
                        "preparationTimeInSeconds": 540,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 104,
                            "locationId": "2a73da9d-3163-4eeb-cda8-08d77746ce6e",
                            "status": null,
                            "name": "Boneless Wings",
                            "description": null,
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 9.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 153,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 104,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 154,
                                            "parentMenuItemModifierId": 153,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey BBQ",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 155,
                                            "parentMenuItemModifierId": 153,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Super Spicy",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 156,
                                            "parentMenuItemModifierId": 153,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Teriyake",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Sauces",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 210,
                        "menuCategoryId": 26,
                        "menuItemId": 102,
                        "name": "Watermelon Sangria",
                        "description": "<p>This sweet twist on sangria's a real crowd-pleaser.</p>",
                        "imageUrl": null,
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 9.95,
                        "serviceCharge": null,
                        "sortOrder": 7,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 102,
                            "locationId": null,
                            "status": null,
                            "name": "Watermelon Sangria",
                            "description": "<p>This sweet twist on sangria's a real crowd-pleaser.</p>",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 9.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 3,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 211,
                        "menuCategoryId": 26,
                        "menuItemId": 100,
                        "name": "Tennessee Meatloaf",
                        "description": "Don't let the number of ingredients discourage you. It's part of the magic in creating a masterpiece!",
                        "imageUrl": null,
                        "calories": null,
                        "cost": 4.95,
                        "revenueTypeId": null,
                        "price": 11.95,
                        "serviceCharge": null,
                        "sortOrder": 8,
                        "preparationTimeInSeconds": 12,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 100,
                            "locationId": null,
                            "status": null,
                            "name": "Tennessee Meatloaf",
                            "description": "Don't let the number of ingredients discourage you. It's part of the magic in creating a masterpiece!",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 12,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 11.95,
                            "cost": 4.95,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": "Note",
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 212,
                        "menuCategoryId": 26,
                        "menuItemId": 103,
                        "name": "World's Best Lasagna",
                        "description": "<p>A little more work - but soooo worth it!</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F103%2Ff54cfefd-7703-4ac1-8d0d-4ad33d630830.jpg",
                        "calories": 250,
                        "cost": 4.52,
                        "revenueTypeId": null,
                        "price": 14.95,
                        "serviceCharge": 3.0,
                        "sortOrder": 9,
                        "preparationTimeInSeconds": 720,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 103,
                            "locationId": "2a73da9d-3163-4eeb-cda8-08d77746ce6e",
                            "status": null,
                            "name": "World's Best Lasagna",
                            "description": "<p>A little more work - but soooo worth it!</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F103%2Ff54cfefd-7703-4ac1-8d0d-4ad33d630830.jpg",
                            "preparationTimeInSeconds": 720,
                            "preparationArea": "Kitchen",
                            "storageArea": "Refrigerator",
                            "preparationNotes": null,
                            "calories": 250,
                            "serviceware": "Oval Tray",
                            "responsibleStaff": "Tony",
                            "price": 14.95,
                            "cost": 4.52,
                            "serviceCharge": 3.0,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 213,
                        "menuCategoryId": 26,
                        "menuItemId": 105,
                        "name": "Tortilla Chips",
                        "description": "<p>Crunch and yummy!</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F105%2F4d9b4637-ca19-48ae-8616-17e67a14d07e.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": 1,
                        "price": 5.95,
                        "serviceCharge": null,
                        "sortOrder": 10,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 105,
                            "locationId": "2a73da9d-3163-4eeb-cda8-08d77746ce6e",
                            "status": null,
                            "name": "Tortilla Chips",
                            "description": "<p>Crunch and yummy!</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F105%2F4d9b4637-ca19-48ae-8616-17e67a14d07e.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": "Kitchen",
                            "storageArea": "Pantry",
                            "preparationNotes": "Prep Notes",
                            "calories": null,
                            "serviceware": "Bowl",
                            "responsibleStaff": "Chef Michael",
                            "price": 5.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": 25.0,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": "<p>Item Notes</p>",
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 157,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 105,
                                    "menuItemModifierTemplateId": 30,
                                    "options": [
                                        {
                                            "id": 158,
                                            "parentMenuItemModifierId": 157,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 31,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Salsa",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": true,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 159,
                                            "parentMenuItemModifierId": 157,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 32,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Ranch",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 160,
                                            "parentMenuItemModifierId": 157,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 33,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Blue Cheese",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 161,
                                            "parentMenuItemModifierId": 157,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 34,
                                            "options": [
                                                {
                                                    "id": 162,
                                                    "parentMenuItemModifierId": 161,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": 35,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Extra",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Queso",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Dips",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 215,
                        "menuCategoryId": 26,
                        "menuItemId": 6,
                        "name": "Ham and Bacon",
                        "description": "Everyone loves bacon!",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F6%2Ffadfec26-e49c-4799-975f-946b4af9b05b.jpg",
                        "calories": 225,
                        "cost": 6.0,
                        "revenueTypeId": null,
                        "price": 5.95,
                        "serviceCharge": null,
                        "sortOrder": 11,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 6,
                            "locationId": null,
                            "status": "0",
                            "name": "Ham and Bacon",
                            "description": "Everyone loves bacon!",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F6%2Ffadfec26-e49c-4799-975f-946b4af9b05b.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 225,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 7.0,
                            "cost": 6.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 233,
                        "menuCategoryId": 26,
                        "menuItemId": 3,
                        "name": "S'mores Brownie",
                        "description": "Very good!",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F3%2F21644997-6c98-4fae-85be-07dc21607ce5.jpg",
                        "calories": 175,
                        "cost": 0.75,
                        "revenueTypeId": 1,
                        "price": 1.44,
                        "serviceCharge": null,
                        "sortOrder": 12,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 3,
                            "locationId": null,
                            "status": "0",
                            "name": "S'mores Brownie",
                            "description": "Very good!",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F3%2F21644997-6c98-4fae-85be-07dc21607ce5.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 175,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 1.44,
                            "cost": 0.75,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "dairy",
                                        "normalizedName": "DAIRY",
                                        "tagType": "Allergen",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 242,
                        "menuCategoryId": 26,
                        "menuItemId": 110,
                        "name": "Bistro Sandwich Collection",
                        "description": "<p>A collection of your choice of sandwich options.</p>",
                        "imageUrl": null,
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 7.0,
                        "serviceCharge": null,
                        "sortOrder": 13,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 110,
                            "locationId": "2a73da9d-3163-4eeb-cda8-08d77746ce6e",
                            "status": null,
                            "name": "Bistro Sandwich Collection",
                            "description": "<p>A collection of your choice of sandwich options.</p>",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 7.0,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 194,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 110,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 195,
                                            "parentMenuItemModifierId": 194,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Mr. Pepper",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 196,
                                            "parentMenuItemModifierId": 194,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Buffalo Chicken Wrap",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 197,
                                            "parentMenuItemModifierId": 194,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Chicken Cheddar Wrap",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 198,
                                            "parentMenuItemModifierId": 194,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Cranberry Almond Chicken Salad",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 199,
                                            "parentMenuItemModifierId": 194,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "The French Suite",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 200,
                                            "parentMenuItemModifierId": 194,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Rustic Tuscany",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 201,
                                            "parentMenuItemModifierId": 194,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Caprese del Sol (VEG)",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 202,
                                            "parentMenuItemModifierId": 194,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Ratatouille (VEGAN)",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Sandwich Choices",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickMany",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 243,
                        "menuCategoryId": 26,
                        "menuItemId": 111,
                        "name": "Mr. Pepper",
                        "description": "<p>Carved roast turkey &nbsp;provolone cheese &nbsp;peppered bacon &nbsp;roasted tomatoes &nbsp;mixed lettuce &nbsp;balsamic vinaigrette &nbsp;asiago cheese ciabatta</p>",
                        "imageUrl": null,
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": null,
                        "price": 7.0,
                        "serviceCharge": null,
                        "sortOrder": 14,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 111,
                            "locationId": "2a73da9d-3163-4eeb-cda8-08d77746ce6e",
                            "status": null,
                            "name": "Mr. Pepper",
                            "description": "<p>Carved roast turkey &nbsp;provolone cheese &nbsp;peppered bacon &nbsp;roasted tomatoes &nbsp;mixed lettuce &nbsp;balsamic vinaigrette &nbsp;asiago cheese ciabatta</p>",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 7.0,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 261,
                        "menuCategoryId": 26,
                        "menuItemId": 1,
                        "name": "Popcorn Shrimp",
                        "description": "<p>Fresh shrimp breaded and deep fried and perfectly sized for dipping in one of our signature dipping sauces. comes with choice of sauces</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F1%2Ffa0edaf7-497a-4529-9e44-541e71205ee3.jpg",
                        "calories": 120,
                        "cost": 4.22,
                        "revenueTypeId": 1,
                        "price": 11.95,
                        "serviceCharge": 10.0,
                        "sortOrder": 15,
                        "preparationTimeInSeconds": 5401,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 1,
                            "locationId": null,
                            "status": "0",
                            "name": "Popcorn Shrimp",
                            "description": "<p>Fresh shrimp breaded and deep fried and perfectly sized for dipping in one of our signature dipping sauces. comes with choice of sauces</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F1%2Ffa0edaf7-497a-4529-9e44-541e71205ee3.jpg",
                            "preparationTimeInSeconds": 5401,
                            "preparationArea": "Kitchen",
                            "storageArea": "Refrigerator",
                            "preparationNotes": null,
                            "calories": 120,
                            "serviceware": "Oval Tray",
                            "responsibleStaff": "Chef Michael, Chef Lisa",
                            "price": 11.95,
                            "cost": 4.22,
                            "serviceCharge": 10.0,
                            "minimumQuantity": 1.0,
                            "defaultQuantity": 1.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": "8 per order",
                            "customerSystemId": "1452",
                            "pluCode": "001",
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 215,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 217,
                                            "parentMenuItemModifierId": 215,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "undefined level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "dfgdfg",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 216,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 218,
                                            "parentMenuItemModifierId": 216,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "1234 level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "1234",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 297,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 298,
                                            "parentMenuItemModifierId": 297,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 299,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 301,
                                            "parentMenuItemModifierId": 299,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf Copy",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 300,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 302,
                                            "parentMenuItemModifierId": 300,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1 asdfasdf",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 312,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 313,
                                            "parentMenuItemModifierId": 312,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1 asdfasdf",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf Copy 2",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "app",
                                        "normalizedName": "APP",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "seafood",
                                        "normalizedName": "SEAFOOD",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "popcorn",
                                        "normalizedName": "POPCORN",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "vegetarian",
                                        "normalizedName": "VEGETARIAN",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [
                                "app",
                                "seafood",
                                "popcorn",
                                "vegetarian"
                            ],
                            "allergens": [],
                            "label": "<p>test</p>",
                            "comment": "test\nfdsf\n\ndfs\nf\n\n\n\n\nfas\n\nf\nads\nf\ndas\nf\ndas\ndfas\nsd",
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 317,
                        "menuCategoryId": 26,
                        "menuItemId": 149,
                        "name": "Truffle Mac and Cheese",
                        "description": "Truffle Mac and Cheese",
                        "imageUrl": null,
                        "calories": null,
                        "cost": 0.3,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 16,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 149,
                            "locationId": null,
                            "status": null,
                            "name": "Truffle Mac and Cheese",
                            "description": "Truffle Mac and Cheese",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": 0.3,
                            "serviceCharge": null,
                            "minimumQuantity": 2.0,
                            "defaultQuantity": 3.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 318,
                        "menuCategoryId": 26,
                        "menuItemId": 150,
                        "name": "Bang Bang Broccoli",
                        "description": "Bang Bang Broccoli",
                        "imageUrl": null,
                        "calories": null,
                        "cost": 1.2,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 17,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 150,
                            "locationId": null,
                            "status": null,
                            "name": "Bang Bang Broccoli",
                            "description": "Bang Bang Broccoli",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": 1.2,
                            "serviceCharge": null,
                            "minimumQuantity": 2.0,
                            "defaultQuantity": 3.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 319,
                        "menuCategoryId": 26,
                        "menuItemId": 147,
                        "name": "Sweet Potato Waffle Fries - Large",
                        "description": "Sweet Potato Waffle Fries",
                        "imageUrl": null,
                        "calories": null,
                        "cost": 0.04,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 18,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 147,
                            "locationId": null,
                            "status": null,
                            "name": "Sweet Potato Waffle Fries - Large",
                            "description": "Sweet Potato Waffle Fries",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": "Kitchen",
                            "storageArea": "Back Freezer",
                            "preparationNotes": "Prep Notes",
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": 0.04,
                            "serviceCharge": null,
                            "minimumQuantity": 2.0,
                            "defaultQuantity": 3.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": "<p>Item Notes</p>",
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 320,
                        "menuCategoryId": 26,
                        "menuItemId": 146,
                        "name": "Bang Bang Broccoli",
                        "description": "Bang Bang Broccoli",
                        "imageUrl": null,
                        "calories": null,
                        "cost": 1.2,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 19,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 146,
                            "locationId": null,
                            "status": null,
                            "name": "Bang Bang Broccoli",
                            "description": "Bang Bang Broccoli",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": 1.2,
                            "serviceCharge": null,
                            "minimumQuantity": 2.0,
                            "defaultQuantity": 3.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 321,
                        "menuCategoryId": 26,
                        "menuItemId": 140,
                        "name": "Scalloped Potatoes",
                        "description": "Scalloped Potatoes",
                        "imageUrl": null,
                        "calories": null,
                        "cost": 0.95,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 20,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 140,
                            "locationId": null,
                            "status": null,
                            "name": "Scalloped Potatoes",
                            "description": "Scalloped Potatoes",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": 0.95,
                            "serviceCharge": null,
                            "minimumQuantity": 2.0,
                            "defaultQuantity": 3.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 334,
                        "menuCategoryId": 26,
                        "menuItemId": 161,
                        "name": "Test",
                        "description": null,
                        "imageUrl": null,
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": 1,
                        "price": 0.0,
                        "serviceCharge": null,
                        "sortOrder": 1,
                        "preparationTimeInSeconds": 9000,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 161,
                            "locationId": "2a73da9d-3163-4eeb-cda8-08d77746ce6e",
                            "status": null,
                            "name": "Test",
                            "description": null,
                            "imageUrl": null,
                            "preparationTimeInSeconds": 9000,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 0.0,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 259,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 161,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 261,
                                            "parentMenuItemModifierId": 259,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "undefined level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 263,
                                            "parentMenuItemModifierId": 259,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "2",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 264,
                                            "parentMenuItemModifierId": 259,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "3",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 265,
                                            "parentMenuItemModifierId": 259,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "4",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 266,
                                            "parentMenuItemModifierId": 259,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "5",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 267,
                                            "parentMenuItemModifierId": 259,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "6",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 268,
                                            "parentMenuItemModifierId": 259,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "7",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 269,
                                            "parentMenuItemModifierId": 259,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "8",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 270,
                                            "parentMenuItemModifierId": 259,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "9",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 271,
                                            "parentMenuItemModifierId": 259,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "10",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 272,
                                            "parentMenuItemModifierId": 259,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 273,
                                                    "parentMenuItemModifierId": 272,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Continental Options Test  level 1 level 2",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                },
                                                {
                                                    "id": 274,
                                                    "parentMenuItemModifierId": 272,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Continental Options Test  level 1 level 2",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Continental Options Test  level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Continental Options Test ",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickMany",
                                    "style": "Button",
                                    "minimumQuantity": 1,
                                    "maximumQuantity": 20,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            },
            {
                "id": 28,
                "name": "From the Bar",
                "color": null,
                "description": "false",
                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuCategory%2F28%2Fcb6a4321-88e7-4ec7-86b0-59c3f10915f0.jpg",
                "menuId": 1,
                "parentMenuCategoryId": null,
                "sortOrder": 2,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 268,
                        "menuCategoryId": 28,
                        "menuItemId": 1,
                        "name": "Popcorn Shrimp",
                        "description": "<p>Fresh shrimp breaded and deep fried and perfectly sized for dipping in one of our signature dipping sauces. comes with choice of sauces</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F1%2Ffa0edaf7-497a-4529-9e44-541e71205ee3.jpg",
                        "calories": 120,
                        "cost": 4.22,
                        "revenueTypeId": 1,
                        "price": 11.95,
                        "serviceCharge": 10.0,
                        "sortOrder": 1,
                        "preparationTimeInSeconds": 5401,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 1,
                            "locationId": null,
                            "status": "0",
                            "name": "Popcorn Shrimp",
                            "description": "<p>Fresh shrimp breaded and deep fried and perfectly sized for dipping in one of our signature dipping sauces. comes with choice of sauces</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F1%2Ffa0edaf7-497a-4529-9e44-541e71205ee3.jpg",
                            "preparationTimeInSeconds": 5401,
                            "preparationArea": "Kitchen",
                            "storageArea": "Refrigerator",
                            "preparationNotes": null,
                            "calories": 120,
                            "serviceware": "Oval Tray",
                            "responsibleStaff": "Chef Michael, Chef Lisa",
                            "price": 11.95,
                            "cost": 4.22,
                            "serviceCharge": 10.0,
                            "minimumQuantity": 1.0,
                            "defaultQuantity": 1.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": "8 per order",
                            "customerSystemId": "1452",
                            "pluCode": "001",
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 215,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 217,
                                            "parentMenuItemModifierId": 215,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "undefined level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "dfgdfg",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 216,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 218,
                                            "parentMenuItemModifierId": 216,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "1234 level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "1234",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 297,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 298,
                                            "parentMenuItemModifierId": 297,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 299,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 301,
                                            "parentMenuItemModifierId": 299,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf Copy",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 300,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 302,
                                            "parentMenuItemModifierId": 300,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1 asdfasdf",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 312,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 313,
                                            "parentMenuItemModifierId": 312,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1 asdfasdf",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf Copy 2",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "app",
                                        "normalizedName": "APP",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "seafood",
                                        "normalizedName": "SEAFOOD",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "popcorn",
                                        "normalizedName": "POPCORN",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "vegetarian",
                                        "normalizedName": "VEGETARIAN",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [
                                "app",
                                "seafood",
                                "popcorn",
                                "vegetarian"
                            ],
                            "allergens": [],
                            "label": "<p>test</p>",
                            "comment": "test\nfdsf\n\ndfs\nf\n\n\n\n\nfas\n\nf\nads\nf\ndas\nf\ndas\ndfas\nsd",
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 271,
                        "menuCategoryId": 28,
                        "menuItemId": 8,
                        "name": "BBQ Wings",
                        "description": "The sauce is excellent.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F8%2F7e102b1a-8e7a-47dc-9652-270851393ad4.jpg",
                        "calories": 450,
                        "cost": 7.0,
                        "revenueTypeId": 1,
                        "price": 0.0,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 8,
                            "locationId": null,
                            "status": "0",
                            "name": "BBQ Wings",
                            "description": "The sauce is excellent.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F8%2F7e102b1a-8e7a-47dc-9652-270851393ad4.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 450,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 11.0,
                            "cost": 7.0,
                            "serviceCharge": null,
                            "minimumQuantity": 2.0,
                            "defaultQuantity": 3.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 25,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": 1,
                                    "options": [
                                        {
                                            "id": 28,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 29,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 4,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Jack Daniel's BBQ",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 30,
                                            "parentMenuItemModifierId": 25,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 17,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Garlic Parmesan",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Wing Sauce",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": 1,
                                    "maximumQuantity": 2,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 26,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 32,
                                            "parentMenuItemModifierId": 26,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 34,
                                                    "parentMenuItemModifierId": 32,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Extra",
                                                    "price": 9.0,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Ranch",
                                            "price": 8.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 33,
                                            "parentMenuItemModifierId": 26,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [
                                                {
                                                    "id": 35,
                                                    "parentMenuItemModifierId": 33,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": null,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Extra",
                                                    "price": 9.0,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Blue Cheese",
                                            "price": 8.0,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Dipping Sauce",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 100,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 303,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 8,
                                    "menuItemModifierTemplateId": 1,
                                    "options": [
                                        {
                                            "id": 305,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 306,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 4,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Jack Daniel's BBQ",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 307,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 17,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Garlic Parmesan",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 308,
                                            "parentMenuItemModifierId": 303,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 3,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Honey Garlic Copy",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Wing Sauce many",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickMany",
                                    "style": "Dropdown",
                                    "minimumQuantity": 3,
                                    "maximumQuantity": 5,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            },
            {
                "id": 29,
                "name": "Drinks",
                "color": null,
                "description": "true",
                "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuCategory%2F-3%2Fe1548d22-9d57-4098-8943-e8a68a6d8192.png",
                "menuId": 1,
                "parentMenuCategoryId": null,
                "sortOrder": 8,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 207,
                        "menuCategoryId": 29,
                        "menuItemId": 98,
                        "name": "Iced Tea",
                        "description": null,
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F98%2F18ad73da-a2d9-4cb8-8339-46abe5d6f196.jpg",
                        "calories": null,
                        "cost": 1.0,
                        "revenueTypeId": null,
                        "price": 3.99,
                        "serviceCharge": null,
                        "sortOrder": 1,
                        "preparationTimeInSeconds": 60,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 98,
                            "locationId": "2a73da9d-3163-4eeb-cda8-08d77746ce6e",
                            "status": null,
                            "name": "Iced Tea",
                            "description": null,
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F98%2F18ad73da-a2d9-4cb8-8339-46abe5d6f196.jpg",
                            "preparationTimeInSeconds": 60,
                            "preparationArea": "Bar",
                            "storageArea": "Refrigerator",
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": "Tony",
                            "price": 3.99,
                            "cost": 1.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 2,
                            "notes": null,
                            "customerSystemId": "444",
                            "pluCode": "4444",
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            }
        ],
        "recordStatus": "Active",
        "createdUtcDateTime": "0001-01-01T00:00:00Z",
        "lastModifiedUtcDateTime": null,
        "createdBy": null,
        "lastModifiedBy": null,
        "typeName": "Menu"
    },
    {
        "id": 23,
        "name": "Test 1",
        "menuCategories": [
            {
                "id": 100,
                "name": "test",
                "color": null,
                "description": "true",
                "imageUrl": null,
                "menuId": 23,
                "parentMenuCategoryId": null,
                "sortOrder": 0,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 350,
                        "menuCategoryId": 100,
                        "menuItemId": 217,
                        "name": "Dance Floor w/setup",
                        "description": "Dance Floor with Setup",
                        "imageUrl": null,
                        "calories": null,
                        "cost": 37.5,
                        "revenueTypeId": null,
                        "price": 75.0,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": true,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 217,
                            "locationId": null,
                            "status": null,
                            "name": "Dance Floor w/setup",
                            "description": "Dance Floor with Setup",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": "Setup",
                            "storageArea": null,
                            "preparationNotes": "",
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 75.0,
                            "cost": 37.5,
                            "serviceCharge": null,
                            "minimumQuantity": 0.0,
                            "defaultQuantity": 1.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 4,
                            "notes": "",
                            "customerSystemId": "00001-00000000000332",
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": true,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "CE",
                                        "normalizedName": "CE",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "AV Equipment",
                                        "normalizedName": "AV EQUIPMENT",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "Test",
                                        "normalizedName": "TEST",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [
                                "CE",
                                "AV Equipment",
                                "Test"
                            ],
                            "allergens": [],
                            "label": "",
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 347,
                        "menuCategoryId": 100,
                        "menuItemId": 220,
                        "name": "Chef's Table Buffet",
                        "description": "Chef's Table Buffet\r\nincludes: Italian mostaccioli, baked chicken, sage dressing, whipped potatoes and gravy, vegetable du jour, tossed salad, potato salad, cole slaw, rolls and butter, dessert, coffee, tea or milk",
                        "imageUrl": "https://horizoncloudfuncdevstor.blob.core.windows.net/caterease/97213e88-1948-4fd8-93c4-916559afd7b2.png",
                        "calories": null,
                        "cost": 0.0,
                        "revenueTypeId": null,
                        "price": 3.0,
                        "serviceCharge": null,
                        "sortOrder": 1,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 220,
                            "locationId": null,
                            "status": null,
                            "name": "Chef's Table Buffet",
                            "description": "Chef's Table Buffet\r\nincludes: Italian mostaccioli, baked chicken, sage dressing, whipped potatoes and gravy, vegetable du jour, tossed salad, potato salad, cole slaw, rolls and butter, dessert, coffee, tea or milk",
                            "imageUrl": "https://horizoncloudfuncdevstor.blob.core.windows.net/caterease/97213e88-1948-4fd8-93c4-916559afd7b2.png",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": "Kitchen",
                            "storageArea": null,
                            "preparationNotes": "In a large skillet over medium high heat, cook the cut up chicken until cooked through.\r\nAdd the bell peppers, onion, and garlic, seasoning with a light hand as you go.\r\nCook for about 10 minutes, or until the veggies are crisp tender but not mushy.\r\nCombine spaghetti sauce with mixture in the pan; bring to a bubble, reduce heat and cover.\r\nWhile the meat/veggie/sauce is simmering, cook the pasta per package directions.\r\nDrain and return the pasta to the cooking pot; add the mixture from the skillet to the pasta and stir.\r\nHeat through.\r\nServe with garlic toast.",
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 3.0,
                            "cost": 0.0,
                            "serviceCharge": null,
                            "minimumQuantity": 0.0,
                            "defaultQuantity": 10.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": "Is there anything wrong with this notes box?",
                            "customerSystemId": "00001-00000000000377",
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "CE",
                                        "normalizedName": "CE",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "Lunch",
                                        "normalizedName": "LUNCH",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "Test",
                                        "normalizedName": "TEST",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [
                                "CE",
                                "Lunch",
                                "Test"
                            ],
                            "allergens": [],
                            "label": "",
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 335,
                        "menuCategoryId": 100,
                        "menuItemId": 154,
                        "name": "Philly Cheesesteak",
                        "description": "<p>Best damn philly in Texas.</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e/menuItem/154/dd5379f2-07db-4ea8-aec3-e73ed61dae46.png",
                        "calories": 50,
                        "cost": 4.25,
                        "revenueTypeId": null,
                        "price": 9.95,
                        "serviceCharge": 0.0,
                        "sortOrder": 2,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 154,
                            "locationId": "2a73da9d-3163-4eeb-cda8-08d77746ce6e",
                            "status": null,
                            "name": "Philly Cheesesteak",
                            "description": "<p>Best damn philly in Texas.</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e/menuItem/154/dd5379f2-07db-4ea8-aec3-e73ed61dae46.png",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": "Back Freezer",
                            "preparationNotes": null,
                            "calories": 50,
                            "serviceware": "Platter",
                            "responsibleStaff": "Chef Michael",
                            "price": 9.95,
                            "cost": 4.25,
                            "serviceCharge": 0.0,
                            "minimumQuantity": 1.0,
                            "defaultQuantity": 2.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": "where do these appear?",
                            "customerSystemId": "12345",
                            "pluCode": "67890",
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 219,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 154,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 220,
                                            "parentMenuItemModifierId": 219,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "choice 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 221,
                                            "parentMenuItemModifierId": 219,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "choice 2",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": true,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "choices",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "non-veg",
                                        "normalizedName": "NON-VEG",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [
                                "non-veg"
                            ],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 337,
                        "menuCategoryId": 100,
                        "menuItemId": 105,
                        "name": "Tortilla Chips",
                        "description": "<p>Crunch and yummy!</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F105%2F4d9b4637-ca19-48ae-8616-17e67a14d07e.jpg",
                        "calories": null,
                        "cost": null,
                        "revenueTypeId": 1,
                        "price": 5.95,
                        "serviceCharge": null,
                        "sortOrder": 3,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 105,
                            "locationId": "2a73da9d-3163-4eeb-cda8-08d77746ce6e",
                            "status": null,
                            "name": "Tortilla Chips",
                            "description": "<p>Crunch and yummy!</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F105%2F4d9b4637-ca19-48ae-8616-17e67a14d07e.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": "Kitchen",
                            "storageArea": "Pantry",
                            "preparationNotes": "Prep Notes",
                            "calories": null,
                            "serviceware": "Bowl",
                            "responsibleStaff": "Chef Michael",
                            "price": 5.95,
                            "cost": null,
                            "serviceCharge": null,
                            "minimumQuantity": 25.0,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": "<p>Item Notes</p>",
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 157,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 105,
                                    "menuItemModifierTemplateId": 30,
                                    "options": [
                                        {
                                            "id": 158,
                                            "parentMenuItemModifierId": 157,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 31,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Salsa",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": true,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 159,
                                            "parentMenuItemModifierId": 157,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 32,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Ranch",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 160,
                                            "parentMenuItemModifierId": 157,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 33,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Blue Cheese",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 161,
                                            "parentMenuItemModifierId": 157,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": 34,
                                            "options": [
                                                {
                                                    "id": 162,
                                                    "parentMenuItemModifierId": 161,
                                                    "menuItemId": null,
                                                    "menuItemModifierTemplateId": 35,
                                                    "options": null,
                                                    "customerSystemId": null,
                                                    "name": "Extra",
                                                    "price": null,
                                                    "revenueTypeId": null,
                                                    "rule": "PickOne",
                                                    "style": "Button",
                                                    "minimumQuantity": null,
                                                    "maximumQuantity": null,
                                                    "default": false,
                                                    "disabled": false,
                                                    "sortOrder": 0,
                                                    "recordStatus": "Active",
                                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                                    "lastModifiedUtcDateTime": null,
                                                    "createdBy": null,
                                                    "lastModifiedBy": null,
                                                    "typeName": "MenuItemModifier"
                                                }
                                            ],
                                            "customerSystemId": null,
                                            "name": "Queso",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Dips",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            },
            {
                "id": 178,
                "name": "My Category",
                "color": null,
                "description": "true",
                "imageUrl": null,
                "menuId": 23,
                "parentMenuCategoryId": null,
                "sortOrder": 1,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 339,
                        "menuCategoryId": 178,
                        "menuItemId": 1,
                        "name": "Popcorn Shrimp",
                        "description": "<p>Fresh shrimp breaded and deep fried and perfectly sized for dipping in one of our signature dipping sauces. comes with choice of sauces</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F1%2Ffa0edaf7-497a-4529-9e44-541e71205ee3.jpg",
                        "calories": 120,
                        "cost": 4.22,
                        "revenueTypeId": null,
                        "price": 11.95,
                        "serviceCharge": 10.0,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 5401,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 1,
                            "locationId": null,
                            "status": "0",
                            "name": "Popcorn Shrimp",
                            "description": "<p>Fresh shrimp breaded and deep fried and perfectly sized for dipping in one of our signature dipping sauces. comes with choice of sauces</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F1%2Ffa0edaf7-497a-4529-9e44-541e71205ee3.jpg",
                            "preparationTimeInSeconds": 5401,
                            "preparationArea": "Kitchen",
                            "storageArea": "Refrigerator",
                            "preparationNotes": null,
                            "calories": 120,
                            "serviceware": "Oval Tray",
                            "responsibleStaff": "Chef Michael, Chef Lisa",
                            "price": 11.95,
                            "cost": 4.22,
                            "serviceCharge": 10.0,
                            "minimumQuantity": 1.0,
                            "defaultQuantity": 1.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": "8 per order",
                            "customerSystemId": "1452",
                            "pluCode": "001",
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 215,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 217,
                                            "parentMenuItemModifierId": 215,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "undefined level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "dfgdfg",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 216,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 218,
                                            "parentMenuItemModifierId": 216,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "1234 level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "1234",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 297,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 298,
                                            "parentMenuItemModifierId": 297,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 299,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 301,
                                            "parentMenuItemModifierId": 299,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf Copy",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 300,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 302,
                                            "parentMenuItemModifierId": 300,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1 asdfasdf",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 312,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 313,
                                            "parentMenuItemModifierId": 312,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1 asdfasdf",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf Copy 2",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "app",
                                        "normalizedName": "APP",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "seafood",
                                        "normalizedName": "SEAFOOD",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "popcorn",
                                        "normalizedName": "POPCORN",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "vegetarian",
                                        "normalizedName": "VEGETARIAN",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [
                                "app",
                                "seafood",
                                "popcorn",
                                "vegetarian"
                            ],
                            "allergens": [],
                            "label": "<p>test</p>",
                            "comment": "test\nfdsf\n\ndfs\nf\n\n\n\n\nfas\n\nf\nads\nf\ndas\nf\ndas\ndfas\nsd",
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 340,
                        "menuCategoryId": 178,
                        "menuItemId": 9,
                        "name": "Egg Rolls",
                        "description": "Fried nicely",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F9%2F854944bd-cc23-46ff-b4b3-22c0b2ccb4ea.jpg",
                        "calories": 420,
                        "cost": 9.0,
                        "revenueTypeId": null,
                        "price": 20.0,
                        "serviceCharge": 5.0,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": true,
                        "noServiceCharge": true,
                        "noGratuity": false,
                        "linkedWithMenuItem": false,
                        "menuItem": {
                            "id": 9,
                            "locationId": null,
                            "status": "0",
                            "name": "Egg Rolls",
                            "description": "Fried nicely",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F9%2F854944bd-cc23-46ff-b4b3-22c0b2ccb4ea.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 420,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 10.0,
                            "cost": 9.0,
                            "serviceCharge": 5.0,
                            "minimumQuantity": 7.0,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 341,
                        "menuCategoryId": 178,
                        "menuItemId": 3,
                        "name": "S'mores Brownie",
                        "description": "Very good!",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F3%2F21644997-6c98-4fae-85be-07dc21607ce5.jpg",
                        "calories": 175,
                        "cost": 0.75,
                        "revenueTypeId": null,
                        "price": 1.44,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 3,
                            "locationId": null,
                            "status": "0",
                            "name": "S'mores Brownie",
                            "description": "Very good!",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F3%2F21644997-6c98-4fae-85be-07dc21607ce5.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 175,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 1.44,
                            "cost": 0.75,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "dairy",
                                        "normalizedName": "DAIRY",
                                        "tagType": "Allergen",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 342,
                        "menuCategoryId": 178,
                        "menuItemId": 11,
                        "name": "Garlic Butter Shrimp Scampi",
                        "description": "Fresh, succulent shrimp on a bed of linguini and covered with a garlic buttery scampi sauce with a hint of white wine and lemon.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F11%2F072f49a1-dfaf-40b4-a4d4-cbab5e196d5b.jpg",
                        "calories": 288,
                        "cost": 20.0,
                        "revenueTypeId": null,
                        "price": 40.0,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 11,
                            "locationId": null,
                            "status": "0",
                            "name": "Garlic Butter Shrimp Scampi",
                            "description": "Fresh, succulent shrimp on a bed of linguini and covered with a garlic buttery scampi sauce with a hint of white wine and lemon.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F11%2F072f49a1-dfaf-40b4-a4d4-cbab5e196d5b.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 288,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 40.0,
                            "cost": 20.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 344,
                        "menuCategoryId": 178,
                        "menuItemId": 20,
                        "name": "Shrimp Cocktail",
                        "description": "Flavorful, poached shrimp boiled and chilled and served with a side of our homemade horseradish cocktail sauce and plenty of lemon.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F20%2F3fc0c9d7-f3da-4b78-b28e-165a2b4f4238.png",
                        "calories": 295,
                        "cost": 5.0,
                        "revenueTypeId": null,
                        "price": 10.0,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 20,
                            "locationId": null,
                            "status": "0",
                            "name": "Shrimp Cocktail",
                            "description": "Flavorful, poached shrimp boiled and chilled and served with a side of our homemade horseradish cocktail sauce and plenty of lemon.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F20%2F3fc0c9d7-f3da-4b78-b28e-165a2b4f4238.png",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 295,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 10.0,
                            "cost": 5.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 346,
                        "menuCategoryId": 178,
                        "menuItemId": 18,
                        "name": "Pineapple Cake",
                        "description": "Decadent!",
                        "imageUrl": null,
                        "calories": 425,
                        "cost": 4.3,
                        "revenueTypeId": null,
                        "price": 9.25,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 18,
                            "locationId": null,
                            "status": "0",
                            "name": "Pineapple Cake",
                            "description": "Decadent!",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 425,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 9.25,
                            "cost": 4.3,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            },
            {
                "id": 179,
                "name": null,
                "color": null,
                "description": "true",
                "imageUrl": null,
                "menuId": 23,
                "parentMenuCategoryId": null,
                "sortOrder": 2,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 348,
                        "menuCategoryId": 179,
                        "menuItemId": 11,
                        "name": "Garlic Butter Shrimp Scampi",
                        "description": "Fresh, succulent shrimp on a bed of linguini and covered with a garlic buttery scampi sauce with a hint of white wine and lemon.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F11%2F072f49a1-dfaf-40b4-a4d4-cbab5e196d5b.jpg",
                        "calories": 288,
                        "cost": 20.0,
                        "revenueTypeId": null,
                        "price": 40.0,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 11,
                            "locationId": null,
                            "status": "0",
                            "name": "Garlic Butter Shrimp Scampi",
                            "description": "Fresh, succulent shrimp on a bed of linguini and covered with a garlic buttery scampi sauce with a hint of white wine and lemon.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F11%2F072f49a1-dfaf-40b4-a4d4-cbab5e196d5b.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 288,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 40.0,
                            "cost": 20.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 349,
                        "menuCategoryId": 179,
                        "menuItemId": 10,
                        "name": "Coconut Shrimp",
                        "description": "Succulent shrimp dipped in batter and rolled in an aromatic blend of shredded coconut and Panko breadcrumbs and fried for an irresistible crunch in every bite.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F10%2Fba9d2df4-7fe9-44d9-86fa-659f357a5143.jpg",
                        "calories": 126,
                        "cost": 20.0,
                        "revenueTypeId": null,
                        "price": 40.0,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 10,
                            "locationId": null,
                            "status": "0",
                            "name": "Coconut Shrimp",
                            "description": "Succulent shrimp dipped in batter and rolled in an aromatic blend of shredded coconut and Panko breadcrumbs and fried for an irresistible crunch in every bite.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F10%2Fba9d2df4-7fe9-44d9-86fa-659f357a5143.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 126,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 40.0,
                            "cost": 20.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            },
            {
                "id": 180,
                "name": null,
                "color": null,
                "description": "true",
                "imageUrl": null,
                "menuId": 23,
                "parentMenuCategoryId": null,
                "sortOrder": 3,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 358,
                        "menuCategoryId": 180,
                        "menuItemId": 217,
                        "name": "Dance Floor w/setup",
                        "description": "Dance Floor with Setup",
                        "imageUrl": null,
                        "calories": null,
                        "cost": 37.5,
                        "revenueTypeId": null,
                        "price": 75.0,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": true,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 217,
                            "locationId": null,
                            "status": null,
                            "name": "Dance Floor w/setup",
                            "description": "Dance Floor with Setup",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": "Setup",
                            "storageArea": null,
                            "preparationNotes": "",
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 75.0,
                            "cost": 37.5,
                            "serviceCharge": null,
                            "minimumQuantity": 0.0,
                            "defaultQuantity": 1.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 4,
                            "notes": "",
                            "customerSystemId": "00001-00000000000332",
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": true,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "CE",
                                        "normalizedName": "CE",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "AV Equipment",
                                        "normalizedName": "AV EQUIPMENT",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "Test",
                                        "normalizedName": "TEST",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [
                                "CE",
                                "AV Equipment",
                                "Test"
                            ],
                            "allergens": [],
                            "label": "",
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 351,
                        "menuCategoryId": 180,
                        "menuItemId": 10,
                        "name": "Coconut Shrimp",
                        "description": "Succulent shrimp dipped in batter and rolled in an aromatic blend of shredded coconut and Panko breadcrumbs and fried for an irresistible crunch in every bite.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F10%2Fba9d2df4-7fe9-44d9-86fa-659f357a5143.jpg",
                        "calories": 126,
                        "cost": 20.0,
                        "revenueTypeId": null,
                        "price": 40.0,
                        "serviceCharge": null,
                        "sortOrder": 1,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 10,
                            "locationId": null,
                            "status": "0",
                            "name": "Coconut Shrimp",
                            "description": "Succulent shrimp dipped in batter and rolled in an aromatic blend of shredded coconut and Panko breadcrumbs and fried for an irresistible crunch in every bite.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F10%2Fba9d2df4-7fe9-44d9-86fa-659f357a5143.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 126,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 40.0,
                            "cost": 20.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 352,
                        "menuCategoryId": 180,
                        "menuItemId": 11,
                        "name": "Garlic Butter Shrimp Scampi",
                        "description": "Fresh, succulent shrimp on a bed of linguini and covered with a garlic buttery scampi sauce with a hint of white wine and lemon.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F11%2F072f49a1-dfaf-40b4-a4d4-cbab5e196d5b.jpg",
                        "calories": 288,
                        "cost": 20.0,
                        "revenueTypeId": null,
                        "price": 40.0,
                        "serviceCharge": null,
                        "sortOrder": 2,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 11,
                            "locationId": null,
                            "status": "0",
                            "name": "Garlic Butter Shrimp Scampi",
                            "description": "Fresh, succulent shrimp on a bed of linguini and covered with a garlic buttery scampi sauce with a hint of white wine and lemon.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F11%2F072f49a1-dfaf-40b4-a4d4-cbab5e196d5b.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 288,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 40.0,
                            "cost": 20.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 353,
                        "menuCategoryId": 180,
                        "menuItemId": 12,
                        "name": "Salmon Bites",
                        "description": "<p>Transforms one of the worlds’ healthiest foods into an appetizer that’s as fun to eat as it is good for you.</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F12%2F5e99a331-b3aa-4d56-964e-fa2f0340e7cc.jpg",
                        "calories": 350,
                        "cost": 7.0,
                        "revenueTypeId": null,
                        "price": 9.0,
                        "serviceCharge": 5.0,
                        "sortOrder": 3,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 12,
                            "locationId": null,
                            "status": "0",
                            "name": "Salmon Bites",
                            "description": "<p>Transforms one of the worlds’ healthiest foods into an appetizer that’s as fun to eat as it is good for you.</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F12%2F5e99a331-b3aa-4d56-964e-fa2f0340e7cc.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 350,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 9.0,
                            "cost": 7.0,
                            "serviceCharge": 5.0,
                            "minimumQuantity": 5.0,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 354,
                        "menuCategoryId": 180,
                        "menuItemId": 14,
                        "name": "All-American Club",
                        "description": "<p>A simple sandwich.</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F14%2F5dae9c0e-3f8c-4460-b426-940fbff75557.jpg",
                        "calories": 300,
                        "cost": 4.0,
                        "revenueTypeId": null,
                        "price": 7.95,
                        "serviceCharge": 0.0,
                        "sortOrder": 4,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 14,
                            "locationId": null,
                            "status": "0",
                            "name": "All-American Club",
                            "description": "<p>A simple sandwich.</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F14%2F5dae9c0e-3f8c-4460-b426-940fbff75557.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 300,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 7.95,
                            "cost": 4.0,
                            "serviceCharge": 0.0,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 355,
                        "menuCategoryId": 180,
                        "menuItemId": 21,
                        "name": "Strawberry Tart",
                        "description": "Chris' favorite!",
                        "imageUrl": null,
                        "calories": 350,
                        "cost": 3.0,
                        "revenueTypeId": null,
                        "price": 7.5,
                        "serviceCharge": null,
                        "sortOrder": 5,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 21,
                            "locationId": null,
                            "status": "0",
                            "name": "Strawberry Tart",
                            "description": "Chris' favorite!",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 350,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 7.5,
                            "cost": 3.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 356,
                        "menuCategoryId": 180,
                        "menuItemId": 20,
                        "name": "Shrimp Cocktail",
                        "description": "Flavorful, poached shrimp boiled and chilled and served with a side of our homemade horseradish cocktail sauce and plenty of lemon.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F20%2F3fc0c9d7-f3da-4b78-b28e-165a2b4f4238.png",
                        "calories": 295,
                        "cost": 5.0,
                        "revenueTypeId": null,
                        "price": 10.0,
                        "serviceCharge": null,
                        "sortOrder": 6,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 20,
                            "locationId": null,
                            "status": "0",
                            "name": "Shrimp Cocktail",
                            "description": "Flavorful, poached shrimp boiled and chilled and served with a side of our homemade horseradish cocktail sauce and plenty of lemon.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F20%2F3fc0c9d7-f3da-4b78-b28e-165a2b4f4238.png",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 295,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 10.0,
                            "cost": 5.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 357,
                        "menuCategoryId": 180,
                        "menuItemId": 19,
                        "name": "Apple Pie",
                        "description": "Mom approved!",
                        "imageUrl": null,
                        "calories": 500,
                        "cost": 7.0,
                        "revenueTypeId": null,
                        "price": 8.0,
                        "serviceCharge": null,
                        "sortOrder": 7,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 19,
                            "locationId": null,
                            "status": "0",
                            "name": "Apple Pie",
                            "description": "Mom approved!",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 500,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 8.0,
                            "cost": 7.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            }
        ],
        "recordStatus": "Active",
        "createdUtcDateTime": "0001-01-01T00:00:00Z",
        "lastModifiedUtcDateTime": null,
        "createdBy": null,
        "lastModifiedBy": null,
        "typeName": "Menu"
    },
    {
        "id": 25,
        "name": "Test 3",
        "menuCategories": [
            {
                "id": 102,
                "name": "",
                "color": null,
                "description": "true",
                "imageUrl": null,
                "menuId": 25,
                "parentMenuCategoryId": null,
                "sortOrder": 0,
                "menuCategories": [
                    {
                        "id": 103,
                        "name": null,
                        "color": null,
                        "description": null,
                        "imageUrl": null,
                        "menuId": null,
                        "parentMenuCategoryId": 102,
                        "sortOrder": 0,
                        "menuCategories": null,
                        "menuCategoryItems": [
                            {
                                "id": 282,
                                "menuCategoryId": 103,
                                "menuItemId": 139,
                                "name": "Sweet Potato Waffle Fries",
                                "description": "Sweet Potato Waffle Fries",
                                "imageUrl": null,
                                "calories": null,
                                "cost": 0.04,
                                "revenueTypeId": null,
                                "price": 4.95,
                                "serviceCharge": null,
                                "sortOrder": 0,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 139,
                                    "locationId": null,
                                    "status": null,
                                    "name": "Sweet Potato Waffle Fries",
                                    "description": "Sweet Potato Waffle Fries",
                                    "imageUrl": null,
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": null,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 4.95,
                                    "cost": 0.04,
                                    "serviceCharge": null,
                                    "minimumQuantity": 2.0,
                                    "defaultQuantity": 3.0,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            },
                            {
                                "id": 283,
                                "menuCategoryId": 103,
                                "menuItemId": 138,
                                "name": "Bang Bang Broccoli",
                                "description": "Bang Bang Broccoli",
                                "imageUrl": null,
                                "calories": null,
                                "cost": 1.2,
                                "revenueTypeId": null,
                                "price": 4.95,
                                "serviceCharge": null,
                                "sortOrder": 0,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 138,
                                    "locationId": null,
                                    "status": null,
                                    "name": "Bang Bang Broccoli",
                                    "description": "Bang Bang Broccoli",
                                    "imageUrl": null,
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": null,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 4.95,
                                    "cost": 1.2,
                                    "serviceCharge": null,
                                    "minimumQuantity": 2.0,
                                    "defaultQuantity": 3.0,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            },
                            {
                                "id": 284,
                                "menuCategoryId": 103,
                                "menuItemId": 137,
                                "name": "Truffle Mac and Cheese",
                                "description": "Truffle Mac and Cheese",
                                "imageUrl": null,
                                "calories": null,
                                "cost": 0.3,
                                "revenueTypeId": null,
                                "price": 4.95,
                                "serviceCharge": null,
                                "sortOrder": 0,
                                "preparationTimeInSeconds": 0,
                                "noTax": false,
                                "noServiceCharge": false,
                                "noGratuity": false,
                                "linkedWithMenuItem": true,
                                "menuItem": {
                                    "id": 137,
                                    "locationId": null,
                                    "status": null,
                                    "name": "Truffle Mac and Cheese",
                                    "description": "Truffle Mac and Cheese",
                                    "imageUrl": null,
                                    "preparationTimeInSeconds": 0,
                                    "preparationArea": null,
                                    "storageArea": null,
                                    "preparationNotes": null,
                                    "calories": null,
                                    "serviceware": null,
                                    "responsibleStaff": null,
                                    "price": 4.95,
                                    "cost": 0.3,
                                    "serviceCharge": null,
                                    "minimumQuantity": 2.0,
                                    "defaultQuantity": 3.0,
                                    "roundingRule": null,
                                    "quantityLinkedToGuestCount": false,
                                    "revenueTypeId": 1,
                                    "notes": null,
                                    "customerSystemId": null,
                                    "pluCode": null,
                                    "noTax": false,
                                    "noServiceCharge": false,
                                    "noGratuity": false,
                                    "menuItemCombo": null,
                                    "modifiers": [],
                                    "itemTags": [],
                                    "tags": [],
                                    "allergens": [],
                                    "label": null,
                                    "comment": null,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItem"
                                },
                                "recordStatus": "Active",
                                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                "lastModifiedUtcDateTime": null,
                                "createdBy": null,
                                "lastModifiedBy": null,
                                "typeName": "MenuCategoryItem"
                            }
                        ],
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategory"
                    }
                ],
                "menuCategoryItems": [],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            }
        ],
        "recordStatus": "Active",
        "createdUtcDateTime": "0001-01-01T00:00:00Z",
        "lastModifiedUtcDateTime": null,
        "createdBy": null,
        "lastModifiedBy": null,
        "typeName": "Menu"
    },
    {
        "id": 26,
        "name": "Test 4",
        "menuCategories": [
            {
                "id": 106,
                "name": "",
                "color": null,
                "description": "true",
                "imageUrl": null,
                "menuId": 26,
                "parentMenuCategoryId": null,
                "sortOrder": 0,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 285,
                        "menuCategoryId": 106,
                        "menuItemId": 151,
                        "name": "Sweet Potato Waffle Fries",
                        "description": "Sweet Potato Waffle Fries",
                        "imageUrl": null,
                        "calories": null,
                        "cost": 0.04,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 151,
                            "locationId": null,
                            "status": null,
                            "name": "Sweet Potato Waffle Fries",
                            "description": "Sweet Potato Waffle Fries",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": 0.04,
                            "serviceCharge": null,
                            "minimumQuantity": 2.0,
                            "defaultQuantity": 3.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            }
        ],
        "recordStatus": "Active",
        "createdUtcDateTime": "0001-01-01T00:00:00Z",
        "lastModifiedUtcDateTime": null,
        "createdBy": null,
        "lastModifiedBy": null,
        "typeName": "Menu"
    },
    {
        "id": 27,
        "name": "Test 5",
        "menuCategories": [
            {
                "id": 107,
                "name": "",
                "color": null,
                "description": "true",
                "imageUrl": null,
                "menuId": 27,
                "parentMenuCategoryId": null,
                "sortOrder": 0,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 286,
                        "menuCategoryId": 107,
                        "menuItemId": 150,
                        "name": "Bang Bang Broccoli",
                        "description": "Bang Bang Broccoli",
                        "imageUrl": null,
                        "calories": null,
                        "cost": 1.2,
                        "revenueTypeId": null,
                        "price": 4.95,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": true,
                        "menuItem": {
                            "id": 150,
                            "locationId": null,
                            "status": null,
                            "name": "Bang Bang Broccoli",
                            "description": "Bang Bang Broccoli",
                            "imageUrl": null,
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": null,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 4.95,
                            "cost": 1.2,
                            "serviceCharge": null,
                            "minimumQuantity": 2.0,
                            "defaultQuantity": 3.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            }
        ],
        "recordStatus": "Active",
        "createdUtcDateTime": "0001-01-01T00:00:00Z",
        "lastModifiedUtcDateTime": null,
        "createdBy": null,
        "lastModifiedBy": null,
        "typeName": "Menu"
    },
    {
        "id": 28,
        "name": "Test test test test 6",
        "menuCategories": [
            {
                "id": 109,
                "name": "",
                "color": null,
                "description": "true",
                "imageUrl": null,
                "menuId": 28,
                "parentMenuCategoryId": null,
                "sortOrder": 0,
                "menuCategories": [],
                "menuCategoryItems": [],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            }
        ],
        "recordStatus": "Active",
        "createdUtcDateTime": "0001-01-01T00:00:00Z",
        "lastModifiedUtcDateTime": null,
        "createdBy": null,
        "lastModifiedBy": null,
        "typeName": "Menu"
    },
    {
        "id": 30,
        "name": "Test test test test 8",
        "menuCategories": [
            {
                "id": 111,
                "name": "",
                "color": null,
                "description": "true",
                "imageUrl": null,
                "menuId": 30,
                "parentMenuCategoryId": null,
                "sortOrder": 0,
                "menuCategories": [],
                "menuCategoryItems": [
                    {
                        "id": 289,
                        "menuCategoryId": 111,
                        "menuItemId": 1,
                        "name": "Popcorn Shrimp",
                        "description": "<p>Fresh shrimp breaded and deep fried and perfectly sized for dipping in one of our signature dipping sauces. comes with choice of sauces</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F1%2Ffa0edaf7-497a-4529-9e44-541e71205ee3.jpg",
                        "calories": 120,
                        "cost": 4.22,
                        "revenueTypeId": null,
                        "price": 11.95,
                        "serviceCharge": 10.0,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 5401,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": false,
                        "menuItem": {
                            "id": 1,
                            "locationId": null,
                            "status": "0",
                            "name": "Popcorn Shrimp",
                            "description": "<p>Fresh shrimp breaded and deep fried and perfectly sized for dipping in one of our signature dipping sauces. comes with choice of sauces</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F1%2Ffa0edaf7-497a-4529-9e44-541e71205ee3.jpg",
                            "preparationTimeInSeconds": 5401,
                            "preparationArea": "Kitchen",
                            "storageArea": "Refrigerator",
                            "preparationNotes": null,
                            "calories": 120,
                            "serviceware": "Oval Tray",
                            "responsibleStaff": "Chef Michael, Chef Lisa",
                            "price": 11.95,
                            "cost": 4.22,
                            "serviceCharge": 10.0,
                            "minimumQuantity": 1.0,
                            "defaultQuantity": 1.0,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": "8 per order",
                            "customerSystemId": "1452",
                            "pluCode": "001",
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 215,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 217,
                                            "parentMenuItemModifierId": 215,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "undefined level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "dfgdfg",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 216,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 218,
                                            "parentMenuItemModifierId": 216,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "1234 level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "1234",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 297,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 298,
                                            "parentMenuItemModifierId": 297,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 299,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 301,
                                            "parentMenuItemModifierId": 299,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf Copy",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 300,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 302,
                                            "parentMenuItemModifierId": 300,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1 asdfasdf",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 312,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 1,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 313,
                                            "parentMenuItemModifierId": 312,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "asdfasdf level 1 asdfasdf",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "asdfasdf Copy 2",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "app",
                                        "normalizedName": "APP",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "seafood",
                                        "normalizedName": "SEAFOOD",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "popcorn",
                                        "normalizedName": "POPCORN",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "vegetarian",
                                        "normalizedName": "VEGETARIAN",
                                        "tagType": "General",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [
                                "app",
                                "seafood",
                                "popcorn",
                                "vegetarian"
                            ],
                            "allergens": [],
                            "label": "<p>test</p>",
                            "comment": "test\nfdsf\n\ndfs\nf\n\n\n\n\nfas\n\nf\nads\nf\ndas\nf\ndas\ndfas\nsd",
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 290,
                        "menuCategoryId": 111,
                        "menuItemId": 2,
                        "name": "Cheeseburger",
                        "description": "<p>A classic beef burger grilled to perfection.</p>",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F2%2F8cf470c1-f5f2-43aa-af34-16994509388f.jpg",
                        "calories": 650,
                        "cost": 5.49,
                        "revenueTypeId": null,
                        "price": 12.99,
                        "serviceCharge": null,
                        "sortOrder": 0,
                        "preparationTimeInSeconds": 420,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": false,
                        "menuItem": {
                            "id": 2,
                            "locationId": null,
                            "status": "0",
                            "name": "Cheeseburger",
                            "description": "<p>A classic beef burger grilled to perfection.</p>",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F2%2F8cf470c1-f5f2-43aa-af34-16994509388f.jpg",
                            "preparationTimeInSeconds": 420,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 650,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 12.99,
                            "cost": 5.49,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [
                                {
                                    "id": 143,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 2,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 145,
                                            "parentMenuItemModifierId": 143,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Rare",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 146,
                                            "parentMenuItemModifierId": 143,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Med-Rare",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 147,
                                            "parentMenuItemModifierId": 143,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Medium",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 148,
                                            "parentMenuItemModifierId": 143,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Med-Well",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 149,
                                            "parentMenuItemModifierId": 143,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Well",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Temperature",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Button",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                },
                                {
                                    "id": 144,
                                    "parentMenuItemModifierId": null,
                                    "menuItemId": 2,
                                    "menuItemModifierTemplateId": null,
                                    "options": [
                                        {
                                            "id": 150,
                                            "parentMenuItemModifierId": 144,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Fries",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": true,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 151,
                                            "parentMenuItemModifierId": 144,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Coleslaw",
                                            "price": null,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        },
                                        {
                                            "id": 152,
                                            "parentMenuItemModifierId": 144,
                                            "menuItemId": null,
                                            "menuItemModifierTemplateId": null,
                                            "options": [],
                                            "customerSystemId": null,
                                            "name": "Onion Rings",
                                            "price": 1.95,
                                            "revenueTypeId": null,
                                            "rule": "PickOne",
                                            "style": "Button",
                                            "minimumQuantity": null,
                                            "maximumQuantity": null,
                                            "default": false,
                                            "disabled": false,
                                            "sortOrder": 0,
                                            "recordStatus": "Active",
                                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                            "lastModifiedUtcDateTime": null,
                                            "createdBy": null,
                                            "lastModifiedBy": null,
                                            "typeName": "MenuItemModifier"
                                        }
                                    ],
                                    "customerSystemId": null,
                                    "name": "Sides",
                                    "price": null,
                                    "revenueTypeId": null,
                                    "rule": "PickOne",
                                    "style": "Dropdown",
                                    "minimumQuantity": null,
                                    "maximumQuantity": null,
                                    "default": false,
                                    "disabled": false,
                                    "sortOrder": 0,
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemModifier"
                                }
                            ],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "dairy",
                                        "normalizedName": "DAIRY",
                                        "tagType": "Allergen",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                },
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "gluten",
                                        "normalizedName": "GLUTEN",
                                        "tagType": "Allergen",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 291,
                        "menuCategoryId": 111,
                        "menuItemId": 3,
                        "name": "S'mores Brownie",
                        "description": "Very good!",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F3%2F21644997-6c98-4fae-85be-07dc21607ce5.jpg",
                        "calories": 175,
                        "cost": 0.75,
                        "revenueTypeId": null,
                        "price": 1.44,
                        "serviceCharge": null,
                        "sortOrder": 2,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": false,
                        "menuItem": {
                            "id": 3,
                            "locationId": null,
                            "status": "0",
                            "name": "S'mores Brownie",
                            "description": "Very good!",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F3%2F21644997-6c98-4fae-85be-07dc21607ce5.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 175,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 1.44,
                            "cost": 0.75,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [
                                {
                                    "id": 0,
                                    "menuItemId": 0,
                                    "itemTagId": 0,
                                    "tag": {
                                        "id": 0,
                                        "name": "dairy",
                                        "normalizedName": "DAIRY",
                                        "tagType": "Allergen",
                                        "recordStatus": "Active",
                                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                        "lastModifiedUtcDateTime": null,
                                        "createdBy": null,
                                        "lastModifiedBy": null,
                                        "typeName": "ItemTag"
                                    },
                                    "recordStatus": "Active",
                                    "createdUtcDateTime": "0001-01-01T00:00:00Z",
                                    "lastModifiedUtcDateTime": null,
                                    "createdBy": null,
                                    "lastModifiedBy": null,
                                    "typeName": "MenuItemTag"
                                }
                            ],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 292,
                        "menuCategoryId": 111,
                        "menuItemId": 10,
                        "name": "Coconut Shrimp",
                        "description": "Succulent shrimp dipped in batter and rolled in an aromatic blend of shredded coconut and Panko breadcrumbs and fried for an irresistible crunch in every bite.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F10%2Fba9d2df4-7fe9-44d9-86fa-659f357a5143.jpg",
                        "calories": 126,
                        "cost": 20.0,
                        "revenueTypeId": null,
                        "price": 40.0,
                        "serviceCharge": null,
                        "sortOrder": 2,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": false,
                        "menuItem": {
                            "id": 10,
                            "locationId": null,
                            "status": "0",
                            "name": "Coconut Shrimp",
                            "description": "Succulent shrimp dipped in batter and rolled in an aromatic blend of shredded coconut and Panko breadcrumbs and fried for an irresistible crunch in every bite.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F10%2Fba9d2df4-7fe9-44d9-86fa-659f357a5143.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 126,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 40.0,
                            "cost": 20.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    },
                    {
                        "id": 293,
                        "menuCategoryId": 111,
                        "menuItemId": 11,
                        "name": "Garlic Butter Shrimp Scampi",
                        "description": "Fresh, succulent shrimp on a bed of linguini and covered with a garlic buttery scampi sauce with a hint of white wine and lemon.",
                        "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F11%2F072f49a1-dfaf-40b4-a4d4-cbab5e196d5b.jpg",
                        "calories": 288,
                        "cost": 20.0,
                        "revenueTypeId": null,
                        "price": 40.0,
                        "serviceCharge": null,
                        "sortOrder": 2,
                        "preparationTimeInSeconds": 0,
                        "noTax": false,
                        "noServiceCharge": false,
                        "noGratuity": false,
                        "linkedWithMenuItem": false,
                        "menuItem": {
                            "id": 11,
                            "locationId": null,
                            "status": "0",
                            "name": "Garlic Butter Shrimp Scampi",
                            "description": "Fresh, succulent shrimp on a bed of linguini and covered with a garlic buttery scampi sauce with a hint of white wine and lemon.",
                            "imageUrl": "https://horizoncoverstagingsa.blob.core.windows.net/public/2a73da9d-3163-4eeb-cda8-08d77746ce6e%2FmenuItem%2F11%2F072f49a1-dfaf-40b4-a4d4-cbab5e196d5b.jpg",
                            "preparationTimeInSeconds": 0,
                            "preparationArea": null,
                            "storageArea": null,
                            "preparationNotes": null,
                            "calories": 288,
                            "serviceware": null,
                            "responsibleStaff": null,
                            "price": 40.0,
                            "cost": 20.0,
                            "serviceCharge": null,
                            "minimumQuantity": null,
                            "defaultQuantity": null,
                            "roundingRule": null,
                            "quantityLinkedToGuestCount": false,
                            "revenueTypeId": 1,
                            "notes": null,
                            "customerSystemId": null,
                            "pluCode": null,
                            "noTax": false,
                            "noServiceCharge": false,
                            "noGratuity": false,
                            "menuItemCombo": null,
                            "modifiers": [],
                            "itemTags": [],
                            "tags": [],
                            "allergens": [],
                            "label": null,
                            "comment": null,
                            "recordStatus": "Active",
                            "createdUtcDateTime": "0001-01-01T00:00:00Z",
                            "lastModifiedUtcDateTime": null,
                            "createdBy": null,
                            "lastModifiedBy": null,
                            "typeName": "MenuItem"
                        },
                        "recordStatus": "Active",
                        "createdUtcDateTime": "0001-01-01T00:00:00Z",
                        "lastModifiedUtcDateTime": null,
                        "createdBy": null,
                        "lastModifiedBy": null,
                        "typeName": "MenuCategoryItem"
                    }
                ],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            }
        ],
        "recordStatus": "Active",
        "createdUtcDateTime": "0001-01-01T00:00:00Z",
        "lastModifiedUtcDateTime": null,
        "createdBy": null,
        "lastModifiedBy": null,
        "typeName": "Menu"
    },
    {
        "id": 29,
        "name": "Test test test test 7",
        "menuCategories": [
            {
                "id": 110,
                "name": "",
                "color": null,
                "description": "true",
                "imageUrl": null,
                "menuId": 29,
                "parentMenuCategoryId": null,
                "sortOrder": 0,
                "menuCategories": [],
                "menuCategoryItems": [],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            }
        ],
        "recordStatus": "Active",
        "createdUtcDateTime": "0001-01-01T00:00:00Z",
        "lastModifiedUtcDateTime": null,
        "createdBy": null,
        "lastModifiedBy": null,
        "typeName": "Menu"
    },
    {
        "id": 16,
        "name": "CE",
        "menuCategories": [
            {
                "id": 82,
                "name": "",
                "color": null,
                "description": "true",
                "imageUrl": null,
                "menuId": 16,
                "parentMenuCategoryId": null,
                "sortOrder": 0,
                "menuCategories": [],
                "menuCategoryItems": [],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            },
            {
                "id": 108,
                "name": null,
                "color": null,
                "description": "true",
                "imageUrl": null,
                "menuId": 16,
                "parentMenuCategoryId": null,
                "sortOrder": 1,
                "menuCategories": [],
                "menuCategoryItems": [],
                "recordStatus": "Active",
                "createdUtcDateTime": "0001-01-01T00:00:00Z",
                "lastModifiedUtcDateTime": null,
                "createdBy": null,
                "lastModifiedBy": null,
                "typeName": "MenuCategory"
            }
        ],
        "recordStatus": "Active",
        "createdUtcDateTime": "0001-01-01T00:00:00Z",
        "lastModifiedUtcDateTime": null,
        "createdBy": null,
        "lastModifiedBy": null,
        "typeName": "Menu"
    }
]
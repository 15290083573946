import * as React from 'react';
// import {
//     Theme, withStyles, Typography
// } from '@mui/material';
// import GridList from '@mui/material/GridList';
// import GridListTile from '@mui/material/GridListTile';
// import EnhancedDealCard from "../../components/DealCard";
// import EnhancedFeatureCard from "../../components/FeatureCard";
// import { withRouter } from "react-router-dom";
// import { RouteComponentProps } from "react-router-dom";
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux';
// import * as AppMenuActionCreators from '../../actions/Menu.Action';
// import GlobalSearchBox from '../../components/search/GlobalSearchBox'
import IMenuItem from '../../models/IMenuItem';
import { Theme } from '@mui/material/styles';
import { Box, CircularProgress, Hidden, ImageList, Stack, Typography } from '@mui/material';
import { getMenuDetailsListStatic } from '../../store/actions/Menu.Action';
import { withStyles } from '@mui/styles';
import IMenuCategoryItem from '../../models/IMenuCategoryItem';
//import { IAppAction } from '../../actions/Helpers';
// import Hidden from '@mui/material/Hidden';
// import IMenuCategoryItem from 'src/models/IMenuCategoryItem';
// import IMenuCategory from 'src/models/IMenuCategory';
// import ScrollButtons from 'src/components/ScrollButtons';
// import CategoryRow from 'src/components/CategoryRow';
import EnhancedFeatureCard from "../../components/FeatureCard";
import EnhancedDealCard from "../../components/DealCard";
import ScrollButtons from '../../components/ScrollButtons';
import * as AppMenuActionCreators from '../../store/actions/Menu.Action';
import { openProductDetailPageModel, closeProductDetailPageModel } from "../../store/actions/App.Actions";
import CategoryRow from '../../components/CategoryRow';
import IMenuCategory from '../../models/IMenuCategory';
import { getMenuCategories } from '../../store/actions/Items.Actions';
import IOrderItem from '../../models/IOrderItem';
import { withRouter } from '../../utilities/withRouter';
const classNames = require('classnames');


const styles = (theme: Theme) => ({
    loading: {
        textAlign: 'center',
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    title: {
        padding: '5px',
        width: '100%',
        textAlign: 'left',
    },
    sectionText: {
        fontSize: 34,
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            fontSize: 27,
        },
    },
    subSectionText: {
        fontSize: 24,
        [theme.breakpoints.down('xs')]: {
            fontSize: 21,
        },
    },
    sectionContainer: {
        position: 'relative',
        width: '100%',
        display: 'inline-block',
    },
    sectionDivider: {
        height: 2,
        backgroundColor: '#cdcdcd',
        width: '100%',
        top: 25,
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            top: 20,
        },
    },
    sectionName: {
        paddingRight: 20,
        fontSize: '22px',
        fontWeight: 600,
    },
    sectionTitle: {
        "display": "flex",
        "flexDirection": "row",
        "justify-content": "space-between",
    },


    section: {
        fontSize: 34,
        backgroundColor: 'white',
        padding: '5px',
        width: '100%',
        textAlign: 'left',
    },
    container: {
        "background-color": "#fafbfc",
        minWidth: '95%',
        padding: 10,
    },
    alignNav: {
        fontSize: 18,
        fontWeight: 50,
        color: '#000000',
        textDecoration: 'none'
    },
    root: {
        'flex-shrink': 'unset',
        alignItems: 'center',
    },
    topBar: {
        "display": "flex",
        "flexDirection": "row",
        "justify-content": "space-between",
    },
    homeButton: {
        "margin": "10px",
        width: '50%',
        height: '100%',
    },
    searchBar: {
        "margin": "10px",
        width: '50%',
        height: '100%',
        borderRadius: '15px',

        flex: 1,
        backgroundColor: 'rgba(0,0,0,.1)',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        width: '80% !important'
    },
    iconButton: {
        padding: 10,
    },
    gridList: {
        scrollBehavior: 'smooth',
        flexWrap: 'nowrap',
        'overflowX': 'auto',
    },
    gridDealTileStyle: {
        cursor: 'pointer',
        position: 'relative',
        float: 'left',
        width: '390px',
        minHeight: '160px',
        minWidth: '300px',
        overflow: 'hidden',
        height: '100% !important',
        marginTop: "10px",
        /* Desktops and laptops ----------- */
        '@media only screen and (min-width : 1224px)': {
            minWidth: '400px',
            minHeight: '220px !important',
        }
    },
    gridFeatureTileStyle: {
        cursor: 'pointer',
        position: 'relative',
        float: 'left',
        width: '100%',
        minHeight: '233px',
        minWidth: '180px',
        overflow: 'hidden',
        marginTop: '10px',
        '@media only screen and (min-width : 1224px)': {
            minHeight: '310px !important',
            minWidth: '250px',
        }
    },
    leftScroolButton: {
        position: 'absolute',
        zIndex: '9999',
        top: '36%',
        width: '5%',
        height: '6%',
        left: '13%',
        marginLeft: '11px',
        '&:hover': {
            display: 'block !important',
            backgroundColor: 'red'
        }
    },
    rightScroolButton: {
        position: 'absolute',
        zIndex: '9999',
        top: '36%',
        width: '5%',
        height: '6%',
        left: '81%',
        marginLeft: '11px',
        '&:hover': {
            display: 'inline-block !important',
            backgroundColor: 'red'
        }
    },
    generalMenuCategoryItemsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginLeft: '8px',
        marginRight: '16px',
        /* iPads (portrait and landscape) ----------- */
        '@media only screen and (max-device-width : 1024px)': {
            justifyContent: 'space-evenly',
        },
    },
    scrollOffset: {
        scrollMarginTop: '82px'
    },
    emptyFeatureCard: {
        width: '380px',
        /* iPads (portrait and landscape) ----------- */
        '@media only screen and (min-width : 768px) and (max-device-width : 1180px)': {
            width: '350px',
        },
        '@media only screen and (min-width : 1024px) and (max-device-width : 1366px)': {
            width: '390px',
        },

        '@media only screen and (min-width : 912px) and (max-device-width : 1368px)': {
            width: '380px',
        },
    },
});

interface IDashboardProps {
    classes?: any;
    theme?: any;
    children?: any;
    router?: any;
    menuCategories?: IMenuCategory[],
    selectedCategoryMenuProduct: (data: any) => any//IAppAction;
    getMenuDetailsList?: any;
    searchValue: string;
    menuItemById: (id: string) => any;
    openProductDetailModel: () => void;
    closeProductDetailModel: () => void;
    getMenuDetailsListStatic: () => void;
    getMenuCategoriesData: (id: number) => void;
    organizationID: number | null;
    selectedMenuId: number | null;
    menuCategoriesLoading: boolean;
    menus?: any;
    orderAddItem: (orderItem: IOrderItem) => Promise<any>;
}

interface IPageState {
    title: string;
    searchValue: string;
}

class HomePage extends React.Component<IDashboardProps, IPageState>  {
    constructor(props: any) {
        super(props);
        this.state = {
            title: "Welcome",
            searchValue: this.props.searchValue,
        }
        this.props.getMenuDetailsListStatic();
    }

    public componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getMenuCategoriesData(this.props.selectedMenuId || -1)
    }

    public componentDidUpdate(prevProps: Readonly<IDashboardProps>, prevState: Readonly<IPageState>, snapshot?: any): void {
        if((prevProps.selectedMenuId !== this.props.selectedMenuId) && typeof this.props.selectedMenuId === "number"){
            this.props.getMenuCategoriesData(this.props.selectedMenuId)
        }
        if (prevProps.menuCategories !== this.props.menuCategories) {
            this.searchAndHandleProduct();
          }
    }

    private searchAndHandleProduct = () => {
        const { router } = this.props
        const { menuCategories } = this.props;
        const searchParams = new URLSearchParams(router.location.search);
        const id = searchParams.get('productId');
        const menuid = searchParams.get('menuId');

        if (menuid) {
            const element = document.getElementById(menuid);
            const containerElement = document.getElementById('main');
            if (element) {
            containerElement?.scrollTo({top: element.offsetTop - 90, behavior: 'smooth' })
            searchParams.delete('menuId');
            searchParams.delete('id');
            searchParams.delete('productId');
            router.navigate(`${router.location.pathname}?${searchParams.toString()}`);
            }
          }
          if (id) {
            const element = document.getElementById(id);
            const containerElement = document.getElementById('main');
            if (element) {
            containerElement?.scrollTo({top: element.offsetTop - 115, behavior: 'smooth' })
            searchParams.delete('menuId');
            searchParams.delete('id');
            searchParams.delete('productId');
            router.navigate(`${router.location.pathname}?${searchParams.toString()}`);
            }
          }
    
        if (id && menuCategories) {
          menuCategories.forEach(category => {
            category?.menuCategoryItems?.forEach((item: IMenuCategoryItem) => {
              if (item.id === parseInt(id)) {
                this.handleProduct(item);
              }
            });
          });
        }
      }

    public handleProduct = async (payload: any) => {
        const item = {
            id: payload.id,
            menuItem: { ...payload, modifiers: [...payload?.menuItemModifiers] },
            name: payload?.name,
            price: payload?.price,
            description: payload?.description,
            calories: payload?.calories,
            imageUrl: payload?.imageUrl
        } 
        this.props?.selectedCategoryMenuProduct(item);
        this.props?.openProductDetailModel();
    }

    public handleChange = (event: any) => {
        this.setState({ searchValue: event.target.value });
    }

    public render(): JSX.Element {
        const {
            classes,
            menuCategories,
            menus
        } = this.props;

        const renderMenuCategory = (menuCategory: any, firstCategory: boolean = false, menuCategoryIndex:number) => {
            return <div key={menuCategory.id +'key'+menuCategoryIndex} id={`scroll${menuCategory.id}`} className={classNames(classes?.alignNav, classes?.scrollOffset)}>
                <Typography component="div" className={classes?.alignNav} >
                    <div className={classes?.title} >
                        <Typography component="div" id={menuCategory.id} sx={{fontSize: {sm: '22px'}, fontWeight: 600}} className={menuCategory.parentMenuCategoryId ? classes?.subSectionText : classes?.sectionText}>
                            <span className={classes?.sectionContainer}>
                                <span className={classes?.sectionName}>{menuCategory.name}</span>
                                {!firstCategory && !menuCategory.parentMenuCategoryId}
                            </span>
                            {firstCategory && <ScrollButtons moduleType={`category${menuCategory.id}`} />}
                        </Typography>
                        {firstCategory &&
                            <Stack direction="row" gap={2} pb={2} sx={{ overflowX: "hidden" }}>
                                {menuCategory.menuCategoryItems.map((menuCategoryItem: IMenuCategoryItem, index:number) => (
                                    <ImageList className={classNames(classes?.gridDealTileStyle, classes?.root)}
                                        key={menuCategoryItem.id +'key' + index}>
                                        <EnhancedDealCard menuCategoryItem={menuCategoryItem}
                                            onClick={() => { this.handleProduct(menuCategoryItem) }} />
                                    </ImageList>
                                ))}
                            </Stack>
                        }
                        {!firstCategory && <div className={classes?.generalMenuCategoryItemsContainer}>
                            {menuCategory.menuCategoryItems?.map((menuCategoryItem: IMenuCategoryItem, index:number) => (
                                <EnhancedFeatureCard
                                    id={menuCategoryItem?.id}
                                    key={menuCategoryItem.id +'key'+index}
                                    menuCategoryItem={menuCategoryItem}
                                    onClick={() => { this.handleProduct(menuCategoryItem) }}
                                />
                            ))}
                            <div className={classes?.emptyFeatureCard} />
                            <div className={classes?.emptyFeatureCard} />
                            <div className={classes?.emptyFeatureCard} />
                            <div className={classes?.emptyFeatureCard} />
                            <div className={classes?.emptyFeatureCard} />
                            <div className={classes?.emptyFeatureCard} />
                            <div className={classes?.emptyFeatureCard} />
                            <div className={classes?.emptyFeatureCard} />
                            <div className={classes?.emptyFeatureCard} />
                            <div className={classes?.emptyFeatureCard} />
                            <div className={classes?.emptyFeatureCard} />
                        </div>}
                        {menuCategory.menuCategoryItems?.length===0 && <Typography align='center' component="span" variant="body1" color="textPrimary">No Items Found!</Typography>}
                    </div>
                </Typography >
                {/* {!!menuCategory.menuCategories &&
                    !!menuCategory.menuCategories.length ?
                    menuCategory.menuCategories.map((subMenuCategory: { menuCategoryItems: string | any[]; }) => {
                        return subMenuCategory.menuCategoryItems.length
                            ? renderMenuCategory(subMenuCategory)
                            : null;
                    }) : <Typography align='center' component="span" variant="body1" color="textPrimary">No Items Found!</Typography>
                } */}
            </div>
        }

        if(menus?.length===0){
            return <div className={classes?.container} >
                    <div className={classes?.loading}>
                    <p>There are no menus assigned for this organization.</p>
                </div>
            </div>
        }

        return (
            <div className={classes?.container} >
                {!menuCategories ? <div className={classes.loading} style={{alignItems: 'center'}}><CircularProgress color='info' /></div>  : menuCategories?.length===0 ?
                    <div className={classes?.loading}>
                        <p>There are no categories assigned for you in this menu.</p>
                    </div> :
                    <div>
                        <div className={classes?.topBar}>
                            <Hidden only={['lg', 'md', 'xl']}>
                                {/* <GlobalSearchBox searchComponentType={'homeSearchMenuAndIteam'} /> */}
                            </Hidden>
                        </div>
                        <Box sx={{marginTop: {xs: 2, sm: 0}}}>
                            <div className={classes?.alignNav}>
                                <div className={classes?.title} >
                                    <Typography sx={{ fontSize: { xs: '22px' }, fontWeight: 600, marginTop: { xs: 4, sm: 2 } }} pb={1}>
                                        Our Menu
                                        <ScrollButtons moduleType="containerCategory" />
                                    </Typography>
                                    <CategoryRow menuCategories={menuCategories}
                                    />
                                </div>
                            </div>
                        </Box>
                        {/* {menuCategories
                            .map((menuCategory: any, menuCategoryIndex: number) => {
                                const firstCategory = menuCategoryIndex === 0;

                                return renderMenuCategory(menuCategory, firstCategory);
                            })
                        } */}
                          {menuCategories?.map((menuCategory: any, menuCategoryIndex: number) => {
                                return renderMenuCategory(menuCategory, false, menuCategoryIndex);
                            })
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    searchValue: state.menu?.homeSearchValue,
    menuCategories: state.items?.menuCategories,
    menus: state.items?.menus,
    organizationID: state?.organizations?.organizationID,
    selectedMenuId: state?.items?.selectedMenuId,
});
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        selectedCategoryMenuProduct: AppMenuActionCreators.selectedCategoryMenuProduct,
        openProductDetailModel: openProductDetailPageModel,
        closeProductDetailModel: closeProductDetailPageModel,
        getMenuDetailsListStatic: getMenuDetailsListStatic,
        getMenuCategoriesData: getMenuCategories,
    }, dispatch);
}
const HomePageView = withStyles(styles as any)(HomePage);
export default withRouter(connect(mapStateToProps, mapDispatchtoProps)(HomePageView as any));
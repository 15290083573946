import { connect } from 'react-redux';
import { AppState } from '../../state/AppState';
import { Alert, Box, Stack, Typography } from '@mui/material';

const Splash = (props: {companyLogo: any, errorGettingOrganization?: boolean}) => {
    const {companyLogo, errorGettingOrganization} = props;
    return <Stack alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100vh'}>
        {/* <Box p={2} borderRadius={1}>
            <img src={companyLogo} alt='' width={100} />
        </Box> */}
        {errorGettingOrganization ? 
            <Alert severity="error" sx={{mt: 2}}>
                ERROR: Unable to get Organization! Please verify the organization domain name.
            </Alert>
        : <Typography variant='subtitle1' mt={1}>Loading ...</Typography>}
    </Stack>
}

const mapStateToProps = (state: AppState) => ({
    // companyLogo: !!state.settings.SettingsKeyValue.GRAZE_COMPANY_LOGO ? state.settings.SettingsKeyValue.GRAZE_COMPANY_LOGO.value : '',
    companyLogo: state?.organizations?.organizationSettings?.grazeLogo || "/static/images/logos/defaultLogo.svg",
    errorGettingOrganization: state?.organizations?.errorGettingOrganization
});

export default connect(mapStateToProps, null)(Splash);
import * as React from 'react';
import {
    Theme,
    createStyles,
} from '@mui/material';
import { Place } from '@mui/icons-material';
import { WithStyles, withStyles } from "@mui/styles";
import GoogleMapReact from 'google-map-react';

const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";

const Marker: any = () =>
    <Place
        id="marker"
        style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            color: 'red',
        }}
    />

const styles = (theme: Theme) => createStyles({
});

interface IProps {
    lat: number;
    lng: number;
}

interface IState {
    hasError: boolean;
}

class Map extends React.Component<WithStyles<typeof styles> & IProps, IState> {

    public state: IState = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): IState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public render() {
        const { lat, lng } = this.props;
        if (!lat || !lng) {
            return null;
        }


        return (
            <div style={{ height: '100%', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: googleApiKey }}
                    defaultCenter={{ lat, lng }}
                    defaultZoom={11}
                >
                    {!this.state.hasError ?
                        <Marker
                            lat={lat}
                            lng={lng}
                        /> : <Marker />}
                </GoogleMapReact>
            </div>
        );
    }
}

export default withStyles(styles)(Map);
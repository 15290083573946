import { ActionType } from '../actions/Helpers';
import IMenu from '../../models/IMenu';
import IMenuCategory from '../../models/IMenuCategory';
import IMenuCategoryItem from '../../models/IMenuCategoryItem';
import IOrderItem from '../../models/IOrderItem';
import IMenuItemModifier from '../../models/IMenuItemModifier';
import { MenuList } from '../../data/OnlineOrder';

export interface IState {
    onlineMenus: IMenu[],
    onlineMenu: IMenu,
    selectedMenuId: number,
    onlineMenuList: Array<{ id: number, name: string }>,
    allOnlineMenuCategoryItems: IMenuCategoryItem[],
    selectedMenuData: any,
    selectedMenuCategoryItem: IMenuCategoryItem,
    allSelectedItemModifiers: { [id: number]: IMenuItemModifier },
    orderItem: IOrderItem | null,
    searchValue: string,
    menuCategories: IMenuCategory[],
};

const initialState: IState = {
    onlineMenus: [] as IMenu[],
    onlineMenu: {} as IMenu,
    selectedMenuId: 0,
    onlineMenuList: [],
    allOnlineMenuCategoryItems: [] as IMenuCategoryItem[],
    selectedMenuData: Object,
    selectedMenuCategoryItem: {} as IMenuCategoryItem,
    allSelectedItemModifiers: {} as { [id: number]: IMenuItemModifier },
    orderItem: null,
    searchValue: '',
    menuCategories: [],
};

const processMenuItems = (menuCategories: IMenuCategory[] = [], allOnlineMenuCategoryItems: IMenuCategoryItem[] = []): IMenuCategoryItem[] => {
    if (allOnlineMenuCategoryItems === null) {
        allOnlineMenuCategoryItems = [];
    }
    if (menuCategories) {
        sortItems(menuCategories);

        menuCategories.forEach(menuCategory => {
            sortItems(menuCategory?.menuCategoryItems);
            menuCategory?.menuCategoryItems?.forEach(item => {
                allOnlineMenuCategoryItems.push(item);
            });
            processMenuItems(menuCategory?.menuCategories, allOnlineMenuCategoryItems);
        })
    }
    return allOnlineMenuCategoryItems;
};

const setMenuItems = (onlineMenu: IMenu) => {
    const allOnlineMenuItems = processMenuItems(onlineMenu?.menuCategories);

    return {
        onlineMenu,
        selectedMenuId: onlineMenu ? onlineMenu.id : 0,
        allOnlineMenuItems,
        menuCategories: sortItems(onlineMenu.menuCategories
            ?.filter(menuCategory => {
                if (menuCategory) {
                    return (menuCategory?.menuCategoryItems?.length && menuCategory?.menuCategoryItems?.length > 0 || menuCategory?.menuCategories?.length && menuCategory?.menuCategories?.length > 0);
                }
            })),
    }
}

const sortItems = (menuCategories: IMenuCategory[] | IMenuCategoryItem[] = []) => {
    return menuCategories
        ?.sort((a: any, b: any) => {
            if (a.sortOrder && b.sortOrder)
                return a.sortOrder < b.sortOrder ? -1 : 1;
            else
                return 0
        });
};

const menuReducer = (state: IState = initialState, action: any) => {
    switch (action.type) {
        case ActionType.GET_MENU_LIST:
            const onlineMenusList: any = action.payload as IMenu[];

            const onlineMenuListMap = onlineMenusList?.map((menu: IMenu) => {
                return {
                    id: menu.id,
                    name: menu.name,
                };
            })

            return {
                ...state,
                ...setMenuItems(onlineMenusList ? onlineMenusList[0] : null),
                onlineMenus: onlineMenusList,
                onlineMenuList: onlineMenuListMap,
            };

        case ActionType.SET_SELECTED_MENU:
            const selectedMenuId = action.payload as number;
            const onlineMenu = state.onlineMenus
                ? state.onlineMenus.find(menu => menu.id === selectedMenuId)
                : null

            console.warn(onlineMenu?.menuCategories);

            return {
                ...state,
                ...setMenuItems(onlineMenu as any),
                menuCategories: sortItems(onlineMenu?.menuCategories),
                selectedMenuId,
            }

        case ActionType.UPDATE_SELECTED_CATEGORY_MENU_ITEMS:
            return {
                ...state, selectedMenuData: action.payload, selectedMenuDataBackUp: action.payload
            }
        case ActionType.SELECTED_CATEGORY_MENU_PRODUCT:
            const { menuCategoryItem, orderItem } = action.payload;
            const allSelectedItemModifiers = {} as { [id: number]: IMenuItemModifier };
            (() => {
                const addModifireOptions = (options: IMenuItemModifier[]) => {
                    if (options) {
                        options.forEach((option: any) => {
                            allSelectedItemModifiers[option.id] = option;
                            addModifireOptions(option.options);
                        });
                    }
                }
                const detailsValue: any = orderItem ? (orderItem as IOrderItem)?.menuCategoryItem?.menuItem?.modifiers : (menuCategoryItem as IMenuCategoryItem)?.menuItem?.modifiers;
                addModifireOptions(detailsValue);
            })();

            return {
                ...state,
                selectedMenuCategoryItem: menuCategoryItem,
                allSelectedItemModifiers,
                orderItem,
            }

        case ActionType.SET_SEARCH_VALUE:
            return { ...state, searchValue: action.payload }

        default:
            const onlineMenus = [...MenuList];
            const onlineMenuList = onlineMenus.map((menu: IMenu) => {
                return {
                    id: menu.id,
                    name: menu.name,
                };
            })

            return {
                ...state,
                ...setMenuItems(onlineMenus ? onlineMenus[0] : null),
                onlineMenus,
                onlineMenuList,
            };
            return state
    }
};

export default menuReducer;
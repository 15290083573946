function getCurrentDomain() {
    const fullHostname = window.location.hostname;
    const parts = fullHostname.split('.');
    if (parts.length > 2) {
        return parts[1];
    } else {
        return '';
    }
}

function getCurrentSubdomain() {
    const fullHostname = window.location.hostname;
    const parts = fullHostname.split('.');
    if (parts.length > 2) {
        return parts[0];
    } else {
        return '';
    }
}

export function getDomainName() {
    const urlParams = new URLSearchParams(window.location.search);
    const domainURL = urlParams.get('domainURL');
    if(getCurrentDomain()==="usegraze"){
        return getCurrentSubdomain()
    }
    if(domainURL && domainURL!==""){
        return domainURL
    }
    return process.env.REACT_APP_DOMAIN_URL
}
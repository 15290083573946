import * as React from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import IOrderListEntry from '../../models/IOrderListEntry';
import ImgPlaceholder from '../../components/ImgPlaceholder';
import { formatMoney } from '../../pipes/PriceFormatter';
import ScrollButtons from '../../components/ScrollButtons';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { addItem } from '../../store/actions/Order.Actions';
import { Info as InfoIcon } from '@mui/icons-material';
import { WithStyles, withStyles } from "@mui/styles";
import { withRouter } from '../../utilities/withRouter';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const sliderRef = React.createRef<any>();
let mouseDown: boolean = false;
let startX: number;
let scrollLeft: number;

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        margin: '40px 10%',
        overflow: 'scroll',
        scrollBehavior: 'smooth',
        [theme.breakpoints.down('sm')]: {
            // margin: '40px auto',
            // maxWidth: 270,
        },
    },
    buttons: {
        margin: '0 5%',
    },
    order: {
        minWidth: 250,
        maxWidth: 250,
        margin: 10,
        'overflowY': 'auto',
        '& ::-webkit-scrollbar': {
            width: '0.4em'
        },
        '& ::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '& ::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
        },
    },
    orderTotal: {
        margin: '0 5px',
    },
    orderContent: {
        marginTop: 15,
        height: 350,
        overflow: 'scroll',
        padding: '0 5px',
    },
    orderItem: {
        display: 'flex',
        margin: '15px 0',
        backgroundColor: '#fff',
        borderRadius: 5,
        border: `solid 1px ${theme.palette.primary.main}`,
        '-webkit-box-shadow': '14px 16px 50px 14px rgba(243 243 246)',
        boxShadow: '14px 16px 50px 14px rgba(243 243 246)',
    },
    orderItemName: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap:'wrap',
        margin: '10px 0',
    },
    orderItemQuantity: {
        color: '#aaa',
    },
    orderItemInfo: {
        minWidth: 148,
        maxWidth: 148,
        margin: '0 7px',
    },
    image: {
        height: 75,
        width: 65,
        borderRadius: 5,
    },
    orderItemBottom: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 5,
    },
    reorderButton: {
        fontSize: 14,
        height: 32,
        width: '100%',
        paddingTop: 7,
    },
    unavailableForReorder: {
        color: theme.palette.error.main,
        fontSize: 12,
        marginTop: 5,
        marginBottom: -5,
    },
    infoIcon: {
        fontSize: 15,
        verticalAlign: 'bottom',
    },
});

type Props = WithStyles<typeof styles> & {
    // orderGetList: () => Promise<IOrderListEntry[]>;
    addItem: any;
    router: any,
    customerOrders:any,
}

interface IState {
    customerOrders: any[],
    showScrollButton: boolean,
}

const initialState: IState = {
    customerOrders: [] as any[],
    showScrollButton: false,
}

class OrderHistoryComp extends React.Component<Props, IState> {
    public state: IState = initialState;

    public startDragging = (event: any) => {
        mouseDown = true;
        startX = event.pageX - sliderRef.current.offsetLeft;
        scrollLeft = sliderRef.current.scrollLeft;
    }

    public stopDragging = (event: any) => {
        mouseDown = false;
    }

    public dragElement = (event: any) => {
        event.preventDefault();

        if(!mouseDown) {
            return;
        }

        const x = event.pageX - sliderRef.current.offsetLeft;
        const scroll = x - startX;
        sliderRef.current.scrollLeft = scrollLeft - scroll;
    }

    public async componentDidMount() {
        //const customerOrders = await this.props.orderGetList();
        
        this.setState({
            customerOrders:this.props.customerOrders,
            showScrollButton: true,
        });

        sliderRef.current.addEventListener('mousedown', this.startDragging, false);
        sliderRef.current.addEventListener('mouseup', this.stopDragging, false);
        sliderRef.current.addEventListener('mouseleave', this.stopDragging, false);
        sliderRef.current.addEventListener('mousemove', this.dragElement, false);
    }

    public componentWillUnmount() {
        sliderRef.current.removeEventListener('mousedown', this.startDragging, false);
        sliderRef.current.removeEventListener('mouseup', this.stopDragging, false);
        sliderRef.current.removeEventListener('mouseleave', this.stopDragging, false);
        sliderRef.current.removeEventListener('mousemove', this.dragElement, false);
    }

    public render(): JSX.Element {
        const { classes } = this.props;
        const { customerOrders, showScrollButton } = this.state;

        return (
            <div>
                {showScrollButton &&
                    <div className={classes.buttons}>
                        <ScrollButtons moduleType={'slider'}/>
                    </div>
                }

                <div id="slider" className={classes.root} ref={sliderRef}>
                    {!!customerOrders && !!customerOrders.length
                    ? customerOrders.map(order => {
                        // const canReorder = !!order?.orderItems?.find((item: any) => item.imageUrl);

                        return (
                            <div key={`order-id-${order.id}`} className={classes.order}>
                                <div className={classes.orderTotal}>
                                    <Typography sx={{ fontSize: { xs: '20px' } }}>{moment(order?.requestedDateTime).format("MM/DD/YYYY")} - ${formatMoney(order.total)}</Typography>
                                </div>
                                <Button
                                    // className={classes.reorderButton}
                                    variant="outlined"
                                    color="secondary"
                                    // disabled={!canReorder}
                                    onClick={async () => {
                                        for(var i =0; i< order?.orderItems?.length; i++){
                                            this.props.addItem({
                                                description: order?.orderItems[i].description,
                                                id: order?.orderItems[i].id,
                                                menuCategoryItemId: order?.orderItems[i].menuCategoryItemId,
                                                menuItemId: order?.orderItems[i].menuItemId,
                                                name: order?.orderItems[i].name,
                                                notes: order?.orderItems[i].notes,
                                                orderItemModifiers: order?.orderItems[i].orderItemModifiers,
                                                price: order?.orderItems[i].unitPrice,
                                                quantity: order?.orderItems[i].quantity,
                                                revenueTypeId: order?.orderItems[i].revenueTypeId,
                                                menuCategoryItem: {
                                                    description: order?.orderItems[i].description,
                                                    id: order?.orderItems[i].menuCategoryItemId,
                                                    imageUrl: order?.orderItems[i].imageUrl,
                                                    name: order?.orderItems[i].name,
                                                    price: order?.orderItems[i].unitPrice,
                                                    menuItem: {...order?.orderItems[i], modifiers: []}
                                                }
                                             })

                                        }
                                        this.props.router.navigate("/proceed-to-checkout")
                                    }}
                                    sx={{ lineHeight: "initial", letterSpacing: "initial", fontSize: { xs: "14px" }, width: "100%", paddingTop: 1.1}}
                                >
                                    Reorder
                                </Button>
                                <div className={classes.orderContent}>
                                    {order?.orderItems?.map((item: any, index: number) => (
                                        <div key={`order-item-${index}`} className={classes.orderItem}>
                                            <ImgPlaceholder
                                                className={classes.image}
                                                name={item.name}
                                                imageUrl={item.imageUrl}
                                                reorderVersion={true}
                                            />
                                            <div className={classes.orderItemInfo}>
                                                {/* {!item.imageUrl &&
                                                    <div className={classes.unavailableForReorder}>
                                                        <InfoIcon fontSize='small' className={classes.infoIcon} />
                                                        Unavailable for reorder
                                                    </div>
                                                } */}
                                                <Typography sx={{ fontSize: {xs: "14px"}}}   className={classes.orderItemName}>
                                                    {item.name} <span><b>${formatMoney(item?.totalPrice)}</b></span>
                                                </Typography>
                                                <Typography sx={{ fontSize: { xs: "14px" } }} className={classes.orderItemQuantity}>x {item.quantity}</Typography>
                                            </div>
                                        </div>
                                    ))}
                                    <div>
                                        <Typography sx={{ fontSize: { xs: "20px" } }} className={classes.orderItemBottom}>
                                            <span><b>Subtotal</b></span> <span>${formatMoney(order.subTotal)}</span>
                                        </Typography>
                                        <Typography sx={{ fontSize: { xs: "20px" } }} className={classes.orderItemBottom}>
                                            <span><b>Tax</b></span> <span>${formatMoney(order.taxTotal)}</span>
                                        </Typography>
                                        <Typography sx={{ fontSize: { xs: "20px" } }} className={classes.orderItemBottom}>
                                            <span><b>Total</b></span> <span>${formatMoney(order.total)}</span>
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        )})
                    : null}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
            addItem,
        }, dispatch);
}

export default withStyles(styles)(withRouter((connect(null, mapDispatchToProps)(OrderHistoryComp)) as React.ComponentType<any>));
import { IAppAction, ActionType } from './Helpers';
import { Utility } from '../../state/Utility';
import { ConfirmBox } from '../../state/ConfirmBox';
import { Spinner } from '../../state/Spinner';
import { Dispatch } from "redux";
import { AppState } from "../../state/AppState";
import { authorize } from '../../services/ApiVariables';
import { Alert } from '../../state/Alert';
import { UIMatch } from 'react-router-dom';

export interface IApplicationProps {
    openDrawer: () => IAppAction;
    closeDrawer: () => IAppAction;
    showPopup: (alert: ConfirmBox) => IAppAction;
    closePopup: () => IAppAction;
    showSpinner: (message?: string) => IAppAction;
    hideSpinner: () => IAppAction;
    createUser: (content: any) => any;
    getUser: (id: any) => any;
    updateUser: (context: any) => any;
    deleteUser: (context: any) => any;
    createMaterial: (content: any) => any;
    getMaterial: (id: any) => any;
    updateMaterial: (context: any) => any;
    deleteMaterial: (context: any) => any;
    getMail: (id: any) => any;
    updateMail: (context: any) => any;
    deleteMail: (context: any) => any;
    match: UIMatch<any>,
    location: any,
    history: any,
    utility: Utility;
    materials: any;
    mail: any[];
    materialCharts: Array<{ name: string, value: number, fill: string }>;
    addCartPopWindowClose: () => IAppAction;
    handleCloseSignInModel: () => IAppAction;
    handleClickOpenSignInModel: () => IAppAction;
    handleRegisterCustomer: (body: any, organizationId: string) => IAppAction;
    showAlertMessage: (message: string, alertType: string) => IAppAction;
    hideAlertMessage: () => IAppAction;
    handleCloseSignUpModel: () => IAppAction;
    handleOpenSignUpModel: () => IAppAction;
    openProductDetailPageModel: () => IAppAction;
    closeProductDetailPageModel: () => IAppAction;
}

export const showAlertMessage = (message: string, alertType: string): IAppAction => {
    return {
        type: ActionType.OPEN_ALERT,
        payload: new Alert({ message, alertType })
    };
};

export const hideAlertMessage = (): IAppAction => {
    return {
        type: ActionType.CLOSE_ALERT
    };
};

export const openDrawer = (): IAppAction => {
    return {
        type: ActionType.OPEN_DRAWER
    };
};

export const closeDrawer = (): IAppAction => {
    return {
        type: ActionType.CLOSE_DRAWER
    };
};

export const showPopup = (data: ConfirmBox): IAppAction => {
    return {
        type: ActionType.OPEN_CONFIRM_BOX,
        payload: data
    };
};

export const closePopup = (): IAppAction => {
    return {
        type: ActionType.CLOSE_CONFIRM_BOX
    };
};

export const showSpinner = (message: string): IAppAction => {
    return {
        type: ActionType.OPEN_SPINNER,
        payload: new Spinner({ message })
    };
};

export const hideSpinner = (): IAppAction => {
    return {
        type: ActionType.CLOSE_SPINNER
    };
};

export const searchProductFromList = (givenString: any): IAppAction => {
    return {
        type: ActionType.SEARCH_PRODUCT_LIST,
        payload: givenString
    }
};

export const addCartPopWindowOpen = (): IAppAction => {
    return {
        type: ActionType.ADD_CART_POP_WINDOW_OPEN,
    }
};

export const addCartPopWindowClose = (): IAppAction => {
    return {
        type: ActionType.ADD_CART_POP_WINDOW_CLOSE,
    }
};

export const handleRegisterCustomer = (body: any, organizationId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });

    return api({ ...authorize.registerCustomer(organizationId), body })
        .then((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            dispatch({
                type: ActionType.OPEN_ALERT,
                payload: new Alert({ message: 'You have successfully registered and logged in.', alertType: 'success' }),
            });
            return response;
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            return response;
        });
};

export const handleCloseSignInModel = (): IAppAction => {
    return {
        type: ActionType.CLOSE_SIGN_IN_MODEL
    };
};

export const handleClickOpenSignInModel = (): IAppAction => {
    return {
        type: ActionType.OPEN_SIGN_IN_MODEL
    };
};

export const handleOpenSignUpModel = (): IAppAction => {

    return {
        type: ActionType.OPEN_SIGN_UP_MODEL
    };
};

export const handleCloseSignUpModel = (): IAppAction => {
    return {
        type: ActionType.CLOSE_SIGN_UP_MODEL
    };
};

export const openProductDetailPageModel = (): IAppAction => {

    return {
        type: ActionType.OPEN_PRODUCT_DETAIL_PAGE_MODEL
    };
};

export const closeProductDetailPageModel = (): IAppAction => {
    return {
        type: ActionType.CLOSE_PRODUCT_DETAIL_PAGE_MODEL
    };
};

export const storeAuth0User = (payload: any): IAppAction => {
    return {
        type: ActionType.GET_AUTH0_USER_DETAILS_SUCCESS,
        payload: payload
    };
};
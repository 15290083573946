import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import debounce from 'lodash/debounce';

export default function GlobalSearch({ onSearchChange }: { onSearchChange: (value: string) => void }) {

  const [searchText, setSearchText] = useState<string>(""); // State to hold search text
  const [showClearIcon, setShowClearIcon] = useState<1 | 0>(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const text = event.target.value;
    setSearchText(text);
    setShowClearIcon(text === "" ? 0 : 1);
  };

  const handleClick = (): void => {
    setSearchText("");
    onSearchChange("");
    setShowClearIcon(0)
  };

  useEffect(() => {
    const debouncedSearchChange = debounce((value: string) => {
      onSearchChange(value);
    }, 500); 

    debouncedSearchChange(searchText);

    return () => {
      debouncedSearchChange.cancel();
    };
  }, [searchText, onSearchChange]);

  return (
    
        <TextField
          size="small"
          variant="outlined"
          value={searchText} 
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{width:{xs:'20px',sm:'25px',md:'30px',lg:'35px'}}} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ opacity: showClearIcon }}
                onClick={handleClick}
              >
                <ClearIcon sx={{width:{xs:'20px',sm:'25px',md:'30px',lg:'35px'}}} />
              </InputAdornment>
            ),
            sx: {
              width: {xs: '370px', md: '100%'},
              borderRadius: '50px',
              height: '40px',
            }
          }}
          placeholder="Search"
        />
  );
};

import * as React from 'react';
// import { withRouter } from "react-router-dom";
// import { RouteComponentProps } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
// import { Theme, withStyles, Typography, Hidden } from '@mui/material';
// import EnhancedFeatureCard from "../../components/FeatureCard";
// import GridList from '@mui/material/GridList';
// import GridListTile from '@mui/material/GridListTile';
// import * as AppMenuActionCreators from '../../actions/Menu.Action';
import { Utility } from '../../state/Utility';
import { Theme, } from '@mui/material/styles';
import { CircularProgress, Typography } from '@mui/material';
// import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import IMenuCategoryItem from '../../models/IMenuCategoryItem';
import { searchName } from '../../services/HelperFunctions';
import EnhancedFeatureCard from "../../components/FeatureCard";

const classNames = require('classnames');
// import { openProductDetailPageModel, closeProductDetailPageModel } from "../../actions/App.Actions";
// import GlobalSearchBox from '../../components/search/GlobalSearchBox'
// import IMenuCategoryItem from 'src/models/IMenuCategoryItem';
// import { searchName } from 'src/services/HelperFunctions';

const styles = (theme: Theme) => ({
    container: {
        "background-color": "#f3f3f6",
        '@media only screen and (min-width : 1224px)': {
            'padding-left': '200px',
            'padding-right': '200px',
            'padding-bottom': '30px',
        }
    },
    title: {
        backgroundColor: 'white',
        padding: '5px',
        margin: '5px',
        width: '100%',
        textAlign: 'center',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        width: '80% !important'
    },
    gridFeatureTileStyle: {
        position: 'relative',
        float: 'left',
        width: '100%',
        minHeight: '210px',
        minWidth: '180px',
        overflow: 'hidden',
        marginTop: "10px",
        '@media only screen and (min-width : 1224px)': {
            minHeight: '310px !important',
            minWidth: '250px',
        }
    },
    root: {
        'flex-shrink': 'unset',
        alignItems: 'center',
    },
    gridList: {
        flexWrap: 'nowrap',
        'overflowX': 'auto',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    searchBar: {
        "margin": "10px",
        minWidth: '300px',
        height: '100%',
        borderRadius: '15px',
        flex: 1,
        backgroundColor: 'rgba(0,0,0,.1)',
    },
    generalMenuCategoryItemsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginLeft: '8px',
        marginRight: '16px',
        paddingBottom:'40px'
    },
    loading: {
        textAlign: 'center',
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
});

interface IProps {
    classes: any;
    utility: Utility;
    searchValue: string;
    allOnlineMenuItems: any;//IMenuCategoryItem[];
    selectedMenuProduct: (body: any) => void;
    openProductDetailModel: () => void;
    closeProductDetailModel: () => void;
    menus: any;
    searchLoader: boolean;
}
interface IPageState {
    title: string;
}

class HomeSearch extends React.Component<IProps, IPageState>  {
    constructor(props: any) {
        super(props);
        this.state = {
            title: "Home Search All",
        }
    }
    public handleProduct = async (payload: any) => {
        payload.isEditCartItem = false;
        this.props.selectedMenuProduct(payload);
        this.props.openProductDetailModel();
    }
    public render() {
        const { classes, utility, allOnlineMenuItems,menus, searchValue, searchLoader } = this.props;
        return (
            <div className={classes?.container}>
                
                {searchLoader ?  <div className={classes.loading} style={{alignItems: 'center'}}><CircularProgress color='info' /></div>
                    : <div className={classes?.generalMenuCategoryItemsContainer}>
                        {!!menus?.length ? menus?.map((menuCategoryItem: IMenuCategoryItem) => (
                            (searchName(menuCategoryItem?.name || "", searchValue) || searchName(menuCategoryItem?.description || "", searchValue)) &&
                            <EnhancedFeatureCard
                                    key={menuCategoryItem.id}
                                    menuCategoryItem={menuCategoryItem}
                                    onClick={() => { this.handleProduct(menuCategoryItem) }}
                                />
                        )) : <div >
                                <Typography align='center' component="span"
                                    variant="body1"
                                    color="textPrimary">No Product Found</Typography>
                            </div>}
                             <div className={classes.emptyFeatureCard} />
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                             <div className={classes.emptyFeatureCard}/>
                            <div className={classes.emptyFeatureCard}/>
                            
                    </div>
                }
            </div>
        )
    }

}

const mapStateToProps = (state: any, props: any) => ({
    utility: state.utility,
    allOnlineMenuItems: state.menu.allOnlineMenuItems,
    searchValue: state.menu.searchValue,
    menus: state.items.menuItems
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({
        // selectedMenuProduct: AppMenuActionCreators.selectedCategoryMenuProduct,
        // openProductDetailModel: openProductDetailPageModel,
        // closeProductDetailModel: closeProductDetailPageModel,
    }, dispatch);
const HomeSearchView = withStyles(styles as any)(HomeSearch);
export default connect(mapStateToProps, mapDispatchToProps)(HomeSearchView);
import { coverApi } from '../../api';
import { authorize, orderAPIs } from '../../services/ApiVariables';
import { ActionType, IAppAction } from './Helpers';
import { Dispatch } from 'redux';
import { AppState } from '../../state/AppState';
import { Spinner } from '../../state/Spinner';
import IOrder from '../../models/IOrder';
import IOrderItem from '../../models/IOrderItem';
import IOrderListEntry from '../../models/IOrderListEntry';
import IOrderCloneSetting from '../../models/IOrderCloneSetting';

export const getWithoutStore = (orderId: number) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });
    return api(orderAPIs.orderGet(orderId))
        .then((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            return response;
        })
        .catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            return response;
        });
}

export const get = (orderId: number) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });
    return orderGet(orderId, dispatch, api)
        .then((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            return response;
        })
        .catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            return response;
        });
}

export const getIfIncomplete = (orderId: number) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return orderGet(orderId, dispatch, api)
        .then((order: IOrder) => {
            if (order) {
                if (!order.orderStatus || order.orderStatus.systemStatus.code !== 'Created' || (order.total && order.total > 0) && (order?.balanceDue !== undefined && order?.balanceDue <= 0)) {
                    dispatch({ type: ActionType.ORDER_SET_DEFAULT });
                }
            }

            return order;
        });
}

export const post = (order: IOrder) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });
    return orderPost(order, dispatch, api)
        .then((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            return response;
        })
        .catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            return response;
        });
}

export const put = (order: IOrder, placeOrder: boolean = false) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    //dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });
    dispatch({ type: ActionType.ORDER_SET, payload: order });
    // return orderPut(order, dispatch, api, placeOrder)
    //     .then((response: any) => {
    //         dispatch({ type: ActionType.CLOSE_SPINNER });
    //         return response;
    //     })
    //     .catch((response: any) => {
    //         dispatch({ type: ActionType.CLOSE_SPINNER });
    //         return response;
    //     });
}

export const addItem = (orderItem: IOrderItem) => async (dispatch: Dispatch<IAppAction>, getState: () => AppState) => {
    const { order } = getState();
    let updatedOrder: any = { ...order, locationId: '123' }; // oidc.user.profile.location_id
    if (order && !order.orderItems) {
        updatedOrder.orderItems = [];
    }
    const isNewOrder = !order?.id;
    if (isNewOrder) {
        await orderPostStatic(updatedOrder, dispatch);
    }
    const newItem = orderItem.id;
    const updatedOrderItemCheck = updatedOrder?.orderItems?.find((item: any) => item.id === orderItem.id);
    if (newItem && !updatedOrderItemCheck) {
        updatedOrder?.orderItems?.push(orderItem);
    } 
    else{
       updatedOrder={...updatedOrder, orderItems:updatedOrder.orderItems.map((item: any)=>item.id===orderItem.id?{...orderItem}:item)}
    }
    // else {
    //     const updatedOrderItem = updatedOrder?.orderItems?.find((item: any) => item.menuCategoryItemId === orderItem.menuCategoryItemId);
    //     if (updatedOrderItem) {
    //         updatedOrderItem.quantity = orderItem.quantity;
    //         updatedOrderItem.orderItemModifiers = orderItem.orderItemModifiers;
    //         updatedOrderItem.notes = orderItem.notes;
    //     }
    // }
    dispatch({ type: ActionType.ORDER_SET, payload: updatedOrder });
};

export const removeItem = (itemId: number) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    // dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });

    const { order } = getState();
    const updatedOrder: any = { ...order };
    const orderItemToDelete = updatedOrder?.orderItems?.filter((item: any) => item.id !== itemId);
    updatedOrder.orderItems = [...orderItemToDelete];
    dispatch({ type: ActionType.ORDER_SET, payload: updatedOrder });
    // if (orderItemToDelete) {
    //     orderItemToDelete.recordStatus = 'Deleted';
    // }

    // return orderPut(updatedOrder, dispatch, api)
    //     .then((response: any) => {
    //         orderGet(updatedOrder.id, dispatch, api)
    //             .then((getResponse: any) => {
    //               //  dispatch({ type: ActionType.CLOSE_SPINNER });
    //                 return getResponse;
    //             })
    //             .catch((getResponse: any) => {
    //             //    dispatch({ type: ActionType.CLOSE_SPINNER });
    //                 return getResponse;
    //             });
    //        // dispatch({ type: ActionType.CLOSE_SPINNER });
    //         return response;
    //     })
    //     .catch((response: any) => {
    //        // dispatch({ type: ActionType.CLOSE_SPINNER });
    //         return response;
    //     });
};

export const removeCart = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.CLEAR_CART });
};


export const clearUnavailableItems = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    const { order } = getState();

    return orderClearUnavailableItems(order?.id as any, dispatch, api).then((response: any) => {
        dispatch({ type: ActionType.ORDER_CLEAR_UNAVAILABLE_ITEMS });
    });
}

export const setDefault = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.ORDER_SET_DEFAULT });
};

export const onlineOrdersEnabled = (date?: Date) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    // dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });

    return api(orderAPIs.onlineOrdersEnabledGet(date ? date.toJSON() : null))
        .then((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            return response;
        })
        .catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            return response;
        });
};

export const getList = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return api(orderAPIs.getAllOldOrders())
    .then((response: any) => {
        dispatch({ type: ActionType.CLOSE_SPINNER });
        return response;
    }).catch((response: any) => {
        dispatch({ type: ActionType.CLOSE_SPINNER });
        return response;
    });
    
  /*   coverApi.getOrderList(user?.appUserDetails?.id || '')
        .then((response: any) => {
            const orders = response.parsedBody as IOrderListEntry[];
            return orders;
        })
        .catch((error: any) => {
            return error;
        }); */
}

export const cloneOrder = (orderId: number) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    const orderCloneSetting: IOrderCloneSetting = {
        includeFiles: false,
        includeNotes: false,
    }

    return coverApi.cloneOrder(orderId, orderCloneSetting)
        .then((response: any) => {
            if (!response) {
                return response;
            }

            const order = response.parsedBody as IOrder;
            dispatch({ type: ActionType.ORDER_SET, payload: order });

            return order;
        })
        .catch((error: any) => {
            return error;
        });
}

export const ApplyCoupon = (orderId: number, couponCode: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return coverApi.ApplyCoupon(orderId, couponCode)
        .then((response: any) => {
            if (!response) {
                return response;
            }

            const order = response.parsedBody as IOrder;
            dispatch({ type: ActionType.ORDER_SET, payload: order });

            return order;
        })
        .catch((error: any) => {
            return error;
        });
}

export const RemoveCoupon = (orderId: number) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return coverApi.RemoveCoupon(orderId)
        .then((response: any) => {
            if (!response) {
                return response;
            }

            const order = response.parsedBody as IOrder;
            dispatch({ type: ActionType.ORDER_SET, payload: order });

            return order;
        })
        .catch((error: any) => {
            return error;
        });
}

const orderPostStatic = (order: IOrder, dispatch: Dispatch<IAppAction>) => {
    order.id = Math.random() * 10;
    dispatch({ type: ActionType.ORDER_SET, payload: order });
};

const orderPost = (order: IOrder, dispatch: Dispatch<IAppAction>, api: any) => {
    return api(orderAPIs.orderPost(order))
        .then((response: any) => {
            dispatch({ type: ActionType.ORDER_SET, payload: response });
            return response;
        }).catch((response: any) => {
            dispatch({ type: ActionType.ORDER_SET_DEFAULT });
            return response;
        });
}

const orderPut = (order: IOrder, dispatch: Dispatch<IAppAction>, api: any, placeOrder: boolean = false) => {
    return api(orderAPIs.orderPut(order, placeOrder))
        .then((response: any) => {
            dispatch({ type: ActionType.ORDER_SET, payload: response });
            return response;
        }).catch((response: any) => {
            return response;
        });
}

const orderGet = (orderId: number, dispatch: Dispatch<IAppAction>, api: any) => {
    return api(orderAPIs.orderGet(orderId))
        .then((response: any) => {
            dispatch({ type: ActionType.ORDER_SET, payload: response });
            return response;
        }).catch((response: any) => {
            return response;
        });
}

const orderClearUnavailableItems = (orderId: number, dispatch: Dispatch<IAppAction>, api: any) => {
    return api(orderAPIs.clearUnavailableItems(orderId))
        .then((response: any) => {
            return response;
        }).catch((response: any) => {
            return response;
        });
}

export const assignOrgToLoggedInUser = (payload: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    // dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });
    return api(authorize.assignUserToOrg(payload))
        .then((response: any) => {
            // dispatch({ type: ActionType.CLOSE_SPINNER });
            return response;
        })
        .catch((error: any) => {
            // dispatch({ type: ActionType.CLOSE_SPINNER });
            return error;
        });
}

export const getUserByEmailId = (email: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    // dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });
    return api(authorize.getUserByEmailId(email))
        .then((response: any) => {
            dispatch({ type: ActionType.GET_APP_USER_DETAILS_SUCCESS, payload: response.data });
            return response;
        })
        .catch((error: any) => {
            dispatch({ type: ActionType.GET_APP_USER_DETAILS_FAILURE, payload: null });
            return error;
        });
}

export const registerUser = (payload: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    // dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner({ message: '' }) });
    return api(authorize.registerUser(payload))
        .then((response: any) => {
            dispatch({ type: ActionType.GET_APP_USER_DETAILS_SUCCESS, payload: Array.isArray(response.data) ? response.data[0] : response.data });
            return response;
        })
        .catch((error: any) => {
            dispatch({ type: ActionType.GET_APP_USER_DETAILS_FAILURE, payload: null });
            return error;
        });
}
export const getModifiersPrice = (modifiers: any) => {
    const selected = modifiers?.map((item: any) => item?.options?.filter((option: any) => option?.checked))?.reduce((acc: any, current: any) => acc.concat(current), []);
    const totalPrice = selected?.reduce(
      (accumulator: any, currentItem: any) => {
        return currentItem?.selectedSubOption!==null 
        ? (accumulator + currentItem.price + currentItem.subOptions?.find((subOpt: any)=> subOpt.id===currentItem.selectedSubOption)?.price) 
        : (accumulator + currentItem.price)}
        , 0);
    return totalPrice || 0
}

export const getReorderModifiersPrice = (modifiers: any) => {

  const selected = modifiers?.map((item: any) => item?.options?.filter((option: any) => option?.checked))
                            ?.reduce((acc: any, current: any) => acc.concat(current), []);

  let totalPrice = selected?.reduce((accumulator: any, currentItem: any) => {
      return currentItem?.selectedSubOption !== null
          ? (accumulator + currentItem.price + currentItem.subOptions?.find((subOpt: any) => subOpt.id === currentItem.selectedSubOption)?.price)
          : (accumulator + currentItem.price);
  }, 0) || 0;

  const hasCheckedField = modifiers.some((item: any) =>
    item?.options?.some((option: any) => option.hasOwnProperty('checked'))
  );
  if (totalPrice === 0 && !hasCheckedField) {
    totalPrice = modifiers?.reduce((accumulator: any, item: any) => {
      const optionsTotal = item.options.reduce((optAcc: any, option: any) => {
        const subOptionPrice = option.subOptions.reduce((subOptAcc: any, subOpt: any) => subOptAcc + (subOpt.price || 0), 0);
        return optAcc + option.price + subOptionPrice;
      }, 0);
      return accumulator + optionsTotal;
    }, 0) || 0;
  }

  return totalPrice;
};

export const getCheckedOptions = (items: any) => {
    return items
      .map((item: any) => item.options.filter((option: any) => option.checked))
      .reduce((acc: any, current: any) => acc.concat(current), []);
}

export const transformArrayFromModifiers = (modifiers: any) => {
  const transformModifiers = (modifiers: any) => {
    return modifiers
      .filter((modifier: any) => modifier.options.some((option: any) => option.checked))
      .map((item: any) => ({
        menuItemModifierId: item.id,
        options: item.options
          .filter((option: any) => option.checked)
          .map((option: any) => ({
            menuItemModifierOptionId: option.id,
            menuItemModifierSubOptionIds: option.selectedSubOption !== null
              ? [option.subOptions?.find((subOpt: any) => subOpt.id === option.selectedSubOption)?.id]
              : [],
          })),
      }));
  };

  // Transform function for reorder data
  const transformReorderData = (reorderData: any) => {
    return reorderData.map((item: any) => ({
      menuItemModifierId: item.menuItemModifierId,
      options: item.options.map((option: any) => ({
        menuItemModifierOptionId: option.menuItemModifierOptionId,
        menuItemModifierSubOptionIds: option.subOptions.map((subOpt: any) => subOpt.menuItemModifierSubOptionId), // Extract subOption IDs
      })),
    }));
  };

  // Process given modifiers
  let transformedData = transformModifiers(modifiers);

  // If transformedData is empty, use the fallbackModifiers array
  if (transformedData.length === 0 && modifiers) {
    transformedData = transformReorderData(modifiers);
  }
  transformedData = transformedData.filter((item: any) => item.menuItemModifierId !== undefined);

  return transformedData;
};
const appKey = `${window.location.host}_graze_`;

export const keys = {
  orderId: `${appKey}order_id`,
  orderType: `${appKey}order_type`,
  organizationId: `${appKey}organization_id`,
  locationId: `${appKey}location_id`,
  redirectPathname: `${appKey}redirect_pathname`,
  logo: `${appKey}logo`,
  contactInfo: `${appKey}contact_info`,
  lastOnlineOrder: `${appKey}last_online_order`,
  mainFontFamily: `${appKey}main_font_family`,
  imagelessLayoutMain: `${appKey}imagelessLayoutMain`,
  imagelessLayoutlight: `${appKey}imagelessLayoutlight`,
  imagelessLayoutContrast: `${appKey}imagelessLayoutContrast`,
  authAccessToken: `${appKey}auth-token`,
}

export function get<T>(key: string): T {

  let value = '';
  if (localStorage.getItem(key)) {
    const valueField: any = localStorage.getItem(key);
    value = JSON.parse(valueField);
  }
  return value as T;
}

export function save(key: string, value: any): void {
  localStorage.setItem(key, JSON.stringify(value));
}

export const remove = (key: string): void => {
  localStorage.removeItem(key);
};

import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
// import AppNavBar from './navigation/App.Bar';
import { ThemeProvider, responsiveFontSizes, Theme, CssBaseline } from '@mui/material';
import { createTheme, getContrastRatio, lighten } from '@mui/material/styles';
import * as LocalStorage from '../src/utilities/LocalStorage'
// import { AppState } from 'src/state/AppState';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { AppState } from './state/AppState';
import AppNavBar from './navigation/App.Bar';

declare module '@mui/material' {
    interface CustomTheme {
        imagelessLayout?: {
            palette?: {
                primary?: {
                    main?: string,
                    light?: string,
                    contrastText?: string,
                }
            }
        };
    }

    // tslint:disable-next-line
    interface Theme extends CustomTheme { }
    // tslint:disable-next-line
    interface ThemeOptions extends CustomTheme { }
}

// Augment the palette to include user's layout color selected in C2
declare module '@mui/material/styles' {
    interface Palette {
        layoutColor: Palette['primary'];
    }

    interface PaletteOptions {
        layoutColor?: PaletteOptions['primary'];
    }
}

// Update the Button's color options to include user's layout color selected in C2
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        layoutColor: true;
    }
}

const LAYOUT_TYPE_GOLD = 'Gold';
const LAYOUT_TYPE_STRAWBERRY = 'Strawberry';
const LAYOUT_TYPE_LIME = 'Lime';
const LAYOUT_COLOR_TYPE_MAIN = 'Main';
const LAYOUT_COLOR_TYPE_LIGHT = 'Light';
const LAYOUT_COLOR_TYPE_CONTRAST = 'Contrast';

let storedMainFontFamily = LocalStorage.get<string>(LocalStorage.keys.mainFontFamily);
let storedImagelessLayoutMain = LocalStorage.get<string>(LocalStorage.keys.imagelessLayoutMain);
let storedImagelessLayoutlight = LocalStorage.get<string>(LocalStorage.keys.imagelessLayoutlight);
let storedImagelessLayoutContrast = LocalStorage.get<string>(LocalStorage.keys.imagelessLayoutContrast);

function getImagelessLayoutColor(colorType: string, settingValue: string): string {
    const layoutTypeSplit = !!settingValue && typeof settingValue === 'string'
        ? settingValue.split('-')
        : '';
    const layoutType = layoutTypeSplit.length === 2
        ? layoutTypeSplit[1].trim()
        : '';

    switch (layoutType) {
        case LAYOUT_TYPE_GOLD:
            switch (colorType) {
                case LAYOUT_COLOR_TYPE_MAIN:
                    return '#FAB436';
                case LAYOUT_COLOR_TYPE_LIGHT:
                    return '#FFECCB';
                case LAYOUT_COLOR_TYPE_CONTRAST:
                    return '#0AAF60';
                default:
                    return '';
            }
        case LAYOUT_TYPE_STRAWBERRY:
            switch (colorType) {
                case LAYOUT_COLOR_TYPE_MAIN:
                    return '#E32A2A';
                case LAYOUT_COLOR_TYPE_LIGHT:
                    return '#FFC1C1';
                case LAYOUT_COLOR_TYPE_CONTRAST:
                    return '#0AAF60';
                default:
                    return '';
            }
        case LAYOUT_TYPE_LIME:
            switch (colorType) {
                case LAYOUT_COLOR_TYPE_MAIN:
                    return '#419A1F';
                case LAYOUT_COLOR_TYPE_LIGHT:
                    return '#DBF1D0';
                case LAYOUT_COLOR_TYPE_CONTRAST:
                    return '#0AAF60';
                default:
                    return '';
            }
        default:
            switch (colorType) {
                case LAYOUT_COLOR_TYPE_MAIN:
                    return '#FAB436';
                case LAYOUT_COLOR_TYPE_LIGHT:
                    return '#FFECCB';
                case LAYOUT_COLOR_TYPE_CONTRAST:
                    return '#0AAF60';
                default:
                    return '';
            }
    }
}

interface IProps {
    mainFontFamily: string;
    websiteIcon: string;
    imagelessLayoutMain: string;
    imagelessLayoutlight: string;
    imagelessLayoutContrast: string;
    organizationSettings: any;
};

interface IState {
    theme: Theme;
};

class App extends React.Component<IProps, IState> {
    public state = {} as IState;

    private buildMuiTheme = () => {
        const { organizationSettings } = this.props;
        const layoutColor = organizationSettings?.layoutColor;
        const theme = responsiveFontSizes(createTheme({
            palette: {
                primary: {
                    main: '#f3f3f6',
                    contrastText: '#000000',
                },
                secondary: {
                    light: '#ffffff',
                    main: '#000000',
                    contrastText: '#ffffff',
                },
                layoutColor: {
                    main: layoutColor,
                    contrastText: organizationSettings?.layoutColor && getContrastRatio(layoutColor, '#fff') > 4.5 ? '#fff' : '#000',
                },
                contrastThreshold: 3,
                tonalOffset: 0.2,
            },
            typography: {
                fontFamily: `"${storedMainFontFamily ? storedMainFontFamily : 'Yanone Kaffeesatz'}", Arial, Helvetica, sans-serif`,
                fontSize: 22,
            },
            imagelessLayout: {
                palette: {
                    primary: {
                        main: `${storedImagelessLayoutMain ? storedImagelessLayoutMain : '#FAB436'}`,
                        light: `${storedImagelessLayoutlight ? storedImagelessLayoutlight : '#FFECCB'}`,
                        contrastText: `${storedImagelessLayoutContrast ? storedImagelessLayoutContrast : '#0AAF60'}`,
                    }
                }
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: (theme) => ({
                        body: {
                            "*::-webkit-scrollbar": {
                                width: "5px",
                                background: "#ffffff",
                            },
                            "*::-webkit-scrollbar-thumb": {
                                background: layoutColor,
                                border: "8px solid",
                                borderColor: layoutColor,
                                borderRadius: "20px",
                            },
                            "*::-webkit-scrollbar-thumb:hover": {
                                background: layoutColor,
                            },
                            scrollbarWidth: "thin",
                            scrollbarColor: layoutColor,
                        },
                    }),
                },
            },
        }));

        this.setState({ theme });
    };

    public componentDidMount(): void {
        this.buildMuiTheme();
    }

    public componentDidUpdate = (prevProps: IProps) => {
        const {
            mainFontFamily,
            imagelessLayoutMain,
            imagelessLayoutlight,
            imagelessLayoutContrast,
        } = this.props;

        if ((!!mainFontFamily && mainFontFamily !== prevProps.mainFontFamily) ||
            (!!imagelessLayoutMain && imagelessLayoutMain !== prevProps.imagelessLayoutMain) ||
            (!!imagelessLayoutlight && imagelessLayoutlight !== prevProps.imagelessLayoutlight) ||
            (!!imagelessLayoutContrast && imagelessLayoutContrast !== prevProps.imagelessLayoutContrast)) {
            LocalStorage.save(LocalStorage.keys.mainFontFamily, mainFontFamily);
            storedMainFontFamily = mainFontFamily;
            LocalStorage.save(LocalStorage.keys.imagelessLayoutMain, imagelessLayoutMain);
            storedImagelessLayoutMain = imagelessLayoutMain;
            LocalStorage.save(LocalStorage.keys.imagelessLayoutlight, imagelessLayoutlight);
            storedImagelessLayoutlight = imagelessLayoutlight;
            LocalStorage.save(LocalStorage.keys.imagelessLayoutContrast, imagelessLayoutContrast);
            storedImagelessLayoutContrast = imagelessLayoutContrast;

            this.buildMuiTheme();
        }
    }

    public render() {
        const { theme } = this.state;
        const { websiteIcon, mainFontFamily } = this.props;

        if (!theme) {
            return null;
        }

        return (
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                        <Helmet>
                            {!!mainFontFamily &&
                                <link rel="stylesheet" href={`https://fonts.googleapis.com/css?family=${mainFontFamily}`} />
                            }
                            <link rel="icon" href={websiteIcon} type="image/x-icon" id="favicon" data-react-helmet="true" />
                        </Helmet>
                    <AppNavBar theme={theme} />
                </ThemeProvider>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        mainFontFamily: state?.organizations?.organizationSettings?.grazeFont,
        websiteIcon: state?.organizations?.organizationSettings?.grazeIcon || "/static/images/logos/defaultfavIconLogo.svg",
        organizationSettings: state?.organizations?.organizationSettings,
        imagelessLayoutMain: !!state.settings.SettingsKeyValue.GRAZE_LAYOUT ? getImagelessLayoutColor(LAYOUT_COLOR_TYPE_MAIN, state.settings.SettingsKeyValue.GRAZE_LAYOUT.value) : '#FAB436',
        imagelessLayoutlight: !!state.settings.SettingsKeyValue.GRAZE_LAYOUT ? getImagelessLayoutColor(LAYOUT_COLOR_TYPE_LIGHT, state.settings.SettingsKeyValue.GRAZE_LAYOUT.value) : '#FFECCB',
        imagelessLayoutContrast: !!state.settings.SettingsKeyValue.GRAZE_LAYOUT ? getImagelessLayoutColor(LAYOUT_COLOR_TYPE_CONTRAST, state.settings.SettingsKeyValue.GRAZE_LAYOUT.value) : '#0AAF60',
    };
}




export default connect(mapStateToProps, null)(App);

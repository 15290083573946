import { IAppAction, ActionType } from './../actions/Helpers';
import { Utility } from '../../state/Utility';

const initStateValue = {
    drawerOpen: false,
    alert: null,
    confirmBox: null,
    spinner: null,
    openSignInModel: false,
    openSignUpModel: false,
    openProductDetailPageModel: false,
}
export const UtilityReducer = (state: any = initStateValue, action: IAppAction): any => {
    switch (action.type) {
        case ActionType.OPEN_DRAWER:
            return {
                ...state,
                drawerOpen: true
            };
        case ActionType.CLOSE_DRAWER:
            return {
                ...state,
                drawerOpen: true
            };
        case ActionType.OPEN_SIGN_IN_MODEL:
            return {
                ...state,
                openSignInModel: true
            };
        case ActionType.OPEN_SIGN_UP_MODEL:
            return {
                ...state,
                openSignInModel: true
            };

        case ActionType.CLOSE_SIGN_IN_MODEL:
            return {
                ...state,
                openSignInModel: false
            };
        case ActionType.CLOSE_SIGN_UP_MODEL:
            return {
                ...state,
                openSignInModel: false
            };
        case ActionType.OPEN_CONFIRM_BOX:
            return {
                ...state,
                confirmBox: action.payload
            };
        case ActionType.CLOSE_CONFIRM_BOX:
            return {
                ...state,
                confirmBox: null
            };
        case ActionType.OPEN_SPINNER:
            return {
                ...state,
                spinner: action.payload
            };
        case ActionType.OPEN_ALERT:
            return {
                ...state,
                alert: action.payload
            };
        case ActionType.CLOSE_ALERT:
            return {
                ...state,
                alert: null
            };
        case ActionType.CLOSE_SPINNER:
            return {
                ...state,
                spinner: null
            };
        case ActionType.OPEN_PRODUCT_DETAIL_PAGE_MODEL:
            return {
                ...state,
                openProductDetailPageModel: true
            };
        case ActionType.CLOSE_PRODUCT_DETAIL_PAGE_MODEL:
            return {
                ...state,
                openProductDetailPageModel: false
            };
        default:
            return state;
    }
};
import * as React from 'react';
import { Box, Stack, Theme } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { showHtml } from '../services/HelperFunctions';
import IMenuCategoryItem from '../models/IMenuCategoryItem';
import ImgPlaceholder from './ImgPlaceholder';
import { withStyles } from '@mui/styles';

interface IEnhancedDealCardProps {
    onRequestSort?: (event: any, property: any) => any;
    onSelectAllClick?: any;
    classes?: any;
    selected?: number;
    count?: number;
    menuCategoryItem: IMenuCategoryItem;
    onClick: () => void;
}

class EnhancedDealCard extends React.Component<IEnhancedDealCardProps> {

    public render() {
        const { classes, menuCategoryItem, onClick } = this.props;
        const { description, imageUrl, name } = menuCategoryItem;

        return (
            <div className={classes.deal_container} onClick={() => onClick()}>
                <div className={classes.left_container}>
                    <Card className={classes.cardStyle}>
                        <ImgPlaceholder
                            className={classes.media}
                            name={name}
                            imageUrl={imageUrl}
                        />
                    </Card>
                </div>
                <div className={classes.dash_container}>
                    <div className={classes.half_circle_top} />
                    <div className={classes.vertical_dash} />
                    <div className={classes.half_circle_bottom} />

                </div>
                <div className={classes.right_container} >
                    <Box>
                        <Stack ml={1} mt={0} pb={0}>
                            <Typography sx={{
                                fontSize: 20,
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}>
                                {showHtml(name ? name : '')}
                            </Typography>
                        </Stack>
                        <Stack ml={1} mr={1}>
                            <Typography sx={{
                                fontSize: { xs: "18px", sm: "18px", md: "20px", lg: "20px" },
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: { xs: "3", sm: "3", md: "3", lg: "5" },
                                WebkitBoxOrient: "vertical",
                            }}>
                                {showHtml(description ? description : '')}
                            </Typography>
                        </Stack>
                    </Box>
                </div>
            </div>
        );
    }
}

const styles = (theme: Theme) => ({
    "dotclock": {
        "height": "20px",
        "width": "20px",
        "position": "relative"
    },
    titleTitleText: {
        fontSize: 20,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    titleDescriptionText: {
        fontSize: 15,
        fontWeight: 300,
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 5,
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('md')]: {
            '-webkit-line-clamp': 3,
            paddingRight: 5,
        },
    },
    orderButton: {
        width: '100%',
        fontSize: 20,
        borderRadius: '50px',
        bottom: '0px',
    },
    orderButtonLabel: {
        margin: '-10px',
        fontWeight: 400,
    },
    "dot": {
        "height": "20px",
        "width": "20px",
        "backgroundColor": "#bbb",
        "border": "3px  #f3f3f6",
        "borderRadius": "50%",
        "display": "inline-block",
        "margin": "0 auto 20px",
        "position": "absolute",
        "left": "0"
    },
    "priceWrap": {
        "display": "flex",
        "flexDirection": "row",
        "margin": "5px"
    },
    "price": {
        "fontSize": "19px",
        "color": "gray",

    },
    "discountPrice": {
        "fontSize": "18px",
        "color": '#fa5176',
        "margin-right": "5px",
    },
    "bestseller": {
        "padding": "3px",
        "color": "black",
        "fontSize": "14px",
        "position": "absolute",
        "bottom": "0",
        "margin-left": "auto",
        "margin-right": "auto",
        "width": "90%"
    },
    "eta": {
        "padding": "3px",
        "color": theme.palette.secondary.contrastText,
    },
    "media": {
        "height": "100%",
        "paddingTop": "120%", // 16:9
    },
    "cardStyle": {
        boxShadow: 'none',
        "flexWrap": "nowrap",
        "width": "95px",
        "margin": "5px",
        "alignItems": "center",
        '@media only screen and (min-device-width: 320px) and (max-device-width : 480px)': {
        },
        /* iPads (portrait and landscape) ----------- */
        '@media only screenand (min-device-width : 768px) and (max-device-width : 1024px)': {

        },
        /* Desktops and laptops ----------- */
        '@media only screen and (min-width : 1224px)': {
            width: '30vw',
        }
    },
    "title": {
        "fontSize": "14px",
        "color": "black",
        "margin": "0",
        marginTop: 9,

    },

    "productWrap": {
        "display": "flex",
        "flexDirection": "row",
        "justify-content": "space-between",
        "margin": "1px"
    },
    "restaurantWrap": {
        "display": "flex",
        "flexDirection": "row",
        "justify-content": "space-between",
        "margin": "3px"
    },
    "restaurant": {
        "fontSize": "16px",
        "color": 'gray',
        "margin": '1px'
    },
    "status": {
        "color": "#3bd4e4",
        "fontSize": "14px",
        "margin": "1px"
    },
    "deal_container": {
        "display": "flex",
        "height": "150px",
        "width": "285px",
        "borderRadius": "5%",
        "margin-left": "5px",
        "position": "relative",
        "background-color": "white",
        /* iPads (portrait and landscape) ----------- */
        '@media only screenand (min-device-width : 768px) and (max-device-width : 1024px)': {

        },
        /* Desktops and laptops ----------- */
        '@media only screen and (min-width : 1224px)': {
            width: '390px',

            "height": '212px'
        }
    },
    "left_container": {
        "display": "flex",
        "width": "40%",
        "borderTopLeftRadius": "5%",
        "borderBottomLeftRadius": "5%",
    },
    "right_container": {
        "width": "60%",
        "borderTopRightRadius": "5%",
        "borderBottomRightRadius": "5%",
        /* Desktops and laptops ----------- */
        '@media only screen and (min-width : 1224px)': {
            "padding-left": '14px',
            "padding-right": '14px',
            'margin-top': '6px'
        }
    },
    "half_circle_bottom": {
        "width": "10px",
        "height": "5px",
        "backgroundColor": "#f3f3f6",
        "borderTopLeftRadius": "15px",
        "borderTopRightRadius": "15px",
        // "border": "1px solid gray",
        "borderBottom": "0",
        "background-color": "#f3f3f6",
        '@media only screen and (min-width : 1224px)': {
            "width": "15px",
            "height": "7.5px",

        }
    },
    "half_circle_top": {
        "width": "10px",
        "height": "5px",
        "backgroundColor": "#f3f3f6",
        "borderBottomLeftRadius": "15px",
        "borderBottomRightRadius": "15px",
        "background-color": "#f3f3f6",
        "borderTop": "0",
        '@media only screen and (min-width : 1224px)': {
            "width": "15px",
            "height": "7.5px",

        }
    },
    "vertical_dash": {
        "border": "3px dashed #f3f3f6",
        "borderStyle": "none dashed none none",
        "width": "7px",
        "height": "142px",
        /* Desktops and laptops ----------- */
        '@media only screen and (min-width : 1224px)': {
            "width": "9px"
            , "height": '197.5px'

        }

    },
    "dash_container": {
        "align": "center",
        "width": "100%",
        "height": "100%",
        "position": "absolute",
        "left": "35%",
        '@media only screen and (min-width : 1224px)': {
            "left": "40%",

        }
    },

});
export default withStyles(styles as any)(EnhancedDealCard as any) as any;


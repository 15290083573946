import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Theme,
  createStyles
} from '@mui/material/styles';
import { bindActionCreators, Dispatch } from 'redux';
import * as AppActionCreators from '../../store/actions/App.Actions';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import * as LocalStorage from '../../utilities/LocalStorage';
import Grid from '@mui/material/Grid';
import LockIcon from '@mui/icons-material/Lock';
import { WithStyles, withStyles } from "@mui/styles";
import Typography from '@mui/material/Typography';
import SimpleReactValidator from 'simple-react-validator';
// import { withRouter } from "react-router-dom";
import { AppState } from '../../state/AppState';
import { normalizePhoneInput } from '../../services/HelperFunctions';
// import { customerGrantType } from '/auth/userManager';
// import { User } from 'oidc-client';

const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

const styles = (theme: Theme) => createStyles({
  root: {
    "background-color": "#f3f3f6",
    '@media only screen and (min-width : 1224px)': {
      'padding-left': '200px',
      'padding-right': '200px',
      'padding-bottom': '30px',

    }
  },
  linkText: {
    color: '#0b22c3',
    cursor: 'pointer'
  },
  paper: {
    // marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

interface ISignUpProps extends WithStyles<typeof styles> {
  user: any;
  history: any;
  dispatch: Dispatch;
  openSignInPopUp: () => void;
  handleRegisterCustomer: (body: string, organizationId: string) => any;
  handleClickOpenSignInModel: () => void;
  handleOpenSignUpModel: () => void;
  handleCloseSignUpModel: () => void;
}

interface IPageState {
  FirstName: string;
  LastName: string;
  Email: string;
  Password: string;
  Phone: string;
  ConfirmPassword: string;
  formError: any;
}

class SignUp extends Component<ISignUpProps, IPageState> {
  public validator: SimpleReactValidator = new SimpleReactValidator();
  constructor(props: any) {
    super(props);
    this.state = {
      FirstName: '',
      LastName: '',
      Email: '',
      Password: '',
      Phone: '',
      ConfirmPassword: '',
      formError: { errorCode: '', errorDescription: '', errorFieldvalue: null }
    }
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  private handlePhone = ({ target: { value } }: any) => {
    this.setState({ Phone: normalizePhoneInput(value, this.state.Phone) });
  };

  private onSignInHandler() {
    this.props.handleCloseSignUpModel();
    this.props.handleClickOpenSignInModel();
  }

  private onhandleChange = (event: any) => {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value
    });
  }

  private handleRegister = async (event: any) => {
    event.preventDefault();
    const { FirstName, LastName, Email, Password, Phone, ConfirmPassword } = this.state;

    if (this.validator.allValid() && (Password === ConfirmPassword) && passwordReg.test(Password) && (this.state.formError.errorFieldvalue !== Email)) {
      const payload = {
        "FirstName": FirstName.trim(),
        "LastName": LastName.trim(),
        "Email": Email,
        "Password": Password,
        "Phone": Phone,
        "ConfirmPassword": ConfirmPassword,
      }

      const response = await this.props.handleRegisterCustomer(JSON.stringify(payload), this.props.user.profile.organization_id);

      if (response.length > 0) {
        const errorResponse = response[0];
        const { code, description } = errorResponse;
        const formError = { ...this.state.formError, errorCode: code, errorDescription: description, errorFieldvalue: code === "DuplicateUserName" ? Email : null }
        this.setState(() => ({ formError }));
      } else {
        const pathname: string = window.location ? window.location.pathname : '';
        LocalStorage.save(LocalStorage.keys.redirectPathname, pathname);

        this.props.handleCloseSignUpModel();
        // const userManager = customerGrantType(Email, Password, true);
        // userManager.signinRedirect();
      }


    } else {
      this.validator.showMessages();
    }
  }

  public render() {
    const { classes } = this.props;
    const { FirstName, LastName, Email, Password, Phone, ConfirmPassword, formError } = this.state;
    const { errorCode, errorFieldvalue } = formError
    this.validator.purgeFields();

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} sm={12}>
              <TextField
                name="FirstName"
                autoComplete="given-name"
                variant="outlined"
                required={true}
                fullWidth={true}
                value={FirstName}
                onChange={(event: any) => this.onhandleChange(event)}
                onBlur={() => this.validator.message('FirstName', typeof FirstName === "string" ? FirstName : FirstName,
                  'required|alpha_num_space'
                )}
                id="firstName"
                label="First Name"
                autoFocus={true}
              />
              {this.validator.message('First Name', FirstName, 'required|alpha_num_space')}
            </Grid>
            <Grid item={true} xs={12} sm={12}>
              <TextField
                name="LastName"
                autoComplete="family-name"
                variant="outlined"
                required={true}
                fullWidth={true}
                id="lastName"
                label="Last Name"
                value={LastName}
                onChange={(event: any) => this.onhandleChange(event)}
              />
              {this.validator.message('Last Name', LastName, 'required|alpha_num_space')}
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                name="Phone"
                autoComplete="tel"
                variant="outlined"
                required={true}
                fullWidth={true}
                id="phone"
                label="Phone Number"
                value={Phone}
                placeholder="(xxx) xxx-xxxx"
                onChange={this.handlePhone}
                onBlur={() => this.validator.showMessageFor('phone')}
              />
              {this.validator.message('phone number', Phone, 'required|phone')}
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                name="Email"
                autoComplete="username"
                variant="outlined"
                required={true}
                fullWidth={true}
                id="email"
                label="Email Address"
                value={Email}
                onChange={(event: any) => this.onhandleChange(event)}
                onBlur={() => this.validator.showMessageFor('email')}
              />
              {this.validator.message('email', Email, 'required|email')}
              {(errorCode === "DuplicateUserName" && errorFieldvalue === Email) ? <Typography className="srv-validation-message"> {errorFieldvalue} is already taken.</Typography> : ''}
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                name="Password"
                autoComplete="new-password"
                variant="outlined"
                required={true}
                fullWidth={true}
                label="Password"
                type="password"
                id="password"
                value={Password}
                onChange={(event: any) => this.onhandleChange(event)}
                onBlur={() => this.validator.message('password', typeof Password === "string" ? Password : Password,
                  'required|min:8'
                )}
              />
              {(Password !== "" && !passwordReg.test(Password)) && <Typography className="srv-validation-message"> The Password should min 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character.</Typography>}
              {this.validator.message('Password', Password, 'required|min:8')}

            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                name="ConfirmPassword"
                autoComplete="off"
                variant="outlined"
                required={true}
                fullWidth={true}
                id="confirmPassword"
                type="password"
                label="Confirm Password"
                value={ConfirmPassword}
                onChange={(event: any) => this.onhandleChange(event)}
                onBlur={() => this.validator.showMessageFor('ConfirmPassword')}
              />
              {this.validator.message('Confirm Password', ConfirmPassword, 'required|min:8')}
              {(Password !== "" && ConfirmPassword !== "") && (Password !== ConfirmPassword) && <Typography className="srv-validation-message">Your password and confirmation password do not match.</Typography>}
            </Grid>
            {/* <Grid item={true} xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
          </Grid>
          <Button
            type="button"
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={(event: any) => this.handleRegister(event)}
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container={true}>
            <Grid item={true}>
              <Typography onClick={() => this.onSignInHandler()} className={classes.linkText}>
                Already have an account? Sign in
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>

    );
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state?.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    handleRegisterCustomer: AppActionCreators.handleRegisterCustomer,
    handleClickOpenSignInModel: AppActionCreators.handleClickOpenSignInModel,
    handleCloseSignInModel: AppActionCreators.handleCloseSignInModel,
    handleOpenSignUpModel: AppActionCreators.handleOpenSignUpModel,
    handleCloseSignUpModel: AppActionCreators.handleCloseSignUpModel,
  }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignUp));
